import { strings } from "./../../services/Localization";

export const transformErrors = (schema: any) => {
    return (errors: any) => {
        return errors.map((error: any) => {

            const ErrorProperty = error.property.replace(/[[][0-9]+]/g, "").substr(1).split(".");
            let FieldName = ErrorProperty[ErrorProperty.length - 1];

            if (ErrorProperty.length > 0) {
                let Property = schema.properties;
                if (ErrorProperty.length === 1) {
                    Property = Property[ErrorProperty];
                    if ((Property !== undefined) && (Property.title !== undefined)) {
                        FieldName = Property.title;
                    }
                } else {
                    ErrorProperty.map((value, index) => {
                        if (index < (ErrorProperty.length - 1)) {
                            Property = Property[value].items.properties;
                        } else if ((Property !== undefined) && (Property[value] !== undefined) &&
                            (Property[value].title !== undefined)) {
                                FieldName = Property[value].title;
                        }
                    });
                }
            }

            const ErrorName = `FORM_ERROR_MESSAGE_${error.name.toUpperCase()}`;
            if (((error.name === "maxItems") || (error.name === "minItems") ||
            (error.name === "minLength") || (error.name === "maxLength")) &&
                (strings[ErrorName] !== undefined)) {
                error.message = strings.formatString(
                    strings[ErrorName], {
                    limit: error.params.limit,
                });
            } else if ((error.name === "pattern") && (strings[ErrorName] !== undefined)) {
                error.message = strings.formatString(
                    strings[ErrorName], {
                    pattern: error.params.pattern,
                });
            } else if (((error.name === "minimum") || (error.name === "maximum")) &&
                (strings.FORM_ERROR_MESSAGE_MINIMUM_MAXIMUM !== undefined)) {
                error.message = strings.formatString(
                    strings.FORM_ERROR_MESSAGE_MINIMUM_MAXIMUM, {
                    comparison: error.params.comparison,
                    limit: error.params.limit,
                });
            } else if ((error.name === "uniqueItems") && (strings[ErrorName] !== undefined)) {
                error.message = strings.formatString(
                    strings[ErrorName], {
                    itemOne: error.params.j + 1,
                    itemTwo: error.params.i + 1,
                });
            } else if (error.name === "type") {
                const TypeErrorLocation = `${ErrorName}_${error.params.type.toUpperCase()}`;
                if (strings[TypeErrorLocation] !== undefined) {
                    error.message = strings[TypeErrorLocation];
                }
            } else {
                if (strings[ErrorName] !== undefined) {
                    error.message = strings[ErrorName];
                }
            }

            error.stack = `${FieldName} ${error.message}`;

            if ((error.name === "anyOf") || (error.name === "enum")) {
                error.message = "";
            }

            return error;
        });
    };
};

export const JsonUISchema = (schema: any) => {
    if (schema.name === "DeviceProfiles") {
        return {
            DefaultDeviceClass: {
                classNames: "required_custom_message",
            },
            MACVersion: {
                classNames: "required_custom_message",
            },
            MaxEIRP: {
                classNames: "required_custom_message",
            },
            RFRegion: {
                classNames: "required_custom_message",
            },
            RegParamsRevision: {
                classNames: "required_custom_message",
            },
            activation: {
                classNames: "required_custom_message",
            },
            dl_max_num_mac_cmd: {
                classNames: "required_custom_message",
            },
            dl_max_size_app_payload: {
                classNames: "required_custom_message",
            },
            dl_max_size_mac_cmd: {
                classNames: "required_custom_message",
            },
            dl_max_size_phy_payload: {
                classNames: "required_custom_message",
            },
            factory_presets: {
                classNames: "required_custom_message required_position",
                items: {
                    channels: {
                        items: {
                            dr_min: {
                                classNames: "full_width",
                            },
                        },
                    },
                    dl_dwell_time: {
                        "ui:placeholder": "Choose an option",
                    },
                    ul_dwell_time: {
                        "ui:placeholder": "Choose an option",
                    },
                },
            },
        };
    } else if (schema.name === "ConnectivityProfiles") {
        return {
            allowed_duty_cycle: {
                classNames: "required_custom_message",
            },
            dl_packet_expiry_time_hours: {
                classNames: "required_custom_message",
            },
            max_dl_bytes_per_period: {
                classNames: "required_custom_message",
            },
            max_dl_msgs_per_period: {
                classNames: "required_custom_message",
            },
            max_dl_queue_length: {
                classNames: "required_custom_message",
            },
            max_ul_bytes_per_period: {
                classNames: "required_custom_message",
            },
            max_ul_msgs_per_period: {
                classNames: "required_custom_message",
            },
            period_hours: {
                classNames: "required_custom_message",
            },
            ul_packet_expiry_time_hours: {
                classNames: "required_custom_message",
            },
        };
    } else if (schema.name === "RoamingProfiles") {
        return {
            passive_roaming_allowed: {
                classNames: "required_custom_message",
            },
        };
    } else if (schema.name === "QOSProfile") {
        return {
            additional_snr_margin_join: {
                classNames: "required_custom_message",
            },
            avg_window_size: {
                classNames: "required_custom_message",
            },
            delay_between_two_nbrep_crtl_steps: {
                classNames: "required_custom_message",
            },
            delay_between_two_pwr_crtl_steps: {
                classNames: "required_custom_message",
            },
            frame_error_rate_limit_for_fsk: {
                classNames: "required_custom_message",
            },
            gateway_aggregation_time: {
                classNames: "required_custom_message",
            },
            max_dr: {
                classNames: "required_custom_message",
            },
            max_dr_steps_for_adr: {
                classNames: "required_custom_message",
            },
            max_dr_steps_for_adr_on_join: {
                classNames: "required_custom_message",
            },
            max_fer_pct: {
                classNames: "required_custom_message",
            },
            max_txp: {
                classNames: "required_custom_message",
            },
            max_txp_index: {
                classNames: "required_custom_message",
            },
            max_unconf_ul_rep: {
                classNames: "required_custom_message",
            },
            min_dr: {
                classNames: "required_custom_message",
            },
            min_txp: {
                classNames: "required_custom_message",
            },
            min_txp_index: {
                classNames: "required_custom_message",
            },
            min_unconf_ul_rep: {
                classNames: "required_custom_message",
            },
            region: {
                classNames: "required_custom_message",
            },
            rssi_margin_db: {
                classNames: "required_custom_message",
            },
            snr_margin_db: {
                classNames: "required_custom_message",
            },
            target_redundancy: {
                classNames: "required_custom_message",
            },
            txp_select: {
                classNames: "required_custom_message",
            },
        };
    } else if (schema.name === "ChannelProfiles") {
        return {
            adr_ack_delay: {
                classNames: "required_custom_message",
            },
            adr_ack_limit: {
                classNames: "required_custom_message",
            },
            channels: {
                "classNames": "required_custom_message",
                "items": {
                    dr_min: {
                        classNames: "full_width",
                    },
                },
                "ui:disabled": ((schema.hasOwnProperty("values")) &&
                schema.values.hasOwnProperty("use_automatic_freq_selection") &&
                (schema.values.use_automatic_freq_selection === true)),
            },
            dl_dwell_time: {
                "classNames": "required_custom_message",
                "ui:placeholder": "Choose an option",
            },
            mask_125khz: {
                classNames: "required_custom_message",
            },
            mask_500khz: {
                classNames: "required_custom_message",
            },
            max_num_of_ul_between_rejoin_req: {
                classNames: "required_custom_message",
            },
            max_time_between_rejoin_req: {
                classNames: "required_custom_message",
            },
            min_lorawan_spec_required: {
                classNames: "required_custom_message",
            },
            ping_slot_dr: {
                classNames: "required_custom_message",
            },
            ping_slot_freq_arr_mhz: {
                classNames: "required_custom_message",
            },
            rx1_dr_offset: {
                classNames: "required_custom_message",
            },
            rx2_dr: {
                classNames: "required_custom_message",
            },
            rx2_freq_arr_mhz: {
                classNames: "required_custom_message",
            },
            rx_delay: {
                classNames: "required_custom_message",
            },
            rx_window_for_linkadrreq: {
                classNames: "required_custom_message",
            },
            rx_window_priority: {
                classNames: "required_custom_message",
            },
            rx_window_priority_rx1_sub_sec_duration: {
                classNames: "required_custom_message",
            },
            ul_dwell_time: {
                "classNames": "required_custom_message",
                "ui:placeholder": "Choose an option",
            },
            use_automatic_freq_selection: {
                classNames: "Legend_Font_style",
            },
        };
     } else {
        return {};
    }
};

const ObjectLocalization = (obj, location) => {
    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            const StringLocation = `${location.toUpperCase()}_${property.toUpperCase()}`;
            if (obj[property].constructor === Object && obj[property] !== null) {
                ObjectLocalization(obj[property], StringLocation);
            } else {
                if (((property === "title") || (property === "description")) &&
                    (strings[StringLocation] !== undefined)) {
                        obj[property] = strings[StringLocation];
                }
            }
        }
    }
    return obj;
};

export const TranslateForm = (profile, schema) => {
    return ObjectLocalization(schema, profile);
};

export const SchemaValidation = (FormDataValues, OldFormDataValues, ValidationProps) => {
    return new Promise((resolve) => {
        if (ValidationProps !== undefined) {
            const NewArray: any = [];
            let ValueChanged = false;
            (function ValidateProp(FormData, OldFormData, Validation, ValidationProp) {
                if ((FormData !== undefined) && (FormData.constructor === Array) && (FormData.length > 0)) {
                    FormData.forEach((value, index) => {
                        ValidateProp(value, OldFormData[index], Validation, ValidationProp);
                    });
                } else if ((FormData !== undefined) && (FormData.constructor === Object) && (FormData !== null)) {
                    for (const key in FormData) {
                        if (FormData.hasOwnProperty(key)) {
                            let Prop: any = key;
                            if (ValidationProp !== undefined) {
                                Prop = `${ValidationProp}.${key}`;
                            }
                            if ((OldFormData !== undefined) && (OldFormData[key] !== undefined)) {
                                ValidateProp(FormData[key], OldFormData[key], Validation, Prop);
                            } else {
                                ValidateProp(FormData[key], OldFormData, Validation, Prop);
                            }
                        }
                    }
                } else {
                    if ((FormData !== undefined)) {
                        if (Validation.includes(ValidationProp)) {
                            if (FormData !== OldFormData) {
                                ValueChanged = true;
                            }
                            NewArray.push({ Property: ValidationProp, Value: FormData });
                        }
                    }
                }
            })(FormDataValues, OldFormDataValues, ValidationProps);

            if (ValueChanged === true) {
                resolve(NewArray);
            } else {
                resolve(undefined);
            }
        }
    });
};

const ChangeDeepValue = (formData, formSchema) => {
    formSchema = formSchema.items.properties;
    return new Promise(async (resolve) => {
        const NewData = await formData.map((FormDataProp) => {
            Object.keys(FormDataProp).map((property) => {
                if (FormDataProp.hasOwnProperty(property)) {
                    if ((FormDataProp[property] !== undefined) && (FormDataProp[property].constructor === Array)) {
                        ChangeDeepValue(FormDataProp[property], formSchema[property]);
                    } else {
                        if (formSchema.hasOwnProperty(property)) {
                            if (formSchema[property].hasOwnProperty("enum")) {
                                const Enum = formSchema[property].enum;
                                if (Enum.includes(FormDataProp[property]) === false) {
                                    return FormDataProp[property] = formSchema[property].default || undefined;
                                } else {
                                    return FormDataProp[property];
                                }
                            } else {
                                return FormDataProp[property];
                            }
                        } else {
                            return delete FormDataProp[property];
                        }
                    }
                }
            });
        });
        Promise.all(NewData).then((values) => {
            if (values.length > 0) {
                values.map((value) => {
                    resolve(value);
                });
            }
            resolve(undefined);
        });
    });
};

export const ChangeValueToDefault = (formData, formSchema) => {
    return new Promise((resolve) => {
        if (Object.keys(formData).length > 0) {
            for (const property in formData) {
                if (formData.hasOwnProperty(property)) {
                    if (formData[property] !== undefined) {
                        if (formData[property].constructor === Array) {
                            ChangeDeepValue(formData[property], formSchema[property]);
                        } else {
                            if (formSchema.hasOwnProperty(property)) {
                                if (formSchema[property].hasOwnProperty("enum")) {
                                    const Enum = formSchema[property].enum;
                                    if (Enum.includes(formData[property]) === false) {
                                        formData[property] = formSchema[property].default || undefined;
                                    }
                                }
                            } else {
                                delete formData[property];
                            }
                        }
                    }
                } else {
                    resolve(formData);
                }
            }
            resolve(formData);
        } else {
            resolve(formData);
        }
    });
};
