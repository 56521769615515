var app = angular.module('dassUiModule');

function OrganizationListController(UserService, ToastService, MessageService, $filter, $uibModal, $log) {
	const $translate = $filter("translate");

	let vm = this;
	
	vm.workingCount = 0;
	vm.numberOfOrganizations = 0;
	vm.have_license = false;
	
	UserService.getUsers('organization').then(
		(organizations) => {
				vm.numberOfOrganizations = organizations.length;
			}
		);

	vm.updateDataType = (userType) => {
		vm.userType = userType;
		let actualType = 'organizations';
		if (userType == 'organization') {
			actualType = 'organizations';
		}
		vm.dataType = `/${actualType}.json`;
	};

	vm.loadOrganizations = (type) => {
		UserService.getUsers('organization').then(
			(organizations) => {
					vm.numberOfOrganizations = organizations.length;
				}
			);

		if (!vm.reloadData) return;

		var reloadPromise = vm.reloadData();
		if (reloadPromise) {
			vm.workingCount += 1;
			reloadPromise.then(() => {
				vm.updateDataType(type);
				vm.type = type;
				vm.workingCount -= 1;
			}).catch(err => {
				vm.workingCount -= 1;
			});
		}
	};

	vm.signInForward = (userid) => {
		console.log(`Forwarding sign in to organization ${userid}`);
		localStorage.removeItem("currentGroup");
		
		UserService.signInForward(userid).then(
			response => window.location.href = "/customers_list.html"
		).catch(
			error => ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
		);
	};

	vm.reActivateUser = (user) => {
		user.account_status = 'active';

		UserService.updateUser(user).then(
			(response) => {
				ToastService.showMessage($translate('ACTIVATE_ORGANIZATION_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('ORGANIZATION_STATUS_ERROR'), "error");
			}
		);
	}

	vm.disableUser = (user) => {
		user.account_status = 'disabled';

		UserService.updateUser(user).then(
			(response) => {
				ToastService.showMessage($translate('DISABLE_ORGANIZATION_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('ORGANIZATION_STATUS_ERROR'), "error");
			}
		);
	}

	vm.suspendUser = (user) => {
		user.account_status = 'suspended';

		UserService.updateUser(user).then(
			(response) => {
				ToastService.showMessage($translate('SUSPEND_ORGANIZATION_SUCCESS'), "success");
			},
			(err) => {
				ToastService.showMessage($translate('ORGANIZATION_STATUS_ERROR'), "error");
			}
		);
	}

	vm.deleteOrganization = (userid, type) => {
		type = $translate('USER_TYPE_ORGANIZATION').toLowerCase();

		if (userid.toLowerCase() == vm.user.userid.toLowerCase()) {
			MessageService.showMessage({
				title: "Oops!",
				body: $translate('USER_TYPE_ORGANIZATION') + " '" + userid + "' cannot delete own account"
			}, (ok) => { });
			return;
		}

		MessageService.showMessage({
			title: $translate('MSG_USER_DELETE_CONFIRM_TITLE', {
				type: type
			}),
			body: $translate('MSG_USER_DELETE_CONFIRM', {
				type: type,
				userid: userid
			})
		}).then((ok) => {
			if (ok == "ok") {
				console.log("sending delete request");
				UserService.deleteUser(userid, vm.userType).then(response => {
					ToastService.showMessage($translate('MSG_USER_DELETE_SUCCESS_BODY', {
						type: type,
						userid: userid
					}), "success");
					vm.loadOrganizations(vm.userType);
				}).catch(err => {
					ToastService.showMessage($translate('MSG_USER_DELETE_FAIL_BODY', {
						type: type
					}), "error");
				});
			}
		});
	};

	vm.addOrganizationOnDass = (organiozation, usertype) => {
		let ut = $translate('USER_TYPE_ORGANIZATION').toLowerCase();
        
		UserService.addOrganizationOnDass(organiozation, usertype).then(response => {
			ToastService.showMessage($translate('MSG_USER_CREATE_SUCCESS_BODY', {
					ut: usertype[0].toUpperCase() + usertype.slice(1)
				}), "success"
			);
			vm.loadOrganizations(usertype);
		}).catch(err => {
			if(err.status === 412 || err.status === 400){
				ToastService.showMessage($translate('PASSWORD_FAILED'), "error");
			} else {
			ToastService.showMessage($translate('MSG_USER_CREATE_FAIL_BODY', {
				ut: usertype[0].toUpperCase() + usertype.slice(1)
				}) , "error"
			);
		}
		});
	}

	vm.doUserSignup = (organiozation, usertype) => {
		vm.addOrganizationOnDass(organiozation, usertype);
	};
	
	vm.addOrganization = (organiozation, usertype) => {
		vm.loadOrganizationData();
		setTimeout(() => {
			if (organiozation) {
				UserService.getUser(organiozation.userid, usertype).then(response => {
					let organiozation_ = response;
					const modalInstance = $uibModal.open({
						backdrop: "static",
						animation: vm.animationsEnabled,
						templateUrl: 'addUserModalContent.html',
						controller: 'AddUserModalCtrl as vm',
						size: "",
						resolve: {
							items: () => {
								return {
									owner: vm.user,
									user: organiozation_,
									usertype: usertype
								};
							}
						}
					});
					modalInstance.result.then((updateduser) => {
						vm.loadOrganizationData();
						vm.loadOrganizations(usertype);
					}, () => {
						$log.info('Modal dismissed at: ' + new Date());
					});
				})
			} else {
				const modalInstance = $uibModal.open({
					backdrop: "static",
					animation: vm.animationsEnabled,
					templateUrl: 'addUserModalContent.html',
					controller: 'AddUserModalCtrl as vm',
					size: "",
					resolve: {
						items: () => {
							return {
								owner: vm.user,
								user: organiozation,
								usertype: usertype
							};
						}
					}
				});
				modalInstance.result.then((updateduser) => {
					vm.loadOrganizationData();
					vm.loadOrganizations(usertype);
				}, () => {
					$log.info('Modal dismissed at: ' + new Date());
				});
			}
		}, 700);
	};

	vm.open = function () {
		var modalInstance = $uibModal.open({
		  backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});
	
		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

	vm.loadOrganizationData = () => {
		return new Promise((resolve, reject) => {
			vm.workingCount += 1;
			UserService.getUserData().then(
				(organizationData) => {
					if((organizationData.user.org_admin && vm.userType == "organization")) {
						vm.user = organizationData.user;
						vm.have_license = (vm.user._license & 0x100) == 0x100;
						vm.currentDevice = organizationData.currentDevice;
						vm.workingCount -= 1;
					}
					else if ((organizationData.user.org_admin == undefined && vm.userType == 'organization')) {
						// if the logged in user doesn`t have org_admin right, then show alert box instead of direct redirect

						vm.open();
					}
					resolve();
				},
				(response) => {
					console.log("error getting user data:" + response.status);
					vm.workingCount -= 1;
					reject();
				}
			);
		});
	};

	vm.tableSelectionChanged = (organization) => {
		console.log("Selection changed ", organization);
		vm.selectedUsers = organization;
	};

	vm.deleteOrganizations = () => {
		const UserType = $translate("USER_TYPE_ORGANIZATION").toLowerCase();
		MessageService.showMessage({
			title: $translate('MSG_USERS_DELETE_CONFIRM_TITLE', {
				type: vm.userType
			}),
			body: $translate('MSG_USERS_DELETE_CONFIRM', {
				count: vm.selectedUsers.length,
				type: UserType
			})
		}).then(async (ok) => {
			console.log("sending delete request");
			try {
                const response = await UserService.deleteUsers(vm.selectedUsers, vm.userType);
                if (response.length > 0) {
                    let success = 0;
                    response.map((res) => {
                        if (res.status === 200) {
                            success = success + 1;
                        }
                    });
                    if (success > 0) {
                        ToastService.showMessage($translate('MSG_USERS_DELETE_SUCCESS_BODY',
                            {
                                count: vm.selectedUsers.length,
                                type: UserType
                            }
                        ), "success");
                        vm.loadOrganizations(vm.userType);
                    }
                }
            } catch (err) {
                let message = "";
                switch (err.status) {
                    case 404:
                        message = 'MSG_USERS_DELETE_BULK_NOT_FOUND_BODY';
                        break;
                    default:
                        message = 'MSG_USERS_DELETE_FAIL_BODY';
                        break;
                }
                ToastService.showMessage($translate(message, {
                        count: vm.selectedUsers.length,
                        types: UserType
                }), "error");
                vm.loadOrganizations(vm.userType);
            }
		});
	}

	vm.initDataTable = () => {
		console.log("Init datatable");
		vm.actions = [{
			type: "action",
			text: "EDIT_ORGANIZATION",
			icon: "fa fa-pencil-square-o fa-fw",
			action: (user) => vm.addOrganization(user, vm.userType)
		}, {
			type: "separator"
		}, {
			type: "action",
			text: "DELETE_ORGANIZATION",
			icon: "fa fa-trash-o fa-fw",
			action: (user) => vm.deleteOrganization(user.userid)
		}, 
		{
			type: "separator"
		},
		{
            type: "action",
            text: "SUSPEND_ORGANIZATION",
            icon: "fa fa-exclamation-triangle",
            action: (user) => vm.suspendUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'active'
		},
		{
            type: "action",
            text: "DISABLE_ORGANIZATION",
            icon: "fa fa-times-circle",
            action: (user) => vm.disableUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'active'
		},
		{
            type: "action",
            text: "REACTIVATE_ORGANIZATION",
            icon: "fa fa-check-circle",
            action: (user) => vm.reActivateUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'suspended'
		},
		{
            type: "action",
            text: "DISABLE_ORGANIZATION",
            icon: "fa fa-times-circle",
            action: (user) => vm.disableUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'suspended'
		},
		{
            type: "action",
            text: "REACTIVATE_ORGANIZATION",
            icon: "fa fa-check-circle",
            action: (user) => vm.reActivateUser(user),
            visible: (entry, user) => vm.have_license && user.account_status == 'disabled'
		},
		{
			type: "action",
			text: "SET_AS_USER",
			icon: "fa fa-arrow-right fa-fw",
			action: (user) => vm.signInForward(user.userid)
		}];
		vm.bulkActions = [{
			type: "action",
			text: "DELETE_ORGANIZATIONS",
			icon: "fa fa-trash-o fa-fw",
			action: vm.deleteOrganizations
		}];
		vm.columns = [{
			key: "userid",
			type: "text",
			title: "USER_ID",
			filterable: true,
			filterField: 'search_id',
			filterType: 'text',
			filterParams: {
				mapper: (x) => x || undefined
			}
		},
		{
			key: "administrator",
			title: "ADMINISTRATOR",
			type: "text",
			filterField: 'search_administrator',
			filterable: true,
			filterType: 'boolean_toggle',
			render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
		}, {
			key: "can_register",
			title: "USER_LIST_REGISTRATION_RIGHTS",
			type: "text",
			filterField: 'search_can_register',
			filterable: true,
			filterType: 'boolean_toggle',
			render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
		}];
	}


	vm.initDataTable();
	vm.loadOrganizationData().then(() => {
		if (vm.have_license) {
			vm.columns.splice(1, 0, {
				key: "account_status",
				title: "STATUS",
				type: "icon_with_tooltip",
				render_icon: 'fa-exclamation-triangle',
				render_icon: (x) => (x.account_status == 'active' ? 'fa fa-check-circle' : x.account_status == 'disabled' ? 'fa fa-times-circle' : 'fa fa-exclamation-triangle'), 
				render_tooltip: x => x.account_status.charAt(0).toUpperCase() + x.account_status.slice(1),
				filterable: false,
				filterField: '',
				filterType: '',
				filterParams: {
					mapper: (x) => x || undefined
				}
			});
			vm.actions.splice(8, 0, {
				type: "separator"
			})
		};
	});
};

app.controller("OrganizationListController", ["UserService", "ToastService", "MessageService", "$filter", "$uibModal", "$log", OrganizationListController])
