import * as React from "react";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "../../services/Localization";
import { userData, Whoami } from "../../services/Login";
import { toast } from "../../utils/Toaster";
import "./signin.css";
declare const constants;
export class SignIn extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            signedIn: false,
            userid: "",
            auco: (constants.disable_autocomplete_for_credentials === true) ? "off" : "on",
            hideContent: false,
        };
    }

    public componentDidMount() {
        this.getUser();
    }

    public validateForm() {
        return this.state.userid.length > 0 && this.state.password.length > 0;
    }

    public handleChangeId = (event) => {
        this.setState({
            userid: event.target.value,
        });
    }

    public handleChangePassword = (event) => {
        this.setState({
            password: event.target.value,
        });
    }

    public getUser = async () => {
        try {
            const response: any = await Whoami();

            if (response.user) {
                window.location.href = "/app";
            } else {
                this.setState({
                    hideContent: true,
                });
            }

        } catch (error) {
            this.setState({
                hideContent: true,
            });
        }
    }

    public handleKeyPress = (e) => {
        if(e.key === "Enter") {

            this.handleSubmit(e);
        }
    }

    public handleSubmit = async (event) => {
        try {
            const response: any = await userData(this.state.userid, this.state.password);
            if (response.user.reset_password) {
                window.location.href = "new_password.html?userid=" + response.user.userid;
            } else {
                window.location.href = `welcome.html`;
            }
        } catch (error) {
            console.log("Error=" + error);
            this.setState({ signedIn: false });
            toast.error(strings.LOGIN_FAILED);
        }
    }

    public render() {
        return (
            <div>
            {this.state.hideContent && (
                <div className="container-fluid background-login results title signin">
                    <div className="row login-wrapper">
                        <div className="form-signin">
                            <img className="img-responsive img_padding_bottom"
                                src={"theme/images/orbiwise-logo-dark.png"} />
                            <div className="Login">
                                <form autoComplete={this.state.auco}>
                                    <div className="user-inner-addon">
                                        <i className="glyphicon glyphicon-user"></i>
                                        <input type="text" className="form-control" placeholder={strings.USER_ID}
                                            value={this.state.userid}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChangeId} autoFocus autoComplete={this.state.auco}></input>
                                    </div>
                                    <div className="user-inner-addon">
                                        <i className="glyphicon glyphicon-lock"></i>
                                        <input type="password" autoComplete={this.state.auco} onKeyPress={this.handleKeyPress} className="form-control"
                                            placeholder={strings.PASSWORD} value={this.state.password}
                                            onChange={this.handleChangePassword}></input>
                                    </div>
                                    <button id="btnSignInSubmit"
                                        className="btn btn-lg btn-block btn-dark-login" type="button" onClick={this.handleSubmit} >
                                        {strings.SIGN_IN}
                                    </button>
                                    <div className="row">
                                        {((constants.forgot_username_link !== undefined) &&
                                            (constants.forgot_username_link === true)) && (
                                            <div className="col-md-6">
                                                <a href="/forgot_username.html"
                                                    className="returnLogin a-link"
                                                >
                                                    {strings.FORGOT_USERNAME}
                                                </a>
                                            </div>
                                        )}
                                        {((constants.forgot_password_link !== undefined) &&
                                            (constants.forgot_password_link === true)) && (
                                            <div className="col-md-6">
                                                <a href="/forgot_password.html"
                                                    className="returnLogin pull-right a-link"
                                                >
                                                    {strings.FORGOT_PASSWORD}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            </div>
        );
    }
}
