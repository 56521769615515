(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('TenantService', TenantServiceFn);

    TenantServiceFn.$inject = ['$http', "$q"];

    function TenantServiceFn($http, $q) {

        function getTenantsImpl(type) {
            var defer = $q.defer();
            $http.get('/tenants.json').then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        return {
            getTenants: getTenantsImpl,
        }
    }
})(angular);