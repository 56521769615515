/*jslint node: true, sloppy: true, bitwise: true, vars: true, eqeq: true, plusplus: true, nomen: true, es5:true */
/*global angular, atob, btoa, google, MessageService.showMessage, constants, WebSocket */

function SignUpController(MessageService, ToastService, $uibModal, $log, $filter, $cookies, UserService, $scope, CommonService) {
    const vm = this;
    const $translate = $filter("translate");
    vm.query = CommonService.parseQueryString(window.location.search.substring(1));
    vm.constants = constants;
    vm.loading = false;
    vm.show_password = false;
    vm.termsAndConditions = false;
    vm.upper = false;
    vm.lower = false;
    vm.special = false;
    vm.length = false;
    vm.complex = false;
    vm.length_value = 0;
    vm.show_length = false;
    vm.history_password = false; 
    vm.admin_policy = false;
    vm.number = false;
    vm.count = 0;
    vm._customer = false;
    vm.type_ = false;
    vm.userData = {};
    vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
    
    if (window.location.pathname == '/forgot_password.html' || window.location.pathname == '/forgot_username.html') {
        // if we visit one of the pages above, we don`t need to send request to whoami
    }
    else {
        UserService.getUserData().then((response) => {
            console.log("Got answer from service: ", response);
            vm.userData = response;
            vm._customer = response.is_customer;
            if(response.administrator === true || response.customer_admin === true || response.sys_admin === true){
                vm.admin_policy = true;
            }
    
        }).catch((response) => {
            ToastService.showMessage($translate("ERROR"), "error");
        });
    }
    
    vm.user = {
        userid: '',
        password: '',
        _passwordConf: '',
        company_address: '',
        company_name: '',
        company_size: '',
        mobile_number: '',
        phone_number: '',
        first_name: '',
        last_name: '',
        industry: '',
        city: '',
        country: '',
        state: '',
        zipcode: '',
        address: ''
    }

    vm.signInCredentials = {
        userid: '',
        password: ''
    }

    vm.doSignIn = (credentials) => {
        console.log("Signing in ", credentials)
        UserService.signInOnDass(credentials).then((response) => {
            console.log("Got answer from service: ", response);
            if(response.user.reset_password === true) {
                window.location.href = "/new_password.html?userid=" + response.user.userid;
            } else
                window.location.href = "/";
        }).catch((response) => {
            ToastService.showMessage($translate('MSG_LOGIN_FAILED_BODY'), "error");
        });
    }

    vm.doSignUp = () => {
        vm.loading = true;
        const objToSend = JSON.parse(JSON.stringify(vm.user));
        delete vm.user.password;
        delete vm.user._passwordConf;
        objToSend.company_size = parseInt(objToSend.company_size) || undefined;

        UserService.signUpUser(objToSend).then(res => {
            console.log("User sign up success", res);
            window.location.href = `/welcome.html?message=USER_SIGN_UP_SUCCESS_MSG`; // refresh
            vm.loading = false;
        }).catch(err => {
            if(err.status === 409){
                MessageService.showMessage({
                    title: $translate("WARNING"),
                    body:  $translate(err.data)
                });
            } else {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body:  $translate(err.data)
                }); 
            }      
            vm.loading = false;     
        });
    }

    vm.storeInitialPassword = () => {
        vm.loading = true;
        const objToSend = {
            userid: vm.query.userid,
            password: vm.user.password,
            _passwordConf: vm.user._passwordConf,
            password_token: vm.query.token ? vm.query.token : undefined
        };

        UserService.setInitialPassword(objToSend).then(res => {
            console.log("User password set", res);
            UserService.signInOnDass(objToSend).then((response) => {
                console.log("Got answer from service: ", response);
                window.location.href = "/welcome.html?message=USER_PASSWORD_SET_SUCCESS_MSG";
                // vm.loadUserData();
            }).catch((response) => {
                ToastService.showMessage($translate('MSG_LOGIN_FAILED_BODY'), "error");
            });
            vm.loading = false;
        }).catch(err => {
            MessageService.showMessage({
                title: $translate("ERROR"),
                body: $translate(err.data)
            });
            vm.loading = false;
        });
    }

    vm.forgotPassword = () => {
        const objToSend = {
            userid: vm.user.userid,
        };

        UserService.setNewPassword(objToSend).then(res => {
            window.location.href = "/password_reset_instructions.html";
        }).catch(err => {
            if(err.data === "invalid userid") {
                window.location.href = "/password_reset_instructions.html";
            } else {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body: $translate(err.data)
                });
            }
        })
    }

    vm.forgotUsername = () => {
        const objToSend = {
            email: vm.user.email
        };

        UserService.forgotUsername(objToSend).then(res => {
            window.location.href = "/email_username.html";
        }).catch(err => {
            MessageService.showMessage({
                title: $translate("ERROR"),
                body: $translate(err.data)
            });
        })
    }

    vm.resetPassword = () => {
        const objToSend = {
            userid: vm.query.userid,
            password: vm.user.password,
            password_token: vm.query.token
        };

        UserService.resetPassword(objToSend).then(res => {
            window.location.href = "/password_successfully_changed.html";
        }).catch(err => {
            if (err.data === "invalid password token") {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body: $translate("INVALID_TOKEN")
                });
            } else {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body: $translate(err.data)
                });
            }
        })
    }

    vm.homePage = () => {
        window.location.href = "/welcome.html";
    }

    vm.checkPasswordPolicy = (password) => {
        if(vm._customer === true){
            vm.type_ = 'customer';
        } else {
            vm.type_ = 'user';
        }
        const objToSend = {
            userid: vm.query.userid,
            password: vm.user.password,
            type: vm.type_,
            administrator: vm.admin_policy
        };
        UserService.validatePasswordPolicy(objToSend).then(validationResult => {
            angular.extend(vm, validationResult.data);
        }).catch(err => {
            MessageService.showMessage({
                title: $translate("ERROR"),
                body: $translate(err.data)
            });
        });
    }

    vm.changePassword = () => {
        const objToSend = {
            userid: vm.userData.userId,
            password: vm.user.password,
        };

        UserService.changePassword(objToSend).then(res => {
            window.location.href = "/";
        }).catch(err => {
            if (err.status === 412 || err.status === 400) {
                ToastService.showMessage($translate('PASSWORD_FAILED'), "error");
            } 
            else {
                MessageService.showMessage({
                    title: $translate("ERROR"),
                    body: $translate(err.data)
                });
            }
        })  
    }
};


var app = angular.module('dassUiModule');

app.controller('SignUpController', ['MessageService', 'ToastService', '$uibModal', '$log', '$filter', '$cookies', 'UserService', "$scope", "CommonService", SignUpController]);
