var app = angular.module('dassUiModule');

const strings = {
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_DE: 'German',
    BUTTON_LANG_ZH: '中文',
    NAV_WELCOME: '欢迎',
    NAV_CUSTOMER_ADMINISTRATOR: '（客户管理员）',

        GLOBAL_APP_STOP: '停止默认推送',
        GLOBAL_APP_START: '启动默认推送',
        EDIT_GLOBAL_PUSH_CONFIG: '编辑默认推送配置',
        SAVE_GLOBAL_PUSH_CFG_SUCCESS: "全局推送配置保存成功",
        SAVE_GLOBAL_PUSH_CFG_FAIL: "全局推送配置保存失败",

        // Navigation
        NAV_SIGNED_IN_AS: '登录为',
        NAV_SIGN_IN_PROMPT: '未登录',
        NAV_USER_SETTINGS: '用户设置',
        NAV_RETURN_TO: '返回{originalUser}',
        NAV_RETURN_VIA: '通过',
        NAV_LANGUAGES_ITEM: '语言',
        NAV_DEVICES_ITEM: '设备',
        NAV_DEVICES_LIST_DEVICES: '设备管理',
        NAV_DEVICES_MAP: '显示设备地图',
        NAV_GATEWAYS_MAP: '显示网关地图',
        NAV_DEVICES_BATCH: '批量注册',
        NAV_DATA_ITEM: '数据',
        NAV_DATA_SHOW_PACKETS: '设备活动',
        NAV_GATEWAYS_ITEM: '网关',
        NAV_GATEWAYS_LIST_GATEWAYS: '网关管理',
        NAV_APPLICATIONS_ITEM: '应用',
        NAV_APPS_LIST: '应用管理',
        NAV_APPS_HTTP_PUSH_CONFIG: '推送配置',
        NAV_USERS_ITEM: '用户',
        NAV_USERS_LIST_USERS: '用户管理',
        NAV_ORGANIZATION_LIST_USERS: '管理组织',
        NAV_CUSTOMERS_ITEM: '客户',
        NAV_CUSTOMERS_LIST_CUSTOMERS: '客户管理',
        NAV_DASHBOARD: '仪表板',
        NAV_CONFIGURE: '配置',
        ACCOUNT_TYPE_CUSTOMER: '客户账号',

        // Welcome
        WELCOME_TITLE: '欢迎',
        WELCOME_MESSAGE: '这是OrbiWise终端客户设备显示界面',
        WELCOME_LEARN_MORE: '了解更多',
        WELCOME_SIGN_UP: '注册',

        SIGNUP_TITLE: '注册',
        SIGNUP_MESSAGE: "请输入客户详细信息以注册",
        SIGNUP_BUTTON: '注册',

        // My Apps
        MY_APPS_TITLE: '我的应用程序',
        MANAGE_APPS_TITLE: '管理应用程序',
        MY_APPS_ADD_APP: '添加应用程序',
        MY_APPS_ACTION_ITEM: '操作',
        MY_APPS_ACTION_EDIT: '编辑应用程序',
        MY_APPS_ACTION_DELETE: '删除应用程序',
        APP_NAME: '应用程序名称',
        APP_ID: '应用程序ID',
        UUID: 'UUID',

        SEARCH_MIN_LENGTH_TOOLTIP_1: "最低 ",
        SEARCH_MIN_LENGTH_TOOLTIP_2: "按下返回键 ",
        SEARCH_MIN_LENGTH_TOOLTIP_3: " 字符 ",
        SEARCH_MIN_LENGTH_TOOLTIP_4: " 字符 ",
        SEARCH_FIND_DEVICE_TOOLTIP: "請輸入完整的DevEUI，然後按Enter",
        // My devices
        MY_DEVICES_TITLE: '我的设备',
        MY_DEVICES_ADD_DEVICE: '添加没有配置文件的设备',
        MY_DEVICES_ACTION_ITEM: '操作',
        MY_DEVICES_ACTION_EDIT: '编辑设备',
        MY_DEVICES_ACTION_SEND: '发送到设备',
        MY_DEVICES_ACTION_DATA: '查看数据',
        MY_DEVICES_ACTION_POSITION: '地图上的位置',
        MY_DEVICES_ACTION_EDIT_DEVICES: '编辑设备',
        MY_DEVICES_ACTION_DELETE_DEVICES: '删除设备',
        MY_DEVICES_ACTION_SUSPEND: '挂起装置',
        MY_DEVICES_ACTION_UNSUSPEND: '取消暂停设备',
        MY_DEVICES_ACTION_DELETE: '删除设备',
        SELECT_DEVICE_MODAL_FILTER: '设备筛选',
        NO_DEVICES_MATCHING: "没有找到与所提供的筛选匹配的设备",
        MY_DEVICES_NUMBER_MESSAGE: "那里 {numberOfDevices, plural, =0{没有设备} 1{是1个设备} other{设 # 设备}} 注册。",
        MY_DEVICES_APPLICATIONS: "应用程序",
        MY_DEVICES_GROUPS: "群组",
        MY_DEVICES_ANY_GROUP: "任何组",
        MY_DEVICES_GROUP_PROMPT: "请从下面的列表中选择一个组：",
        MY_DEVICES_SELECTED_MESSAGE: '{numSelected} 选定的设备',
        MY_DEVICES_ASSIGN_LABEL: '设备{numSelected} 已选定，从下拉菜单中选择一个，然后单击“分配设备”，将它们分配给一个组。',
        MY_DEVICES_APPLY: "分配设备",
        MY_GROUPS_NUMBER_MESSAGE: "那裡 {numberOfGroups, plural, =0{不是團體} =1{是 1 組} other{是 # 組}} 註冊.",
        MY_DEVICES_NUMBER_MESSAGE_TABLE:  "那里 {total, plural, =0{没有设备} 1{是1个设备} other{设 # 设备}} 發現。",
        MY_USERS_NUMBER_MESSAGE_TABLE:  "那里 {total, plural, =0{用戶} 1{是1用戶} other{设 # 用戶}} 發現。",
        FIND_NUMBER_MESSAGE_TABLE: "那里 {total, plural, =0{沒有記錄} 1{是1用戶 1 記錄} other{设 # 沒有記錄}} 發現。",
        CLOSE: "关闭",
        ASSIGN_GROUPS: "为设备'{deveui}'分配群组",
        MANAGE_GROUPS: "管理群组",
        ADD_GROUP: "添加组",
        ADD_GROUPS: "添加群组",
        ADD_APP: "添加应用程序",
        ADD_APPS: "添加应用程序",
        REMOVE_APPS: "移除应用程序",
        ADD_TO_GROUPS: "添加到群组",
        ADD_TO_APPS: "添加到应用程序",
        GROUP_ASSIGNMENT: "组分配",
        APP_ASSIGNMENT: "应用程序分配",
        REMOVE_FROM_APPS: "从应用程序中移除",
        REMOVE_FROM_GROUPS: "从群组中移除",
        EDIT_GROUP: "编辑组",
        UPDATE_GROUP: "更新组",
        SAVE_GROUP: "保存组",
        DELETE_GROUP: "删除组",
        DELETE_SELECTED_GROUPS: "删除群组",
        REMOVE_GROUPS: "移除群组",
        SEND_MULTICAST: "发送多播",
        GROUP_ID: "组ID",
        GROUP_TITLE: "标题",
        ADD_GROUP_MODAL_GROUP_ID_HELP: "用于识别组的简短唯一标识符。至少有6个字符，可以包含字母数字和下列任意一个: (@, _, -)。",
        ADD_GROUP_MODAL_GROUP_TITLE_HELP: " 组标题是一个字段，这是用来给这个组命名一个人性化的的名称。",
        ADD_GROUP_MODAL_COMMENT_HELP: "这是一个用户定义的字段，可以用来保存任何类型的信息。",
        GROUPS_SELECTED_SUFFIX: "选定群组",
        GROUPS_SELECT_BUTTON: "群组",
        GROUPS_SELECT_ASSIGN_BUTTON: "选择要分配的群组",
        GROUPS_SELECT_ALL: "选择全部",
        GROUPS_SELECT_NONE: "选择无",
        APPS_SELECTED_SUFFIX: "选定应用程序",
        APPS_SELECT_BUTTON: "应用程序",
        APPS_SELECT_ASSIGN_BUTTON: "选择要分配的应用程序",
        APPS_SELECT_ALL: "选择全部",
        APPS_SELECT_NONE: "选择无",

        // Batch Registration
        BATCH_UPLOAD_INSTRUCTIONS: '把CSV文件拖到这里然后点击上传',
        BATCH_UNSUPPORTED_MESSAGE: '此浏览器不支持文件拖/放',
        BATCH_STATUS_404: '设备不存在',
        BATCH_STATUS_0: '注册成功',
        BATCH_STATUS_1: '已经注册',
        BATCH_STATUS_2: '已删除',
        BATCH_STATUS_3: '未找到',
        BATCH_STATUS_4: '已更新',
        BATCH_STATUS_5: '更新失败',
        BATCH_STATUS_REGISTERED: '已注册',
        BATCH_STATUS_ADDING: '添加中',
        BATCH_STATUS_DELETING: '删除中',
        BATCH_STATUS_DELETED: '已删除',
        BATCH_STATUS_ERROR: '错误',
        BATCH_STATUS_null: ' ',
        ERROR: "错误",
        BATCH_REGISTER_DEVICES_ERROR1: '列 {column_num} 字段为空',
        BATCH_REGISTER_DEVICES_ERROR2: '缺少标题中的一些必填字段DevEUI，device_profile_uuid和service_profile_uuid',
        BATCH_REGISTER_DEVICES_SERVER_ERROR: '在{index}行注册设备失败，因为 - {message_error}',
        BATCH_UPDATE_DEVICES_SERVER_ERROR: '由于 - {message_error}，在行{index}上更新设备失败',
        BATCH_ERROR_REGISTERING: '注册设备错误',
        BATCH_ERROR_UPDATE: '更新设备错误',
        BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS: '缺少必填字段 {field_name}。',

        // Data
        DATA_SEND_DATA: '发送数据',
        DEVICE_PAYLOADS_MESSAGE: '设备的 {number, plural, =0{没有有效载荷} =1{是 1 个有效载荷} other{是 # 没有有效载荷}} ',
        GROUP_MULTICASTS_MESSAGE: '设备的 {number, plural, =0{组} =1{是 1 组} other{are # 组}} ',
        DATA_DELETE_PACKET: '删除数据包',
        DATA_DELETE_PACKETS: '删除包',
        DIRECTION_UP: '上',
        DIRECTION_DOWN: '下',
        DIRECTION_ANY: '任何',
        DIRECTION: '方向',

        PACKET_STATUS_ANY: '任何',
        PACKET_STATUS_0: '队列中',
        PACKET_STATUS_1: '已发送，等待应答',
        PACKET_STATUS_1_1: '發送',
        PACKET_STATUS_2: '应答OK',
        PACKET_STATUS_3: '应答失败',
        PACKET_STATUS_4: '错误',
        PACKET_STATUS_5: '已取消',
        PACKET_STATUS_SENT: '已发送',

        TIME: '时间',
        FCNT: 'FCNT',
        PORT: '端口',
        DATA_RATE: '数据速率',
        DATA: '日期',
        RSSI: 'RSSI',

        // Send Data Modal
        DATA_PAYLOAD_HELP: '以十六进制格式输入payload。当前位宽{size}字节',
        DATA_PAYLOAD_PORT_HELP: ' 选择要用于下行链路的端口。有效范围1到255',
        DATA_PAYLOAD_FCNT_HELP: '用AppKey注册的设备FCNT应该保留空白',
        DATA_PAYLOAD_MSG_TYPE: '消息类型',
        DATA_PAYLOAD_MSG_TYPE_HELP: '请选择下行消息类型。下行链路默认为确认',
        DATA_PAYLOAD_CONFIRMED: '确认',
        DATA_PAYLOAD_UNCONFIRMED: '未确认的',

        // Gateway Related
        GATEWAYS_TITLE: '网关',
        GATEWAY_ID: '网关ID',
        GATEWAY_NAME: '网关名称',
        GATEWAY_NUMBER_MESSAGE: '有{length}在这个用户帐户注册',
        VIEW_GATEWAY: '查看网关',
        DELETE_GATEWAY: '删除网关',
        ADD_GATEWAY: '添加网关',
        ADD_GATEWAY_MODAL_ADD_GATEWAY_HELP: '输入十六进制格式的网关ID。标识符是8字节长度（即16个字符）',
        GATEWAY_STATUS_ANY: '任何',
        GATEWAY_STATUS_0: '状态 #0',
        GATEWAY_STATUS_1: '状态 #1',
        GATEWAY_STATUS_2: '状态 #2',
        GATEWAY_STATUS_3: '状态 #3',

        // Modal Add Gateway
        ADD_GATEWAY_MODAL_HELP_BLOCK: '请输入十六进制格式的网关ID。标识符是8字节长度（即16个字符）',
        ADD_GATEWAY_MODAL_NOTE: "您可以用这个形式在系统中添加一个新网关",

        // Map
        MAP_FILTER_DEVICES: '筛选设备',
        MAP_SHOW_LABEL: '显示标签',
        MAP_SHOW_DEVICES: '显示设备',
        MAP_SHOW_GATEWAYS: '显示网关',
        MAP_MOVABLE: '可移动',

        // Position on map modal
        POSITION_ON_MAP_MODAL_TITLE: '地图上的位置',
        POSITION_ON_MAP_MODAL_LABEL: '在地图上',
        POSITION_ON_MAP_MODAL_SAVE_POSITION: '储',
        VIEW_GATEWAY_ON_MAP_MODAL_TITLE: '地图上的位置',
        VIEW_GATEWAY_ON_MAP_MODAL_LABEL: '在地图上',
        VIEW_GATEWAY_ON_MAP_MODAL_SAVE_POSITION: '储',

        // Customers
        CUSTOMERS_TITLE: '客户',
        ADD_CUSTOMER: '添加客户',
        EDIT_CUSTOMER: '编辑客户',
        DELETE_CUSTOMER: '删除客户',
        SET_AS_USER: '设置为用户',
        CUSTOMER_ID: '客户ID',
        ADMINISTRATOR: '管理员',

        // Edit Device Modal
        EDIT_DEVICE_HELP_BLOCK: ' DevEUI是一个8字节的唯一的基于IEEE EUI-64标识。强制的。',
        APP_EUI_HELP: 'AppEUI从设备发送。',
        APP_EUI_HELP2: 'AppEUI识别相关的应用。可选。',
        DEVICE_COMMENT_HELP_BLOCK: '设备的注释只是为了方便。可选。',
        REGISTRATION_TYPE: '登记注册类型',
        OTAA: 'OTAA',
        ABP: 'ABP',
        REGISTRATION_TYPE_HELP_BLOCK: '个性化设备具有预生成的会话密钥，不会执行连接过程。',
        DEV_ADDR: 'DevAddr',
        DEV_ADDR_HELP_BLOCK: 'DevAddr（设备地址）是一个4字节的十六进制值。强制性的.',

        MULTICAST_SETTINGS_TITLE: '多播设置',
        NWKS_KEY: 'NwkSKey',
        NWKS_KEY_HELP: 'NwkSKey是一个16字节的加密密钥用于加密LoRaWAN协议帧。强制性的。',
        APP_KEY: 'App Key',
        APPS_KEY: 'AppSKey',
        APPS_KEY_HELP: 'AppSKey是一个16字节的用于加密数据payload的加密密钥。如果提供的所有加密都由网络管理，如果没有提供，则必须由应用程序管理payload加密。可选。',
        APP_KEY_HELP: ' App Key是一个用于加密数据payload的16字节加密密钥。如果提供的所有加密都由网络管理，如果没有提供，则必须由应用程序管理payload加密。可选。',

        DEVICE_PROPERTIES: '设备性能',
        DEVICE_PROPERTIES_HELP: '选择设备性能或未指定',
        DEVICE_PROP_MOBILITY_NOT_SPECIFIED: '未指定',
        DEVICE_PROP_MOBILITY_STATIC: '静态',
        DEVICE_PROP_MOBILITY_MOBILE: '移动',
        DEVICE_PROP_LOCATION_NOT_SPECIFIED: '未指定',
        DEVICE_PROP_LOCATION_OUTDOOR: '户外',
        DEVICE_PROP_LOCATION_INDOOR: '室内',

        QOS_CLASS: 'QoS等级',
        QOS_CLASS_HELP: '选择设备的QoS的级别',

        UPLINK_REDUNDANCY: '上行链路冗余',
        UPLINK_REDUNDANCY_AUTO: '自动',
        UPLINK_REDUNDANCY_HELP: '选择每个上行链路的传输设备的数量。设置数量为1意味着没有冗余。',

        ALLOWED_DUTY_CYCLE: '允许占空比',
        ALLOWED_DUTY_CYCLE_HELP: '允许对设备指定的最大占空比%。如果超过了网络限制会尝试执行。可选。',

        EXPECTED_DUTY_CYCLE: '期望占空比',
        EXPECTED_DUTY_CYCLE_HELP: '指定期望的平均占空比。可选',

        DOWNLINK_PACKET_EXPIRY_TIME: '下行链路数据包有效时间',
        DOWNLINK_PACKET_EXPIRY_TIME_HELP: '下行链路数据包存储在持久存储中的时间（以小时为单位）。 到期后，无论数据包是否被发送，或者应用程序已经看到数据包的状态，数据包都将被丢弃。',

        UPLINK_PACKET_EXPIRY_TIME: '上行链路数据包有效时间',
        UPLINK_PACKET_EXPIRY_TIME_HELP: '上行链路数据包在持久存储器中的存储时间。超出有效时间的数据包将被丢弃，无论数据包有没有被读取。',

        DEVICE_CLASS: '设备类型',
        DEVICE_CLASS_HELP: '支持的设备类型.',

        LORAWAN_MAC_VERSION: ' LoRaWAN Mac的版本',
        LORAWAN_MAC_VERSION_HELP: '请选择设备即将实施的的LoRaWAN Mac的版本.',

        DEVICE_ACTIVATED: '已激活',
        DEVICE_ACTIVATED_HELP: '选择是，允许设备在网络上激活操作。如果设备没有被激活，则不会接收到数据，也无法发送数据。',

        DEVICE_DOWNLINK_ENABLED: '启用下行链路',
        DEVICE_DOWNLINK_ENABLED_HELP: '选择是，允许设备在网络上激活操作。如果没有激活设备，则不会接收到数据，也无法发送数据。',

        MAX_EIRP: 'MaxEIRP',
        MAX_EIRP_HELP: '设备的最大输出功率。这是只适用于1.0.2 Rev B或以后的设备。',

        FCNT_SIZE: 'FCNT位宽',
        FCNT_SIZE_HELP: '请选择FCNT的位宽.',

        DOWNLINK_SLOT_DELAY: '下行链路时隙延迟',
        DOWNLINK_SLOT_DELAY_HELP: '选择默认的延迟从一端传输到设备监听下行。此参数仅对个性化设备非常重要。',

        RX_SLOT_2: 'RX Slot 2',
        RX_SLOT_2_HELP: '数据速率（扩频因子和带宽）的下行Slot-2和Class C默认使用',

        LORA_LOCATION: 'LoRa位置',
        LORA_LOCATION_HELP: '选择启用可以估计该设备的位置',

        DEVICE_USER: '设备所有者',
        DEVICE_USER_HELP: '重新分配设备的所有者',

        OPTIONS_HELP: '選項是一個4字節的十六進制值。 通常留空。',

        CLICK_TO_UNLOCK: '单击解锁',
        EDIT_DEVICE_PARAMETERS: '编辑设备参数',

        TAB_KEYS: '键',
        TAB_QOS: 'QoS',
        TAB_PACKET_STORAGE: '数据包存储',
        TAB_LORA_PARAMETERS: 'LoRa参数',
        TAB_LORA_LOCATION: 'LoRa位置',
        TAB_MISCELLANIOUS: '其他选项',
        TAB_TAGS: '标签',

        EDIT_DEVICE_BTN: '编辑设备',
        UPDATE_DEVICE_BTN: '更新设备',
        ADD_DEVICE_BTN: '添加设备',
        ADD_NEW_DEVICE_BTN: '添加新设备',
        EXPORT_DEVICE_BTN: '出口',

        // HTTP Push config
        HTTP_PUSH_CONFIG_TITLE: '推送设置',
        HTTP_PUSH_HOSTNAME: '网址',
        HTTP_PUSH_HOST_PLACEHOLDER: 'http(s)://your.public.applicationserver.fqdn',
        HTTP_PUSH_PORT: '端口',
        HTTP_PUSH_PATH_PREFIX: '路径前缀',
        HTTP_PUSH_PATH_AUTH_METHODS: '认证方法:',
        HTTP_PUSH_PATH_BASIC_AUTH: '基本',
        HTTP_PUSH_PATH_TOKEN_AUTH: '访问令牌',
        HTTP_PUSH_PATH_CUSTOM_AUTH: '基于证书的身份验证（可选',
        HTTP_PUSH_PATH_CERTIFICATE_DESC: '内容将直接在HTTP授权请求标头中发送',
        HTTP_PUSH_PATH_CERTIFICATE_TITLE: '基于证书的身份验证（可选',
        HTTP_PUSH_CRT_FILE: '证书：',
        HTTP_PUSH_KEY_FILE: '私钥：',
        HTTP_PUSH_PASSPHRASE: '密码:',
        HTTP_PUSH_DATA_FORMAT: '数据格式:',
        HTTP_PUSH_STORE_PAYLOAD: '存储有效载荷:',
        HTTP_PUSH_SUBSCRIPTIONS: '订阅:',
        HTTP_PUSH_DRAG_DROP_TEXT: '在此处拖放文件',
        HTTP_PUSH_SHOOSE_FILE: '选择文件',
        HTTP_PUSH_OR_TEX: '要么',
        HTTP_PUSH_HOST_USERNAME: '主机用户名',
        HTTP_PUSH_HOST_USERNAME_PLACEHOLDER: '你的应用服务器用户名',
        HTTP_PUSH_HOST_PASSWORD: '主机密码',
        HTTP_PUSH_HOST_PASSWORD_PLACEHOLDER: '你的应用服务器密码',
        HTTP_PUSH_STATUS_RUNNING: '推送过程目前正在运行',
        HTTP_PUSH_STATUS_STOPPED: '推送过程目前已经停止',
        HTTP_PUSH_STATUS: '状态',
        HTTP_PUSH_EDIT: '编辑应用程序',
        HTTP_PUSH_DELETE: '删除应用程序',
        HTTP_PUSH_START: '开始推送',
        HTTP_PUSH_STOP: '停止推送',
        HTTP_PUSH_SAVE: '保存应用程序',
        HTTP_PUSH_CREATE: '添加应用程序',
        PUSH_MODE_START_SUCCESS: '应用程序启用推送模式成功{accountid}',
        PUSH_MODE_START_FAIL: '应用程序启用推送模式失败{accountid}.',
        PUSH_MODE_STOP_SUCCESS: '应用程序停止推送模式成功{accountid}',
        PUSH_MODE_STOP_FAIL: '应用程序停止推送模式失败{accountid}',
        PUSH_HOST_USERNAME_HELP: "輸入主機用戶名，只包含字母，數字，短劃線（' - '），下劃線（'_'），點（'。'）和'@'，主機用戶名不能超過40個字符。",
        PUSH_HOST_PASSWORD_HELP: "輸入主機密碼，只包含字母，數字，短劃線（' - '），下劃線（'_'），點（'。'）和'@'。 ID必須至少為8個字符。",
        PUSH_HOST_HOSTNAME_HELP: "输入以http：//或https：//和端口号开头的有效URL",
        PUSH_HOST_PORT_HELP: "输入1到65535之间的有效端口号.",

        BATCH_PUSH_MODE_START_SUCCESS: '应用程序启用推送模式成功{length}',
        BATCH_PUSH_MODE_START_FAIL: '启用推送模式失败{length}',
        BATCH_PUSH_MODE_STOP_SUCCESS: '停止推送模式成功{length}',
        BATCH_PUSH_MODE_STOP_FAIL: '应用程序停止推送模式失败{length}',

        // Warnings
        WARNING_DEVICE_STATUS_LT2: ' 警告，该设备已成功地在网络上可见。更改键将导致设备状态被重置，并可能丢失与设备的通信！您确定要更新设置吗？',
        WARNING_DEVICE_STATUS_OTHER: '更改键将导致设备状态被重置。您确定要更新设置吗？',
        WARNING_DIFF_DANGER: '警告，改变活动状态终端的参数可能导致下行链路的丢失。您确定要更新吗？',
        WARNING_UPDATE_DEVICE_TITLE: '更新设备！',

        // Add user modal
        ADD_USER_ADD_NEW: '添加新的',
        ADD_USER_ADD: '添加新的',
        ADD_USER_EDIT: '编辑',
        ADD_USER_UPDATE: '更新',

        USER_TYPE_CUSTOMER: '客户',
        USER_TYPE_USER: '用户',
        USER_TYPE_ORGANIZATION: '组织',

        // Messages
        MSG_TITLE_OK: '好',
        MSG_TITLE_OOPS: '哎呀',
        MSG_LOGIN_FAILED_BODY: '登录失败',
        MSG_LOGIN_FORWARD_FAILED: '登录失败',
        MSG_DEVICE_ADDED_SUCCESS_BODY: '新设备{deveui}添加成功 ',
        MSG_DEVICE_ADDED_FAIL_BODY: '创建新设备失败',
        MSG_DEVICE_UPDATE_SUCCESS_BODY: '设备{deveui}成功更新',
        MSG_DEVICE_UPDATE_FAIL_BODY: '更新设备失败"{data}"',
        MSG_DEVICE_DEVICE: "设备",
        MSG_DEVICE_DEVICES: "设备",
        MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY: '{device}已成功更新',
        MSG_DEVICE_UPDATE_BULK_FAIL_BODY: '无法更新{device}。',
        MSG_NODE_UPDATE_FAIL: '更新节点信息失败',
        MSG_GATEWAY_POS_UPDATE_SUCCESS: '网关已成功更新',
        MSG_GATEWAY_POS_UPDATE_FAIL: '无法更新位置',
        MSG_GATEWAY_ID_NOT_FOUND: 'ID为{gateway_id}的网关不存在。',

        MSG_APPLICATION_ADDED_SUCCESS_BODY: '新的应用程序成功添加{accountid}',
        MSG_APPLICATION_ADDED_FAIL_BODY: '创建新的应用程序失败 {data}',
        MSG_APPLICATION_ADDED_FAIL_EXISTS: '应用已经存在 {accountid} ',

        MSG_APPLICATION_EDIT_SUCCESS_BODY: '应用程序成功编辑{accountid}',
        MSG_APPLICATION_EDIT_FAIL_BODY: '应用程序编辑失败 {data}',

        MSG_GROUP_ADDED_SUCCESS_BODY: '新的组{groupid}成功添加',
        MSG_GROUP_ADDED_FAIL_BODY: '创建新的组失败"{data}"',

        MSG_GROUPS_DELETED_SUCCESS_BODY: '成功删除组{count}',
        MSG_GROUPS_DELETED_FAIL_BODY: '删除组失败',

        MSG_DELETE_GROUPS_TITLE: '删除群组',
        MSG_DELETE_GROUPS_BODY: '您确定要删除{count}吗？',

        MSG_DELETE_APPS_TITLE: '删除应用程序',
        MSG_DELETE_APPS_BODY: '您确定要删除{count}吗？',
        MSG_DELETE_APPS_SUCCESS_BODY: '{length}删除成功',
        MSG_DELETE_APPS_FAIL_BODY: '{length}删除失败',

        MSG_DELETE_DEVICES_TITLE: '删除设备',
        MSG_DELETE_DEVICES_BODY: '您确定要删除{count}吗？',
        MSG_DELETE_DEVICES_SUCCESS_BODY: '{length}删除成功',
        MSG_DELETE_DEVICES_FAIL_BODY: '{length}删除失败',

        MSG_DELETE_DEVICE_TITLE: '删除设备',
        MSG_DELETE_DEVICE_BODY: '您确定要删除设备{deveui}吗？',
        MSG_DELETE_DEVICE_SUCCESS_BODY: '设备{deveui}删除成功',
        MSG_DELETE_DEVICE_FAIL_BODY: '设备删除失败',

        MSG_SUSPEND_DEVICE_TITLE: '挂起装置',
        MSG_SUSPEND_DEVICE_BODY: '您确定要暂停设备{deveui}?',
        MSG_SUSPEND_DEVICE_SUCCESS_BODY: '设备{deveui}已成功挂起.',
        MSG_SUSPEND_DEVICE_FAIL_BODY: '无法挂起设备.',
        MSG_UNSUSPEND_DEVICE_TITLE: '取消暂停设备',
        MSG_UNSUSPEND_DEVICE_BODY: '您确定要取消暂停设备{deveui}吗?',
        MSG_UNSUSPEND_DEVICE_SUCCESS_BODY: '设备{deveui}已成功暂停.',
        MSG_UNSUSPEND_DEVICE_FAIL_BODY: '无法取消暂停设备.',


        MSG_ASSIGN_DEVICES_SUCCESS_BODY: '成功将设备分配到群组',
        MSG_ASSIGN_DEVICES_FAIL_BODY: '分配设备到组失败',

        MSG_REMOVE_DEVICES_SUCCESS_BODY: '成功将设备从群组中移除',
        MSG_REMOVE_DEVICES_FAIL_BODY: '从组中删除设备失败',

        MSG_ASSIGN_DEVICES_APPS_SUCCESS_BODY: '成功将设备分配到群组',
        MSG_ASSIGN_DEVICES_APPS_FAIL_BODY: '将设备分配到组失败',

        MSG_UNASSIGN_DEVICES_APPS_SUCCESS_BODY: ' 成功将设备从应用程序中移除',
        MSG_UNASSIGN_DEVICES_APPS_FAIL_BODY: '将设备从应用程序中移除失败',

        MSG_REMOVE_GROUPS_NONE_SELECTED: '请从列表中选择一些组删除',
        MSG_ADD_GROUPS_NONE_SELECTED: '请从列表中选择一些组添加',
        MSG_REMOVE_GROUP_SUCCESS_BODY: '成功将设备从组中移除',
        MSG_REMOVE_GROUP_FAIL_BODY: '将设备从组中移除失败',
        MSG_REMOVE_GROUPS_SUCCESS_BODY: '成功将设备从群组中移除',
        MSG_REMOVE_GROUPS_FAIL_BODY: '将设备从群组中移除失败',
        MSG_DELETE_GROUPS_SUCCESS: ' {length}删除成功',
        MSG_DELETE_GROUPS_FAIL: '{length}删除失败',
        MSG_CREATE_GROUP_SUCCESS: '成功创建组"{groupid}"',
        MSG_CREATE_GROUP_FAIL: '创建组"{groupid}"失败',
        MSG_MULTICAST_SUCCESS: '发送多播到组"{groupid}"',
        MSG_MULTICAST_FAIL: '发送多播到组"{groupid}"失败',
        MSG_UPDATE_GROUP_SUCCESS: '成功更新组"{groupid}"',
        MSG_UPDATE_GROUP_FAIL: '更新组"{groupid}"失败',
        MSG_DELETE_GROUP_SUCCESS: '组"{groupid}"删除成功',
        MSG_DELETE_GROUP_FAIL: '删除组"{groupid}"失败',

        MSG_NO_LOCATION_TITLE: 'No Location 没有位置信息',
        MSG_NO_LOCATION_BODY: '设备"{deveui}"没有位置信息。您想在地图上添加一个标记吗？',

        MSG_GATEWAY_ADDED_SUCCESS_BODY: '网关{id}已添加',
        MSG_GATEWAY_ADDED_FAIL_BODY: '网关添加失败"{data}"',

        MSG_DELETE_GATEWAY_TITLE: '删除网关',
        MSG_DELETE_GATEWAY_BODY: ' 您确定要删除网关"{id}"吗？',
        MSG_DELETE_GATEWAY_SUCCESS_BODY: '网关删除成功',
        MSG_DELETE_GATEWAY_FAIL_BODY: '网关删除失败',


        MSG_DELETE_APP_TITLE: '删除应用程序',
        MSG_DELETE_APP_BODY: '您确定要删除这个应用程序"{accountid}"吗？',
        MSG_DELETE_APP_SUCCESS_BODY: '应用程序已成功删除',
        MSG_DELETE_APP_FAIL_BODY: '无法删除应用',


        MSG_DELETE_PACKET_TITLE: '删除数据包',
        MSG_DELETE_PACKET_SUCCESS_BODY: '数据包已删除',
        MSG_DELETE_PACKET_FAIL_BODY: '删除数据包失败',

        MSG_SEND_DL_PACKET_SUCCESS_BODY: ' 数据排队中将发送到"{deveui}。流水编号："{transaction_id}"',
        MSG_SEND_DL_PACKET_FAIL_BODY: '排队中的数据发送失败',
        MSG_SEND_DL_PACKET_FULL_DOWNLINK_QUEUE_BODY: '下行链路队列已满，{payloads}个有效负载已排队',
        MSG_SEND_DL_PACKET_FAIL_MUST_SPECIFY_FCNT: "必须在appskey不存在时指定fcnt",
        MSG_SEND_DL_PACKET_FAIL_DEVICE_IS_NOT_ACTIVATED: "设备{deveui}未激活",

        MSG_SELECT_DEVICE_BODY: '请先选择一个设备',

        MSG_USER_DELETE_CONFIRM_TITLE: '删除{type}！',
        MSG_USER_DELETE_CONFIRM: '您确定要删除"{type}" "{userid}"吗？',
        MSG_USER_DELETE_SUCCESS_BODY: '成功删除"{type}" "{userid}"',
        MSG_USER_DELETE_FAIL_BODY: ' "{type}"删除失败.',

        MSG_USER_CREATE_SUCCESS_BODY: '新的{ut}成功添加',
        MSG_USER_CREATE_FAIL_BODY: '新的{ut}创建失败',

        MSG_USER_UPDATE_SUCCESS_BODY: '用户更新成功',
        MSG_USER_UPDATE_FAIL_BODY: '用户更新失败',
        MSG_ERROR_LOADING_APPS: '加载应用程序出错',
        MSG_ERROR_LOADING_TENANTS: '加载租户出错',
        MSG_ERROR_LOADING_DATA: '加载数据出错',
        MSG_ERROR_LOADING_DEVICES: '加载设备出错',
        MSG_ERROR_LOADING_GATEWAYS: '加载网关出错',

        // Add User modal
        ADD_USER_PRESET_LABEL: '预设值',
        ADD_USER_PRESET_HELP: '从预设列表中选择一个来创建此用户。',

        // Edit User Modal
        EDIT_USER_TITLE_ACCOUNT_ID: '账号ID',
        EDIT_USER_ACCOUNT_ID_HELP: "输入账号ID，仅限于字母，数字，破折号(' - '), 下划线('_'), 点符号('.') 和'@'",
        EDIT_USER_PASSWORD_HELP: ".密码区分大小写，必须至少有8个字符长。",
        EDIT_USER_PASSWORD_CONFIRM: '密码确认',
        EDIT_USER_PASSWORD_CONFIRM2: '再次输入密码来确认',
        MANAGE_APP_PASSWORD_CONFIRM2: '重新输入密码确认。仅当密码不为空时才需要）',

        USER_COMPANY_NAME: '公司名称',
        USER_COMPANY_ADDRESS: '公司地址',
        USER_EMAIL: '电子邮件',
        USER_FULL_NAME: '全名',
        USER_FIRST_NAME: '名字',

        USER_SIGN_UP_SUCCESS_TITLE: "发送电子邮件确认",
        USER_SIGN_UP_SUCCESS_MSG: "请检查您的电子邮件，然后点击该链接以验证您的电子邮件。",
        NOTIFICATION: "通知",

        ERROR_SENDING_EMAIL: "发送邮件出错",
        INVALID_VERIFICATION_TOKEN: "无效验证密匙",
        INVALID_ACTIVATION_TOKEN: "无效激活密匙",
        VERIFICATION_SUCCESS_MSG: "帐户验证成功。注册信息发送给管理员进行审查。",
        ACTIVATION_SUCCESS_MSG: "帐户激活成功。发送通知邮件到客户端。",
        DECLINE_SUCCESS_MSG: "帐户取消成功。发送通知邮件到客户端。",
        ACTIVATION_FAIL_EMAIL_UNVERIFIED: "您要激活的帐户没有经过电子邮件验证。",

        EDIT_USER_ADMIN_RIGHTS: '管理员权限',
        EDIT_USER_GATEWAYS: '网关',
        EDIT_USER_LORA_LOCATION: 'Lora位置',
        EDIT_USER_SYSTEM_SETTINGS: '系统设置',
        EDIT_USER_INFO: '用户信息',
        EDIT_USER_TENANCY: '租赁',
        EDIT_USER_PASSWORD_POLLICY: "密码政策",

        TENANT_MSG: "用户属于租户'{name}'",
        TENANT_DELETED_MSG: "用户被分配给具有ID的未知租户 '{deletedTenantId}' 请将此客户重新分配给其他租户或房东",
        NO_TENANT_ASSIGNED: "用户未分配租户",
        CHANGE_TENANT: '更改租户',
        SELECT_A_TENNANT: '选择一个租户',
        TENANT_ID: "租户ID",
        TENANT_NAME: "租户名称",

        GATEWAY: '网关',

        EDIT_CUSTOMER_TENNANCY_LABEL: '租户',
        EDIT_CUSTOMER_TENNANCY_HELP: '指定此客户属于哪个租户。',

        TENANT_SELECTED_SUFFIX: "租户选择",
        TENANT_SELECT_BUTTON: "租户",
        TENANT_SELECT_ASSIGN_BUTTON: "选择租户",
        TENANT_SELECT_ALL: "选择全部",
        TENANT_SELECT_NONE: "全部不选",

        EDIT_ORGANIZATION_ADMIN_RIGHTS_LABEL: '组织管理员权限',
        EDIT_ORGANIZATION_ADMIN_RIGHTS_HELP: '具有组织管理员权限的帐户可以添加和删除组织。',

        EDIT_CUSTOMER_ADMIN_RIGHTS_LABEL: '客户管理员权限',
        EDIT_CUSTOMER_ADMIN_RIGHTS_HELP: ' 具有客户管理员权限的帐户可以添加和删除客户。',

        EDIT_USER_ADMIN_RIGHTS_LABEL: '用户管理员权限',
        EDIT_USER_ADMIN_RIGHTS_HELP: '具有用户管理员权限的帐户可以添加和删除用户。',

        EDIT_USER_GATEWAY_ADMIN_RIGHTS_LABEL: '网关管理员权限',
        EDIT_USER_GATEWAY_ADMIN_RIGHTS_HELP: '网关管理帐户可以添加和管理其他用户的网关。',

        EDIT_USER_DEVICE_ADMIN_RIGHTS_LABEL: '设备注册权利',
        EDIT_USER_DEVICE_ADMIN_RIGHTS_HELP: '用户可以将设备注册到其帐户。',

        EDIT_USER_NETWORK_ACCESS_LABEL: '可以访问网络网关信息',
        EDIT_USER_NETWORK_ACCESS_HELP: '用户可以读取哪些网关接收来自用户设备的消息，并可以访问有关网络网关的位置信息。',

        EDIT_USER_GATEWAY_OWNERSHIP_LABEL: '可以拥有网关',
        EDIT_USER_GATEWAY_OWNERSHIP_HELP: '设置后，用户可以在其帐户上拥有网关，并可以查看网关的状态。',

        EDIT_USER_GATEWAY_ADD_LABEL: '可以添加网关',
        EDIT_USER_GATEWAY_ADD_HELP: '拥有此权限的用户可以直接向他们的帐户添加网关。',

        EDIT_USER_GATEWAY_MANAGE_LABEL: '可以管理网关',
        EDIT_USER_GATEWAY_MANAGE_HELP: '具有网关管理权限的用户可以更新网关上的名称、位置等设置。',

        EDIT_USER_LORA_LOCATION_LABEL: '本地服务',
        EDIT_USER_LORA_LOCATION_HELP: '激活Lora位置服务的用户可通过Lora定位系统给指定的设备本地化。',

        EDIT_USER_SYSTEM_ADMIN_LABEL: '系统管理员权限',
        EDIT_USER_SYSTEM_ADMIN_HELP: '具有系统管理权限的账户可以修改系统设置，如日志记录参数。',

        EDIT_USER_DATA_ARCHIVE_ADMIN_LABEL: "Data Archive Administrator Rights",
        EDIT_USER_DATA_ARCHIVE_ADMIN_HELP: "Account can control data archiving by enabling and disabling archiving per account",

        EDIT_USER_AUTO_ARCHIVE_DATA_LABEL: "Auto Archive Data",
        EDIT_USER_AUTO_ARCHIVE_DATA_HELP: "When enabled, uplink data will be automatically archived in archive files for long-term storage.",

        EDIT_USER_CAN_ACCESS_LOGS_LABEL: "Can Access User Logs",
        EDIT_USER_CAN_ACCESS_LOGS_HELP: "Users with this right can access the user logs",

        EDIT_USER_EXTENDED_LOGGING_LABEL: '激活扩展日志',
        EDIT_USER_EXTENDED_LOGGING_HELP: '扩展日志有助于更好的分析与调试',

        EDIT_USER_INFO_LABEL: '用户信息',
        EDIT_USER_INFO_HELP: '关于用户的免费文本信息',

        EDIT_USER_TAGS: '标签',
        EDIT_USER_TAGS_HELP: '关于用户的自由文本信息。',
        EDIT_USER_TAGS_NAME: '标签ID',
        EDIT_USER_TAGS_DESCRIPTION: '标签值',
        TAG_NAME_AVAILABLE_CHARS: '您只能输入字母，数字和-_。',
        TAG_NAME_UNIQUE: '标签名称应唯一',

        // Manage App Modal
        MANAGE_APP_ACCOUNT_ID: '账户ID',
        MANAGE_APP_ACCOUNT_ID_HELP: "请输入账户ID，只能是字母，数字，破折号（'-'），下划线（'_'），点（'.'）和'@'",

        // Users list
        USER_LIST_ADD_USER: '添加用户',
        USER_LIST_TITLE: '用户',
        USER_LIST_REGISTRATION_RIGHTS: '注册权利',

        // Organization list
        ORGANIZATION_LIST_ADD_ORGANIZATION: '添加组织',
        ORGANIZATION_LIST_TITLE: "组织",
        DELETE_ORGANIZATION: '删除组织',
        DELETE_ORGANIZATIONS: '删除组织',
        EDIT_ORGANIZATION: '编辑组织',

        ADD_USER: '添加用户',
        EDIT_USER: '编辑用户',
        DELETE_USER: '删除用户',
        UPDATE_GROUP_BTN: "更新",
        // Generic
        APP_EUI: 'AppEUI',
        DEVEUI: 'DevEUI',
        COMMENT: '注释',
        STATUS: '状态',
        LAST_SEEN: '最后看到',
        ACTION: '操作',
        BULK_ACTION: '操作选项',
        REFRESH_LIST: '刷新清单',
        SELECT_DEVICE: '选择设备',
        SELECT_GROUP: '选择组',
        SELECT_DEVICE_PROMPT: '请选择一个设备',
        SELECT_GROUP_PROMPT: '请选择一个组',
        SECOND_UNIT: 's',

        START: '开始',
        LOADING: '加载中',
        SIGN_IN: '登录',
        SIGN_OUT: '退出',
        USER_ID: '用户ID',
        PASSWORD: '密码',

        TAG_NAME: '标签ID',
        TAG_DESC: '标签值',

        PAYLOAD: 'Payload',
        PAYLOADS: '有效载荷',
        DOWNLOAD: '下载',
        PAYLOADS_EXPORT: '出口有效载荷',
        COORDINATES: '坐标',
        LATITUDE: '纬度',
        LONGITUDE: '经度',
        ALTITUDE: '高度',

        UPLINK: '上行链路',
        DOWNLINK: '下行链路',

        VIA: '通过{originalUser}',
        POWERED_BY: '技术支持',
        WELCOME_RIGHT_MESSAGE: '未来更美好的合作伙伴',

        REFRESH: '刷新',
        REGISTER: '注册',
        DELETE: '删除',
        UPDATE: '更新',
        CLEAR: '清除',
        ENABLE: '启用',
        DISABLE: '禁用',

        START_GLOBAL_PUSH_SUCCESS: "默认推送开始成功",
        STOP_GLOBAL_PUSH_SUCCESS: "默认推送停止成功",

        START_GLOBAL_PUSH_FAIL: "默认推送开启失败",
        STOP_GLOBAL_PUSH_FAIL: "默认推送停止失败",

        PING_SLOT: 'Ping-slot週期',
        PING_SLOT_HELP: '設置B類組播的ping時隙週期，或選擇C類（默認）',

        ADD: '添加',
        REMOVE: '移除',
        OPTIONS: '选项',
        OK: '好',
        TRUE: "对",
        FALSE: "错",
        SAVE: '保存',
        CANCEL: '取消',
        YES: '是',
        NO: '否',
        DEFAULT: "Default",
        FIRST: '首页',
        LAST: '末页',
        PREVIOUS: '前一页',
        NEXT: '下一页',

        SHOWING_PER_PAGE: "每页展示",
        MY_APPLICATIONS_TITLE: "应用",
        NAV_MANAGE_GROUPS: '群组管理',
        NAV_MANAGE_GROUPS_TAB: '管理群组',
        NAV_MANAGE_MULTICAST_ACTIVITY: '多播活动',
        NETWORK_ACTIVITY_TITLE: "设备活动",
        GROUP_ACTIVITY_TITLE: "小组活动",
        NAV_ADMIN_DEVICES: "查找设备",
        NAV_ADMIN_USERS: "查找用户",
        NAV_ADMIN_ITEM: '管理员',
        ADMIN_DEVICES: '所有设备',
        NO_ITEMS_MATCHING: "没有找到与所提供的过滤器匹配的项目",
        SET_PARENT_AS_USER: '将上一级设置为用户',
        SET_OWNER_AS_USER: '将所有者设置为用户',
        OWNER: '所有者',
        DEVICE_MAP: '这个设备没有位置设置',
        GATEWAY_MAP: '这个网关没有位置设置',
        SNWKS_KEY: "SNwkSIntKey",
        SNWKS_KEY_HELP: "SNwkSIntKey是一个16字节加密密钥用于LoRa消息认证。必须项。",
        FNWKS_KEY: "FNwkSIntKey",
        FNWKS_KEY_HELP: "FNwkSIntKey是一个16字节的加密密钥用于LoRa消息认证。必须项。",
        NWKSE_KEY: "NwkSEncKey",
        NWKSE_KEY_HELP: "NwkSEncKey是一个16字节的加密密钥用于LoRa消息认证。必须项。",
        NWK_KEY: "NwkKey",
        NWK_KEY_HELP: "NwkKey是一个16字节的加密密钥用于LoRa消息的加密和认证。可选项。",

        // forgot password functionality
        HOME_PAGE_ORBIWISE: "主页",
        SEND: "发送",
        FORGOT_PASSWORD: "忘记密码吗？",
        FORGOT_USERNAME: "忘记用户名吗？",
        USERNAME: "用户名",
        RESET: "重置",
        FORGOT_USERNAME_EMAIL: "忘记用户名",
        RESET_PASSWORD: "重置密码",
        PASSWORD_CHANGED_SUCCESSFULLY: "您的密码已经修改成功",
        ENTER_PASSWORD: "请输入账户的新密码",
        NEW_PASSWORD: "新的密码",
        FORGOT_USERNAME_INSTRUCTIONS_ORBIWISE: "请输入您的邮箱地址，我们将把您的用户名以邮件方式发送至邮箱。",
        EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE: "邮件已发送至您邮箱",
        FORGOT_PASSWORD_INSTRUCTIONS_ORBIWISE: "请在下面输入您的用户名，我们会向您发送电子邮件如何更改密码的说明",
        INSTRUCTIONS_ORBIWISE: "操作指南已发送",
        PASSWORD_CHANGED_SUCCESSFULLY_ORBIWISE: "密码修改成功",
        INVALID_EMAIL: "无效的邮件",
        INVALID_USERNAME: "无效的用户名",
        INVALID_TOKEN: "密码令牌已过期，请重新提交重置密码需求",

        //password policies
        USERS: "用户",
        ADMINISTRATORS: "管理员",
        PASSWORD_HISTORY: "修改密码历史",
        PASSWORD_HISTORY_DESC: "新密码不能与旧的密码一致",
        PASSWORD_HISTORY_DESC_HOVER: "新的密码不能与旧密码一致",
        COMPLEX_PASSWORD: "启用复杂密码",
        COMPLEX_PASSWORD_DESC: "密码必须包含字符",
        COMPLEX_PASSWORD_DESC_HOVER: "密码必须包含以下四类字符中的三类：n1.英文大写字母 A, B, C, ... Z \n2.英语小写字母a, b, c, ... z\n3. 阿拉伯数字0, 1, 2, ... 9\n4. 非字母数字（特殊字符, !,$,#,%）",
        PASSWORD_LENGTH: "最小密码长度",
        PASSWORD_LENGTH_DESC_HOVER: "启用至少8或更多的最小密码长度 ",
        PASSWORD_AGE: "密码最长期限",

        MAX_DEVICES: "设备最大数量",
        MAX_DEVICES_HELP: "允许该用户注册的最大设备数量",
        MAX_GATEWAYS: "网关最大数量",
        MAX_GATEWAYS_HELP: "允许该用户注册的最大网关数量",
        SETTINGS: "设置",
        MAX_NUM_REACHED: "已达到注册的{item}的最大数量",

        UPPER_CASE: "包含大写字母",
        LOWER_CASE: "包含小写字母",
        SPECIAL_CHARACTERS: "包含特殊字符",
        NEW_PASSWORD_LENGTH: '至少{length_value}字符长度 ',
        CONFIRM_NEW_PASSWORD: "确认新密码",
        PASSWORD_MATCH: "密码匹配",
        REPEAT_PASSWORD: "重复密码",
        CHANGE_PASSWORD: "修改密码",
        NEW_PASSWORD_INFO: "密码必须符合所有列出的要求",
        CREATE_NEW_PASSWORD: "创建新密码",
        PASSWORD_DIFFERENT_FROM_OLD: "不能重复使用密码",
        NUMBER: "包含数字",
        PASSWORD_FAILED: "密码不符合设置的密码策略：可能的原因之一是新密码与旧密码相似",
        MSG_USER_UPDATE_INCORRECT_TENANT_ID: 'tenant_id 不正确',
        USER_PASSWORD_ALREADY_SET: "Password already set",

        MAP_DEVICE_POSITION: "这个设备没有位置设定",
        MAP_GATEWAY_POSITION: "这个网关没有位置设定",

        NAV_PROFILES_ITEM: "简介",
        NAV_DEVICE_PROFILES: "设备配置文件",
        NAV_SERVICE_PROFILES: "服务资料",
        NAV_CONNECTIVITY_PROFILES: "连通性配置文件",
        NAV_ROAMING_PROFILES: "漫游配置文件",

        DEVICE_PROFILES_TITLE: "设备配置文件",
        ADD_DEVICE_PROFILE: "添加设备配置文件",

        SERVICE_PROFILES_TITLE: "服务资料",
        ADD_SERVICE_PROFILE: "添加服务配置文件",
        DEVICE_PROFILE_NAME: "设备配置文件",

        CONNECTIVITY_PROFILES_TITLE: "连通性配置文件",
        ADD_CONNECTIVITY_PROFILE: "添加连接配置文件",
        LOGIN_FAILED: "登录失败",

        ROAMING_PROFILES_TITLE: "漫游配置文件",
        ADD_ROAMING_PROFILE: "添加漫游配置文件",

        CAN_NOT_GET_DATA: "无法获取数据",
        ACTION_EDIT: "更新配置文件",
        ACTION_ADD: "添加个人资料",
        PROFILE_NOT_UPDATED: "配置文件未更新",
        PROFILE_UPDATED_SUCCESS: "配置文件已成功更新",
        PROFILE_CREATED_SUCCESS: "配置文件已成功创建",
        PROFILE_NOT_CREATED: "未创建配置文件",

        GTW_STATUS_NEVER_SEEN: '从未上线',
        GTW_STATUS_GTW_INIT: "已注册，从未上线",
        GTW_STATUS_OK: '好',
        GTW_STATUS_OFF: '关闭',
        GTW_STATUS_BACKHAUL_ISSUE: '连接问题',
        GTW_STATUS_OFF_OR_BACKHAUL_ISSUE: '关闭或者连接问题',
        GTW_STATUS_RADIO_OFF: 'LoRa无线关闭',
        USER_PARENT: '上一级用户',
        GLOBAL_PUSH_CONFIGURATION: "全局推送配置",
        FIND_DEVICES_TITLE: "查找设备",
        FIND_USERS_TITLE: "查找用户",
        DELETE_GATEWAYS: '删除网关',
        EDIT_USER_SETTINGS: "设置",
        BATCH_REGISTER_MAX_DEVICES: "设备数量大于用户允许的设备数量",
        ACTION_IN_PROGRESS: "行动仍在进行中",
        DATA_SEND_DATA_BTN: "发送",
        CLOSE_BUTTON_TEXT: "取消",
        CONNECTIVITY_PROFILE_NAME: "连通性配置文件",
        SERVICE_PROFILE_NAME: "服务资料",
        ROAMING_PROFILE_NAME: "漫游资料",

        PROFILE_DELETED_SUCCESS: "配置文件已成功删",
        ACTION_DELETE: "删除",
        TABLE_PROFILE_NAME: "档案名称",
        TABLE_COMMENT: "描述",
        TABLE_UUID: "UUID",
        TABLE_LINK: "链接",
        DELETE_PROFILE_MODAL_TITLE: "删除个人资料",
        DELETE_PROFILE_MESSAGE_MODAL_BODY: "你确定你要删除 {ProfileName} 轮廓?",
        DELETE_PROFILES_MESSAGE_MODAL_BODY: "你确定你要删除 {ProfilesNames} 轮廓?",

        PROFILE_EDIT_ACTION: "编辑个人资料",
        PROFILE_DELETE_ACTION: "删除个人资料",
        BULK_ACTION_DELETE_PROFILES: "删除配置文件",
        PROFILES: "简介",
        PROFILES_CAN_LIST_NAME: "名单 {name} 简介 ",
        PROFILES_CAN_INSPECT_NAME: "检查 {name} 简介",
        PROFILES_CAN_CREATE_NAME: "创建 {name} 简介",
        PROFILES_CAN_LINK_NAME: "名单 {name} 简介",
        PROFILES_CAN_RESTRICT_NAME: "限制 {name} 简介",
        PROFILES_CAN_LIST_HELP: "可以列出 {name} 简介",
        PROFILES_CAN_INSPECT_HELP: "可以看到的背景 {name} 简介",
        PROFILES_CAN_CREATE_HELP: "可以创建一个 {name} 简介",
        PROFILES_CAN_LINK_HELP: "可以创建链接 {name} 简介",
        PROFILES_CAN_RESTRICT_HELP: "将显示受限制的参数 {name} 简介",

        SUCCESS_DELETED_PROFILES_MESSAGE: "成功删除 {NumOfProfiles} 简介",
        FAIL_DELETE_PROFILES_MESSAGE: "无法删除 {NumOfProfiles} 简介",
        SUCCESS_FAIL_DELETE_PROFILES_MESSAGE: "删除 {NumOfDelProfiles} 从 {NumOfSelProfiles} 简介",
        FAIL_DELETE_PROFILE: "无法删除个人资料",

        PROFILES_TABLE_LINKED_FILTER: "关联",
        PROFILES_TABLE_UNLINKED_FILTER: "未链接",
        PROFILES_TABLE_ENTER_PLACEHOLDER_TEXT: "",

        PROFILES_VIEW_PROFILE: "查看资料",
        CAN_NOT_SEE_PROFILES: "无权查看个人资料",

        SHARE_PROFILE_CHECKBOX: "分享这个档案",

        QOS_PROFILES_TITLE: "QoS配置文件",
        ADD_QOS_PROFILE: "添加QoS配置文件",
        QOS_PROFILE_NAME: "QoS配置文件",

        NAV_QOS_PROFILES: "QoS配置文件",

        BATCH_UPLOAD_INSTRUCTIONS_CHOSE: "选择要上传的CSV文件",
        BATCH_UPLOAD_INSTRUCTIONS_DROP: "或拖放主题在这里",

        NAV_CHANNEL_PROFILES: "频道配置文件",
        CHANNEL_PROFILES_TITLE: "频道配置文件",
        ADD_CHANNEL_PROFILE: "添加频道配置文件",
        CHANNEL_PROFILE_NAME: "频道资料",

        REQUIRED_PROPERTY_MESSAGE: "是必需的财产",
        SERVICE_PROFILE_CONNECTIVITY: "连通性配置文件",
        SERVICE_PROFILE_ROAMING: "漫游资料",
        SERVICE_PROFILE_QoS: "QoS配置文件",
        SERVICE_PROFILE_CHANNEL: "频道资料",
        SERVICE_PROFILE_LEGEND: "服务资料",
        SELECT_PROFILE_PLACEHOLDER: "选择个人资料",
        LEAST_6_CHARACTERS_LONG_ERROR_MESSAGE: "必须至少6个字符",
        MOST_64_CHARACTERS_LONG_ERROR_MESSAGE: "最长不得超过64个字符",

        DECRYPTED: "解密",
        ENCRYPTED: "加密",
        WARNING: "警告",
        RSSI_WARNING: "RSSI 過濾器高於 過濾器",
        USER_LAST_NAME: "警告",
        USER_COUNTRY: "國家",
        USER_CITY: "城市",
        USER_ZIP_CODE: "郵政編碼",
        USER_STATE: "狀態",
        USER_PHONE: "電話",
        USER_MOBILE: "移動",
        USER_INDUSTRY: "行業",
        COMPANY_SIZE_HELP: "公司規模",
        USER_COMPANY_INFO: "其他公司信息",
        PLEASE_SELECT: "請選擇",

        //Static messages
        STATIC_MESSAGE_READ: "我已閱讀並同意",
        STATIC_MESSAGE_TERMS_OF_USE: "使用條款",
        STATIC_MESSAGE_AND: "和”",
        STATIC_MESSAGE_PRIVACY_POLICY: "隱私政策",
        STATIC_MESSAGE_PLEASE_SELECT: "請選擇",

        BATCH_MUST_BE_A_STRING: "必须是一个字符串",
        BATCH_FIELD: "Field",
        BATCH_INVALID_VALUE: "包含无效值。",
        BATCH_MUST_8: "必须是一个8字节的标识符",
        BATCH_ERROR_MUST_16BIT: "必须是16字节的标识符。",
        BATCH_ERROR_MUST_32BIT: "必须是32位标识符。",
        BATCH_VALID_VALIE_BETWEEN: "有效值介于”之间",
        BATCH_TO: "到",
        BATCH_VALID_IS_NOT_SUPPORTED: "不支持",
        BATCH_MUST_BE_BOOLEN: "必须被盗",
        BATCH_CAN_ONLY_BE_A_NUMBER: "只能是一个数字",
        BATCH_ALLOWED_VALUES_FOR: "允许的值",
        BATCH_ALLOWED_VALUES_FOR_IS: "是sf7，sf8，sf9，sf10，sf11和sf12",
        BATCH_ALLOWED_VALUES_FOR_STATIC: "静态移动室内和室外静电，室内静电户外移动，室内移动，户外",
        BATCH_OTAA_ABP: "只能有OTAA或ABP值",
        BATCH_0_9: "有效值为0到100",
        BATCH_0: "只能是0",
        BATCH_CAN_ONLY_VERSION: "只能是1.1.1,1.0.3,1.0.2,1.0.1或1.0.0",
        BATCH_CAN_A_B: "只能是A或B",
        BATCH_CAN_EU_US_CH: "可以是EU868，US902, China779，EU433，Australia915，China470，AS923或INDIA",
        BATCH_ERROR_ROW: "在行上发现错误",
        BATCH_ERROR: "错误",

        PASSWORD_LENGTH_DESC: "启用最小密码长度",
        PASSWORD_AGE_DESC: "强制用户在......之后更改密码.",
        PASSWORD_AGE_DESC_HOVER: "强制用户在一段时间后（以天为单位）更改密码",
        NO_RIGHTS_FOR_REQUESTED_OPERATION: "该帐户对所请求的操作没有足够的权限",
        PROFILE_NOT_FOUND: "找不到个人资料",
        REMOVE_REFERENCE_BEFORE_DELETE: "删除之前删除对配置文件的所有引用",
        PROFILES_NOT_DELETED: "配置文件未删除",
        SIMPLE_REGISTRATION: "添加设备",
        DEVICE_PROFILE: "设备配置文件",
        SERVICE_PROFILE: "服务资料",

        LINKED_PROFILES_NOT_FOUND: "找不到一个或多个链接的配置文件（或此帐户不可见）",
        MAC_MSG: "MAC消息",
        MAC_MSG_HELP: "设置用户查看解码的MAC消息的权限",
        SHOW_MAC_MSG: "显示MAC消息",
        MAC_MSG: "MAC消息",
        SHOW_DECODE_PAYLOAD: "解码的有效载荷",
        SHOW_DATA: "数据",
        POSITION_ESTIMATES: "位置估计",
        EDIT_USER_NETWORK_ACCESS_LOC_INFO: "网关位置信息",
        EDIT_USER_NETWORK_ACCESS_LOC_INFO_HELP: "可以查看网关位置信息",
        DUPLICATE_HEADERS: "csv文件包含重复的标头",

        // Linked Profiles
        ADD_NEW_LINKED_PROFILE: "添加链接配置文件",
        LINKED_PROFILE_MODAL_SOURCE_PROFILE: "来源简介",
        LINKED_PROFILE_MODAL_CAN_INSPECT_PROFILE: "可以检查配置文件",
        GETTING_TARGET_PROFILES_LIST_ERROR: "无法获取源配置文件的配置文件列表",
        CAN_NOT_GET_TARGET_PROFILE_NAME: "无法获取源配置文件名称",
        CREATE_LINKED_PROFILE_ACTION: "创建链接配置文件",

        BATCH_REGISTER_DEVICES_ERROR3: "csv文件必须包含具有配置文件的设备的必需属性",
        BATCH_WRONG_FORMAT: " 格式不正确",
        UNLINK_PROFILE_MESSAGE_MODAL_BODY: "您确定要取消关联 {ProfileName} 个人资料吗？",
        UNLINK_LINKED_PROFILE_MODAL_TITLE: "取消关联个人资料",
        UNLINK_LINKED_PROFILE_ACTION: "取消关联个人资料",
        UNLINK_PROFILE_MODAL_CONFIRM_BUTTON: "取消链接",
        PROFILE_UNLINKED_SUCCESS: "配置文件已成功取消链接",
        PROFILE_UNLINK_ERROR: "取消链接配置文件时出错",
        LINKED_PROFILE_MODAL_TARGET_USER: "目标用户",
        LINKED_PROFILE_MODAL_TARGET_USER_PLACEHOLDER: "选择目标配置文件的所有者",
        ERROR_GETTING_PROFILE_OWNER_LIST: "获取个人资料所有者列表时出错",
        CAN_NOT_GET_PROFILE_OWNER_NAME: "无法获取个人资料所有者名称",
        LINKED_PROFILE_MODAL_SOURCE_USER: "来源用户",
        LINKED_PROFILE_MODAL_SOURCE_USER_PLACEHOLDER: "选择源配置文件的所有者",

        DEFAULT_PUSH_CONFIGURATION: '默认推送配置',
        CHOSE_DEVICE: '选择要管理的设备',
        MY_APPS_ACTION_BULK_DELETE: '删除应用',
        MY_DEVICES_ACTION_ASSIGN_GROUP: "分配到群组",
        MSG_DELETE_GATEWAYS_TITLE: '删除网关',
        MSG_DELETE_GATEWAYS_BODY: '您确定要删除{count，plural，= 0 {}一个{1 gateway}其他{#gateways}}',
        MSG_DELETE_GATEWAYS_SUCCESS_BODY: '网关已成功删除',
        MSG_DELETE_GATEWAYS_FAIL_BODY: '无法删除网关',
        E_GTW_INFO: '信息',
        E_GTW_INFO_MSG_1: '要在新网关上安装OrbiWAN网关守护程序，请执行以下步骤',
        E_GTW_INFO_MSG_2: '1）输入网关的名称/描述（可选）',
        E_GTW_INFO_MSG_3: '2）从下面的列表中选择网关型号',
        E_GTW_INFO_MSG_4: '3）单击“单击此处复制到剪贴板”以复制安装命令',
        E_GTW_INFO_MSG_5: '4）连接网关，并（SSH）登录到网关（有关如何登录网关的更多信息，请参阅网关制造商的文档）',
        E_GTW_INFO_MSG_6: '5）在网关的SSH Shell中粘贴并执行安装命令',
        E_GTW_INFO_MSG_7: '可以找到更多信息和说明',
        E_GTW_INFO_MSG_8: '这里..',
        E_GTW_INSTALLATION: '安装',
        E_GTW_COPY_TO_CLIPBOARD: '点击复制到剪贴板',
        E_GTW_MODELS: '网关模型',
        E_GTW_NO_MODELS: "没有网关型号",

        MSG_DELETE_PACKET_BODY: '您确定要删除设备“{deveui}”的数据包吗 ？',
        MSG_DELETE_PACKET_BODY_GROUP: '您确定要删除组“{groupid}”的数据包吗？',

        MSG_DELETE_PACKETS_TITLE: '无法删除数据包',
        MSG_DELETE_PACKETS_BODY: '您确定要为设备“{deveui}”删除{count，plural，= 0 {} =1 {1 packet}其他{#package}} 包 "{deveui}" ?',
        MSG_DELETE_PACKETS_BODY_GROUP: '你确定你要删除 {count, plural, =0{} =1{1 packet} 其他{# packets}}  对于小组 "{groupid}" ?',
        MSG_DELETE_PACKETS_SUCCESS_BODY: '已删除{count，plural，= 0 {}一个{1数据包}其他{#package}}设备“{deveui}”',
        MSG_DELETE_PACKETS_FAIL_BODY: '无法为设备“{deveui}”删除{count，plural，= 0 {} one {1 packet}其他{#package}} "{deveui}"',
        MSG_DELETE_PACKETS_GROUP_SUCCESS_BODY: '删除 {count, plural, =0{} =1{1 packet} 其他{# packets}} 对于小组 "{groupid}"',
        MSG_DELETE_PACKETS_GROUP_FAIL_BODY: '无法删除 {count, plural, =0{} =1{1 packet} 其他{# packets}} 对于小组 "{group}"',
        MSG_USERS_DELETE_CONFIRM_TITLE: '删除{type}！',
        MSG_USERS_DELETE_CONFIRM: '你确定你要删除 {count, plural, =0{} =1{ 1 {type}} other{# {type}}}?',
        MSG_USERS_DELETE_SUCCESS_BODY: '成功删除 {count, plural, =0{} =1{ 1 {type}} other{# {type}}}.',
        MSG_USERS_DELETE_FAIL_BODY: '无法删除{types} s。',
        SHOW_SELECTED_USERS: '显示所选用户',
        SHOW_ALL_USERS: '显示所有用户',
        DELETE_USERS: '删除用户',
        SUSPEND_USER: 'DISABLE_USER',
        DISABLE_USER: '禁用用户',
        REACTIVATE_USER: '重新激活用户',
        SUSPEND_USER_SUCCESS: '用户已成功暂停',
        DISABLE_USER_SUCCESS: '用户成功禁用',
        ACTIVATE_USER_SUCCESS: '用户成功激活',
        USER_STATUS_ERROR: '用户状态更改期间发生错误',

        SUSPEND_ORGANIZATION: '暂停组织',
        DISABLE_ORGANIZATION: '禁用组织',
        REACTIVATE_ORGANIZATION: '重新激活组织',
        SUSPEND_ORGANIZATION_SUCCESS: '组织成功暂停',
        DISABLE_ORGANIZATION_SUCCESS: '组织成功禁用',
        ACTIVATE_ORGANIZATION_SUCCESS: '组织成功激活',
        ORGANIZATION_STATUS_ERROR: '组织状态更改期间发生错误',

        FROM: '用户',
        TO: '除',
        USER_PASSWORD_SET_SUCCESS_MSG: "您的密码已成功设置。欢迎！",
        INITIAL_PASSWORD_TITLE: "设置初始密码",
        INITIAL_PASSWORD_MESSAGE: "请使用以下字段设置您的第一个密码",
        PER_PAGE: "每页",
        ADMIN_DEVICES_TITLE: "查找设备",
        HOME_PAGE: "转到主页",
        FORGOT_PASSWORD_INSTRUCTIONS: "请在下面输入您的用户名，我们会向您发送电子邮件如何更改密码的说明",
        FORGOT_USERNAME_INSTRUCTIONS: "在下面输入您的电子邮件地址，我们会将您的用户名发送给您",
        RETURN_TO_LOGIN: "返回登录",
        EMAIL_USERNAME_INSTRUCTIONS: "已向您发送包含您用户名的电子邮件",
        PASSWORD_RESET_INSTRUCTIONS: "已发送密码重置说明",
        PASSWORD_CHANGED: "密码已更改！",
        SHOW: "密码",
        ERROR_SIGNUP_STEP1: "服务器问题",
        MSG_DELETE_GROUPS_BODY_SINGLE: '您确定要删除群组{groupid}吗？',

        // API Based Table
        ERROR_IN_QUERY: "查询字段中存在错误",
        ERROR_UNKNOWN_FILTER_SOURCE: "指定的过滤器引用未知资源",
        ERROR_PAGE_STATE_EXPIRED: "页面状态已过期",
        ERROR_TOO_BIG_QUERY: "结果中有太多记录",
        ERROR_PAGE_STATE_REQUIRED: "页面状态需要能够使用特定选项进行查询",

        TABLE_SHARED: "共享",
        FILTER_PROFILES_ERROR: "过滤配置文件时出错",
        ERROR_GETTING_USER_LIST: "获取用户列表时出错",
        ERROR_GETTING_CUSTOMER_LIST: "获取客户列表时出错",

        EDIT_USER_TENANCY_ADMIN_RIGHTS_LABEL: "租户管理员权利",
        EDIT_USER_TENANCY_ADMIN_RIGHTS_HELP: "具有租户管理员权限的用户可以查看客户并将其分配给租户",
        EMPTY_SPACE_FILTER_ERROR: "搜索值不能包含空格",

        PROFILES_NOTES_FIELD: "笔记",

        MY_GATEWAY_NUMBER_MESSAGE:  "他們的 {numberOfGateways, plural, =0{不是網關} =1{是 1 網關} other{是 # 不是網關}} 已註冊.",
        USERS_NUMBER_MESSAGE:  "他們的 {numberOfUsers, plural, =0{不是網關} =1 {是 1 不網關} other{是 # 不網關}} i已註冊",
        CUSTOMERS_NUMBER_MESSAGE:  "他們的 {numberOfCustomers, plural, =0{是關} =1{是 1 網關} other{是 # 不關}} 已註冊.",
        ORGANIZATIONS_NUMBER_MESSAGE:  "他們的 {numberOfUsers, plural, =0{没有组织} =1{是 1 个组织} other{是 # 组织机构}} 已註冊.",
        APPS_NUMBER_MESSAGE:  "他們的 {numberOfApps, plural, =0{是關} =1{是 1 網關} other{是 # 不關}} 已註冊.",
        NUMBER_OF_ITEMS_IN_TABLE:  "他們的 {NumberOfItemsInTable} 您列表中的項目。",

    // Device Profiles Json Schema Form
    DEVICEPROFILES_TITLE: "设备配置文件",
    DEVICEPROFILES_PROPERTIES_ACTIVATION_TITLE: "设备激活模式",
    DEVICEPROFILES_PROPERTIES_RFREGION_TITLE: "LoRaWAN地区",
    DEVICEPROFILES_PROPERTIES_MACVERSION_TITLE: "LoRaWAN MAC版",
    DEVICEPROFILES_PROPERTIES_REGPARAMSREVISION_TITLE: "LoRaWAN MAC区域参数修订版",
    DEVICEPROFILES_PROPERTIES_MAXEIRP_TITLE: "最大EIRP [dBm]",
    DEVICEPROFILES_PROPERTIES_MAXEIRP_DESCRIPTION: "设置器件最大等效全向辐射功率（EIRP）。这是设备的最大EIRP能力（不一定是默认值）",
    DEVICEPROFILES_PROPERTIES_DEFAULTDEVICECLASS_TITLE: "复位后设备的默认设备",
    DEVICEPROFILES_PROPERTIES_SUPPORTSCLASSB_TITLE: "支持B类",
    DEVICEPROFILES_PROPERTIES_SUPPORTSCLASSC_TITLE: "支持C类",
    DEVICEPROFILES_PROPERTIES_TRANSMIT_CAPABLE_ONLY_TITLE: "仅发送能力的设备不能接收下行链路（即它没有接收器）。设置此项后，网络将永远不会向设备发送任何下行链路",
    DEVICEPROFILES_PROPERTIES_LORA_FCNT32BITS_TITLE: "FCNT 32位计数器（仅适用于1.0.x设备）",
    DEVICEPROFILES_PROPERTIES_LORA_FCNT32BITS_DESCRIPTION: "选择FCNT计数器的大小",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_APP_PAYLOAD_TITLE: "Max接受的下行应用有效载荷大小[字节]",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_PHY_PAYLOAD_TITLE: "最大接收下行链路PHY有效载荷大小[字节]",
    DEVICEPROFILES_PROPERTIES_DL_MAX_NUM_MAC_CMD_TITLE: "下行链路消息中的最大MAC命令数",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_MAC_CMD_TITLE: "下行消息中MAC命令的最大接受大小[字节]",
    DEVICEPROFILES_PROPERTIES_ADR_CAPABILITY_TITLE: "器件支持自适应数据速率（ADR）",
    DEVICEPROFILES_PROPERTIES_SUPPORT_FUOTA_TITLE: "设备支持无线固件更新（FUOTA）",
    DEVICEPROFILES_PROPERTIES_PERSISTED_FCNT_TITLE: "设备保持FCNT并在设备重置时保持FCNT值（对于LoraWan 1.1）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_TITLE: "设备出厂设置",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_DESCRIPTION: "如果未提供工厂预设，则网络将使用该区域的LoraWan区域默认设置。",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_TITLE: "区域预设",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RFREGION_DESCRIPTION: "LoRaWAN地区",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAXEIRP_TITLE: "最大EIRP [dBm]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAXEIRP_DESCRIPTION: "设置器件最大等效全向辐射功率（EIRP）。这是当前区域使用的默认值（不一定是最大功能）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_UL_DWELL_TIME_TITLE: "上行停留时间（仅限AU915，AS923和JP923区域）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_UL_DWELL_TIME_DESCRIPTION: "设置0表示无约束，1表示400毫秒",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_DL_DWELL_TIME_TITLE: "下行停留时间（仅限AU915，AS923和JP923区域）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_DL_DWELL_TIME_DESCRIPTION: "设置0表示无约束，1表示400毫秒",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_ADR_ACK_LIMIT_TITLE: "LoraWan规范中定义的AD_ACK_LIMIT。 （仅限LoraWan 1.1）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_ADR_ACK_DELAY_TITLE: "LoraWan规范中定义的ADR_ACK_DELAY（仅限LoraWan 1.1）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_TIME_BETWEEN_REJOIN_REQ_TITLE: "重新加入尝试之间的最长时间[秒]（仅限LoraWan 1.1）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_NUM_OF_UL_BETWEEN_REJOIN_REQ_TITLE: "REJOIN请求之间的最大上行链路数（仅限LoraWan 1.1）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_UL_DR_SUPPORTED_TITLE: "支持最高的上行链路数据速率",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX2_DR_TITLE: "RX2下行链路时隙的默认数据速率",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX2_FREQ_MHZ_TITLE: "RX2下行链路时隙的默认频率[MHz]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX1_DELAY_TITLE: "TX时隙后RX1下行链路时隙的默认延迟[秒]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX1_DR_OFFSET_TITLE: "上行链路和下行链路之间的默认数据速率偏移",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_FREQ_MHZ_TITLE: "B类ping槽的默认频率[MHz]。设置为0时，使用跳跃槽。",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_DR_TITLE: "B类ping槽的默认数据速率",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_PERIODICITY_TITLE: "Ping-slot的B类周期",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_TITLE: "渠道定义",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_FREQ_MHZ_TITLE: "频道频率[MHz]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_MASKED_TITLE: "频道禁用（蒙面）",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_DL_FREQ_MHZ_TITLE: "下行链路频率如果与上行链路频率不同[MHz]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MASK_125KHZ_TITLE: "125kHz通道的通道掩码",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MASK_500KHZ_TITLE: "500kHz通道的通道掩码",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_FREQ_CHANGE_NOT_SUPPORTED_TITLE: "设备频率列表是固定的，不能通过网络更改",

    // Connectivity Profiles Json Schema Form
    CONNECTIVITYPROFILES_TITLE: "连通性配置文件",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_QUEUE_LENGTH_TITLE: "最大下行队列大小",
    CONNECTIVITYPROFILES_PROPERTIES_LORA_LOC_ENABLE_TITLE: "启用LoRa位置服务",
    CONNECTIVITYPROFILES_PROPERTIES_CLASS_B_ALLOWED_TITLE: "允许B类操作",
    CONNECTIVITYPROFILES_PROPERTIES_CLASS_C_ALLOWED_TITLE: "允许C类操作",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOW_NON_ADR_TITLE: "允许不启用ADR的设备",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOW_NON_ADR_DESCRIPTION: "对于不使用ADR的设备，如果未设置此标志，则不允许与设备通信。任何收到的上行链路都不会被转发，并且会向应用程序发送错误。",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOWED_DUTY_CYCLE_TITLE: "允许的工作周期。根据LoraWan规范编码。",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOWED_DUTY_CYCLE_DESCRIPTION: "聚合的工作周期被计算为1 / allowed_duty_cycle ^ 2",
    CONNECTIVITYPROFILES_PROPERTIES_ENFORCE_DUTY_CYCLE_TITLE: "网络将通过向设备发送占空比MAC命令来强制执行占空比",
    CONNECTIVITYPROFILES_PROPERTIES_DL_PACKET_EXPIRY_TIME_HOURS_TITLE: "下行数据包到期时间[小时]",
    CONNECTIVITYPROFILES_PROPERTIES_UL_PACKET_EXPIRY_TIME_HOURS_TITLE: "上行数据包到期时间[小时]",
    CONNECTIVITYPROFILES_PROPERTIES_ALWAYS_PERSIST_UPLINK_PAYLOAD_TITLE: "无论应用程序的推送答案如何，始终存储上行链路有效",
    CONNECTIVITYPROFILES_PROPERTIES_FORCE_MAC_CMD_ENCRYPTION_TITLE: "强制MAC命令加密",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_UL_MSGS_PER_PERIOD_TITLE: "仅供将来使用：每个周期的最大上行链路消息数",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_MSGS_PER_PERIOD_TITLE: "仅供将来使用：每个周期的最大下行链路消息数",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_UL_BYTES_PER_PERIOD_TITLE: "仅供将来使用：每个周期的上行链路中的最大字节数",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_BYTES_PER_PERIOD_TITLE: "仅供将来使用：每个周期的下行链路中的最大字节数",
    CONNECTIVITYPROFILES_PROPERTIES_PERIOD_HOURS_TITLE: "仅供将来使用：小时数",
    CONNECTIVITYPROFILES_PROPERTIES_RT_MSG_ALLOWED_TITLE: "提供实时API",
    CONNECTIVITYPROFILES_PROPERTIES_RT_MSG_DELAY_TITLE: "分配给应用程序的时间响应实时下行链路请求消息[s]",
    CONNECTIVITYPROFILES_PROPERTIES_DOWNLINK_ALLOWED_TITLE: "允许向设备应用下行链路有效载荷",
    CONNECTIVITYPROFILES_PROPERTIES_CONFIRMED_UPLINK_ALLOWED_TITLE: "设备可以发送确认的上行链如果在不允许的情况下发送确认的上行链路，则不会向设备发送确认，但仍将接收上行链路",
    CONNECTIVITYPROFILES_PROPERTIES_DEVICE_INITIATED_MAC_COMMANDS_ALLOWED_TITLE: "设备可以使用设备启动的MAC命令（例如LinkCheckReq和DeviceTimeReq）",
    CONNECTIVITYPROFILES_PROPERTIES_MULTICAST_ALLOWED_TITLE: "允许多播",
    CONNECTIVITYPROFILES_PROPERTIES_FUOTA_ALLOWED_TITLE: "允许通过空中固件更新（FUOTA）",

    // Roaming Profiles Json Schema Form
    ROAMINGPROFILES_TITLE: "漫游资料",
    ROAMINGPROFILES_PROPERTIES_PASSIVE_ROAMING_ALLOWED_TITLE: "允许被动漫游",

    // QoS Profiles Json Schema Form
    QOSPROFILE_TITLE: "QoS配置文件",
    QOSPROFILE_PROPERTIES_REGION_TITLE: "LoRaWAN地区",
    QOSPROFILE_PROPERTIES_MIN_DR_TITLE: "最低数据速率",
    QOSPROFILE_PROPERTIES_MIN_DR_DESCRIPTION: "上行链路帧使用的最小数据速率。 min_dr的值必须等于或小于max_dr的值。",
    QOSPROFILE_PROPERTIES_MAX_DR_TITLE: "最大数据速率",
    QOSPROFILE_PROPERTIES_MAX_DR_DESCRIPTION: "上行链路帧使用的最大数据速率。 max_dr的值必须大于或等于min_dr的值",
    QOSPROFILE_PROPERTIES_MIN_TXP_TITLE: "最小TX功率",
    QOSPROFILE_PROPERTIES_MIN_TXP_DESCRIPTION: "以dBm为单位的最小发射功率，用于上行链路帧。 min_txp的值必须等于或小于max_txp的值。",
    QOSPROFILE_PROPERTIES_MAX_TXP_TITLE: "最大TX功率",
    QOSPROFILE_PROPERTIES_MAX_TXP_DESCRIPTION: "以dBm为单位的最大发射功率，用于上行链路帧。 man_txp的值必须大于或等于min_txp的值。",
    QOSPROFILE_PROPERTIES_MIN_TXP_INDEX_TITLE: "最小TX功率指数（LoRaWAN指数值）",
    QOSPROFILE_PROPERTIES_MIN_TXP_INDEX_DESCRIPTION: "用于上行链路帧的最小TX功率的索引。请注意，较低的索引是较高的功率级别，这意味着索引= 0是最高可能的功率。",
    QOSPROFILE_PROPERTIES_MAX_TXP_INDEX_TITLE: "最大TX功率指数（LoRaWAN指数值）",
    QOSPROFILE_PROPERTIES_MAX_TXP_INDEX_DESCRIPTION: "要用于上行链路帧的最大TX功率的索引。请注意，较低的索引是较高的功率级别，这意味着索引= 0是最高可能的功率。",
    QOSPROFILE_PROPERTIES_TXP_SELECT_TITLE: "按功率级索引或功率级dBm选择TX功率控制。如果选择索引，则仅使用上面的索引最小/最大功率水平，反之亦然，如果选择功率水平，则仅使用上述最小/最大功率水平值。",
    QOSPROFILE_PROPERTIES_SNR_MARGIN_DB_TITLE: "SNR余量以dB为单位",
    QOSPROFILE_PROPERTIES_SNR_MARGIN_DB_DESCRIPTION: "该参数用于数据速率适配。这里指定的值是对解调所需SNR的平均SNR所取的余量。",
    QOSPROFILE_PROPERTIES_RSSI_MARGIN_DB_TITLE: "RSSI余量以dB为单位",
    QOSPROFILE_PROPERTIES_RSSI_MARGIN_DB_DESCRIPTION: "该参数用于发射功率控制。这里指定的值是解调所需RSSI的平均RSSI余量。",
    QOSPROFILE_PROPERTIES_TARGET_REDUNDANCY_TITLE: "目标冗余",
    QOSPROFILE_PROPERTIES_TARGET_REDUNDANCY_DESCRIPTION: "此参数用于控制每个上行链路未确认帧的重新传输次数。冗余被定义为总体分集：空间分集（看到终端设备的网关的数量）+时间分集（每个未确认的上行链路帧的传输的数量）。",
    QOSPROFILE_PROPERTIES_MAX_FER_PCT_TITLE: "最大FER％",
    QOSPROFILE_PROPERTIES_MAX_FER_PCT_DESCRIPTION: "最大可接受的FER（帧错误率）。",
    QOSPROFILE_PROPERTIES_MIN_UNCONF_UL_REP_TITLE: "最小未经证实的UL重复",
    QOSPROFILE_PROPERTIES_MIN_UNCONF_UL_REP_DESCRIPTION: "应在网络服务器上接收单个未确认的上行链路帧的最小次数。 min_unconf_ul_rep的值必须等于或小于max_unconf_ul_rep的值。",
    QOSPROFILE_PROPERTIES_MAX_UNCONF_UL_REP_TITLE: "最大未经证实的UL重复",
    QOSPROFILE_PROPERTIES_MAX_UNCONF_UL_REP_DESCRIPTION: "应在网络服务器上接收单个未确认的上行链路帧的最大次数。 max_unconf_ul_rep的值必须大于或等于min_unconf_ul_rep的值。",
    QOSPROFILE_PROPERTIES_AVG_WINDOW_SIZE_TITLE: "平均窗口长度",
    QOSPROFILE_PROPERTIES_AVG_WINDOW_SIZE_DESCRIPTION: "平均窗口长度。 ADR算法使用此参数来计算用于链路自适应的历史（平均RSSI，SNR，FER）。",
    QOSPROFILE_PROPERTIES_ADDITIONAL_SNR_MARGIN_JOIN_TITLE: "加入时的额外SNR余量",
    QOSPROFILE_PROPERTIES_ADDITIONAL_SNR_MARGIN_JOIN_DESCRIPTION: "当SNR统计不好时，此参数用于JOIN或RESET上的数据速率自适应。添加额外的余量以决定第一个接收的数据包。",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_ON_JOIN_TITLE: "加入时ADR的最大SF步长",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_ON_JOIN_DESCRIPTION: "在JOIN或RESET上一次性更改的最大数据速率步数。",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_TITLE: "ADR的最大SF步长",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_DESCRIPTION: "在常规模式下，可以一次性更改的最大数据速率步数。",
    QOSPROFILE_PROPERTIES_FRAME_ERROR_RATE_LIMIT_FOR_FSK_TITLE: "FSK的帧错误率[％]限制，之后数据速率将降低",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_PWR_CRTL_STEPS_TITLE: "两个TxPower控制步骤之间的延迟",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_PWR_CRTL_STEPS_DESCRIPTION: "两个连续发射功率控制步骤之间的延迟。此延迟用于在进行另一个决策之前让平均RSSI收敛到新值。延迟单元是多个接收的上行链路帧。",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_NBREP_CRTL_STEPS_TITLE: "两个NbRep控制步骤之间的延迟",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_NBREP_CRTL_STEPS_DESCRIPTION: "两个连续重复次数（NbRep）控制步骤之间的延迟。此延迟用于在进行另一个决策之前让平均冗余收敛到新值。",
    QOSPROFILE_PROPERTIES_GATEWAY_AGGREGATION_TIME_TITLE: "网关聚合时间[ms]",
    QOSPROFILE_PROPERTIES_GATEWAY_AGGREGATION_TIME_DESCRIPTION: "网络服务器将等待从所有网关收集一个上行链路数据包的消息的时间[ms]。请注意，无论设置的值如何，网络服务器都将等待至少350毫秒。如果在此期间接收到新的上行链路分组（具有更高的FCNT），则此时中止聚合。",

    // Channel Profiles Json Schema Form
    CHANNELPROFILES_TITLE: "频道简介",
    CHANNELPROFILES_PROPERTIES_REGION_TITLE: "LoRaWAN地区",
    CHANNELPROFILES_PROPERTIES_RX2_DR_TITLE: "RX2 DL插槽数据速率",
    CHANNELPROFILES_PROPERTIES_RX2_FREQ_ARR_MHZ_TITLE: "RX2 DL插槽频率",
    CHANNELPROFILES_PROPERTIES_RX2_FREQ_ARR_MHZ_ITEMS_TITLE: "频率[MHz]",
    CHANNELPROFILES_PROPERTIES_RX1_DR_OFFSET_TITLE: "RX1 UL数据速率到DL数据速率偏移",
    CHANNELPROFILES_PROPERTIES_MAXEIRP_TITLE: "Max EIRP",
    CHANNELPROFILES_PROPERTIES_MAXEIRP_DESCRIPTION: "设备最大等效全向辐射功率（EIRP）",
    CHANNELPROFILES_PROPERTIES_UL_DWELL_TIME_TITLE: "上行停留时间",
    CHANNELPROFILES_PROPERTIES_UL_DWELL_TIME_DESCRIPTION: "设置0表示无约束，1表示400毫秒",
    CHANNELPROFILES_PROPERTIES_DL_DWELL_TIME_TITLE: "下行停留时间",
    CHANNELPROFILES_PROPERTIES_DL_DWELL_TIME_DESCRIPTION: "设置0表示无约束，1表示400毫秒",
    CHANNELPROFILES_PROPERTIES_ADR_ACK_LIMIT_TITLE: "ADR确认限制",
    CHANNELPROFILES_PROPERTIES_ADR_ACK_DELAY_TITLE: "ADR确认延迟",
    CHANNELPROFILES_PROPERTIES_MAX_TIME_BETWEEN_REJOIN_REQ_TITLE: "重新加入尝试之间的最长时间（秒）",
    CHANNELPROFILES_PROPERTIES_MAX_NUM_OF_UL_BETWEEN_REJOIN_REQ_TITLE: "REJOIN请求之间的最大上行链路数",
    CHANNELPROFILES_PROPERTIES_RX_DELAY_TITLE: "延迟[s]从TX插槽到RX1插槽",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_FREQ_ARR_MHZ_TITLE: "Ping-slot频率",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_FREQ_ARR_MHZ_ITEMS_TITLE: "频率[MHz]",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_DR_TITLE: "Ping槽数据速率",
    CHANNELPROFILES_PROPERTIES_CHANNELS_TITLE: "渠道定义",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_FREQ_MHZ_TITLE: "频道频率[MHz]",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_MASKED_TITLE: "频道禁用（蒙面）",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_DL_FREQ_MHZ_TITLE: "下行链路频率如果与上行链路频率不同[MHz]",
    CHANNELPROFILES_PROPERTIES_MASK_125KHZ_TITLE: "用于基于FCC的MAC的125kHz信道的信道掩码",
    CHANNELPROFILES_PROPERTIES_MASK_500KHZ_TITLE: "用于基于FCC的MAC的500kHz信道的信道掩码",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_PRIORITY_TITLE: "当TX持续时间高于980ms时，RX下行链路时隙优先级",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_PRIORITY_RX1_SUB_SEC_DURATION_TITLE: "当持续时间小于980ms时，TX的RX下行链路时隙优先级",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_FOR_LINKADRREQ_TITLE: "下行链路的RX下行链路时隙优先级可以包含LinkAdrReq",
    CHANNELPROFILES_PROPERTIES_USE_AUTOMATIC_FREQ_SELECTION_TITLE: "使用网关渠道计划",
    CHANNELPROFILES_PROPERTIES_USE_AUTOMATIC_FREQ_SELECTION_DESCRIPTION: "设置为true时，设备将配置主网关的频率，下面定义的通道将被忽略。使用通道配置文件的通道时应设置为false。",
    CHANNELPROFILES_PROPERTIES_MIN_LORAWAN_SPEC_REQUIRED_TITLE: "最低要求的LoRaWAN MAC版本",
    CHANNELPROFILES_PROPERTIES_SEND_FULL_MASK_CONFIGURATION_TITLE: "每次发送掩码时强制网络服务器发送完整掩码。默认是仅发送修改的掩码部分。",

    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册服务档案。",
    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个服务档案。",
    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable}服务配置文件.",

    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册设备配置文件。",
    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个设备配置文件。",
    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable}设备配置文件.",

    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册连接配置文件。",
    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个连接配置文件。",
    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable}连接配置文件.",

    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册漫游配置文件。",
    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个漫游配置文件。",
    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable}漫游配置文件.",

    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册QoS个人资料。",
    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个QoS个人资料。",
    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable} QoS个人资料.",

    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "没有注册频道个人资料。",
    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "已注册1个频道个人资料。",
    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "已注册{NumberOfItemsInTable}频道配置文件.",

    FORM_ERROR_MESSAGE_REQUIRED: "是必需的财产",
    FORM_ERROR_MESSAGE_ENUM: "应该等于允许值之一",
    FORM_ERROR_MESSAGE_MAXITEMS: "不应超过{limit}项",
    FORM_ERROR_MESSAGE_MINITEMS: "不应少于{limit}项",
    FORM_ERROR_MESSAGE_MINIMUM_MAXIMUM: "应该 {comparison} {limit}",
    FORM_ERROR_MESSAGE_MINLENGTH: "不应短于{limit}个字符",
    FORM_ERROR_MESSAGE_ANYOF: "应该匹配anyOf中的某些模式",
    FORM_ERROR_MESSAGE_ONEOF: "应该只匹配oneOf中的一个模式",
    FORM_ERROR_MESSAGE_TYPE_NUMBER: "应该是数字",
    FORM_ERROR_MESSAGE_TYPE_INTEGER: "应该是整数",
    FORM_ERROR_MESSAGE_UNIQUEITEMS: "不应该有重复的项目（项目## {itemOne}和{itemTwo}相同）",
    FORM_ERROR_MESSAGE_PATTERN: "应匹配模式 '{pattern}'",
    FORM_ERROR_MESSAGE_MAXLENGTH: "不应超过{limit}个字符",

    FIND: "找",
    ACCOUNTS: "帐号",
    NAV_SIGNED_AS: "签名为",

    UNAUTHORIZED_ERROR: "基本认证方案中使用的用户名/密码无效或不存在。",
    FORBIDDEN_ERROR: "帐户没有客户管理权限",

    PROFILES_DEVICE_PROFILE: "设备",
    PROFILES_SERVICE_PROFILE: "服务",
    PROFILES_CONNECTIVITY_PROFILE: "连接",
    PROFILES_ROAMING_PROFILE: "漫游",
    PROFILES_CHANNEL_PROFILE: "渠道",
    PROFILES_QOS_PROFILE: "服务质量",

    ADMIN_TYPE_CUSTOMER: "客户管理员",
    ADMIN_TYPE_USER: "用户管理员",
    ADMIN_TYPE_ORGANIZATION: "组织管理员",
    TYPE_CUSTOMER: "顾客",
    TYPE_USER: "用户",

    GET_USER_INFO_ERROR_400: "JSON消息有问题",
    GET_USER_INFO_ERROR_404: "用户ID不存在",
    CAN_NOT_GET_USER_INFO: "无法获取用户信息",

    GET_PAGINATION_ERROR_400: "查询字段中存在错误",
    GET_PAGINATION_ERROR_404: "指定的过滤器引用未知资源",
    GET_PAGE_STATE_ERROR_410: "使用page_state字段，页面已过期",
    GET_PAGINATION_ERROR_413: "结果中有太多记录",
    GET_PAGE_STATE_ERROR_416: "页面状态需要能够使用特定排序选项进行查询。",

    UPDATE_USER_ERROR_401: "不允许更改tenant_id",
    UPDATE_USER_ERROR_403: "无法分配该帐户较高的权限",

    MSG_ASSIGN_APPS_SUCCESS_BODY: '已成功将应用分配给群组.',
    MSG_ASSIGN_APPS_FAIL_BODY: '无法将应用分配给组.',

    MSG_REMOVE_APPS_SUCCESS_BODY: '已成功从群组中删除应用.',
    MSG_REMOVE_APPS_FAIL_BODY: '无法从群组中删除应用.',


    CHANGE_OWNER_ASSIGN_TO: "分配给",
    MSG_USER_CREATE_FORBIDDEN_BODY: "无法注册具有比创建者更多权限的{ut}",
    MSG_USER_UPDATE_FORBIDDEN_BODY: "无法分配该帐户较高的权限",

    MIGRATE_DEVICE: "迁移设备",
    MIGRATE_DEVICES: "迁移设备",
    MIGRATE_DEVICE_ACTION_WARNING: "警告此操作是不可逆转的",
    MSG_ERROR_INVALID_FIELD: "搜索字段的值无效",

    CREATABLE_SELECT_LABEL: "UUID",
    INVALID_PROFILE_UUID_FORMAT: "配置文件UUID无效。有效格式为 XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX (0-9，a-z)",

    ACTION_EDIT_TITLE: "编辑",
    ACTION_ADD_TITLE: "加",

    FORM_ERROR_MESSAGE_UNIQUE_ITEMS: "频道定义不应包含重复项",

    SUPPORT: "支持",
    ALLOW: "允许",
    PROFILE_DOES_NOT_SUPPORT_CLASS_B_C: "{profile}不支持{support} 类 {class}",

    FORM_ERROR_MESSAGE_MIN_GREATER_THEN_MAX: "{property} 项目 {item}: dr_min值不能大于dr_max值",

    MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED: "不允许更改设备的设备配置文件",
    HELP_MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED: "不允许更改设备配置文件",

    MSG_DEVICE_BULK_EDIT: "无法编辑使用配置文件和参数一起创建的设备",
    MSG_DEVICE_DEVADDR_NWKSKEY_ALREADY_REGISTERED: "Devaddr和nwkskey对已经注册了其他DevEUI",
    MSG_DEVICE_DEVADDR_NWKSKEY_ON_MULTIPLE_DEVICES_NOT_ALLOWED: "不允许在多个设备上设置devaddr和nwkskey",

    MSG_USERS_DELETE_BULK_FORBIDDEN_BODY: "禁止 - 无法删除属于其他父级的 {count, plural, =0{} =1{{types}} other{{types}}}",
    MSG_USERS_DELETE_BULK_NOT_FOUND_BODY: "找不到某些选定的{types}",
    MSG_USERS_DELETE_FORBIDDEN_BODY: "禁止 - 无法删除属于其他父级的 {type}",
    MSG_USERS_DELETE_NOT_FOUND_BODY: "找不到{type}",

    CAN_NOT_RETURN_TO_PARENT: "恢复行动失败",

    PUSH_MODE_START_FAIL_INVALID_HOST: "无法启动推送，没有为app {accountid}设置的有效主机",
    PUSH_MODE_START_FAIL_INVALID_PORT: "无法启动推送，没有为app {accountid}设置有效端口",

    PROFILE_IS_LOCKED: "设备配置文件正在使用中，无法更改",
    SHARE_PROFILE_ALLOWED_ONLY_FOR_CUSTOMERS_LEVEL: '"{option}"只能在客户级别的配置文件中设置',
    CHANGE_OWNER_ASSIGN_TO_PARENT: "分配给父<{parentId}>",
    NO_RIGHTS_TO_SEE_THIS_PAGE: '用户无权查看此页面。',
    ALERT_MODAL_TITLE_TEXT: "错误",
    DEVICE_PROFILE_PARAMETER_CAN_NOT_BE_CHANGED: "设备配置文件正在使用中，字段'{parameterName}'无法更改",
    ERROR_UPDATING_DEAFULT_PUSH_CONFIG: "更新默认推送配置时出错",
    UPDATE_DEFAULT_PUSH_CONFIG_SUCCESS: "成功复制安装命令",
    UPDATE_DEFAULT_PUSH_CONFIG_ERROR_400: "从网关模型列表中选择一个模型",
    MANAGE_APPLICATIONS_NAV_TITLE: "管理应用程序",

    ACTIVE_CONNECTIONS_NAV_TITLE: "活动连接",
    ACTIVE_CONNECTIONS_PAGE_TITLE: "活动连接",
    ACTIVE_CONNECTIONS_RES_401: "基本认证方案中使用的用户名/密码无效或不存在",
    ACTIVE_CONNECTIONS_CANT_GET_DATA: "无法获取数据",
    ACTIVE_CONNECTIONS_TABLE_COL_TYPE: "类型",
    ACTIVE_CONNECTIONS_TABLE_COL_STATUS: "Status",
    ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME: "连接时间",
    ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION: "应用",
    ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS: "远程地址/目标URL",
    ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS: "订阅",
    ACTIVE_CONNECTIONS_TABLE_COL_ERRORS: "Errors",

    EVENT_LOG_NAV_TITLE: "事件簿",
    EVENT_LOG_PAGE_TITLE: "事件簿",
    EVENT_LOGS_TABLE_COL_TIMESTAMP: "时间戳",
    EVENT_LOGS_TABLE_COL_EVENT: "事件",
    EVENT_LOGS_TABLE_COL_APPLICATION: "应用",
    EVENT_LOGS_TABLE_COL_TEXT: "文本",
    EVENT_LOG_TABLE_PAGINATION_RES_401: "基本认证方案中使用的用户名/密码无效或不存在",
    EVENT_LOG_CANT_GET_DATA: "无法获取数据",
    EVENT_LOG_TABLE_DATA_RES_401: "基本认证方案中使用的用户名/密码无效或不存在",
    EVENT_LOG_TABLE_DATA_RES_404: "page_state无效",
    EVENT_LOG_LOG_LEVEL: "日志级别",
    EVENT_LOG_LEVEL_ERROR_OPTION: "错误",
    EVENT_LOG_LEVEL_INFO_OPTION: "信息",
    EVENT_LOG_LEVEL_DEBUG_OPTION: "调试",
    EVENT_LOG_TIME_DURATION: "时间持续时间",
    EVENT_LOG_TIME_DURATION_1D_OPTION: "1天",
    EVENT_LOG_TIME_DURATION_3D_OPTION: "3天",
    EVENT_LOG_TIME_DURATION_1W_OPTION: "1周",
    EVENT_LOG_TIME_DURATION_2W_OPTION: "2周",
    EVENT_LOG_LOG_LEVEL_ERROR: "设置日志级别时出错",

    UNKNOWN_APP_ID_ERROR: "URL中的未知应用程序ID。",
    APP_FORBIDDEN_ERROR: "帐户没有管理权限",

    DEVICE_PROFILES_EDIT_PAYLOAD_DECODER: "编辑有效负载解码器",
    DEVICE_PROFILES_TABLE_ACTION_EDIT_PAYLOAD_DECODER: "编辑有效负载解码器",
    UNDO: "解开",
    PAYLOAD_DECODER_UPDATED_SUCCESS: "有效载荷解码器架构更新成功",

    HTTP_PUSH_PATH_INVALID_BASIC_AUTH: "无效的基本授权",

    LOG_LEVEL_DROPDOWN_DESC: "设置日志级别自设置之时起生效，并持续一段时间，之后该级别将自动设置回错误级别。",

    RE_PUSH_PAYLOAD: "（重新）推载",
    RE_PUSH_PAYLOAD_SUCCESS: "有效载荷重新推入成功",
    RE_PUSH_PAYLOAD_ERROR: "重新推送有效载荷时出错",

    EDIT_USER_ORGANIZATION: "组织",
    EDIT_CUSTOMER_ORGANIZATION_LABEL: "组织",
    ORGANIZATION_MSG: "用户属于组织 '{name}'",
    NO_ORGANIZATION_ASSIGNED: "未分配组织",
    CHANGE_ORGANIZATION: "变更组织",
    EDIT_CUSTOMER_ORGANIZATION_HELP: "指定此客户所属的组织。",

    SELECT_A_ORGANIZATION: "选择一个组织",
    ORGANIZATION_ID: "机构编号",

    MSG_DEVICE_OTAA_CANT_MIGRATE_TO_ABP_PROFILE: "{device} 设备无法迁移到 {profile} 配置文件",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_MAC_VERSION_PROFILE: "无法将设备迁移到具有不同MAC版本的配置文件",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_REG_PARAM_REVISION: "无法将设备迁移到具有不同RegParamsRevision的配置文件",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_RX2_DATA_RATE_PROFILE: "无法将设备迁移到具有不同默认rx2数据速率的配置文件",

    MSG_ERROR_LOADING_ORGANIZATIONS: '加载组织时出错',
    ENTER_TAG_ID: "输入标签ID",
    ENTER_TAG_VALUE: "输入标签值",

    ERROR_MSG_BOTH_PROFILES_ARE_REQUIRED_TO_MIGRATE_DEVICE: "设备和服务配置文件都需要迁移设备",
    ERROR_MSG_CORRUPTED_DEVICE: "此设备注册似乎已损坏",
    ERROR_MSG_MIGRATE_DIFFERENT_FCNT: "无法使用其他FCNT 16/32位设置将设备迁移到配置文件",
    ERROR_MSG_MIGRATE_DIFFERENT_RX1_DELAY: "无法将设备迁移到具有不同rx1_delay的配置文件",
    ERROR_MIGRATE_ASSIGN_PROFILE_TO_DIRECT_REG_DEVICE: "不允许将配置文件分配给直接注册的设备",
    ERROR_NOT_UPDATED_DEVICES: "{num}个设备的更新失败",
    MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_WITH_FAILS_BULK: "OK，更新{success}个设备，{fail}个设备失败了",
    MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_BULK: "好的，更新了{success}设备",
    ERROR_MSG__DEVICE_INVALID_DEVEUI: "无效的收益 {deveui}",
    ERROR_MSG_DEVEUI_CANT_BE_UPDATED: "更新中不允许使用字段开发",
    ERROR_MSG_CANT_MODIFY_SUSPENDED_STATUS: "帐户无权修改暂停状态",
    ERROR_MSG_DEVICE_LORA_RX_DELAY: "lora_rx_delay2必须为lora_rx_delay1 + 1",
    ERROR_MSG_DEVICE_INVALID_OPTION_VALUE: "无效的期权价值",
    ERROR_MSG_DEVICE_PAYLOAD_DECODER: "有效负载解码器中的错误: {message}",
    ERROR_MSG_MUST_HAVE_USERS_ADMIN_TO_SET_USERID: "必须是用户管理员才能设置用户ID字段",
    ERROR_MSG_DEVICE_USER_ID_NOT_FOUND: "找不到用户ID {userid}",
    ERROR_MSG_DEVICE_MUST_BE_CUSTOMER_ADMIN: "必须是客户管理员才能转移到其他客户",
    ERROR_MSG_DEVICE_INVALID_DEVEUI: "未知的组ID或无效的开发 {deveui}",
    ERROR_MSG_CANT_SET_DEVADDR_AND_NWKSKEY_MULTIPLE_DEVICES: "错误，不允许在多个设备上设置devaddr和nwkskey",
    ERROR_MSG_DEVADDR_NWKSKEY_ALREADY_REGISTERED: "错误，devaddr和nwkskey对已经向其他开发人员注册",
    ERROR_MSG_DEVICE_SET_PROFILE_UUID_NOT_ALLOWED: "不允许设置{profile}_profile_uuid",
    ERROR_MSG_MANDATORY_WHEN_SWITCH_TO_ABP: "{fields} 和devaddr在切换到ABP激活模式时是必需的",
    ERROR_MSG_DEVICE_INVALID_MAC_VERSION: "无效的MAC版本/修订版",
    ERROR_MSG_DEVICE_IS_BUSY: "设备正忙，请稍后再试",
    ERROR_MSG_CANT_RESOLVE_DEVICE_PROFILES: "无法解析设备配置文件",
    ERROR_MSG_SPECIFY_ONLY_ONE_DEVICE: "使用non_owner_access选项时，请仅指定一台设备",
    ERROR_MSG_CANT_RESOLVE_CURRENT_DEVICE_PROFILES: "无法解析当前设备配置文件",
    ERROR_MSG_DEVICE_CANT_CHANGE_DEVICE_PROFILE: "无法更改设备配置文件. {key}的值不兼容."

};

app.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.translations('zh', strings);
}]);

module.exports = strings;
