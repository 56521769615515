import * as React from "react";
import {
    Button,
    ButtonToolbar,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    Modal,
    Nav,
    NavItem,
    OverlayTrigger,
    Popover,
    Row,
    Tab,
    Table,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import { strings } from "./../../services/Localization";
import { DefaultSizePerPage, TenantsPageData, TenantsPagination } from "./../../services/Tenants";
import { DefaultSizePerPage as OrganizationDefaultSizePerPage, OrganizationsPageData, OrganizationsPagination } from "./../../services/Organizations";
import {
    ForwardUserInfo,
    GetTenantName,
    GetOrganizationName,
    LoggedUserInfo,
    UpdateUserSettings,
    ValidatePasswordPolicy,
} from "./../../services/UserSettings";
import { toast } from "./../../utils/Toaster";
import TenancyTable from "./TenancyTable";
import OrganizationTable from "./OrganizationTable";
declare const constants;
import { userData } from "./../../services/Login";
import ProfilesRights from "./ProfilesRights";

export default class UserSettingsModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        const { User } = this.props;
        this.state = {
            ActiveProfileTab: undefined,
            ActualTenant: null,
            AutoComplete: constants.disable_autocomplete_for_credentials ? "off" : "on",
            ComplexPassword: true,
            ConfirmPassword: "",
            Count: 0,
            CurrentPage: 1,
            CurrentPageOrganization: 1,
            DisableButton: false,
            DisableConfirmPassword: false,
            Filtering: [],
            FilteringOrganization: [],
            ForwardUser: User,
            LengthOK: true,
            LengthValue: 0,
            Loading: true,
            LoginUserId: "",
            LowerCase: false,
            NewPassword: "",
            Number: false,
            PageLimit: DefaultSizePerPage,
            PaginationData: {
                pages: [],
                per_page: DefaultSizePerPage,
                total: 1,
            },
            PageLimitOrganizations: OrganizationDefaultSizePerPage,
            PaginationDataOrganizations: {
                pages: [],
                per_page: OrganizationDefaultSizePerPage,
                total: 1,
            },
            PasswordLength: false,
            Rights: {
                password_policies: {
                    admin: {
                        enable_complex_password: false,
                        enforce_password_history: false,
                        max_password_age: null,
                        min_password_length: null,
                    },
                    app: {
                        enable_complex_password: false,
                        enforce_password_history: false,
                        max_password_age: null,
                        min_password_length: null,
                    },
                    user: {
                        enable_complex_password: false,
                        enforce_password_history: false,
                        max_password_age: null,
                        min_password_length: null,
                    },
                },
                userid: "",
            },
            ShowPasswordLength: false,
            ShowTenantTable: false,
            ShowOrganizationTable: false,
            Sorting: {
                sortName: undefined,
                sortOrder: undefined,
            },
            SortingOrganization: {
                sortName: undefined,
                sortOrder: undefined,
            },
            SpecialCharacters: false,
            Tenants: [],
            Organizations: [],
            UpperCase: false,
            User: User || null,
            tagsData: [],
            newTagName: "",
            newTagDesc: "",
            isTagNameValid: true,
            globalTagNameValid: true,
            oldTagName: "",
            oldTagDesc: "",
            initialTagsLength: 0,
            sameTagName: false,
            hasSameTagName: false,
            unlockButtonUserSettings: "glyphicon-lock",
            unlockChangeText: strings.CLICK_TO_UNLOCK,
            readOnly: true,
            organizationAdmin: null
        };

        this.newTagNameRef = React.createRef();
        this.newTagDescRef = React.createRef();

        this.inputRefs = [];
    }

    public setRef = (ref) => {
        this.inputRefs.push(ref);
    };

    public async componentDidMount() {
        // document.addEventListener("mousedown", this.handleClickOutside);

        try {
            const response: any = await LoggedUserInfo();
            if (response.status === 200) {
                const UserResponse = await response.json();
                const User = UserResponse.user;

                let counter = 0;
                let tagsArray = [] as any;
                for (const key in User.tags) {
                    const tagObj = {
                        id: counter++,
                        name: key,
                        desc: User.tags[key],
                        editable: "hide-input-container",
                        validName: true,
                        isSameName: false,
                    };

                    tagsArray.push(tagObj);
                }

                this.setState({
                    tagsData: tagsArray,
                    initialTagsLength: tagsArray.length,
                });

                this.setState({
                    LoginUserId: UserResponse.loginuserid,
                });
                if (UserResponse.loginuserid.includes("/")) {
                    const ForwardUserResponse: any = await ForwardUserInfo(User.userid);
                    if (ForwardUserResponse.status === 200) {
                        const ForwardUser = await ForwardUserResponse.json();
                        this.StoreDataIntoState(User, ForwardUser);
                    } else {
                        let message = "";
                        switch (response.status) {
                            case 400:
                                message = strings.GET_USER_INFO_ERROR_400;
                                break;
                            case 401:
                                message = strings.UNAUTHORIZED_ERROR;
                                break;
                            case 403:
                                message = strings.FORBIDDEN_ERROR;
                                break;
                            case 404:
                                message = strings.GET_USER_INFO_ERROR_404;
                                break;
                            default:
                                message = strings.CAN_NOT_GET_USER_INFO;
                                break;
                        }
                        toast.error(message);
                        this.props.ToggleModal();
                    }
                } else {
                    this.StoreDataIntoState(User, User);
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = strings.GET_USER_INFO_ERROR_400;
                        break;
                    case 401:
                        message = strings.UNAUTHORIZED_ERROR;
                        break;
                    case 403:
                        message = strings.FORBIDDEN_ERROR;
                        break;
                    case 404:
                        message = strings.GET_USER_INFO_ERROR_404;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_USER_INFO;
                        break;
                }
                toast.error(message);
                this.props.ToggleModal();
            }

        } catch (error) {
            console.log(error);
            this.props.ToggleModal();
        }
    }

    private newTagNameRef: React.RefObject<any>;
    private newTagDescRef: React.RefObject<any>;
    private inputRefs;

    public componentWillUnmount() {
        // document.removeEventListener("mousedown", this.handleClickOutside);
    }

    public handleClickOutside = (e: any) => {
        try {
            const parts = e.target.className.split(" ");
            let matches = 0;
            let onotherCheck = 0;
            for (let i = 0; i < parts.length; i++) {
                if (parts[i] === "edit-icon" || parts[i] === "edit-tag") {
                    matches++;
                }

                if (parts[i] === "tag-input-container") {
                    onotherCheck++;
                }
            }

            if (matches < 2 && onotherCheck <= 0) {  // we have cliked outside the edit icon or the input fields itself and therefore hide the input text for the tags
                const tags = this.state.tagsData;
                for (let i = 0; i < tags.length; i++) {
                    if (tags[i].validName) {
                        tags[i].editable = "hide-input-container";
                    }
                }

                this.setState({
                    tagsData: tags,
                });
            }

            return;
        }
        catch(error) {
            return;
        }
    }

    public StoreDataIntoState = async (LoggedUser, ForwardUser) => {
        this.setState({
            ForwardUser,
            Loading: false,
            Rights: {
                password_policies: {
                    admin: {
                        enable_complex_password:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.admin
                            && ForwardUser.password_policies.admin.enable_complex_password
                        ) ? true : false,
                        enforce_password_history:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.admin
                            && ForwardUser.password_policies.admin.enforce_password_history
                        ) ? true : false,
                        max_password_age:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.admin
                            && ForwardUser.password_policies.admin.max_password_age !== undefined
                        ) ? ForwardUser.password_policies.admin.max_password_age : null,
                        min_password_length:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.admin
                            && ForwardUser.password_policies.admin.min_password_length !== undefined
                        ) ? ForwardUser.password_policies.admin.min_password_length : null,
                    },
                    app: {
                        enable_complex_password:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.app
                            && ForwardUser.password_policies.app.enable_complex_password
                        ) ? true : false,
                        enforce_password_history:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.app
                            && ForwardUser.password_policies.app.enforce_password_history
                        ) ? true : false,
                        max_password_age:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.app
                            && ForwardUser.password_policies.app.max_password_age !== undefined
                        ) ? ForwardUser.password_policies.app.max_password_age : null,
                        min_password_length:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.app
                            && ForwardUser.password_policies.app.min_password_length !== undefined
                        ) ? ForwardUser.password_policies.app.min_password_length : null,
                    },
                    user: {
                        enable_complex_password:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.user
                            && ForwardUser.password_policies.user.enable_complex_password
                        ) ? true : false,
                        enforce_password_history:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.user
                            && ForwardUser.password_policies.user.enforce_password_history
                        ) ? true : false,
                        max_password_age:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.user
                            && ForwardUser.password_policies.user.max_password_age !== undefined
                        ) ? ForwardUser.password_policies.user.max_password_age : null,
                        min_password_length:
                        (
                            ForwardUser.password_policies
                            && ForwardUser.password_policies.user
                            && ForwardUser.password_policies.user.min_password_length !== undefined
                        ) ? ForwardUser.password_policies.user.min_password_length : null,
                    },
                },
                userid: ForwardUser.userid || "",
            },
            User: LoggedUser,
            organizationAdmin: LoggedUser.org_admin,
        });
        if (ForwardUser.tenant_id !== undefined) {
            let TenantName = ForwardUser.tenant_id;
            try {
                const response: any = await GetTenantName(ForwardUser.tenant_id);
                if (response.status === 200) {
                    const Tenant = await response.json();

                    for (let i = 0; i < Tenant.length; i++) {
                        if (Tenant[i].tenantid == ForwardUser.tenant_id) {
                            TenantName = Tenant[i];

                            break;
                        }
                    }
                }

                this.setState({ ActualTenant: TenantName });
            } catch (error) {
                this.setState({ ActualTenant: TenantName });
            }
        }

        if (ForwardUser.organisationid !== undefined) {
            let OrganizationName = ForwardUser.organisationid;
            try {
                const response: any = await GetOrganizationName(ForwardUser.organisationid);
                if (response.status === 200) {
                    const Organization = await response.json();

                    for (let i = 0; i < Organization.length; i++) {
                        if (Organization[i].organisationid == ForwardUser.organisationid) {
                            OrganizationName = Organization[i];

                            break;
                        }
                    }
                }

                this.setState({ ActualOrganization: OrganizationName });
            } catch (error) {
                this.setState({ ActualOrganization: OrganizationName });
            }
        }
    }

    public CheckPasswordPolicy = async (input) => {
        const User = this.state.User;
        const UserType = User.is_customer ? "customer" : "user";
        let password = input.value;
        if (password === undefined) {
            password = null;
        }

        if (password.length > 0) {
            this.setState({
                DisableConfirmPassword: false,
            });
        } else {
            this.setState({
                ConfirmPassword: "",
                DisableConfirmPassword: true,
            });
        }

        let ParentId = false;
        if (User.parentid) {
            ParentId = true;
        }

        let AdminPolicy = false;
        const UserId = User.userid;
        if (!this.props.EditMode || (this.props.EditMode && password && password.length === 1)) {
            if ((User.administrator === true) || (User.sys_admin === true) || (User.customer_admin === true)) {
                AdminPolicy = true;
            }
        }

        const ObjToSend = {
            administrator: AdminPolicy,
            other_policy: UserType === "customer" && this.props.EditMode,
            parentid: ParentId,
            password,
            type: UserType,
            userid: UserId,
        };

        try {
            const validationResults: any = await ValidatePasswordPolicy(ObjToSend);
            if (validationResults.status === 200) {
                const response: any = await validationResults.json();
                this.setState({
                    ComplexPassword: response.complex,
                    Count: response.count,
                    LengthOK: response.ok_length,
                    LengthValue: response.length_value,
                    LowerCase: response.lower,
                    Number: response.number,
                    PasswordLength: response.length,
                    ShowPasswordLength: response.show_length,
                    SpecialCharacters: response.special,
                    UpperCase: response.upper,
                });
            }
        } catch (error) {
            console.log(error);
            toast.error(error);
        }
    }

    public ChangeOrganizationAdminRight = () => {
        var orgAdmin = this.state.organizationAdmin;
        orgAdmin = !orgAdmin;

        this.setState({
            organizationAdmin: orgAdmin
        });
    }
    
    public ChangeAdminRight = () => {
        if (!this.props.EditMode && (this.state.NewPassword > 0)) {
            this.CheckPasswordPolicy(this.state.NewPassword);
        }
    }

    public HandleChangeRights = async (value, right, type = "") => {
        const ChangedUserRights = this.state.Rights;
        const UserRights = this.state.User;
        if ((right === "tenant_admin") && (value === "true")) {
            if (UserRights.customer_admin) {
                ChangedUserRights.customer_admin = true;
            }
            if (UserRights.administrator) {
                ChangedUserRights.administrator = true;
            }
        }

        if ((!this.state.Rights.tenant_admin || !this.state.User.tenant_admin) &&
        (right === "customer_admin") && (value === "true")) {
            if (UserRights.administrator) {
                ChangedUserRights.administrator = true;
            }
        }

        if ((right === "can_own_gtw") && (value === "false")) {
            if (UserRights.can_add_gtw || (ChangedUserRights.can_add_gtw === true)) {
                ChangedUserRights.can_add_gtw = false;
            }
            if (UserRights.can_mng_gtw || (ChangedUserRights.can_mng_gtw === true)) {
                ChangedUserRights.can_mng_gtw = false;
            }
        }
        if ((value === "true") || (value === "false")) {
            value = JSON.parse(value);
        }
        if (type === "number") {
            if (value !== "") {
                value = JSON.parse(value);
            } else {
                value = 0;
            }
        }
        if (type === "list" && !value) {
            const ProfileRights = ["inspect", "create", "link", "restrict"];
            const Profile = this.state.ActiveProfileTab;
            await ProfileRights.map((ProfileRight) => {
                if (this.state.User[`can_${ProfileRight}_${Profile}_profile`]) {
                    ChangedUserRights[`can_${ProfileRight}_${Profile}_profile`] = false;
                }
            });
        }
        ChangedUserRights[right] = value;
        this.setState({
            Rights: ChangedUserRights,
        });
    }

    public LoadTenants = async (
        LimitPerPage = this.state.PageLimit,
        CurrentPage = this.state.CurrentPage,
        Filtering = this.state.Filtering,
        Sorting = this.state.Sorting,
    ) => {
        if (this.state.User.customer_admin === false) {
            return;
        }

        try {
            const GetTenantsPages: any = await TenantsPagination(
                LimitPerPage,
                Filtering,
                Sorting,
            );
            if (GetTenantsPages.status === 200) {
                const PaginationData = await GetTenantsPages.json();
                if (Math.ceil(PaginationData.total / PaginationData.per_page) < CurrentPage) {
                    CurrentPage = Math.ceil(PaginationData.total / PaginationData.per_page);
                    if (CurrentPage < 1) {
                        CurrentPage = 1;
                    }
                }
                if (JSON.stringify(Filtering) !== JSON.stringify(this.state.Filtering)) {
                    CurrentPage = 1;
                }
                this.setState({
                    CurrentPage,
                    Filtering,
                    PaginationData,
                    Sorting,
                });
                if (PaginationData.pages.length > 0) {
                    this.TenantsPageList(
                        PaginationData.pages[CurrentPage - 1].page_state,
                        LimitPerPage,
                        Filtering,
                        Sorting,
                    );
                } else {
                    this.setState({
                        Tenants: [],
                    });
                }
            } else {
                let message = "";
                switch (GetTenantsPages.status) {
                    case 400:
                        message = strings.GET_PAGINATION_ERROR_400;
                        break;
                    case 401:
                        message = strings.UNAUTHORIZED_ERROR;
                        break;
                    case 404:
                        message = strings.GET_PAGINATION_ERROR_404;
                        break;
                    case 413:
                        message = strings.GET_PAGINATION_ERROR_413;
                        break;
                    default:
                        message = strings.MSG_ERROR_LOADING_TENANTS;
                        break;
                }
                toast.error(message);
                this.setState({
                    Tenants: [],
                });
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_TENANTS);
        }
    }

    public LoadOrganizations = async (
        LimitPerPage = this.state.PageLimitOrganizations,
        CurrentPage = this.state.CurrentPageOrganization,
        Filtering = this.state.FilteringOrganization,
        Sorting = this.state.SortingOrganization,
    ) => {
        if (this.state.User.customer_admin === false) {
            return;
        }

        try {
            const GetOrganizationsPages: any = await OrganizationsPagination(
                LimitPerPage,
                Filtering,
                Sorting,
            );
            if (GetOrganizationsPages.status === 200) {
                const PaginationData = await GetOrganizationsPages.json();
                if (Math.ceil(PaginationData.total / PaginationData.per_page) < CurrentPage) {
                    CurrentPage = Math.ceil(PaginationData.total / PaginationData.per_page);
                    if (CurrentPage < 1) {
                        CurrentPage = 1;
                    }
                }
                if (JSON.stringify(Filtering) !== JSON.stringify(this.state.Filtering)) {
                    CurrentPage = 1;
                }
                this.setState({
                    CurrentPageOrganization: CurrentPage,
                    FilteringOrganization: Filtering,
                    PaginationDataOrganizations: PaginationData,
                    SortingOrganization: Sorting,
                });
                if (PaginationData.pages.length > 0) {
                    this.OrganizationsPageList(
                        PaginationData.pages[CurrentPage - 1].page_state,
                        LimitPerPage,
                        Filtering,
                        Sorting,
                    );
                } else {
                    this.setState({
                        Organizations: [],
                    });
                }
            } else {
                let message = "";
                switch (GetOrganizationsPages.status) {
                    case 400:
                        message = strings.GET_PAGINATION_ERROR_400;
                        break;
                    case 401:
                        message = strings.UNAUTHORIZED_ERROR;
                        break;
                    case 404:
                        message = strings.GET_PAGINATION_ERROR_404;
                        break;
                    case 413:
                        message = strings.GET_PAGINATION_ERROR_413;
                        break;
                    default:
                        message = strings.MSG_ERROR_LOADING_TENANTS;
                        break;
                }
                toast.error(message);
                this.setState({
                    Organizations: [],
                });
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_TENANTS);
        }
    }

    public TenantsPageList = async (
        PageState = this.state.PaginationData.pages[0].page_state,
        PageLimit = this.state.PageLimit,
        Filtering = this.state.Filtering,
        Sorting = this.state.Sorting,
    ) => {
        this.setState({
            Tenants: [],
        });
        try {
            const GetCurrentPage: any = await TenantsPageData(PageLimit, PageState, Filtering, Sorting);
            if (GetCurrentPage.status === 200) {
                this.setState({
                    Tenants: await GetCurrentPage.json(),
                });
            } else {
                let message = "";
                switch (GetCurrentPage.status) {
                    case 400:
                        message = strings.GET_PAGINATION_ERROR_400;
                        break;
                    case 401:
                        message = strings.UNAUTHORIZED_ERROR;
                        break;
                    case 403:
                        message = strings.FORBIDDEN_ERROR;
                        break;
                    case 410:
                        message = strings.GET_PAGE_STATE_ERROR_410;
                        break;
                    case 416:
                        message = strings.GET_PAGE_STATE_ERROR_416;
                        break;
                    default:
                        message = strings.MSG_ERROR_LOADING_TENANTS;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_TENANTS);
        }
    }

    public ShowTenantTable = () => {
        this.setState({
            ShowTenantTable: !this.state.ShowTenantTable,
        });
        if (this.state.ShowTenantTable === false) {
            this.LoadTenants();
        }
    }

    public OrganizationsPageList = async (
        PageState = this.state.PaginationData.pages[0].page_state,
        PageLimit = this.state.PageLimitOrganizations,
        Filtering = this.state.FilteringOrganization,
        Sorting = this.state.SortingOrganization,
    ) => {
        this.setState({
            Organizations: [],
        });
        try {
            const GetCurrentPage: any = await OrganizationsPageData(PageLimit, PageState, Filtering, Sorting);
            if (GetCurrentPage.status === 200) {
                this.setState({
                    Organizations: await GetCurrentPage.json(),
                });
            } else {
                let message = "";
                switch (GetCurrentPage.status) {
                    case 400:
                        message = strings.GET_PAGINATION_ERROR_400;
                        break;
                    case 401:
                        message = strings.UNAUTHORIZED_ERROR;
                        break;
                    case 403:
                        message = strings.FORBIDDEN_ERROR;
                        break;
                    case 410:
                        message = strings.GET_PAGE_STATE_ERROR_410;
                        break;
                    case 416:
                        message = strings.GET_PAGE_STATE_ERROR_416;
                        break;
                    default:
                        message = strings.MSG_ERROR_LOADING_ORGANIZATIONS;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_ERROR_LOADING_ORGANIZATIONS);
        }
    }

    public ShowOrganizationTable = () => {
        this.setState({
            ShowOrganizationTable: !this.state.ShowOrganizationTable,
        });
        if (this.state.ShowOrganizationTable === false) {
            this.LoadOrganizations();
        }
    }

    public changeTab = () => {
        this.setState({
            ShowTenantTable: false,
            ShowOrganizationTable: false,
        });
    }

    public SelectTenant = (SelectedTenant) => {
        this.setState({
            Rights: {...this.state.Rights, tenant_id: SelectedTenant.tenantid},
        });
    }

    public SelectOrganization = (SelectedOrganization) => {
        this.setState({
            Rights: {...this.state.Rights, organisationid: SelectedOrganization.userid},
        });
    }

    public ChangeProfileTab = (profile) => {
        const { Rights, ForwardUser } = this.state;
        let { DisableButton } = this.state;
        if (Rights[`can_list_${profile}_profile`] !== undefined) {
            if (Rights[`can_list_${profile}_profile`]) {
                DisableButton = false;
            } else {
                DisableButton = true;
            }
        } else {
            if (ForwardUser[`can_list_${profile}_profile`] === true) {
                DisableButton = false;
            } else {
                DisableButton = true;
            }
        }
        this.setState({
            ActiveProfileTab: profile,
            DisableButton,
        });
    }

    public HandlePasswordPolicy = (value, right, type) => {
        const Rights = this.state.Rights;
        if (value !== "") {
            Rights.password_policies[type][right] = JSON.parse(value);
        } else {
            Rights.password_policies[type][right] = null;
        }
        this.setState({
            Rights,
        });
    }

    public PopOverHover = (content) => {
        return (
            <Popover
                id="PasswordPolicy"
                className="pass_policy_popover_new_style"
            >
                {content}
            </Popover>
        );
    }

    public transformTags() {

        const tags = this.state.tagsData;
        const tagsArray = {};
        for (let i = 0; i < tags.length; i++) {
            tagsArray[tags[i].name] = tags[i].desc;
        }

        return tagsArray;
    }

    public SubmitUserRights = async () => {
        const Rights = this.state.Rights;

        Rights.tags = this.transformTags();

        if (this.state.NewPassword !== "") {
            Rights.password = this.state.NewPassword;
        } else if (this.state.NewPassword === "") {
            if (Rights.hasOwnProperty("password")) {
                delete Rights.password;
            }
        }

        if (this.state.User.org_admin != this.state.organizationAdmin) {
            Rights.org_admin = this.state.organizationAdmin;
        }

        try {
            const UpdateResponse: any = await UpdateUserSettings(Rights);
            if (UpdateResponse.status === 200 && this.state.newTagName == "" && this.state.newTagDesc == "") {
                toast.success(strings.MSG_USER_UPDATE_SUCCESS_BODY);
                if (!this.state.User.new_userid) {
                    if ((this.state.NewPassword !== "") && !this.state.LoginUserId.includes("/")) {
                        userData(Rights.userid, Rights.password)
                        .then(() => {
                            this.props.ToggleModal();
                        });
                    } else {
                        this.props.ToggleModal();
                    }
                } else {
                    this.props.ToggleModal();
                }
            } else {
                let message = "";
                switch (UpdateResponse.status) {
                    case 400:
                        message = strings.PASSWORD_FAILED;
                        break;
                    case 401:
                        message = strings.UPDATE_USER_ERROR_401;
                        break;
                    case 403:
                        message = strings.UPDATE_USER_ERROR_403;
                        break;
                    case 412:
                        message = strings.PASSWORD_FAILED;
                        break;
                    default:
                        message = strings.MSG_USER_UPDATE_FAIL_BODY;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.MSG_USER_UPDATE_FAIL_BODY);
        }
    }

    public ToggleValue = (right) => {
        const Rights = this.state.Rights;
        const User = this.state.ForwardUser;

        return (Rights[right] !== undefined) ?
        `${Rights[right]}` :
        (User[right] !== undefined) ?
        `${User[right]}` : "false";
    }

    public NumberFieldValue = (right, tab, type = "") => {
        const User = this.state.ForwardUser;
        const Rights = this.state.Rights;
        if (tab === "PasswordPolicy") {
            if (Rights.password_policies[type][right] !== undefined) {
                if (Rights.password_policies[type][right] === null) {
                    return "";
                } else {
                    return Rights.password_policies[type][right];
                }
            } else {
                if (User.password_policies[type][right] !== undefined) {
                    if (User.password_policies[type][right] === null) {
                        return "";
                    } else {
                        return User.password_policies[type][right];
                    }
                } else {
                    return "";
                }
            }
        } else if (tab === "Settings") {
            if (Rights[right] !== undefined) {
                if (Rights[right] === 0) {
                    return "";
                } else {
                    return Rights[right];
                }
            } else {
                if (User[right] !== undefined) {
                    return User[right];
                } else {
                    return "";
                }
            }
        }
    }

    public editTag(index) {
        if (this.state.globalTagNameValid) {
            let currentTagName = "";
            let currentTagDesc = "";

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].id === index && this.state.globalTagNameValid) {
                    tags[i].editable = "show-input-container";

                    currentTagName = tags[i].name;
                    currentTagDesc = tags[i].desc;
                    
                    const that = this;
                    setTimeout(function() {
                        if (that.inputRefs && that.inputRefs[i]) {
                            that.inputRefs[i].focus();
                        }

                    }, 0);
                } 
                else if (this.state.globalTagNameValid) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
                oldTagName: currentTagName,
                oldTagDesc: currentTagDesc,
            });
        }
    }

    public saveEditingTag() {
        if (!this.state.hasSameTagName) {
            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public deleteTag(index) {
        const tags = this.state.tagsData;
        const newTags = [] as any;
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id !== index) {
                newTags.push(tags[i]);
            }
            else if (!tags[i].validName && this.state.isTagNameValid) {
                globalTagNameValidHelper = true;
            }
        }

        this.setState({
            tagsData: newTags,
            globalTagNameValid: globalTagNameValidHelper,
        });
    }

    public tagDescChange(index, e) {
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].desc = e.target.value;
            }
        }

        this.setState({
            tagsData: tags,
        });
    }

    public tagNameChange(index, e) {
        const tags = this.state.tagsData;
        let globalCurrentTagNameValid = this.state.globalTagNameValid && this.state.isTagNameValid;
        let bHasSameTagName = false;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].id === index) {
                tags[i].name = e.target.value;
                
                let regexTagName = /^[a-zA-Z\d\-_]*$/;
                let result = tags[i].name.match(regexTagName);
                if (!result) {
                    tags[i].validName = false;
                    globalCurrentTagNameValid = false;
                }
                else {
                    tags[i].validName = true;
                    globalCurrentTagNameValid = true && this.state.isTagNameValid;
                }

                let matchCounter = 0;
                for (let j = 0; j < tags.length; j++) { 
                    if (tags[j].name == e.target.value) {
                        matchCounter++;
                    }
                }

                if (matchCounter > 1) {
                    tags[i].isSameName = true;
                    bHasSameTagName = true;
                }
                else {
                    tags[i].isSameName = false;
                }

                break;
            }
        }
        
        this.setState({
            tagsData: tags,
            globalTagNameValid: globalCurrentTagNameValid,
            hasSameTagName: bHasSameTagName,
        });
    }
    
    public handleKeyPress = (e) => {
        if(e.key === "Enter" && e.target.value != '') {
            
            if (this.state.newTagName != "" && this.state.newTagDesc != "") {
                this.insertNewTag();
            }
            else if (e.target.classList.contains("new-tag-name")) {
                this.newTagDescRef.current.focus();
            }
            else if (e.target.classList.contains("new-tag-description")) {
                this.newTagNameRef.current.focus();
            }
        }
    }

    public handleKeyPressOnEditTag = (e) => {
        if(e.key === "Enter" && e.target.value != '' && !this.state.hasSameTagName) {

            const tags = this.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].validName) {
                    tags[i].editable = "hide-input-container";
                }
            }

            this.setState({
                tagsData: tags,
            });
        }
    }

    public newTagNameChange(e) {
        const that = this;
        setTimeout(function() {
            let bFlag = false;
            const tags = that.state.tagsData;
            for (let i = 0; i < tags.length; i++) {
                if (tags[i].name == that.state.newTagName) {
                    bFlag = true;
                    
                    break;
                }
            }

            that.setState({
                sameTagName: bFlag,
            });

        }, 0);

        let regexTagName = /^[a-zA-Z\d\-_]*$/;
        let result = e.target.value.match(regexTagName);

        if(result != null){
            this.setState({
                isTagNameValid: true,
                globalTagNameValid: true
            });
        } else {
            if(e.target.value == ""){
                this.setState({
                    isTagNameValid: true,
                    globalTagNameValid: true
                });
            } else {
                this.setState({
                    isTagNameValid: false,
                    globalTagNameValid: false
                });
            }
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                this.setState({
                    globalTagNameValid: false,
                });

                break;
            }
        }

        this.setState({
            newTagName: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public newTagDescChange(e) {
        this.setState({
            newTagDesc: e.target.value,
        });

        this.handleKeyPress(e);
    }

    public insertNewTag() {
        let bFlag = false;
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].name == this.state.newTagName) {
                bFlag = true;
                
                break;
            }
        }

        this.setState({
            sameTagName: bFlag,
        });

        if (bFlag) {
            return;
        }

        if (this.state.newTagName !== "" && this.state.newTagDesc !== "" && this.state.isTagNameValid) {
            const tags = this.state.tagsData;
            const tag = {
                id: this.state.initialTagsLength,
                name: this.state.newTagName,
                desc: this.state.newTagDesc,
                editable: "hide-input-container",
                validName: true,
                isSameName: false,
            };

            this.newTagNameRef.current.focus();

            let newTagsLength = this.state.initialTagsLength + 1;

            tags.push(tag);
            this.setState({
                tagsData: tags,
                newTagName: "",
                newTagDesc: "",
                initialTagsLength: newTagsLength,
            });
        } 
        else if (this.state.newTagDesc !== "" && !this.state.isTagNameValid) {
            this.newTagNameRef.current.focus();
        }
        else if (this.state.newTagDesc == "") {
            this.newTagDescRef.current.focus();
        }
        else if (this.state.newTagName == "" && this.state.newTagDesc == "") {
            this.newTagNameRef.current.focus();
        }
    }

    public clearTagInputs() {
        let globalTagNameValidHelper = this.state.globalTagNameValid;
        if (!this.state.isTagNameValid) {
            globalTagNameValidHelper = true;
        }

        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {
            if (!tags[i].validName) {
                globalTagNameValidHelper = false;

                break;
            }
        }

        this.setState({
            newTagName: "",
            newTagDesc: "",
            globalTagNameValid: globalTagNameValidHelper,
            isTagNameValid: true,
            sameTagName: false,
        });
    }

    public clearCurrentTag(tagId){
        const tags = this.state.tagsData;
        for (let i = 0; i < tags.length; i++) {

            if (tags[i].id == tagId) {
                tags[i].name = this.state.oldTagName;
                tags[i].desc = this.state.oldTagDesc;
                tags[i].validName = true;
                tags[i].isSameName = false;
            }
        }
        
        const hasSameTagNameHelper = this.state.hasSameTagName;
        this.setState({
            tagsData: tags,
            globalTagNameValid: true && this.state.isTagNameValid,
            hasSameTagName: false && hasSameTagNameHelper,
        });
    }

    public unlockUserSettings = (e) => {
        this.setState({ 
            unlockButtonUserSettings: "glyphicon-edit",
            unlockChangeText: strings.EDIT_DEVICE_PARAMETERS,
            readOnly: false,
        });
    }

    public render() {
        const that = this;
        const listTags = this.state.tagsData.map(function(tag) {
            return (
                <tr key={tag.id}>
                    <td className={`tag-name-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.name }
                        </div>

                        <div className="hide-input">
                            <input
                                type="text"
                                value={tag.name}
                                className="tag-input-container"
                                onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                                ref={that.setRef}
                                onChange={that.tagNameChange.bind(that, tag.id)} />

                                {!tag.validName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_AVAILABLE_CHARS}
                                    </span>
                                )}

                                {tag.isSameName && (
                                    <span className="popover-tag-name-react">
                                        {strings.TAG_NAME_UNIQUE}
                                    </span>
                                )}
                        </div>
                    </td>

                    <td className={`tag-desc-input ${tag.editable}`}>
                        <div className="hide-value">
                            { tag.desc }
                        </div>

                        <div className="hide-input">
                            <input
                            type="text"
                            value={tag.desc}
                            className="tag-input-container"
                            onKeyPress={that.handleKeyPressOnEditTag.bind(that)}
                            onChange={that.tagDescChange.bind(that, tag.id)} />
                        </div>
                    </td>

                    <td className="tag-acion-container">
                        <div className={`current-tag-edit-btns ${tag.editable}`}>
                            <button
                                className="fa fa-pencil-square-o fa-fw edit-icon edit-tag"
                                disabled={that.state.readOnly}
                                onClick={that.editTag.bind(that, tag.id)} >
                            </button>

                            <button
                                className="fa fa-trash-o fa-fw delete-icon"
                                disabled={that.state.readOnly}
                                onClick={that.deleteTag.bind(that, tag.id)} >
                            </button>
                        </div>

                        <div className={`current-tag-action-btns ${tag.editable}`}>
                            <button
                                className="fa fa-check fa-fw edit-icon"
                                onClick={that.saveEditingTag.bind(that)} >
                            </button>

                            <button
                                className="fa fa-times fa-fw clear-input"
                                onClick={that.clearCurrentTag.bind(that, tag.id)} >
                            </button>
                        </div>
                    </td>
                </tr>
            );
        });

        const Count = this.state.Count;
        const User = this.state.User;
        const ForwardUser = this.state.ForwardUser;
        const Rights = this.state.Rights;
        const PasswordPolicies = Rights.password_policies;
        const PassPolicy = User.new_userid ? User.new_userid.includes("/") : undefined;
        const DeviceProfiles = User.can_list_device_profile ||
        User.can_inspect_device_profile || User.can_create_device_profile ||
        User.can_link_device_profile;
        const ServiceProfiles = User.can_list_service_profile ||
        User.can_inspect_service_profile || User.can_create_service_profile ||
        User.can_link_service_profile || User.can_restrict_service_profile;
        const ConnectivityProfiles = User.can_list_connectivity_profile ||
        User.can_inspect_connectivity_profile ||
        User.can_create_connectivity_profile || User.can_link_connectivity_profile;
        const RoamingProfiles = User.can_list_roaming_profile ||
        User.can_inspect_roaming_profile || User.can_create_roaming_profile || User.can_link_roaming_profile;
        const ChannelProfiles = User.can_list_channel_profile ||
        User.can_inspect_channel_profile || User.can_create_channel_profile || User.can_link_channel_profile;
        const QoSProfiles = User.can_list_qos_profile || User.can_inspect_qos_profile ||
        User.can_create_qos_profile || User.can_link_qos_profile;
        const DisableGatewaysButtons = this.state.Rights.can_own_gtw !== undefined ?
        this.state.Rights.can_own_gtw : this.state.ForwardUser.can_own_gtw;
        const TenantAdminRights = Rights.tenant_admin !== undefined ?
        Rights.tenant_admin : ForwardUser.tenant_admin || false;
        const CustomerAdminRights = Rights.customer_admin !== undefined ?
        Rights.customer_admin : ForwardUser.customer_admin || false;
        const minPassFieldValidation =
        (((PasswordPolicies.user.min_password_length !== null) && (PasswordPolicies.user.min_password_length < 8 || PasswordPolicies.user.min_password_length % 1 !== 0)) ||
        ((PasswordPolicies.admin.min_password_length !== null) && (PasswordPolicies.admin.min_password_length < 8 || PasswordPolicies.admin.min_password_length % 1 !== 0)) ||
        ((PasswordPolicies.app.min_password_length !== null) && (PasswordPolicies.app.min_password_length < 8 || PasswordPolicies.app.min_password_length % 1 !== 0)))

        return (
            <div>
                <Modal
                    show={this.props.ShowModal}
                    onHide={this.props.ToggleModal}
                    bsSize="lg"
                    className="ReactModal"
                    backdrop="static"
                >
                    <Modal.Header
                        closeButton={true}
                    >
                        <Modal.Title>
                            {this.props.ModalTitle || ""}
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="all_padding_0 react_user_settings">
                    {this.state.Loading ? (
                    <Row className="new_style_modal_body white-background padding_top_30 padding_bottom_30">
                        <Col sm={12} className="LoaderWrapper">
                            <i className="fa fa-spinner fa-spin fa-5x"></i>
                        </Col>
                    </Row>
                    ) :
                    (<div>
                        <Row className="new_style_modal_body white-background padding_top_30">
                            <Col sm={12} className="all_padding_0">
                                <Form horizontal>
                                    <FormGroup
                                        controlId="AccountID"
                                    >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.EDIT_USER_TITLE_ACCOUNT_ID}
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="text"
                                                className="new_style_focus"
                                                disabled={this.props.EditMode ? (false || this.state.readOnly) : (true || this.state.readOnly)}
                                                required={true}
                                                autoComplete={this.state.AutoComplete}
                                                value={Rights.userid}
                                            />
                                            <HelpBlock
                                                className="new_style_help_block_color font_size_11"
                                            >
                                                {strings.EDIT_USER_ACCOUNT_ID_HELP}
                                            </HelpBlock>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup
                                        controlId="NewPassword"
                                        className="margin_b_45"
                                        validationState={((this.state.NewPassword.length > 0) &&
                                            ((this.state.NewPassword.length < this.state.LengthValue) ||
                                            (this.state.ComplexPassword === false))) ?
                                                "error" : null
                                        }
                                    >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.PASSWORD}
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                type="password"
                                                className="new_style_focus"
                                                autoComplete={this.state.AutoComplete}
                                                disabled={this.state.readOnly}
                                                onChange={(event: any) => {
                                                    this.CheckPasswordPolicy(event.target);
                                                    this.setState({ NewPassword: event.target.value });
                                                }}
                                            />
                                            <HelpBlock className="show_field_errors new_password">
                                                <div>
                                                    {(Count > 0) && (
                                                        <span>
                                                            {((this.state.UpperCase && (Count >= 3)) ||
                                                            (!this.state.UpperCase && (Count !== 3))) && (
                                                                <span>
                                                                    <span className={(this.state.UpperCase &&
                                                                        (Count >= 3)) ?
                                                                        "glyphicon glyphicon-ok marker_green" :
                                                                        (!this.state.UpperCase && (Count !== 3)) ?
                                                                        "glyphicon glyphicon-remove marker_ed" :
                                                                        undefined
                                                                    }></span>
                                                                    <span className="size_sma color_text font_size_11">
                                                                        {strings.UPPER_CASE}
                                                                    </span>
                                                                </span>
                                                            )}
                                                            {((this.state.LowerCase && (Count >= 3)) ||
                                                            (!this.state.LowerCase && (Count !== 3))) && (
                                                                <span>
                                                                    <span className={(this.state.LowerCase &&
                                                                    (Count >= 3)) ?
                                                                        "glyphicon glyphicon-ok marker_green" :
                                                                        (!this.state.LowerCase && (Count !== 3)) ?
                                                                        "glyphicon glyphicon-remove marker_ed" :
                                                                        undefined
                                                                    }></span>
                                                                    <span className="size_sma color_text font_size_11">
                                                                        {strings.LOWER_CASE}
                                                                    </span>
                                                                </span>
                                                            )}
                                                            {((this.state.SpecialCharacters && (Count >= 3)) ||
                                                            (!this.state.SpecialCharacters && (Count !== 3))) && (
                                                                <span>
                                                                    <span className={(this.state.SpecialCharacters &&
                                                                    (Count >= 3)) ?
                                                                        "glyphicon glyphicon-ok marker_green" :
                                                                        (!this.state.SpecialCharacters &&
                                                                        (Count !== 3)) ?
                                                                            "glyphicon glyphicon-remove marker_ed" :
                                                                            undefined
                                                                    }></span>
                                                                    <span className="size_sma color_text font_size_11">
                                                                        {strings.SPECIAL_CHARACTERS}
                                                                    </span>
                                                                </span>
                                                            )}
                                                            {((this.state.Number && (Count >= 3)) ||
                                                            (!this.state.Number && (Count !== 3))) && (
                                                                <span>
                                                                    <span className={(this.state.Number &&
                                                                    (Count >= 3)) ?
                                                                        "glyphicon glyphicon-ok marker_green" :
                                                                        (!this.state.Number && (Count !== 3)) ?
                                                                            "glyphicon glyphicon-remove marker_ed" :
                                                                            undefined
                                                                    }></span>
                                                                    <span className="size_sma color_text font_size_11">
                                                                        {strings.NUMBER}
                                                                    </span>
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                    {(this.state.ShowPasswordLength &&
                                                    (this.state.NewPassword.length > 0)) && (
                                                        <span>
                                                            <span className={this.state.PasswordLength ?
                                                                "glyphicon glyphicon-ok marker_green" :
                                                                "glyphicon glyphicon-remove marker_ed"
                                                            }></span>
                                                            <span className="size_sma color_text font_size_11">
                                                                {strings.formatString(
                                                                    strings.NEW_PASSWORD_LENGTH,
                                                                    {
                                                                        length_value: this.state.LengthValue,
                                                                    },
                                                                )}
                                                            </span>
                                                        </span>
                                                    )}
                                                </div>
                                            </HelpBlock>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup
                                        controlId="ConfirmPassword"
                                        className="margin_b_45"
                                        validationState={
                                            (this.state.NewPassword !== this.state.ConfirmPassword) ?
                                                "error" : null
                                        }
                                    >
                                        <Col
                                            componentClass={ControlLabel}
                                            className="new_style_font_weight font_size_18"
                                            sm={3}
                                        >
                                            {strings.EDIT_USER_PASSWORD_CONFIRM}
                                        </Col>
                                        <Col sm={9}>
                                            <FormControl
                                                value={this.state.ConfirmPassword}
                                                type="password"
                                                className="new_style_focus"
                                                autoComplete={this.state.AutoComplete}
                                                disabled={this.state.DisableConfirmPassword || this.state.readOnly}
                                                onChange={(event: any) => {
                                                    this.setState({ ConfirmPassword: event.target.value });
                                                }}
                                            />
                                            <HelpBlock className="show_field_errors">
                                                {(this.state.NewPassword.length > 0) && (
                                                    <div>
                                                        <span className={(
                                                        this.state.NewPassword === this.state.ConfirmPassword) ?
                                                            "glyphicon glyphicon-ok marker_green" :
                                                            "glyphicon glyphicon-remove marker_ed"
                                                        }></span>
                                                        <span className="size_sma color_text font_size_11">
                                                            {strings.PASSWORD_MATCH}
                                                        </span>
                                                    </div>
                                                )}
                                            </HelpBlock>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} className="all_padding_0">
                                <Tab.Container id="UserSettingsTab" defaultActiveKey="AdminRights">
                                    <Row className="clearfix">
                                        <Col className="react_new_style_tabs tabs_style">
                                            <Nav bsStyle="pills" className="react_nav_tabs" stacked>
                                                {User.administrator && (
                                                    <NavItem eventKey="AdminRights" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_ADMIN_RIGHTS}
                                                    </NavItem>
                                                )}
                                                {(User.administrator && (User.can_access_gtw_info ||
                                                (User.can_own_gtw && User.gtw_admin) ||
                                                (User.can_add_gtw && User.gtw_admin && User.can_own_gtw) ||
                                                (User.can_mng_gtw && User.gtw_admin && User.can_own_gtw) ||
                                                User.can_access_gtw_loc_info)) && (
                                                    <NavItem eventKey="Gateways" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_GATEWAYS}
                                                    </NavItem>
                                                )}
                                                {User.loraloc_enable && (
                                                    <NavItem eventKey="LoraLocation" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_LORA_LOCATION}
                                                    </NavItem>
                                                )}
                                                {(User.sys_admin || User.data_archive_admin || User.can_access_logs) && (
                                                    <NavItem eventKey="SystemSettings" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_SYSTEM_SETTINGS}
                                                    </NavItem>
                                                )}
                                                {(constants.enable_limit_devices || constants.enable_limit_gateways ||
                                                User.can_view_mac_msg) && (
                                                    <NavItem eventKey="Settings" onClick={this.changeTab}>
                                                        {strings.SETTINGS}
                                                    </NavItem>
                                                )}
                                                <NavItem eventKey="EditUserInfo" onClick={this.changeTab}>
                                                    {strings.EDIT_USER_INFO}
                                                </NavItem>
                                                { (User.tenant_admin && ( User.customer_admin && User.is_customer ) || ( User.org_admin && User.is_organisation )) && (
                                                    <NavItem eventKey="UserTenancy">
                                                        {strings.EDIT_USER_TENANCY}
                                                    </NavItem>
                                                )}
                                                { (User.org_admin && User.is_customer) && (
                                                    <NavItem eventKey="UserOrganization" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_ORGANIZATION}
                                                    </NavItem>
                                                )}
                                                {(DeviceProfiles || ServiceProfiles || ConnectivityProfiles ||
                                                RoamingProfiles || ChannelProfiles || QoSProfiles) && (
                                                    <NavItem eventKey="Profiles" onClick={this.changeTab}>
                                                        {strings.PROFILES}
                                                    </NavItem>
                                                )}
                                                {(User.customer_admin && constants.enable_password_policies &&
                                                    (User.is_customer || PassPolicy)) && (
                                                    <NavItem eventKey="PasswordPolicy" onClick={this.changeTab}>
                                                        {strings.EDIT_USER_PASSWORD_POLLICY}
                                                    </NavItem>
                                                )}

                                                <NavItem eventKey="Tags" onClick={this.changeTab}>
                                                    {strings.EDIT_USER_TAGS}
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                        <Col className="react_tab_content_bg style_tabs">
                                            <Tab.Content animation>
                                                {User.administrator && (
                                                    <Tab.Pane eventKey="AdminRights">
                                                        <Form horizontal>
                                                            {User.tenant_admin &&
                                                                <FormGroup controlId="TenantAdminRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_TENANCY_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="TenantButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                (Rights.tenant_admin !== undefined) ?
                                                                                `${Rights.tenant_admin}` :
                                                                                `${ForwardUser.tenant_admin}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event,
                                                                                        "tenant_admin",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    disabled={this.state.readOnly}
                                                                                    value="true"
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    disabled={this.state.readOnly}
                                                                                    value="false"
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                        {strings.EDIT_USER_TENANCY_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            }
                                                            {User.org_admin &&
                                                                <FormGroup controlId="CustomerAdminRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_ORGANIZATION_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="CustomerAdministratorButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                    (User.org_admin !== undefined) ?
                                                                                    `${this.state.organizationAdmin}` :
                                                                                    `${false}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.ChangeOrganizationAdminRight();
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={
                                                                                    (User.org_admin !== undefined && User.org_admin) ?
                                                                                        false || this.state.readOnly :
                                                                                        true || this.state.readOnly
                                                                                    }
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={
                                                                                    (User.org_admin !== undefined && User.org_admin) ?
                                                                                        false || this.state.readOnly :
                                                                                        true || this.state.readOnly
                                                                                    }
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_ORGANIZATION_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            }
                                                            {User.customer_admin && (
                                                                <FormGroup controlId="CustomerAdminRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_CUSTOMER_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="CustomerAdministratorButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                (Rights.customer_admin !== undefined) ?
                                                                                    `${Rights.customer_admin}` :
                                                                                    `${ForwardUser.customer_admin}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.ChangeAdminRight();
                                                                                    this.HandleChangeRights(
                                                                                        event, "customer_admin",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={
                                                                                    Rights.tenant_admin !== undefined ?
                                                                                        (Rights.tenant_admin || this.state.readOnly) :
                                                                                        (ForwardUser.tenant_admin || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={
                                                                                    Rights.tenant_admin !== undefined ?
                                                                                        (Rights.tenant_admin || this.state.readOnly) :
                                                                                        (ForwardUser.tenant_admin || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_CUSTOMER_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {User.administrator && (
                                                                <FormGroup controlId="UserAdministratorRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="UserAdministratorButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                (Rights.administrator !== undefined) ?
                                                                                    `${Rights.administrator}` :
                                                                                    `${ForwardUser.administrator}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.ChangeAdminRight();
                                                                                    this.HandleChangeRights(
                                                                                        event,
                                                                                        "administrator",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={
                                                                                        (TenantAdminRights || this.state.readOnly) ||
                                                                                        (CustomerAdminRights || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={
                                                                                        (TenantAdminRights || this.state.readOnly) ||
                                                                                        (CustomerAdminRights || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_USER_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.administrator && User.gtw_admin) && (
                                                                <FormGroup controlId="GatewayAdminRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_GATEWAY_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="GatewayButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                    (Rights.gtw_admin !== undefined) ?
                                                                                    `${Rights.gtw_admin}` :
                                                                                    `${ForwardUser.gtw_admin}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event,
                                                                                        "gtw_admin",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                        {strings.EDIT_USER_GATEWAY_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.administrator && User.can_register) && (
                                                                <FormGroup controlId="RegistrationRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_DEVICE_ADMIN_RIGHTS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="RegistrationButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={
                                                                                (Rights.can_register !== undefined) ?
                                                                                    `${Rights.can_register}` :
                                                                                    `${ForwardUser.can_register}`
                                                                                }
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event,
                                                                                        "can_register",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                        {strings.EDIT_USER_DEVICE_ADMIN_RIGHTS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                        </Form>
                                                    </Tab.Pane>
                                                )}
                                                {(User.administrator && (User.can_access_gtw_info ||
                                                (User.can_own_gtw && User.gtw_admin) ||
                                                (User.can_add_gtw && User.gtw_admin && User.can_own_gtw) ||
                                                (User.can_mng_gtw && User.gtw_admin && User.can_own_gtw) ||
                                                User.can_access_gtw_loc_info)) && (
                                                    <Tab.Pane eventKey="Gateways">
                                                        <Form horizontal>
                                                            {(User.administrator && User.can_access_gtw_info) && (
                                                                <FormGroup controlId="AccessGtwInfo">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_NETWORK_ACCESS_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="AccessGtwInfoButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue(
                                                                                    "can_access_gtw_info",
                                                                                )}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event, "can_access_gtw_info",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_USER_NETWORK_ACCESS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.gtw_admin && User.can_own_gtw) && (
                                                                <FormGroup controlId="GtwOwnership">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_GATEWAY_OWNERSHIP_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="GtwOwnershipButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue("can_own_gtw")}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event, "can_own_gtw",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_USER_GATEWAY_OWNERSHIP_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.gtw_admin && User.can_add_gtw) && (
                                                                <FormGroup controlId="AddGateway">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_GATEWAY_ADD_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="AddGatewayButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue("can_add_gtw")}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event, "can_add_gtw",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={
                                                                                        DisableGatewaysButtons ?
                                                                                        (false || this.state.readOnly) : (true || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={
                                                                                        DisableGatewaysButtons ?
                                                                                        (false || this.state.readOnly) : (true || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_USER_GATEWAY_ADD_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.gtw_admin && User.can_mng_gtw) && (
                                                                <FormGroup controlId="ManageGateway">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_GATEWAY_MANAGE_LABEL}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="ManageGatewayButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue("can_mng_gtw")}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event, "can_mng_gtw",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={
                                                                                        DisableGatewaysButtons ?
                                                                                        (false || this.state.readOnly) : (true || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={
                                                                                        DisableGatewaysButtons ?
                                                                                        (false || this.state.readOnly) : (true || this.state.readOnly)
                                                                                    }
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.EDIT_USER_GATEWAY_MANAGE_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {(User.administrator && User.can_access_gtw_loc_info) && (
                                                                <FormGroup controlId="GtwLocation">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.EDIT_USER_NETWORK_ACCESS_LOC_INFO}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="GtwLocationButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue(
                                                                                    "can_access_gtw_loc_info",
                                                                                )}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event,
                                                                                        "can_access_gtw_loc_info",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                        {strings.EDIT_USER_NETWORK_ACCESS_LOC_INFO_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                        </Form>
                                                    </Tab.Pane>
                                                )}
                                                {User.loraloc_enable && (
                                                    <Tab.Pane eventKey="LoraLocation">
                                                        <Form horizontal>
                                                            <FormGroup controlId="LoraLocationService">
                                                                <Col
                                                                    componentClass={ControlLabel}
                                                                    sm={5}
                                                                >
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_LORA_LOCATION_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="LoraServiceButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("loraloc_enable")}
                                                                            onChange={(event) => {
                                                                                this.HandleChangeRights(
                                                                                    event, "loraloc_enable",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton
                                                                                value="true"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.YES}
                                                                            </ToggleButton>
                                                                            <ToggleButton
                                                                                value="false"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.NO}
                                                                            </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small
                                                                            className="new_style_help_block_color"
                                                                        >
                                                                        {strings.EDIT_USER_LORA_LOCATION_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>
                                                        </Form>
                                                    </Tab.Pane>
                                                )}
                                                {(User.sys_admin || User.data_archive_admin || User.can_access_logs) && (
                                                    <Tab.Pane eventKey="SystemSettings">
                                                        <Form horizontal>
                                                            {User.sys_admin && (<FormGroup controlId="SystemAdminRights">
                                                                <Col
                                                                    componentClass={ControlLabel}
                                                                    sm={5}
                                                                >
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_SYSTEM_ADMIN_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="SystemAdminButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("sys_admin")}
                                                                            onChange={(event) => {
                                                                                this.ChangeAdminRight();
                                                                                this.HandleChangeRights(
                                                                                    event, "sys_admin",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton
                                                                                value="true"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.YES}
                                                                            </ToggleButton>
                                                                            <ToggleButton
                                                                                value="false"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.NO}
                                                                            </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small
                                                                            className="new_style_help_block_color"
                                                                        >
                                                                        {strings.EDIT_USER_SYSTEM_ADMIN_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>)}

                                                            {User.data_archive_admin && (<FormGroup controlId="DataArchiveAdminRights">
                                                                <Col componentClass={ControlLabel} sm={5}>
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_DATA_ARCHIVE_ADMIN_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="SystemAdminButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("data_archive_admin")}
                                                                            onChange={(event) => {
                                                                                this.HandleChangeRights(
                                                                                    event, "data_archive_admin",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton value="true" disabled={this.state.readOnly}> {strings.YES} </ToggleButton>
                                                                            <ToggleButton value="false" disabled={this.state.readOnly}> {strings.NO} </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small className="new_style_help_block_color">
                                                                        {strings.EDIT_USER_DATA_ARCHIVE_ADMIN_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>)}

                                                            {User.can_access_logs && (<FormGroup controlId="DataArchiveAdminRights">
                                                                <Col componentClass={ControlLabel} sm={5}>
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_CAN_ACCESS_LOGS_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="SystemAdminButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("can_access_logs")}
                                                                            onChange={(event) => {
                                                                                this.HandleChangeRights(
                                                                                    event, "can_access_logs",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton value="true" disabled={this.state.readOnly}> {strings.YES} </ToggleButton>
                                                                            <ToggleButton value="false" disabled={this.state.readOnly}> {strings.NO} </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small className="new_style_help_block_color">
                                                                        {strings.EDIT_USER_CAN_ACCESS_LOGS_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>)}

                                                            {User.sys_admin && (<FormGroup controlId="ExtendedLogging">
                                                                <Col
                                                                    componentClass={ControlLabel}
                                                                    sm={5}
                                                                >
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_EXTENDED_LOGGING_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="ExtendedLoggingButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("verbose_log")}
                                                                            onChange={(event) => {
                                                                                this.HandleChangeRights(
                                                                                    event, "verbose_log",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton
                                                                                value="true"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.YES}
                                                                            </ToggleButton>
                                                                            <ToggleButton
                                                                                value="false"
                                                                                disabled={this.state.readOnly}
                                                                            >
                                                                                {strings.NO}
                                                                            </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small
                                                                            className="new_style_help_block_color"
                                                                        >
                                                                        {strings.EDIT_USER_EXTENDED_LOGGING_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>)}

                                                            {User.data_archive_admin && (<FormGroup controlId="AutoArchiveData">
                                                                <Col componentClass={ControlLabel} sm={5}>
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_AUTO_ARCHIVE_DATA_LABEL}
                                                                    </strong>
                                                                </Col>
                                                                <Col sm={7}>
                                                                    <ButtonToolbar>
                                                                        <ToggleButtonGroup
                                                                            name="SystemAdminButtons"
                                                                            type="radio"
                                                                            defaultValue="false"
                                                                            value={this.ToggleValue("auto_archive_data")}
                                                                            onChange={(event) => {
                                                                                this.HandleChangeRights(
                                                                                    event, "auto_archive_data",
                                                                                );
                                                                            }}
                                                                        >
                                                                            <ToggleButton value="enabled" disabled={this.state.readOnly}> {strings.YES} </ToggleButton>
                                                                            <ToggleButton value="disabled" disabled={this.state.readOnly}> {strings.NO} </ToggleButton>
                                                                            <ToggleButton value="default" disabled={this.state.readOnly}> {strings.DEFAULT} </ToggleButton>
                                                                        </ToggleButtonGroup>
                                                                    </ButtonToolbar>
                                                                    <HelpBlock>
                                                                        <small className="new_style_help_block_color">
                                                                        {strings.EDIT_USER_AUTO_ARCHIVE_DATA_HELP}
                                                                        </small>
                                                                    </HelpBlock>
                                                                </Col>
                                                            </FormGroup>)}

                                                        </Form>
                                                    </Tab.Pane>
                                                )}
                                                {(constants.enable_limit_devices || constants.enable_limit_gateways ||
                                                User.can_view_mac_msg) && (
                                                    <Tab.Pane eventKey="Settings">
                                                        <Form horizontal>
                                                            {constants.enable_limit_devices === true && (
                                                                <FormGroup
                                                                    controlId="MaxNumDevices"
                                                                    validationState={ Rights.max_num_devices < 0 ?
                                                                        "error" : null
                                                                    }
                                                                >
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.MAX_DEVICES}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <FormControl className="limitDevicesGataways"
                                                                            // tslint:disable-next-line:max-line-length
                                                                            disabled={User.is_customer ? (!User.customer_admin || this.state.readOnly) : (!User.administrator || this.state.readOnly)}
                                                                            type="number"
                                                                            value={this.NumberFieldValue(
                                                                                "max_num_devices",
                                                                                "Settings",
                                                                            )}
                                                                            onChange={(event: any) => {
                                                                                this.HandleChangeRights(
                                                                                    event.target.value,
                                                                                    "max_num_devices",
                                                                                    "number",
                                                                                );
                                                                            }}
                                                                        />
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                                {strings.MAX_DEVICES_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {constants.enable_limit_gateways === true && (
                                                                <FormGroup
                                                                    controlId="MaxNumGtws"
                                                                    validationState={
                                                                        Rights.max_num_gateways < 0 ? "error" : null
                                                                    }
                                                                >
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.MAX_GATEWAYS}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <FormControl className="limitDevicesGataways"
                                                                            // tslint:disable-next-line:max-line-length
                                                                            disabled={User.is_customer ? (!User.customer_admin || this.state.readOnly) : (!User.administrator || this.state.readOnly)}
                                                                            type="number"
                                                                            value={this.NumberFieldValue(
                                                                                "max_num_gateways",
                                                                                "Settings",
                                                                            )}
                                                                            onChange={(event: any) => {
                                                                                this.HandleChangeRights(
                                                                                    event.target.value,
                                                                                    "max_num_gateways",
                                                                                    "number",
                                                                                );
                                                                            }}
                                                                        />
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                                {strings.MAX_GATEWAYS_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                            {User.can_view_mac_msg && (
                                                                <FormGroup controlId="MacMsgRights">
                                                                    <Col
                                                                        componentClass={ControlLabel}
                                                                        sm={5}
                                                                    >
                                                                        <strong className="new_style_font_weight">
                                                                            {strings.MAC_MSG}
                                                                        </strong>
                                                                    </Col>
                                                                    <Col sm={7}>
                                                                        <ButtonToolbar>
                                                                            <ToggleButtonGroup
                                                                                name="MacMsgButtons"
                                                                                type="radio"
                                                                                defaultValue="false"
                                                                                value={this.ToggleValue(
                                                                                    "can_view_mac_msg",
                                                                                )}
                                                                                onChange={(event) => {
                                                                                    this.HandleChangeRights(
                                                                                        event, "can_view_mac_msg",
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ToggleButton
                                                                                    value="true"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.YES}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    value="false"
                                                                                    disabled={this.state.readOnly}
                                                                                >
                                                                                    {strings.NO}
                                                                                </ToggleButton>
                                                                            </ToggleButtonGroup>
                                                                        </ButtonToolbar>
                                                                        <HelpBlock>
                                                                            <small
                                                                                className="new_style_help_block_color"
                                                                            >
                                                                            {strings.MAC_MSG_HELP}
                                                                            </small>
                                                                        </HelpBlock>
                                                                    </Col>
                                                                </FormGroup>
                                                            )}
                                                        </Form>
                                                    </Tab.Pane>
                                                )}
                                                <Tab.Pane eventKey="EditUserInfo">
                                                    <Form>
                                                        <FormGroup
                                                            controlId="UserInfo"
                                                            className="margin_bottom_0"
                                                        >
                                                            <Col smOffset={1} sm={11} >
                                                                <ControlLabel>
                                                                    <strong className="new_style_font_weight">
                                                                        {strings.EDIT_USER_INFO_LABEL}
                                                                    </strong>
                                                                </ControlLabel>
                                                                <FormControl
                                                                    componentClass="textarea"
                                                                    disabled={this.state.readOnly}
                                                                    value={(Rights.userinfo !== undefined) ?
                                                                        Rights.userinfo : ForwardUser.userinfo
                                                                    }
                                                                    className="new_style_textarea"
                                                                    onChange={(event: any) => {
                                                                        this.HandleChangeRights(
                                                                            event.target.value,
                                                                            "userinfo",
                                                                        );
                                                                    }}
                                                                />
                                                                <HelpBlock>
                                                                    <small
                                                                        className="new_style_help_block_color"
                                                                    >
                                                                        {strings.EDIT_USER_INFO_HELP}
                                                                    </small>
                                                                </HelpBlock>
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                </Tab.Pane>
                                                { (User.tenant_admin && ( User.customer_admin && User.is_customer ) || ( User.org_admin && User.is_organisation )) && (
                                                    <Tab.Pane eventKey="UserTenancy">
                                                        <Col smOffset={1} sm={11}>
                                                            <div className="margin-bottom-tenancy">
                                                                <strong className="display_inline">
                                                                    {strings.EDIT_CUSTOMER_TENNANCY_LABEL}:
                                                                </strong> &nbsp;
                                                                {this.state.ActualTenant && this.state.ActualTenant.name && (
                                                                    <div className="display_inline">
                                                                        {strings.formatString(strings.TENANT_MSG, {
                                                                            name: this.state.ActualTenant.name,
                                                                        })}
                                                                    </div>
                                                                )}
                                                                {this.state.ActualTenant && !this.state.ActualTenant.name && (
                                                                    <div className="display_inline">
                                                                        {strings.formatString(strings.TENANT_DELETED_MSG, {
                                                                            deletedTenantId: this.state.ActualTenant,
                                                                        })}
                                                                    </div>
                                                                )}
                                                                {!this.state.ActualTenant && (
                                                                    <div className="display_inline">
                                                                        {strings.NO_TENANT_ASSIGNED}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {this.state.ShowTenantTable === false && (
                                                                <Button
                                                                    className={`pull-right ${this.state.readOnly == true ? "" : "black_b_btn"}`}
                                                                    disabled={this.state.readOnly}
                                                                    onClick={this.ShowTenantTable}
                                                                >
                                                                    {strings.CHANGE_TENANT}
                                                                </Button>
                                                            )}
                                                            {this.state.ShowTenantTable && (
                                                                <Button
                                                                    className="
                                                                        pull-right
                                                                        red_b_btn_cancel
                                                                    "
                                                                    disabled={this.state.readOnly}
                                                                    onClick={this.ShowTenantTable}
                                                                >
                                                                    {strings.CANCEL}
                                                                </Button>
                                                            )}
                                                            <HelpBlock>
                                                                <small className="new_style_help_block_color">
                                                                    {strings.EDIT_CUSTOMER_TENNANCY_HELP}
                                                                </small>
                                                            </HelpBlock>
                                                        </Col>
                                                        {this.state.ShowTenantTable && (
                                                            <Col sm={12}
                                                                className="all_padding_0"
                                                                id="data_table_modal"
                                                            >
                                                                <TenancyTable
                                                                    Tenants={this.state.Tenants}
                                                                    TablePagination={this.LoadTenants}
                                                                    GetTableData={this.TenantsPageList}
                                                                    PaginationData= {this.state.PaginationData}
                                                                    SelectedRow={this.SelectTenant}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Tab.Pane>
                                                )}
                                                { (User.org_admin && User.is_customer) && (
                                                    <Tab.Pane eventKey="UserOrganization">
                                                        <Col smOffset={1} sm={11}>
                                                            {this.state.ShowOrganizationTable === false && (
                                                                <Button
                                                                    className="pull-right black_b_btn"
                                                                    disabled={this.state.readOnly}
                                                                    onClick={this.ShowOrganizationTable}
                                                                >
                                                                    {strings.CHANGE_ORGANIZATION}
                                                                </Button>
                                                            )}
                                                            {this.state.ShowOrganizationTable && (
                                                                <Button
                                                                    className="
                                                                        pull-right
                                                                        red_b_btn_cancel
                                                                    "
                                                                    onClick={this.ShowOrganizationTable}
                                                                >
                                                                    {strings.CANCEL}
                                                                </Button>
                                                            )}
                                                        </Col>
                                                        {this.state.ShowOrganizationTable && (
                                                            <Col sm={12}
                                                                className="all_padding_0"
                                                                id="data_table_modal"
                                                            >
                                                                <OrganizationTable
                                                                    Organizations={this.state.Organizations}
                                                                    TablePagination={this.LoadOrganizations}
                                                                    GetTableData={this.OrganizationsPageList}
                                                                    PaginationData= {this.state.PaginationDataOrganizations}
                                                                    SelectedRow={this.SelectOrganization}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Tab.Pane>
                                                )}
                                                {(DeviceProfiles || ServiceProfiles || ConnectivityProfiles ||
                                                RoamingProfiles || ChannelProfiles || QoSProfiles) && (
                                                    <Tab.Pane eventKey="Profiles">
                                                        <ButtonToolbar className="margin_left_0">
                                                            {DeviceProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                        (this.state.ActiveProfileTab === "device") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("device");
                                                                    }}
                                                                >
                                                                    {strings.NAV_DEVICE_PROFILES}
                                                                </Button>
                                                            )}
                                                            {ServiceProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                        (this.state.ActiveProfileTab === "service") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("service");
                                                                    }}
                                                                >
                                                                    {strings.NAV_SERVICE_PROFILES}
                                                                </Button>
                                                            )}
                                                            {ConnectivityProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                    (this.state.ActiveProfileTab === "connectivity") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("connectivity");
                                                                    }}
                                                                >
                                                                    {strings.NAV_CONNECTIVITY_PROFILES}
                                                                </Button>
                                                            )}
                                                            {RoamingProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                        (this.state.ActiveProfileTab === "roaming") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("roaming");
                                                                    }}
                                                                >
                                                                    {strings.NAV_ROAMING_PROFILES}
                                                                </Button>
                                                            )}
                                                            {ChannelProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                        (this.state.ActiveProfileTab === "channel") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("channel");
                                                                    }}
                                                                >
                                                                    {strings.NAV_CHANNEL_PROFILES}
                                                                </Button>
                                                            )}
                                                            {QoSProfiles && (
                                                                <Button
                                                                    className="button_profiles"
                                                                    active={
                                                                        (this.state.ActiveProfileTab === "qos") ?
                                                                            true : false
                                                                    }
                                                                    onClick={() => {
                                                                        this.ChangeProfileTab("qos");
                                                                    }}
                                                                >
                                                                    {strings.NAV_QOS_PROFILES}
                                                                </Button>
                                                            )}
                                                        </ButtonToolbar>
                                                        {(this.state.ActiveProfileTab !== undefined) && (
                                                            <ProfilesRights
                                                                ActiveTab={this.state.ActiveProfileTab}
                                                                User={User}
                                                                readOnly={this.state.readOnly}
                                                                ForwardUser={ForwardUser}
                                                                HandleRightChange={this.HandleChangeRights}
                                                                DisableButton={this.state.DisableButton}
                                                            />
                                                        )}
                                                    </Tab.Pane>
                                                )}
                                                {(User.customer_admin && constants.enable_password_policies &&
                                                (User.is_customer || PassPolicy)) && (
                                                    <Tab.Pane eventKey="PasswordPolicy">
                                                        <Row className="pass_policy_row padding_top_0_important">
                                                            <Col
                                                                md={4}
                                                            >
                                                                <Form className="pass_policy_box_bg">
                                                                    <FormGroup
                                                                        controlId="Users"
                                                                        className="padding_top_1"
                                                                    >
                                                                        <h4 className="pass_policy_h">
                                                                            <strong className="new_style_font_weight">
                                                                                {strings.USERS}
                                                                            </strong>
                                                                        </h4>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="PassHistory"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_HISTORY}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar >
                                                                                <ToggleButtonGroup
                                                                                    name="PassHistory"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    disabled={this.state.readOnly}
                                                                                    value={
                                                                    `${PasswordPolicies.user.enforce_password_history}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enforce_password_history",
                                                                                            "user",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_HISTORY_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_HISTORY_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="ComplexPass"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.COMPLEX_PASSWORD}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar>
                                                                                <ToggleButtonGroup
                                                                                    name="ComplexPass"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    value={
                                                                    `${PasswordPolicies.user.enable_complex_password}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enable_complex_password",
                                                                                            "user",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.COMPLEX_PASSWORD_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block">
                                                                                {strings.COMPLEX_PASSWORD_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="UserMinPassLength"
                                                                        className="margin_bottom_0"
                                                                        validationState={
                                                            ((PasswordPolicies.user.min_password_length !== null) &&
                                                            (PasswordPolicies.user.min_password_length < 8  || PasswordPolicies.user.min_password_length % 1 !== 0)) ?
                                                                        "error" : null
                                                                        }
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_LENGTH}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                min="8"
                                                                                disabled={this.state.readOnly}
                                                                                className="pass_policy_input"
                                                                                value={this.NumberFieldValue(
                                                                                    "min_password_length",
                                                                                    "PasswordPolicy",
                                                                                    "user",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "min_password_length",
                                                                                        "user",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_LENGTH_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_LENGTH_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="UserMaxPassAge"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_AGE}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                className="pass_policy_input"
                                                                                disabled={this.state.readOnly}
                                                                                value={this.NumberFieldValue(
                                                                                    "max_password_age",
                                                                                    "PasswordPolicy",
                                                                                    "user",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "max_password_age",
                                                                                        "user",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_AGE_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_AGE_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                            <Col
                                                                md={4}
                                                            >
                                                                <Form className="pass_policy_box_bg">
                                                                    <FormGroup
                                                                        controlId="Administrators"
                                                                        className="padding_top_1"
                                                                    >
                                                                        <h4 className="pass_policy_h">
                                                                            <strong className="new_style_font_weight">
                                                                                {strings.ADMINISTRATORS}
                                                                            </strong>
                                                                        </h4>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="PassHistory"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_HISTORY}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar>
                                                                                <ToggleButtonGroup
                                                                                    name="PassHistory"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    disabled={this.state.readOnly}
                                                                                    value={
                                                                    `${PasswordPolicies.admin.enforce_password_history}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enforce_password_history",
                                                                                            "admin",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_HISTORY_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_HISTORY_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="ComplexPass"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.COMPLEX_PASSWORD}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar>
                                                                                <ToggleButtonGroup
                                                                                    name="ComplexPass"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    disabled={this.state.readOnly}
                                                                                    value={
                                                                    `${PasswordPolicies.admin.enable_complex_password}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enable_complex_password",
                                                                                            "admin",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.COMPLEX_PASSWORD_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.COMPLEX_PASSWORD_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="AdminMinPassLength"
                                                                        className="margin_bottom_0"
                                                                        validationState={
                                                                            ((PasswordPolicies.admin.min_password_length !== null) &&
                                                                            (PasswordPolicies.admin.min_password_length < 8  || PasswordPolicies.admin.min_password_length % 1 !== 0)) ?
                                                                                        "error" : null
                                                                        }
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_LENGTH}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                min="8"
                                                                                disabled={this.state.readOnly}
                                                                                className="pass_policy_input"
                                                                                value={this.NumberFieldValue(
                                                                                    "min_password_length",
                                                                                    "PasswordPolicy",
                                                                                    "admin",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "min_password_length",
                                                                                        "admin",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_LENGTH_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_LENGTH_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="AdminMaxPassAge"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_AGE}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                className="pass_policy_input"
                                                                                disabled={this.state.readOnly}
                                                                                value={this.NumberFieldValue(
                                                                                    "max_password_age",
                                                                                    "PasswordPolicy",
                                                                                    "admin",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "max_password_age",
                                                                                        "admin",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_AGE_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_AGE_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                            <Col
                                                                md={4}
                                                            >
                                                                <Form className="pass_policy_box_bg">
                                                                    <FormGroup
                                                                        controlId="Applications"
                                                                        className="padding_top_1"
                                                                    >
                                                                        <h4 className="pass_policy_h">
                                                                            <strong className="new_style_font_weight">
                                                                                {strings.NAV_APPLICATIONS_ITEM}
                                                                            </strong>
                                                                        </h4>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="PassHistory"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            sm={8}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_HISTORY}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar>
                                                                                <ToggleButtonGroup
                                                                                    name="PassHistory"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    value={
                                                                    `${PasswordPolicies.app.enforce_password_history}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enforce_password_history",
                                                                                            "app",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_HISTORY_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_HISTORY_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="ComplexPass"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.COMPLEX_PASSWORD}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <ButtonToolbar>
                                                                                <ToggleButtonGroup
                                                                                    name="ComplexPass"
                                                                                    type="radio"
                                                                                    defaultValue="false"
                                                                                    value={
                                                                    `${PasswordPolicies.app.enable_complex_password}`
                                                                                    }
                                                                                    onChange={(event) => {
                                                                                        this.HandlePasswordPolicy(
                                                                                            event,
                                                                                            "enable_complex_password",
                                                                                            "app",
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <ToggleButton
                                                                                        value="true"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.YES}
                                                                                    </ToggleButton>
                                                                                    <ToggleButton
                                                                                        value="false"
                                                                                        disabled={this.state.readOnly}
                                                                                        className="
                                                                                            pass_policy_btn_new_style
                                                                                        "
                                                                                    >
                                                                                        {strings.NO}
                                                                                    </ToggleButton>
                                                                                </ToggleButtonGroup>
                                                                            </ButtonToolbar>
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.COMPLEX_PASSWORD_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.COMPLEX_PASSWORD_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="AppMinPassLength"
                                                                        className="margin_bottom_0"
                                                                        validationState={
                                                                            ((PasswordPolicies.app.min_password_length !== null) &&
                                                                            (PasswordPolicies.app.min_password_length < 8  || PasswordPolicies.app.min_password_length % 1 !== 0)) ?
                                                                                        "error" : null
                                                                        }
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_LENGTH}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                min="8"
                                                                                disabled={this.state.readOnly}
                                                                                className="pass_policy_input"
                                                                                value={this.NumberFieldValue(
                                                                                    "min_password_length",
                                                                                    "PasswordPolicy",
                                                                                    "app",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "min_password_length",
                                                                                        "app",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_LENGTH_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_LENGTH_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                    <FormGroup
                                                                        controlId="AppMaxPassAge"
                                                                        className="margin_bottom_0"
                                                                    >
                                                                        <Col
                                                                            componentClass={ControlLabel}
                                                                            className="pass_policy_label
                                                                                padding_left_right_0 pull-left
                                                                                margin_left_0"
                                                                        >
                                                                            {strings.PASSWORD_AGE}
                                                                        </Col>
                                                                        <Col
                                                                            className="padding_left_right_0 pull-right"
                                                                        >
                                                                            <FormControl
                                                                                type="number"
                                                                                disabled={this.state.readOnly}
                                                                                className="pass_policy_input"
                                                                                value={this.NumberFieldValue(
                                                                                    "max_password_age",
                                                                                    "PasswordPolicy",
                                                                                    "app",
                                                                                )}
                                                                                onChange={(event: any) => {
                                                                                    this.HandlePasswordPolicy(
                                                                                        event.target.value,
                                                                                        "max_password_age",
                                                                                        "app",
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <OverlayTrigger
                                                                            trigger={["hover", "focus"]}
                                                                            placement="bottom"
                                                                            overlay={this.PopOverHover(
                                                                                strings.PASSWORD_AGE_DESC_HOVER,
                                                                            )}
                                                                        >
                                                                            <HelpBlock
                                                                                className="pass_policy_desc
                                                                                inline_block"
                                                                            >
                                                                                {strings.PASSWORD_AGE_DESC}
                                                                            </HelpBlock>
                                                                        </OverlayTrigger>
                                                                    </FormGroup>
                                                                </Form>
                                                            </Col>
                                                        </Row>
                                                    </Tab.Pane>
                                                )}

                                                <Tab.Pane eventKey="Tags">
                                                    <Row className="pass_policy_row padding_top_0_important">
                                                        <Table className="table table-striped table-hover new_style_dark tags-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{strings.EDIT_USER_TAGS_NAME}</th>
                                                                    <th>{strings.EDIT_USER_TAGS_DESCRIPTION}</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="table-body">
                                                                { listTags }

                                                                <tr className="new-tag-container">
                                                                    <td>
                                                                        <input
                                                                            ref={this.newTagNameRef}
                                                                            type="text"
                                                                            value={this.state.newTagName}
                                                                            className="new-tag new-tag-name"
                                                                            disabled={this.state.readOnly}
                                                                            placeholder="Enter Tag ID"
                                                                            onKeyPress={this.handleKeyPress}
                                                                            onChange={this.newTagNameChange.bind(that)}
                                                                        />

                                                                        {!this.state.isTagNameValid && (
                                                                            <span className="popover-new-tag-name">
                                                                                {strings.TAG_NAME_AVAILABLE_CHARS}
                                                                            </span>
                                                                        )}

                                                                        {this.state.sameTagName && (
                                                                            <span className="popover-tag-name-react">
                                                                                {strings.TAG_NAME_UNIQUE}
                                                                            </span>
                                                                        )}
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            ref={this.newTagDescRef}
                                                                            type="text"
                                                                            value={this.state.newTagDesc}
                                                                            className="new-tag new-tag-description"
                                                                            disabled={this.state.readOnly}
                                                                            placeholder="Enter Tag Value"
                                                                            onKeyPress={this.handleKeyPress}
                                                                            onChange={this.newTagDescChange.bind(that)}
                                                                        />
                                                                    </td>

                                                                    <td className="tag-acion-container">
                                                                        <button
                                                                            className="fa fa-check fa-fw edit-icon"
                                                                            disabled={this.state.readOnly}
                                                                            onClick={that.insertNewTag.bind(that)} >
                                                                        </button>

                                                                        <button
                                                                            className="fa fa-times fa-fw clear-input"
                                                                            disabled={this.state.readOnly}
                                                                            onClick={that.clearTagInputs.bind(that)} >
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Row>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>)}
                    </Modal.Body>
                    <Modal.Footer className="new_style_modal_footer">
                        <Button
                            className={`glyphicon pull-left ${this.state.unlockButtonUserSettings}`}
                            onClick={this.unlockUserSettings}
                            >
                        </Button>
                        <small className="pull-left text-near-glyphicon">
                            {this.state.unlockChangeText}
                        </small>

                        {this.state.Loading === false && (
                            <Button
                                className="new_style_btn_ok"
                                onClick={this.SubmitUserRights}
                                disabled={(this.state.readOnly) || ((this.state.hasSameTagName || !this.state.globalTagNameValid || ((this.state.NewPassword.length > 0) &&
                                    (this.state.NewPassword !== this.state.ConfirmPassword)) ||
                                    ((Rights.max_num_devices < 0) || (Rights.max_num_gateways < 0)) ||
                                    ((this.state.NewPassword.length > 0) && (!this.state.LengthOK ||
                                    !this.state.ComplexPassword)) || minPassFieldValidation))
                                }
                            >
                                {`${strings.ADD_USER_UPDATE} ${ForwardUser.is_customer ?
                                    strings.USER_TYPE_CUSTOMER : strings.USER_TYPE_CUSTOMER}`
                                }
                            </Button>
                        )}
                        <Button
                            className="new_style_btn_cancel"
                            onClick={this.props.ToggleModal}
                        >
                            {strings.CANCEL}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
