/*jslint node: true, sloppy: true, bitwise: true, vars: true, eqeq: true, plusplus: true, nomen: true, es5:true */
/*global angular, atob, btoa, google, MessageService.showMessage, constants, WebSocket */

function WelcomeController(MessageService, ToastService, $uibModal, $log, $translate, $filter, $cookies, UserService, $location, CommonService) {
	var $translateSvc = $translate;
	var vm = this;
    vm.constants = constants;
	var $translate = $filter('translate');
	
	const { error, message } = CommonService.parseQueryString(window.location.search.substring(1));

	if (error) {
		MessageService.showMessage({
			title: $translate("ERROR"),
			body: $translate(error)
		});
	} else if (message) {
		MessageService.showMessage({
			title: $translate("NOTIFICATION"),
			body: $translate(message)
		});
	}
};

var app = angular.module('dassUiModule');

app.controller('WelcomeController', ['MessageService', 'ToastService', '$uibModal', '$log', '$translate', '$filter', '$cookies', 'UserService', '$location', "CommonService", WelcomeController]);
