import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { DefaultPaginationSize, DefaultSizePerPage } from "../../services/Organizations";
import { FilterText } from "./../../controllers/Profiles/ProfileTableController";
import { strings } from "./../../services/Localization";

export default class OrganizationTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            CountClicks: 0,
            CurrentPage: 1,
            FilterColumns: {},
            PaginationSize: DefaultPaginationSize || 3,
            SizePerPage: DefaultSizePerPage || 3,
            sortName: undefined,
            sortOrder: undefined,
        };
    }

    public renderPaginationPanel = (props: any) => {
        setTimeout(() => {
            if (props.currPage < 1) {
                props.changePage(1);
            } else if (props.currPage > props.totalPages) {
                props.changePage(props.totalPages);
            }
        }, 100);
        return (
            <div className="container-fluid all_padding_0 user_settings_organization_table">
                <div className="row React_marg_11_top">
                    <div className="col-sm-3 React_per_page_style all_padding_0">
                        <span>{strings.SHOWING_PER_PAGE} </span>
                        {props.components.sizePerPageDropdown}
                    </div>
                    <div
                        className="col-sm-offset-1 col-sm-8 all_padding_0"
                        id="React_pagination_style"
                    >
                        {props.components.pageList}
                    </div>
                </div>
            </div>
        );
    }

    public onRowSelect = (row) => {
        this.props.SelectedRow(row);
    }

    public onPageChange = async (page, sizePerPage) => {
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions();
        if ((page > 0) && (page <= this.props.PaginationData.pages.length) &&
            (this.state.SizePerPage === sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });

            this.props.GetTableData(
                this.props.PaginationData.pages[page - 1].page_state,
                sizePerPage,
                FilterValues,
                Sorting,
            );

        } else if ((page > 0) && (page <= this.props.PaginationData.pages.length) &&
            (this.state.SizePerPage !== sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });
            this.props.TablePagination(
                sizePerPage,
                page,
                FilterValues,
                Sorting,
            );
        }
    }

    public FilterOptions = (FilterColumns = this.state.FilterColumns) => {
        const FilterValues: any = [];
        if (Object.keys(FilterColumns).length > 0) {
            Object.keys(FilterColumns).map((filter: any) => {
                const SetFilterValue: any = {};
                if (filter === "organizationid") {
                    SetFilterValue.colName = "id";
                    SetFilterValue.colValue = FilterColumns[filter].value;
                } else if (filter === "name") {
                    SetFilterValue.colName = "name";
                    SetFilterValue.colValue = FilterColumns[filter].value;
                } else {
                    SetFilterValue.colName = filter;
                    SetFilterValue.colValue = FilterColumns[filter].value;
                }
                FilterValues.push(SetFilterValue);
            });
        }
        return FilterValues;
    }

    public onFilterChange = async (FilterObj) => {
        this.setState({
            FilterColumns: FilterObj,
        });
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions(FilterObj);
        this.props.TablePagination(
            this.state.SizePerPage,
            this.state.CurrentPage,
            FilterValues,
            Sorting,
        );
    }

    public SortingOptions = (sortName = this.state.sortName, sortOrder = this.state.sortOrder) => {
        if (sortName === "name") {
            sortName = "name";
        }
        const Sorting = {
            sortName,
            sortOrder,
        };
        return Sorting;
    }

    public onSortChange = async (sortName, sortOrder) => {
        const Sorting = await this.SortingOptions(sortName, sortOrder);
        const FilterValues = await this.FilterOptions();
        let CountClicks = this.state.CountClicks;
        if (this.state.sortName === sortName) {
            CountClicks = CountClicks + 1;
        } else {
            CountClicks = 0;
        }
        this.setState({
            CountClicks,
            sortName,
            sortOrder,
        });
        if (CountClicks === 2) {
            Sorting.sortName = undefined,
            Sorting.sortOrder = undefined,
            this.setState({
                CountClicks: 0,
                sortName: undefined,
                sortOrder: undefined,
            });
            this.props.TablePagination(
                this.state.SizePerPage,
                this.state.CurrentPage,
                FilterValues,
                Sorting,
            );
        } else {
            this.props.TablePagination(
                this.state.SizePerPage,
                this.state.CurrentPage,
                FilterValues,
                Sorting,
            );
        }
    }

    public renderSizePerPageDropDown = (props) => {
        return (
            <select
                className="form-control custom_size_per_page_drop_down"
                defaultValue={props.currSizePerPage}
                onChange={(event) => props.changeSizePerPage(event.target.value)}
            >
                {
                    props.sizePerPageList.map((optionVal, index) => {
                        return (
                            <option
                                key={index}
                                value={optionVal.value}
                            >
                                {optionVal.text}
                            </option>
                        );
                    })
                }
            </select>
        );
    }
    public customTextFilter = (filterHandler, customFilterParameters) => {
        return <FilterText FilterHandler = {filterHandler} customFilterParameters = {customFilterParameters}/>;
    }
    public render() {
        const selectRow: any = {
            mode: "radio",
            onSelect: this.onRowSelect,
        };
        const options: any = {
            alwaysShowAllBtns: true,
            firstPage: strings.FIRST,
            lastPage: strings.LAST,
            nextPage: strings.NEXT,
            noDataText: strings.NO_ITEMS_MATCHING,
            onFilterChange: this.onFilterChange,
            onPageChange: this.onPageChange,
            onSortChange: this.onSortChange,
            page: this.state.CurrentPage,
            pageStartIndex: 1,
            paginationPanel: this.renderPaginationPanel,
            paginationPosition: "top",
            paginationSize: this.state.PaginationSize,
            prePage: strings.PREVIOUS,
            sizePerPage: this.state.SizePerPage,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            sizePerPageList: [{
                text: "3", value: 3,
            }, {
                text: "10", value: 10,
            }, {
                text: "25", value: 25,
            }, {
                text: "50", value: 50,
            }, {
                text: "100", value: 100,
            }],
            sortIndicator: false,
            sortName: this.state.sortName,
            sortOrder: this.state.sortOrder,
            withFirstAndLast: true,
        };
        const CustomFilter: any = "CustomFilter";
        const CustomPlaceHolderTableColId: any = {
                placeholder: "ORGANIZATION_ID"};
        return (
            <BootstrapTable
                striped={true}
                hover={true}
                pagination={true}
                remote={true}
                options={options}
                selectRow={selectRow}
                data={this.props.Organizations}
                fetchInfo={{
                    dataTotalSize: (this.props.PaginationData.total > 0) ? this.props.PaginationData.total : 1,
                }}
                tableContainerClass="ReactPages new_style_dark"
            >
                <TableHeaderColumn
                    isKey={true}
                    dataField="userid"
                    filter={{
                        customFilterParameters: CustomPlaceHolderTableColId,
                        getElement: this.customTextFilter,
                        type: CustomFilter,
                    }}
                >
                    {strings.ORGANIZATION_ID}
                </TableHeaderColumn>
            </BootstrapTable>
        );
    }
}
