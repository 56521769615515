var app = angular.module('dassUiModule');

const strings = {
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_ZH: '中文',

    GLOBAL_APP_STOP: 'Stop Default Push',
    GLOBAL_APP_START: 'Start Default Push',
    GLOBAL_PUSH_CONFIGURATION: "Global Push Configuration",

    EDIT_GLOBAL_PUSH_CONFIG: 'Edit Default Push Config',
    SAVE_GLOBAL_PUSH_CFG_SUCCESS: "Successfully saved global push configuration",
    SAVE_GLOBAL_PUSH_CFG_FAIL: "Failed to save global push configuration",

    DEFAULT_PUSH_CONFIGURATION: 'Default Push Configuration',

    // Navigation
    NAV_SIGNED_IN_AS: 'Signed in as',
    NAV_WELCOME: 'Welcome',
    NAV_SIGN_IN_PROMPT: 'Not signed in',
    NAV_USER_SETTINGS: 'User Settings',
    NAV_RETURN_TO: 'Return to {originalUser}',
    NAV_RETURN_VIA: 'via',
    NAV_CUSTOMER_ADMINISTRATOR: '(Customer Administrator)',

    NAV_LANGUAGES_ITEM: 'Language',

    NAV_DEVICES_ITEM: 'Devices',
    NAV_DEVICES_LIST_DEVICES: 'Manage Devices',
    NAV_MANAGE_GROUPS: 'Groups',
    NAV_MANAGE_GROUPS_TAB: 'Manage Groups',
    NAV_MANAGE_MULTICAST_ACTIVITY: 'Multicast Activity',
    NAV_DEVICES_MAP: 'Network Map',
    NAV_GATEWAYS_MAP: 'Show Gateway Map',
    NAV_DEVICES_BATCH: 'Batch Registration',

    NAV_DATA_ITEM: 'Data',
    NAV_DATA_SHOW_PACKETS: 'Device Activity',

    NAV_GATEWAYS_ITEM: 'Gateways',
    NAV_GATEWAYS_LIST_GATEWAYS: 'Manage Gateways',

    NAV_APPLICATIONS_ITEM: 'Applications',
    NAV_APPS_LIST: 'Manage Applications',
    NAV_APPS_HTTP_PUSH_CONFIG: 'HTTP Push configuration',

    NAV_USERS_ITEM: 'Users',
    NAV_USERS_LIST_USERS: 'Manage Users',
    NAV_ORGANIZATION_LIST_USERS: 'Manage Organization',


    NAV_CUSTOMERS_ITEM: 'Customers',
    NAV_CUSTOMERS_LIST_CUSTOMERS: 'Manage Customers',
    NAV_DASHBOARD: 'Dashboard',
    NAV_CONFIGURE: 'Configure',

    ACCOUNT_TYPE_CUSTOMER: 'Customer Account',
    CHOSE_DEVICE: 'Choose a device to manage',
    // Welcome
    WELCOME_TITLE: 'Welcome',
    WELCOME_MESSAGE: 'This is the OrbiWise end-customer device dashboard',
    WELCOME_LEARN_MORE: 'Learn More',
    WELCOME_SIGN_UP: 'Sign Up',

    SIGNUP_TITLE: 'Sign Up',
    SIGNUP_MESSAGE: "Enter customer details to sign up.",
    SIGNUP_BUTTON: 'Sign Up',

    // My Apps
    MY_APPS_TITLE: 'My Apps',
    MANAGE_APPS_TITLE: 'Manage Apps',
    MY_APPS_ADD_APP: 'Add Application',
    MY_APPS_ACTION_ITEM: 'Action',
    MY_APPS_ACTION_EDIT: 'Edit App',
    MY_APPS_ACTION_DELETE: 'Delete App',
    MY_APPS_ACTION_BULK_DELETE: 'Delete Apps',
    APP_NAME: 'App Name',
    APP_ID: 'App ID',
    UUID: 'UUID',

    SEARCH_MIN_LENGTH_TOOLTIP_1: "Minimum ",
    SEARCH_MIN_LENGTH_TOOLTIP_2: "and Return key pressed",
    SEARCH_MIN_LENGTH_TOOLTIP_3: " character ",
    SEARCH_MIN_LENGTH_TOOLTIP_4: " characters ",
    SEARCH_FIND_DEVICE_TOOLTIP: " Please enter complete DevEUI and press enter",
    // My devices
    MY_DEVICES_TITLE: 'Devices',
    MY_DEVICES_ADD_DEVICE: 'Add Device w/o profile',
    MY_DEVICES_ACTION_ITEM: 'Action',
    MY_DEVICES_ACTION_EDIT: 'Edit Device',
    MY_DEVICES_ACTION_SEND: 'Send to device',
    MY_DEVICES_ACTION_DATA: 'View Data',
    MY_DEVICES_ACTION_POSITION: 'Position on map',
    MY_DEVICES_ACTION_EDIT_DEVICES: 'Edit Devices',
    MY_DEVICES_ACTION_DELETE_DEVICES: 'Delete Devices',
    MY_DEVICES_ACTION_SUSPEND: 'Suspend Device',
    MY_DEVICES_ACTION_UNSUSPEND: 'Unsuspend Device',
    MY_DEVICES_ACTION_DELETE: 'Delete Device',
    MY_DEVICES_ACTION_ASSIGN_GROUP: "Assign to Groups",
    SELECT_DEVICE_MODAL_FILTER: 'Filter Devices',
    NO_ITEMS_MATCHING: "No items matching the provided filters were found.",
    MY_DEVICES_NUMBER_MESSAGE: "{numberOfDevices, plural, =0{There are no devices} one{Showing 1 device} other{Showing # devices}}{scanning, plural, =0{} other{ (searching #)}}.",
    MY_DEVICES_NUMBER_MESSAGE_TABLE: "There {total, plural, =0{are no devices} one{is 1 device} other{are # devices}} found.",  // Is this used??
    MY_USERS_NUMBER_MESSAGE_TABLE: "There {total, plural, =0{are no users} one{is 1 user} other{are # users}} found.",
    FIND_NUMBER_MESSAGE_TABLE: "There {total, plural, =0{are no records} one{is 1 record} other{are # records}} found.",

    MY_DEVICES_APPLICATIONS: "Apps",
    MY_DEVICES_GROUPS: "Groups",
    MY_DEVICES_ANY_GROUP: "Any group",
    MY_DEVICES_GROUP_PROMPT: "Please select a group from the list below:",
    MY_DEVICES_SELECTED_MESSAGE: '{numSelected} devices selected. ',
    MY_DEVICES_ASSIGN_LABEL: '{numSelected} Devices selected.  To assign them to a group, pick one from the drop-down below and click "Assign Devices".',
    MY_DEVICES_APPLY: "Assign Devices",
    CLOSE: "Close",
    ASSIGN_GROUPS: "Assign Groups for device '{deveui}'",
    MANAGE_GROUPS: "Groups",
    ADD_GROUP: "Add Group",
    ADD_GROUPS: "Add Groups",
    ADD_APP: "Add App",
    ADD_APPS: "Add Apps",
    REMOVE_APPS: "Remove Apps",
    ADD_TO_GROUPS: "Add to Groups",
    ADD_TO_APPS: "Add to Apps",
    GROUP_ASSIGNMENT: "Group Assignment",
    APP_ASSIGNMENT: "App Assignment",
    REMOVE_FROM_APPS: "Remove from Apps",
    REMOVE_FROM_GROUPS: "Remove from Groups",
    EDIT_GROUP: "Edit Group",
    UPDATE_GROUP: "Update Group",
    SAVE_GROUP: "Save Group",
    DELETE_GROUP: "Delete Group",
    DELETE_SELECTED_GROUPS: "Delete Groups",
    REMOVE_GROUPS: "Remove Groups",
    SEND_MULTICAST: "Send Multicast",
    GROUP_ID: "Group ID",
    GROUP_TITLE: "Title",
    ADD_GROUP_MODAL_GROUP_ID_HELP: "Short unique identifier used to identify the group. At least 6 characters, can contain alphanumeric and any of the following: (@, _, -).",
    ADD_GROUP_MODAL_GROUP_TITLE_HELP: "The group Title is a field that's used to give the group a human-friendly name.",
    ADD_GROUP_MODAL_COMMENT_HELP: "This is a user-defined field that can be used to keep any kind of information.",
    GROUPS_SELECTED_SUFFIX: "groups selected",
    GROUPS_SELECT_BUTTON: "Groups",
    GROUPS_SELECT_ASSIGN_BUTTON: "Select Groups to Assign",
    GROUPS_SELECT_ALL: "Select All",
    GROUPS_SELECT_NONE: "Select None",
    MY_GROUPS_NUMBER_MESSAGE: "There {numberOfGroups, plural, =0{are no groups} one{is 1 group} other{are # groups}} registered.",
    APPS_SELECTED_SUFFIX: "Apps selected",
    APPS_SELECT_BUTTON: "Apps",
    APPS_SELECT_ASSIGN_BUTTON: "Select apps to Assign",
    APPS_SELECT_ALL: "Select All",
    APPS_SELECT_NONE: "Select None",

    // Network Activity
    NETWORK_ACTIVITY_TITLE: "Device Activity",
    
    // Group Activity
    GROUP_ACTIVITY_TITLE: "Group Activity",

    // Applications
    MY_APPLICATIONS_TITLE: "Applications",

    // Find Devices
    FIND_DEVICES_TITLE: "Find Devices",

    // Find Users
    FIND_USERS_TITLE: "Find Users",

    // Batch Registration
    BATCH_UPLOAD_INSTRUCTIONS: 'Drop CSV files here or click to upload',
    BATCH_UNSUPPORTED_MESSAGE: 'File Drag/Drop is not supported for this browser',
    BATCH_STATUS_404: 'Device not found',
    BATCH_STATUS_0: 'Register Successful',
    BATCH_STATUS_1: 'Already registered',
    BATCH_STATUS_2: 'Deleted',
    BATCH_STATUS_3: 'Not Found',
    BATCH_STATUS_4: 'Updated',
    BATCH_STATUS_5: 'Update failed',
    BATCH_STATUS_REGISTERED: 'Registered',
    BATCH_STATUS_ADDING: 'Adding',
    BATCH_STATUS_DELETING: 'Deleting',
    BATCH_STATUS_DELETED: 'Deleted',
    BATCH_STATUS_ERROR: 'Error',
    BATCH_STATUS_null: '',
    ERROR: "Error",
    BATCH_REGISTER_DEVICES_ERROR1: 'There is an empty field at column {column_num}',
    BATCH_REGISTER_DEVICES_ERROR2: 'Missing some of the mandatory fields DevEUI, device_profile_uuid and service_profile_uuid in the header',
    BATCH_REGISTER_DEVICES_SERVER_ERROR: 'Registering the device on row {index} failed because of - {message_error}',
    BATCH_UPDATE_DEVICES_SERVER_ERROR: 'Updating the device on row {index} failed because of - {message_error}',
    BATCH_ERROR_REGISTERING: 'Error registering devices',
    BATCH_ERROR_UPDATE: 'Error updating devices',
    BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS: 'Mandatory field {field_name} is missing.',

    // Data
    DATA_SEND_DATA: 'Send Data',
    DEVICE_PAYLOADS_MESSAGE: '{number, plural, =0{There are no messages} one{Showing 1 message} other{Showing # messages}} {scanning, plural, =0{} other{(searched #) }}for device: ',
    GROUP_MULTICASTS_MESSAGE: 'There {number, plural, =0{are no multicasts} one{is 1 multicast} other{are # multicasts}} for group: ',
    DATA_DELETE_PACKET: 'Delete Packet',
    DIRECTION_UP: 'Up',
    DIRECTION_DOWN: 'Down',
    DIRECTION_ANY: 'Any',
    DATA_DELETE_PACKETS: 'Delete Packets',

    DIRECTION: 'Direction',

    PACKET_STATUS_ANY: 'Any',
    PACKET_STATUS_0: 'Enqueued',
    PACKET_STATUS_1: 'Sent, waiting Ack',
    PACKET_STATUS_1_1: 'Sent',
    PACKET_STATUS_2: 'Ack Ok',
    PACKET_STATUS_3: 'Ack Fail',
    PACKET_STATUS_4: 'Error',
    PACKET_STATUS_5: 'Cancelled',
    PACKET_STATUS_SENT: 'Sent',

    TIME: 'Time',
    FCNT: 'FCNT',
    PORT: 'Port',
    DATA_RATE: 'Data Rate',
    DATA: 'Data',
    RSSI: 'RSSI',

    // Send Data Modal
    DATA_PAYLOAD_HELP: 'Enter payload data in hex format. Current size {size} bytes.',
    DATA_PAYLOAD_PORT_HELP: 'Select the port to use for the downlink. Valid range 1 to 255.',
    DATA_PAYLOAD_FCNT_HELP: 'For devices registered with the AppKey the FCNT should be kept blank.',
    DATA_PAYLOAD_MSG_TYPE: 'Message Type',
    DATA_PAYLOAD_MSG_TYPE_HELP: 'Select downlink message type. Default for downlink is Confirmed.',
    DATA_PAYLOAD_CONFIRMED: 'Confirmed',
    DATA_PAYLOAD_UNCONFIRMED: 'Unconfirmed',

    // Gateway Related
    GATEWAYS_TITLE: 'Gateways',
    GATEWAY_ID: 'Gateway ID',
    GATEWAY_NAME: 'Gateway Name',
    GATEWAY_NUMBER_MESSAGE: 'There {length, plural, =0{are no gateways} one{is 1 gateway} other{are # gateways}} registered on this user account.',
    VIEW_GATEWAY: 'View Gateway',
    DELETE_GATEWAY: 'Delete Gateway',
    DELETE_GATEWAYS: 'Delete Gateways',
    ADD_GATEWAY: 'Add Gateway',
    ADD_GATEWAY_MODAL_ADD_GATEWAY_HELP: 'Enter Gateway ID in hex format. The identifier is 8-bytes long (i.e. 16 hex characters)',

    GATEWAY_STATUS_ANY: 'Any',
    GATEWAY_STATUS_0: 'Status #0',
    GATEWAY_STATUS_1: 'Status #1',
    GATEWAY_STATUS_2: 'Status #2',
    GATEWAY_STATUS_3: 'Status #3',

    // Modal Add Gateway
    ADD_GATEWAY_MODAL_HELP_BLOCK: 'Enter Gateway ID in hex format. The identifier is 8-bytes long (i.e. 16 hex characters)',

    // Map
    MAP_FILTER_DEVICES: 'Filter devices',
    MAP_SHOW_LABEL: 'Show Label',
    MAP_SHOW_DEVICES: 'Show Devices',
    MAP_SHOW_GATEWAYS: 'Show Gateways',
    MAP_MOVABLE: 'Movable',

    // Position on map modal
    POSITION_ON_MAP_MODAL_TITLE: 'Position on Map',
    POSITION_ON_MAP_MODAL_LABEL: 'On Map',
    POSITION_ON_MAP_MODAL_SAVE_POSITION: 'Save',

    VIEW_GATEWAY_ON_MAP_MODAL_TITLE: 'Position on Map',
    VIEW_GATEWAY_ON_MAP_MODAL_LABEL: 'On Map',
    VIEW_GATEWAY_ON_MAP_MODAL_SAVE_POSITION: 'Save',

    // Customers
    CUSTOMERS_TITLE: 'Customers',
    ADD_CUSTOMER: 'Add Customer',
    EDIT_CUSTOMER: 'Edit Customer..',
    DELETE_CUSTOMER: 'Delete Customer',
    SET_AS_USER: 'Set as User',
    SET_PARENT_AS_USER: 'Set parent as User',
    SET_OWNER_AS_USER: 'Set owner as User',
    CUSTOMER_ID: 'Customer ID',
    ADMINISTRATOR: 'Administrator',
    ADMIN_DEVICES: 'All Devices',
    NAV_ADMIN_ITEM: 'Administrator',

    // Edit Device Modal
    EDIT_DEVICE_HELP_BLOCK: 'The DevEUI is a 8-byte unique identified based on IEEE EUI-64. Mandatory.',
    APP_EUI_HELP: 'The AppEUI sent from the device.',
    APP_EUI_HELP2: 'The AppEUI identify the associated application. Optional.',
    DEVICE_COMMENT_HELP_BLOCK: 'The device comment is for convenience only. Optional.',
    REGISTRATION_TYPE: 'Registration type',
    OTAA: 'OTAA',
    ABP: 'ABP',
    REGISTRATION_TYPE_HELP_BLOCK: 'Personalised devices have pre-generated session keys and will not perform the JOIN procedure.',
    DEV_ADDR: 'DevAddr',
    DEV_ADDR_HELP_BLOCK: 'The DevAddr (device address) is a 4 byte hex value.',
    MULTICAST_SETTINGS_TITLE: 'Multicast Settings',
    NWKS_KEY: 'NwkSKey',
    NWKS_KEY_HELP: 'The NwkSKey is a 16-byte encryption key used to encrypt the LoRaWAN protocol frames.',
    APP_KEY: 'App Key',
    APPS_KEY: 'AppSKey',
    APPS_KEY_HELP: 'The AppSKey is a 16-byte encryption key used to encrypt the data payloads. If provided all encryption is managed by the network.If not provided, the payload encryption must be managed by the application.Optional.',
    APP_KEY_HELP: 'The App Key is a 16-byte encryption key used to encrypt the data payloads. If provided all encryption is managed by the network.If not provided, the payload encryption must be managed by the application.Optional.',
    SNWKS_KEY: "SNwkSIntKey",
    SNWKS_KEY_HELP: "The SNwkSIntKey is a 16-byte encryption key used for authentication of Lora messages. Mandatory",
    FNWKS_KEY: "FNwkSIntKey",
    FNWKS_KEY_HELP: "The FNwkSIntKey is a 16-byte encryption key used for authentication of Lora messages. Mandatory",
    NWKSE_KEY: "NwkSEncKey",
    NWKSE_KEY_HELP: "The NwkSEncKey is a 16-byte encryption key used for authentication of Lora messages. Mandatory",
    NWK_KEY: "NwkKey",
    NWK_KEY_HELP: "The NwkKey is a 16-byte encryption key used for encryption and authentication of lora messages. Optional.",

    PING_SLOT: 'Ping-slot Periodicity',
    PING_SLOT_HELP: 'Set the ping-slot period for Class B multicast, or select Class C (default)',

    DEVICE_PROPERTIES: 'Device Properties',
    DEVICE_PROPERTIES_HELP: 'Select Device Properties or not specified.',
    DEVICE_PROP_MOBILITY_NOT_SPECIFIED: 'Not Specified',
    DEVICE_PROP_MOBILITY_STATIC: 'Static',
    DEVICE_PROP_MOBILITY_MOBILE: 'Mobile',

    DEVICE_PROP_LOCATION_NOT_SPECIFIED: 'Not Specified',
    DEVICE_PROP_LOCATION_OUTDOOR: 'Outdoor',
    DEVICE_PROP_LOCATION_INDOOR: 'Indoor',

    QOS_CLASS: 'QoS Class',
    QOS_CLASS_HELP: 'Select the QoS level for the device.',

    UPLINK_REDUNDANCY: 'Uplink redundancy',
    UPLINK_REDUNDANCY_AUTO: 'Auto',
    UPLINK_REDUNDANCY_HELP: 'Select number of transmissions device will do for each uplink. Setting the count to 1 means no redundancy.',

    ALLOWED_DUTY_CYCLE: 'Allowed duty cycle',
    ALLOWED_DUTY_CYCLE_HELP: 'Specify the maximum dutycycle in % the device is allowed. If it is exceeded the network try to enforce it. Optional.',

    EXPECTED_DUTY_CYCLE: 'Expected duty cycle',
    EXPECTED_DUTY_CYCLE_HELP: 'Specify the expected average duty cycle. Optional.',

    DOWNLINK_PACKET_EXPIRY_TIME: 'Downlink Packet Expiry Time',
    DOWNLINK_PACKET_EXPIRY_TIME_HELP: 'Time in hours that a downlink packet is stored in the persistent storage. After expiry the packet will be discarded regardless if the packet was sent or not, or if the application have seen the status of the packet.',

    UPLINK_PACKET_EXPIRY_TIME: 'Uplink Packet Expiry Time',
    UPLINK_PACKET_EXPIRY_TIME_HELP: 'Time in hours that a received packet will be stored in the persistent storage. After expiry the packet will be discarded regardless if the packet have been read or not.',

    DEVICE_CLASS: 'Device Class',
    DEVICE_CLASS_HELP: 'Supported device class.',

    LORAWAN_MAC_VERSION: 'LoRaWAN Mac Version',
    LORAWAN_MAC_VERSION_HELP: 'Select the version of the LoRaWAN MAC that the device is implementing.',

    DEVICE_ACTIVATED: 'Activated',
    DEVICE_ACTIVATED_HELP: 'Select Yes to allow the device to actively operate on the network. If a device is not activated, no data will be received from it and data cannot be sent to it.',

    DEVICE_DOWNLINK_ENABLED: 'Downlink Enabled',
    DEVICE_DOWNLINK_ENABLED_HELP: 'Select Yes to allow the network to send downlink on the network. If a not allowed, the network will not send any user payloads to the device',

    MAX_EIRP: 'MaxEIRP',
    MAX_EIRP_HELP: 'Maximum output power in dBm of the device. This is only applicable to 1.0.2 Rev B or later devices.',

    FCNT_SIZE: 'FCNT Size',
    FCNT_SIZE_HELP: 'Select size of FCNT.',

    DOWNLINK_SLOT_DELAY: 'Downlink slot delay',
    DOWNLINK_SLOT_DELAY_HELP: 'Select default delay from end of transmission till the device listen for downlink. This parameter is only important for personalized devices.',

    RX_SLOT_2: 'RX Slot 2',
    RX_SLOT_2_HELP: 'Data rate (spreading factor and Bandwidth) used by default in downlink for slot-2 and for class C.',

    LORA_LOCATION: 'Lora Location',
    LORA_LOCATION_HELP: 'Select enable to make have the location estimated for this device.',

    OWNER: 'Owner',
    DEVICE_USER: 'Change Owner',
    DEVICE_USER_HELP: 'Reassign the owner of the device.',

    OPTIONS_HELP: 'The Options is a 4 byte hex value. Normally left blank.',

    CLICK_TO_UNLOCK: 'Click to unlock',
    EDIT_DEVICE_PARAMETERS: 'Edit device parameters',

    TAB_KEYS: 'Keys',
    TAB_QOS: 'QoS',
    TAB_PACKET_STORAGE: 'Packet Storage',
    TAB_LORA_PARAMETERS: 'LoRa Parameters',
    TAB_LORA_LOCATION: 'LoRa Location',
    TAB_MISCELLANIOUS: 'Additional',
    TAB_TAGS: 'Tags',

    EDIT_DEVICE_BTN: 'Edit Device',
    UPDATE_DEVICE_BTN: 'Update Device',
    ADD_DEVICE_BTN: 'Add Device',
    ADD_NEW_DEVICE_BTN: 'Add New Device',
    EXPORT_DEVICE_BTN: 'Export',

    // HTTP Push config
    HTTP_PUSH_CONFIG_TITLE: 'Push Settings',
    HTTP_PUSH_HOSTNAME: 'URL',
    HTTP_PUSH_HOST_PLACEHOLDER: 'http(s)://your.public.applicationserver.fqdn',
    HTTP_PUSH_PORT: 'Port',
    HTTP_PUSH_PATH_PREFIX: 'Path prefix',
    HTTP_PUSH_PATH_AUTH_METHODS: 'Authentication Methods:',
    HTTP_PUSH_PATH_BASIC_AUTH: 'Basic',
    HTTP_PUSH_PATH_TOKEN_AUTH: 'Access Token',
    HTTP_PUSH_PATH_CUSTOM_AUTH: 'Authorization',
    HTTP_PUSH_PATH_CERTIFICATE_DESC: 'Content will be send directly in the HTTP Authorization request header',
    HTTP_PUSH_PATH_CERTIFICATE_TITLE: 'Certificate-Based Authentication (Optional)',
    HTTP_PUSH_CRT_FILE: 'Certificate:',
    HTTP_PUSH_KEY_FILE: 'Private key:',
    HTTP_PUSH_PASSPHRASE: 'Passphrase:',
    HTTP_PUSH_DATA_FORMAT: 'Data Format:',
    HTTP_PUSH_STORE_PAYLOAD: 'Store payload:',
    HTTP_PUSH_SUBSCRIPTIONS: 'Subscriptions:',
    HTTP_PUSH_DRAG_DROP_TEXT: 'Drag and drop files here',
    HTTP_PUSH_SHOOSE_FILE: 'Choose File',
    HTTP_PUSH_OR_TEX: 'or',
    HTTP_PUSH_HOST_USERNAME: 'Host Username:',
    HTTP_PUSH_HOST_USERNAME_PLACEHOLDER: 'Your application server username',
    HTTP_PUSH_HOST_PASSWORD: 'Host Password',
    HTTP_PUSH_HOST_PASSWORD_PLACEHOLDER: 'Your application server password',
    HTTP_PUSH_STATUS_RUNNING: 'The push process is currently running',
    HTTP_PUSH_STATUS_STOPPED: 'The push process is currently stopped',
    HTTP_PUSH_STATUS: 'Status',
    HTTP_PUSH_EDIT: 'Edit Application',
    HTTP_PUSH_DELETE: 'Delete Application',
    HTTP_PUSH_START: 'Start Push',
    HTTP_PUSH_STOP: 'Stop Push',
    HTTP_PUSH_SAVE: 'Save Application',
    HTTP_PUSH_CREATE: 'Add Application',
    PUSH_MODE_START_SUCCESS: 'Push mode started successfully for app {accountid}.',
    PUSH_MODE_START_FAIL: 'Failed to start push mode for app {accountid}.',
    PUSH_MODE_STOP_SUCCESS: 'Push mode stopped successfully for app {accountid}.',
    PUSH_MODE_STOP_FAIL: 'Failed to stop push mode for app {accountid}.',
    PUSH_HOST_USERNAME_HELP: "Enter host username with only letters, numbers, dash (' - '), underscore ('_'), dot ('.') and '@', The host username cannot be longer than 40 characters.",
    PUSH_HOST_PASSWORD_HELP: "Enter host password with only letters, numbers, dash (' - '), underscore ('_'), dot ('.') and '@'. The host password must be at least 8 characters long.",
    PUSH_HOST_HOSTNAME_HELP: "Enter valid URL starting with http:// or https:// and port number",
    PUSH_HOST_PORT_HELP: "Enter valid port number between 1 and 65535",

    BATCH_PUSH_MODE_START_SUCCESS: 'Push mode started successfully for {length} apps.',
    BATCH_PUSH_MODE_START_FAIL: 'Failed to start push mode for {length} apps.',
    BATCH_PUSH_MODE_STOP_SUCCESS: 'Push mode stopped successfully for {length} apps.',
    BATCH_PUSH_MODE_STOP_FAIL: 'Failed to stop push mode for {length} apps.',

    // Warnings
    WARNING_DEVICE_STATUS_LT2: 'Warning, this devices has already been successfully seen on the network. Changing keys will cause device states to be reset and communication to the device may be lost! Are you sure you wish to update the settings?',
    WARNING_DEVICE_STATUS_OTHER: 'Changing keys will cause device states to be reset. Are you sure you wish to update the settings?',
    WARNING_DIFF_DANGER: 'Warning, changing parameters of an active device may result in loss of downlink. Are you sure you wish to update?',
    WARNING_UPDATE_DEVICE_TITLE: 'Update device!',

    // Add user modal
    ADD_USER_ADD_NEW: 'Add New ',
    ADD_USER_ADD: 'Add New ',
    ADD_USER_EDIT: 'Edit ',
    ADD_USER_UPDATE: 'Update ',

    USER_TYPE_CUSTOMER: 'Customer',
    USER_TYPE_USER: 'User',
    USER_TYPE_ORGANIZATION: 'Organization',

    // Messages
    MSG_TITLE_OK: 'Ok',
    MSG_TITLE_OOPS: 'Oops',
    MSG_LOGIN_FAILED_BODY: 'Login Failed',
    MSG_LOGIN_FORWARD_FAILED: 'Login Forward Failed',
    MSG_DEVICE_ADDED_SUCCESS_BODY: 'New device {deveui} successfully added',
    MSG_DEVICE_ADDED_FAIL_BODY: 'Failed creating a new device.',
    MSG_DEVICE_UPDATE_SUCCESS_BODY: 'Device {deveui} successfully updated',
    MSG_DEVICE_UPDATE_FAIL_BODY: 'Failed to update device. "{data}"',
    MSG_DEVICE_DEVICE: "device",
    MSG_DEVICE_DEVICES: "devices",
    MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY: '{device} successfully updated',
    MSG_DEVICE_UPDATE_BULK_FAIL_BODY: 'Failed to update {device}.',
    MSG_NODE_UPDATE_FAIL: 'Failed to update node information',
    MSG_GATEWAY_POS_UPDATE_SUCCESS: 'Gateway successfully updated',
    MSG_GATEWAY_POS_UPDATE_FAIL: 'Failed to update position',
    MSG_GATEWAY_ID_NOT_FOUND: 'Gateway with id {gateway_id} does not exist.',

    MSG_APPLICATION_ADDED_SUCCESS_BODY: 'New app {accountid} successfully added',
    MSG_APPLICATION_ADDED_FAIL_BODY: 'Failed to create new app. {data}',
    MSG_APPLICATION_ADDED_FAIL_EXISTS: 'App {accountid} already exists',

    MSG_APPLICATION_EDIT_SUCCESS_BODY: 'App {accountid} successfully edited.',
    MSG_APPLICATION_EDIT_FAIL_BODY: 'Failed to edit app. {data}',

    MSG_GROUP_ADDED_SUCCESS_BODY: 'New group {groupid} successfully added',
    MSG_GROUP_ADDED_FAIL_BODY: 'Failed to create new group. "{data}"',

    MSG_GROUPS_DELETED_SUCCESS_BODY: 'Successfully deleted {count} groups.',
    MSG_GROUPS_DELETED_FAIL_BODY: 'Failed to delete groups.',

    MSG_DELETE_GROUPS_TITLE: 'Delete Groups',
    MSG_DELETE_GROUPS_BODY: 'Are you sure you want to delete {count, plural, =0{} one{1 group} other{# groups}} ?',

    MSG_DELETE_APPS_TITLE: 'Delete Apps',
    MSG_DELETE_APPS_BODY: 'Are you sure you want to delete {count, plural, =0{} one{1 app} other{# apps}} ?',
    MSG_DELETE_APPS_SUCCESS_BODY: '{length, plural, =0{} one{1 App} other{# Apps}} successfully deleted.',
    MSG_DELETE_APPS_FAIL_BODY: 'Failed to delete {length, plural, =0{} one{1 app} other{# apps}}.',

    MSG_DELETE_DEVICES_TITLE: 'Delete devices',
    MSG_DELETE_DEVICES_BODY: 'Are you sure you want to delete {count, plural, =0{} one{1 device} other{# devices}} ?',
    MSG_DELETE_DEVICES_SUCCESS_BODY: '{length, plural, =0{} one{1 device} other{# devices}} successfully deleted.',
    MSG_DELETE_DEVICES_FAIL_BODY: 'Failed to delete {length, plural, =0{} one{1 device} other{# devices}}.',

    MSG_DELETE_DEVICE_TITLE: 'Delete Device',
    MSG_DELETE_DEVICE_BODY: 'Are you sure you want to delete device {deveui} ?',
    MSG_DELETE_DEVICE_SUCCESS_BODY: 'Device {deveui} successfully deleted.',
    MSG_DELETE_DEVICE_FAIL_BODY: 'Failed to delete device.',

    MSG_SUSPEND_DEVICE_TITLE: 'Suspend Device',
    MSG_SUSPEND_DEVICE_BODY: 'Are you sure you want to suspend device {deveui} ?',
    MSG_SUSPEND_DEVICE_SUCCESS_BODY: 'Device {deveui} successfully suspended.',
    MSG_SUSPEND_DEVICE_FAIL_BODY: 'Failed to suspend device.',
    MSG_UNSUSPEND_DEVICE_TITLE: 'Unsuspend Device',
    MSG_UNSUSPEND_DEVICE_BODY: 'Are you sure you want to unsuspend device {deveui} ?',
    MSG_UNSUSPEND_DEVICE_SUCCESS_BODY: 'Device {deveui} successfully unsuspended.',
    MSG_UNSUSPEND_DEVICE_FAIL_BODY: 'Failed to unsuspend device.',


    MSG_ASSIGN_DEVICES_SUCCESS_BODY: 'Successfully assigned device to groups.',
    MSG_ASSIGN_DEVICES_FAIL_BODY: 'Failed to assign devices to group.',

    MSG_REMOVE_DEVICES_SUCCESS_BODY: 'Successfully removed devices from groups.',
    MSG_REMOVE_DEVICES_FAIL_BODY: 'Failed to remove devices from group.',

    MSG_ASSIGN_DEVICES_APPS_SUCCESS_BODY: 'Successfully assigned device to apps.',
    MSG_ASSIGN_DEVICES_APPS_FAIL_BODY: 'Failed to assign devices to apps.',

    MSG_UNASSIGN_DEVICES_APPS_SUCCESS_BODY: 'Successfully removed device from apps.',
    MSG_UNASSIGN_DEVICES_APPS_FAIL_BODY: 'Failed to remove device from apps.',

    MSG_REMOVE_GROUPS_NONE_SELECTED: 'Please select some groups from the list in order to remove them.',
    MSG_ADD_GROUPS_NONE_SELECTED: 'Please select some groups from the list in order to add them.',
    MSG_REMOVE_GROUP_SUCCESS_BODY: 'Successfully removed device from group.',
    MSG_REMOVE_GROUP_FAIL_BODY: 'Failed to remove device from group.',
    MSG_REMOVE_GROUPS_SUCCESS_BODY: 'Successfully removed device from groups.',
    MSG_REMOVE_GROUPS_FAIL_BODY: 'Failed to remove device from groups.',
    MSG_DELETE_GROUPS_SUCCESS: 'Successfully deleted {length, plural, =0{} one{one group} other{# groups}}.',
    MSG_DELETE_GROUPS_FAIL: 'Failed to delete {length, plural, =0{} one{one group} other{# groups}}.',
    MSG_CREATE_GROUP_SUCCESS: 'Successfully created group "{groupid}".',
    MSG_CREATE_GROUP_FAIL: 'Failed to create group "{groupid}".',
    MSG_MULTICAST_SUCCESS: 'Sent multicast to group "{groupid}".',
    MSG_MULTICAST_FAIL: 'Failed to send multicast to group "{groupid}".',
    MSG_UPDATE_GROUP_SUCCESS: 'Successfully updated group "{groupid}".',
    MSG_UPDATE_GROUP_FAIL: 'Failed to update group "{groupid}".',
    MSG_DELETE_GROUP_SUCCESS: 'Successfully deleted group "{groupid}".',
    MSG_DELETE_GROUP_FAIL: 'Failed to delete group "{groupid}".',

    MSG_NO_LOCATION_TITLE: 'No Location',
    MSG_NO_LOCATION_BODY: 'Device "{deveui}" does not have a location. Do you want to add a pin on the map?',

    MSG_GATEWAY_ADDED_SUCCESS_BODY: 'Gateway {id} added',
    MSG_GATEWAY_ADDED_FAIL_BODY: 'Gateway adding failed "{data}".',

    MSG_DELETE_GATEWAY_TITLE: 'Delete Gateway',
    MSG_DELETE_GATEWAY_BODY: 'Are you sure you want to delete gateway {id} ?',
    MSG_DELETE_GATEWAY_SUCCESS_BODY: 'Gateway deleted successfully',
    MSG_DELETE_GATEWAY_FAIL_BODY: 'Failed to delete gateway',

    MSG_DELETE_GATEWAYS_TITLE: 'Delete Gateways',
    MSG_DELETE_GATEWAYS_BODY: 'Are you sure you want to delete {count, plural, =0{} one{1 gateway} other{# gateways}} ?',
    MSG_DELETE_GATEWAYS_SUCCESS_BODY: 'Gateways deleted successfully',
    MSG_DELETE_GATEWAYS_FAIL_BODY: 'Failed to delete gateways',
    E_GTW_INFO: 'Info',
    E_GTW_INFO_MSG_1: 'To install OrbiWAN gateway daemon on a new gateway, please do the following steps',
    E_GTW_INFO_MSG_2: '1) Enter a name / description for your gateway (optional)',
    E_GTW_INFO_MSG_3: '2) Select the gateway model from the list below',
    E_GTW_INFO_MSG_4: '3)  Click on the “click-here to copy to clipboard” to copy the installation commands',
    E_GTW_INFO_MSG_5: '4) Connect the gateway and  (SSH) login to the gateway (for further information how to login on the gateway, consult the documentation form the gateway manufacturer)',
    E_GTW_INFO_MSG_6: '5)  Paste and execute the installation command in the SSH shell on the gateways',
    E_GTW_INFO_MSG_7: 'More information and instructions can be found ',
    E_GTW_INFO_MSG_8: 'here..',
    E_GTW_INSTALLATION: 'Installation',
    E_GTW_COPY_TO_CLIPBOARD: 'Click to copy to clipboard',
    E_GTW_MODELS: 'Gateway Models',
    E_GTW_NO_MODELS: "No gateway models",

    MSG_DELETE_APP_TITLE: 'Delete app',
    MSG_DELETE_APP_BODY: 'Are you sure you want to delete app "{accountid}" ?',
    MSG_DELETE_APP_SUCCESS_BODY: 'App deleted successfully',
    MSG_DELETE_APP_FAIL_BODY: 'Failed to delete app',

    MSG_DELETE_PACKET_TITLE: 'Delete Packet',
    MSG_DELETE_PACKET_BODY: 'Are you sure you want to delete packet for device "{deveui}" ?',
    MSG_DELETE_PACKET_BODY_GROUP: 'Are you sure you want to delete packet for group "{groupid}" ?',
    MSG_DELETE_PACKET_SUCCESS_BODY: 'Packet deleted',
    MSG_DELETE_PACKET_FAIL_BODY: 'Failed to delete packet',

    MSG_DELETE_PACKETS_TITLE: 'Delete Packets',
    MSG_DELETE_PACKETS_BODY: 'Are you sure you want to delete {count, plural, =0{} one{1 packet} other{# packets}}  for device "{deveui}" ?',
    MSG_DELETE_PACKETS_BODY_GROUP: 'Are you sure you want to delete {count, plural, =0{} one{1 packet} other{# packets}}  for group "{groupid}" ?',
    MSG_DELETE_PACKETS_SUCCESS_BODY: 'Deleted {count, plural, =0{} one{1 packet} other{# packets}}  for device "{deveui}"',
    MSG_DELETE_PACKETS_FAIL_BODY: 'Failed to delete {count, plural, =0{} one{1 packet} other{# packets}}  for device "{deveui}"',
    MSG_DELETE_PACKETS_GROUP_SUCCESS_BODY: 'Deleted {count, plural, =0{} one{1 packet} other{# packets}} for group "{groupid}"',
    MSG_DELETE_PACKETS_GROUP_FAIL_BODY: 'Failed to delete {count, plural, =0{} one{1 packet} other{# packets}} for group "{group}"',

    MSG_SEND_DL_PACKET_SUCCESS_BODY: 'Data enqueued to be send to "{deveui}". Transaction ID: "{transaction_id}',
    MSG_SEND_DL_PACKET_FAIL_BODY: 'Failed to enqueue data to be send',
    MSG_SEND_DL_PACKET_FULL_DOWNLINK_QUEUE_BODY: 'Downlink queue full, {payloads} payloads already enqueued',
    MSG_SEND_DL_PACKET_FAIL_MUST_SPECIFY_FCNT: "Must specify fcnt when appskey is not present",
    MSG_SEND_DL_PACKET_FAIL_DEVICE_IS_NOT_ACTIVATED: "Device {deveui} is not activated",

    MSG_SELECT_DEVICE_BODY: 'Please select a device first.',

    MSG_USER_DELETE_CONFIRM_TITLE: 'Delete {type}',
    MSG_USER_DELETE_CONFIRM: 'Are you sure you want to delete {type} "{userid}" ?',
    MSG_USER_DELETE_SUCCESS_BODY: 'Successfully deleted {type} "{userid}" ?',
    MSG_USER_DELETE_FAIL_BODY: 'Failed to delete {type}.',

    MSG_USERS_DELETE_CONFIRM_TITLE: 'Delete {type}!',
    MSG_USERS_DELETE_CONFIRM: 'Are you sure you want to delete {count, plural, =0{} one{1 {type}} other{# {type}s}} ?',
    MSG_USERS_DELETE_SUCCESS_BODY: 'Successfully deleted {count, plural, =0{} one{1 {type}} other{# {type}s}}.',
    MSG_USERS_DELETE_FAIL_BODY: 'Failed to delete {types}s.',

    MSG_USER_CREATE_SUCCESS_BODY: 'New {ut} successfully added',
    MSG_USER_CREATE_FAIL_BODY: '{ut} already exists',

    MSG_USER_UPDATE_SUCCESS_BODY: 'User successfully updated',
    MSG_USER_UPDATE_FAIL_BODY: 'Updating user failed',
    MSG_ERROR_LOADING_APPS: 'Error loading apps',
    MSG_ERROR_LOADING_TENANTS: 'Error loading tenants',
    MSG_ERROR_LOADING_ORGANIZATIONS: 'Error loading organizations',
    MSG_ERROR_LOADING_DATA: 'Error loading data',
    MSG_USER_UPDATE_INCORRECT_TENANT_ID: 'Incorrect tenant_id',

    // Add User modal
    ADD_USER_PRESET_LABEL: 'Preset',
    ADD_USER_PRESET_HELP: 'Choose one from a list of presets to create this user as.',

    // Edit User Modal
    EDIT_USER_TITLE_ACCOUNT_ID: 'Account ID',
    EDIT_USER_ACCOUNT_ID_HELP: "Enter account ID in with only letters, numbers, dash (' - '), underscore ('_'), dot ('.') and '@'. The ID must be at least 6 characters long.",
    EDIT_USER_PASSWORD_HELP: "A password is case sensitive and must be at least 8 characters long.",
    EDIT_USER_PASSWORD_CONFIRM: 'Password Confirmation',
    EDIT_USER_PASSWORD_CONFIRM2: 'Re-enter the password to confirm.',
    MANAGE_APP_PASSWORD_CONFIRM2: 'Re-enter the password to confirm. (Only required if password isn\'t empty)',

    USER_COMPANY_NAME: 'Company Name',
    USER_COMPANY_ADDRESS: 'Company Address',
    USER_EMAIL: 'Email',
    USER_FULL_NAME: 'Full Name',

    USER_SIGN_UP_SUCCESS_TITLE: "Email Confirmation Sent",
    NOTIFICATION: "Notification",

    ERROR_SENDING_EMAIL: "Error sending email",
    INVALID_VERIFICATION_TOKEN: "Invalid verification token.",
    INVALID_ACTIVATION_TOKEN: "Invalid activation token.",
    VERIFICATION_SUCCESS_MSG: "Account successfully verified. Sign up information sent to admin for review.",
    ACTIVATION_SUCCESS_MSG: "Account successfully activated. Notification email sent to client.",
    DECLINE_SUCCESS_MSG: "Account successfully declined. Notification email sent to client.",
    ACTIVATION_FAIL_EMAIL_UNVERIFIED: "The account you're trying to activate hasn't had its email verified.",

    EDIT_USER_ADMIN_RIGHTS: 'Admin Rights',
    EDIT_USER_GATEWAYS: 'Gateways',
    EDIT_USER_LORA_LOCATION: 'Lora Location',
    EDIT_USER_SYSTEM_SETTINGS: 'System Settings',
    EDIT_USER_INFO: 'User Info',
    EDIT_USER_TENANCY: 'Tenancy',
    EDIT_USER_PASSWORD_POLLICY: "Password policy",
    EDIT_USER_SETTINGS: "Settings",

    TENANT_MSG: "User belongs to tenant '{name}'",
    TENANT_DELETED_MSG: "User is assigned to unknown tenant with ID '{deletedTenantId}' Please reassign this customer to another tenant or the landlord",
    NO_TENANT_ASSIGNED: "User not assigned to a tenant",
    CHANGE_TENANT: 'Change Tenant',
    SELECT_A_TENNANT: 'Select a Tenant',
    TENANT_ID: "Tenant ID",
    TENANT_NAME: "Tenant Name",

    GATEWAY: 'Gateway',

    EDIT_CUSTOMER_TENNANCY_LABEL: 'Tenant',
    EDIT_CUSTOMER_TENNANCY_HELP: 'Specifies which tenant this customer belongs to.',

    TENANT_SELECTED_SUFFIX: "tenant selected",
    TENANT_SELECT_BUTTON: "Tenants",
    TENANT_SELECT_ASSIGN_BUTTON: "Select Tenant",
    TENANT_SELECT_ALL: "Select All",
    TENANT_SELECT_NONE: "Select None",

    EDIT_ORGANIZATION_ADMIN_RIGHTS_LABEL: 'Organization Administrator Rights',
    EDIT_ORGANIZATION_ADMIN_RIGHTS_HELP: 'Accounts with organization administrator rights can add and remove organizations.',

    EDIT_CUSTOMER_ADMIN_RIGHTS_LABEL: 'Customer Administrator Rights',
    EDIT_CUSTOMER_ADMIN_RIGHTS_HELP: 'Accounts with customer administrator rights can add and remove customers.',

    EDIT_USER_ADMIN_RIGHTS_LABEL: 'User Administrator Rights',
    EDIT_USER_ADMIN_RIGHTS_HELP: 'Accounts with user administrator rights can add and remove users.',

    EDIT_USER_GATEWAY_ADMIN_RIGHTS_LABEL: 'Gateway Administrator Rights',
    EDIT_USER_GATEWAY_ADMIN_RIGHTS_HELP: 'Accounts that have gateway administrator can add and manage gateways for other users.',

    EDIT_USER_DEVICE_ADMIN_RIGHTS_LABEL: 'Device Registration Rights',
    EDIT_USER_DEVICE_ADMIN_RIGHTS_HELP: 'Users can register device to its account.',

    EDIT_USER_NETWORK_ACCESS_LABEL: 'Can Access Network Gateway Information',
    EDIT_USER_NETWORK_ACCESS_HELP: 'Users can read information about which gateways received messages from users devices and can access position information about the network gateways.',

    EDIT_USER_GATEWAY_OWNERSHIP_LABEL: 'Can Own Gateways',
    EDIT_USER_GATEWAY_OWNERSHIP_HELP: 'When set, user can have gateways on its account and can see status of the gateways.',

    EDIT_USER_GATEWAY_ADD_LABEL: 'Can Add Gateways',
    EDIT_USER_GATEWAY_ADD_HELP: 'Users with this right users can add gateways to their account directly.',

    EDIT_USER_GATEWAY_MANAGE_LABEL: 'Can Manage Gateways',
    EDIT_USER_GATEWAY_MANAGE_HELP: 'Users with gateway management rights can update settings such as name, location, etc. on the gateways.',

    EDIT_USER_LORA_LOCATION_LABEL: 'Lora Location Service',
    EDIT_USER_LORA_LOCATION_HELP: 'Users with Lora Location Service enabled may assigned devices to be localised via the Lora Localisation system.',

    EDIT_USER_SYSTEM_ADMIN_LABEL: 'System Administrator Rights',
    EDIT_USER_SYSTEM_ADMIN_HELP: 'Account has system administration rights and can modify system settings, such as logging parameters.',

    EDIT_USER_DATA_ARCHIVE_ADMIN_LABEL: "Data Archive Administrator Rights",
    EDIT_USER_DATA_ARCHIVE_ADMIN_HELP: "Account can control data archiving by enabling and disabling archiving per account",

    EDIT_USER_AUTO_ARCHIVE_DATA_LABEL: "Auto Archive Data",
    EDIT_USER_AUTO_ARCHIVE_DATA_HELP: "When enabled, uplink data will be automatically archived in archive files for long-term storage.",

    EDIT_USER_CAN_ACCESS_LOGS_LABEL: "Can Access User Logs",
    EDIT_USER_CAN_ACCESS_LOGS_HELP: "Users with this right can access the user logs",

    EDIT_USER_EXTENDED_LOGGING_LABEL: 'Enable extended logging',
    EDIT_USER_EXTENDED_LOGGING_HELP: 'Extended logging enable better analysis and debugging.',

    EDIT_USER_INFO_LABEL: 'User Information',
    EDIT_USER_INFO_HELP: 'Free-text information about user.',

    EDIT_USER_TAGS: 'Tags',
    EDIT_USER_TAGS_HELP: 'Free-text information about user.',
    EDIT_USER_TAGS_NAME: 'Tag ID',
    EDIT_USER_TAGS_DESCRIPTION: 'Tag Value',
    TAG_NAME_AVAILABLE_CHARS: 'You can enter only letter, number and -_.',
    TAG_NAME_UNIQUE: 'Tag ID should be unique',

    // Manage App Modal
    MANAGE_APP_ACCOUNT_ID: 'Account ID',
    MANAGE_APP_ACCOUNT_ID_HELP: "Enter account ID with only letters, numbers, dash (' - '), underscore ('_'), dot ('.') and '@'. The ID must be at least 6 characters long.",

    // Users list
    USER_LIST_ADD_USER: 'Add User',
    USER_LIST_TITLE: 'Users',
    USER_LIST_REGISTRATION_RIGHTS: 'Registration Rights',

    // Organization list
    ORGANIZATION_LIST_ADD_ORGANIZATION: 'Add Organization',
    ORGANIZATION_LIST_TITLE: 'Organizations',
    DELETE_ORGANIZATION: 'Delete Organization',
    DELETE_ORGANIZATIONS: 'Delete Organizations',
    EDIT_ORGANIZATION: 'Edit Organization',

    ADD_USER: 'Add User',
    EDIT_USER: 'Edit User',
    DELETE_USER: 'Delete User',
    SHOW_SELECTED_USERS: 'Show Selected Users',
    SHOW_ALL_USERS: 'Show all users',
    DELETE_USERS: 'Delete Users',
    SUSPEND_USER: 'Suspend User',
    DISABLE_USER: 'Disable User',
    REACTIVATE_USER: 'Reactivate User',
    SUSPEND_USER_SUCCESS: 'User successfully suspended',
    DISABLE_USER_SUCCESS: 'User successfully disabled',
    ACTIVATE_USER_SUCCESS: 'User successfully activated',
    USER_STATUS_ERROR: 'There was an error during user status change',

    SUSPEND_ORGANIZATION: 'Suspend Organization',
    DISABLE_ORGANIZATION: 'Disable Organization',
    REACTIVATE_ORGANIZATION: 'Reactivate Organization',
    SUSPEND_ORGANIZATION_SUCCESS: 'Organization successfully suspended',
    DISABLE_ORGANIZATION_SUCCESS: 'Organization successfully disabled',
    ACTIVATE_ORGANIZATION_SUCCESS: 'Organization successfully activated',
    ORGANIZATION_STATUS_ERROR: 'There was an error during organization status change',

    // Generic
    APP_EUI: 'AppEUI',
    DEVEUI: 'DevEUI',
    COMMENT: 'Comment',
    STATUS: 'Status',
    LAST_SEEN: 'Last Seen',
    ACTION: 'Action',
    BULK_ACTION: 'Bulk  Action',
    REFRESH_LIST: 'Refresh List',
    SELECT_DEVICE: 'Select Device',
    SELECT_GROUP: 'Select Group',
    SELECT_DEVICE_PROMPT: 'Please select a device',
    SELECT_GROUP_PROMPT: 'Please select a group',
    SECOND_UNIT: 's',


    START: 'Start',
    LOADING: 'Loading',
    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    USER_ID: 'User ID',
    PASSWORD: 'Password',

    TAG_NAME: 'Tag ID',
    TAG_DESC: 'Tag Value',

    PAYLOAD: 'Payload',
    PAYLOADS: 'Payloads',
    DOWNLOAD: 'Download',
    PAYLOADS_EXPORT: 'Export Payloads',
    COORDINATES: 'Coordinates',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    ALTITUDE: 'Altitude',

    UPLINK: 'Uplink',
    DOWNLINK: 'Downlink',

    VIA: 'via {originalUser}',
    POWERED_BY: 'Powered by',
    WELCOME_RIGHT_MESSAGE: 'PARTNER FOR A SMARTER FUTURE',

    REFRESH: 'Refresh',
    REGISTER: 'Register',
    DELETE: 'Delete',
    UPDATE: 'Update',
    CLEAR: 'Clear',
    ENABLE: 'Enable',
    DISABLE: 'Disable',

    START_GLOBAL_PUSH_SUCCESS: "Default push started successfully",
    STOP_GLOBAL_PUSH_SUCCESS: "Default push stopped successfully",

    START_GLOBAL_PUSH_FAIL: "Default push start failed",
    STOP_GLOBAL_PUSH_FAIL: "Default push stop failed",

    GTW_STATUS_NEVER_SEEN: 'Never Seen',
    GTW_STATUS_GTW_INIT: "Registered, never seen",
    GTW_STATUS_OK: 'OK',
    GTW_STATUS_OFF: 'Off',
    GTW_STATUS_BACKHAUL_ISSUE: 'Connection Problem',
    GTW_STATUS_OFF_OR_BACKHAUL_ISSUE: 'Off or Connection Problem',
    GTW_STATUS_RADIO_OFF: 'LoRa Radio Off',

    USER_PARENT: 'Parent',

    ADD: 'Add',
    REMOVE: 'Remove',
    OPTIONS: 'Options',
    OK: 'OK',
    TRUE: "True",
    FALSE: "False",
    SAVE: 'Save',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    DEFAULT: "Default",
    FIRST: 'First',
    LAST: 'Last',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    FROM: 'From',
    TO: 'To',
    DECRYPTED: 'Decrypted',
    ENCRYPTED: 'Encrypted',
    WARNING: 'Warning',
    RSSI_WARNING: 'RSSI "From" filter is higher than the "To" filter.',

    ADD_GATEWAY_MODAL_NOTE: 'Note: You can use this dialog to create a new gateway.',
    USER_COUNTRY: 'Country',
    USER_CITY: 'City',
    USER_STATE: 'State',
    USER_ZIP_CODE: 'Zip Code',
    USER_COMPANY_INFO: 'Additional company Info',
    COMPANY_SIZE_HELP: 'Company size',
    USER_PASSWORD_SET_SUCCESS_MSG: "Your password has been set successfully. Welcome!",
    USER_SIGN_UP_SUCCESS_MSG: "Please check your email, and click the link to verify your email.",
    USER_FIRST_NAME: 'First Name',
    USER_LAST_NAME: 'Last Name',
    USER_PHONE: 'Phone',
    USER_MOBILE: 'Mobile',
    USER_INDUSTRY: 'Industry',
    INITIAL_PASSWORD_TITLE: "Set initial password",
    INITIAL_PASSWORD_MESSAGE: "Please use the fields below to set your first password",
    PER_PAGE: "Per page",
    SHOWING_PER_PAGE: "Showing per page",
    NAV_ADMIN_DEVICES: "Find Devices",
    NAV_ADMIN_USERS: "Find Users",
    ADMIN_DEVICES_TITLE: "Find Devices",

    // forgot password functionality
    HOME_PAGE: "GO TO HOME PAGE",
    HOME_PAGE_ORBIWISE: "HOME PAGE",
    SEND: "SEND",
    FORGOT_PASSWORD: "Forgot your password?",
    FORGOT_USERNAME: "Forgot your username?",
    FORGOT_PASSWORD_INSTRUCTIONS: "Please enter your username below and we'll send you instructions to your email how to change your password",
    FORGOT_USERNAME_INSTRUCTIONS: "Enter your email address below and we'll send you your username",
    USERNAME: "Username",
    RETURN_TO_LOGIN: "Return to Log in",
    RESET: "Reset",
    FORGOT_USERNAME_EMAIL: "Forgot Username",
    EMAIL_USERNAME_INSTRUCTIONS: "An e-mail with your username has been sent to you",
    PASSWORD_RESET_INSTRUCTIONS: "Password reset instructions have been sent",
    RESET_PASSWORD: "Reset your password",
    PASSWORD_CHANGED: "Password Changed!",
    PASSWORD_CHANGED_SUCCESSFULLY: "Your password has been successfully changed",
    ENTER_PASSWORD: "Enter new password for your account",
    FORGOT_USERNAME_INSTRUCTIONS_ORBIWISE: "Enter your email address, and we'll send you an email with your usernames",
    EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE: "An e-mail has been sent to you",
    FORGOT_PASSWORD_INSTRUCTIONS_ORBIWISE: "Please enter your username below and we'll send you instructions to your email how to change your password",
    INSTRUCTIONS_ORBIWISE: "Instructions have been send",
    PASSWORD_CHANGED_SUCCESSFULLY_ORBIWISE: "The password has been successfully changed",
    SHOW: "Show",
    INVALID_EMAIL: "Invalid email",
    INVALID_USERNAME: "Invalid username",
    INVALID_TOKEN: "The password token has expired, please make a new request for resetting your password",

    //password policies
    USERS: "Users",
    ADMINISTRATORS: "Administrators",
    PASSWORD_HISTORY: "Enforce Password History",
    PASSWORD_HISTORY_DESC: "New password cannot be the same as the o...",
    PASSWORD_HISTORY_DESC_HOVER: "New password cannot be the same as the old password",
    COMPLEX_PASSWORD: "Enable Complex Password",
    COMPLEX_PASSWORD_DESC: "Requires that passwords contain characte...",
    COMPLEX_PASSWORD_DESC_HOVER: "Requires that passwords contain characters from at least three (3) of the following four (4) classes\n1. English Upper Case Letters	A, B, C, ... Z\n2. English Lower Case Letters	a, b, c, ... z\n3. Westernized Arabic Numerals	0, 1, 2, ... 9\n4. Non-alphanumeric (special characters), !,$,#,%).",
    PASSWORD_LENGTH_DESC: "Enable minimum password length of at lea...",
    PASSWORD_LENGTH_DESC_HOVER: "Enable minimum password length of at least 8 or more",
    PASSWORD_AGE: "Maximum Password Age",
    PASSWORD_AGE_DESC: "Force users to change the password after ...",
    PASSWORD_AGE_DESC_HOVER: "Force users to change the password after the period of time (in days)",

    MAX_DEVICES: "Maximum Number of devices",
    MAX_DEVICES_HELP: "Maximum number of allowed devices to be registered for this user",
    MAX_GATEWAYS: "Maximum Number of gateways",
    MAX_GATEWAYS_HELP: "Maximum number of gateways allowed to be registered for this user",
    SETTINGS: "Settings",
    MAX_NUM_REACHED: "Maximum number of assigned {item} is reached",

    PASSWORD_LENGTH: "Minimum Password Length",
    UPPER_CASE: "Includes upper case letters",
    LOWER_CASE: "Includes lower case letters",
    SPECIAL_CHARACTERS: "Includes special characters",
    NEW_PASSWORD_LENGTH: 'At least {length_value} characters long',
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
    PASSWORD_MATCH: "Passwords match",
    REPEAT_PASSWORD: "Repeat Password",
    NEW_PASSWORD: "New Password",
    CHANGE_PASSWORD: "Change Password",
    NEW_PASSWORD_INFO: "The password must meet all of the listed requirements",
    CREATE_NEW_PASSWORD: "Create a new password",
    PASSWORD_DIFFERENT_FROM_OLD: "Cannot reuse password",
    NUMBER: "Includes numbers",
    PASSWORD_FAILED: "Password is not compliant with the set password policy: one of the possible reasons is that the new password is like the old",

    BATCH_UPLOAD_INSTRUCTIONS_CHOSE: "Choose CSV files to upload",
    BATCH_UPLOAD_INSTRUCTIONS_DROP: "or drag and drop theme here",

    ERROR_SIGNUP_STEP1: "Server Problem",

    MSG_DELETE_GROUPS_BODY_SINGLE: 'Are you sure you want to delete group { groupid } ?',

    UPDATE_GROUP_BTN: "Update",
    DATA_SEND_DATA_BTN: "Send",
    USER_PASSWORD_ALREADY_SET: "Password already set",
    MAP_DEVICE_POSITION: "This device has no position set.",
    MAP_GATEWAY_POSITION: "This gateway has no position set.",
    BATCH_REGISTER_MAX_DEVICES: "The number of devices is bigger that the allowed number of devices for the user",
    ACTION_IN_PROGRESS: "Action still in progress",
    NAV_PROFILES_ITEM: "Profiles",
    NAV_DEVICE_PROFILES: "Device Profiles",
    NAV_SERVICE_PROFILES: "Service Profiles",
    NAV_CONNECTIVITY_PROFILES: "Connectivity Profiles",
    NAV_ROAMING_PROFILES: "Roaming Profiles",

    DEVICE_PROFILES_TITLE: "Device Profiles",
    ADD_DEVICE_PROFILE: "Add Device Profile",
    DEVICE_PROFILE_NAME: "Device Profile",

    SERVICE_PROFILES_TITLE: "Service Profiles",
    ADD_SERVICE_PROFILE: "Add Service Profile",

    CONNECTIVITY_PROFILES_TITLE: "Connectivity Profiles",
    ADD_CONNECTIVITY_PROFILE: "Add Connectivity Profile",
    LOGIN_FAILED: "Login Failed",

    ROAMING_PROFILES_TITLE: "Roaming Profiles",
    ADD_ROAMING_PROFILE: "Add Roaming Profile",

    CAN_NOT_GET_DATA: "Can not get data",
    ACTION_EDIT: "Update Profile",
    ACTION_ADD: "Add Profile",
    PROFILE_NOT_UPDATED: "Profile is not updated",
    PROFILE_UPDATED_SUCCESS: "Profile successfully updated",
    PROFILE_CREATED_SUCCESS: "Profile successfully created",
    PROFILE_NOT_CREATED: "Profile is not created",
    CLOSE_BUTTON_TEXT: "Cancel",

    CONNECTIVITY_PROFILE_NAME: "Connectivity Profile",
    SERVICE_PROFILE_NAME: "Service Profile",
    ROAMING_PROFILE_NAME: "Roaming Profile",

    PROFILE_DELETED_SUCCESS: "Profile successfully deleted",
    ACTION_DELETE: "Delete",
    TABLE_PROFILE_NAME: "Profile Name",
    TABLE_COMMENT: "Description",
    TABLE_UUID: "UUID",
    TABLE_LINK: "Link",
    DELETE_PROFILE_MODAL_TITLE: "Delete Profile",
    DELETE_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} profile?",
    DELETE_PROFILES_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfilesNames} profiles?",

    PROFILE_EDIT_ACTION: "Edit Profile",
    PROFILE_DELETE_ACTION: "Delete Profile",
    BULK_ACTION_DELETE_PROFILES: "Delete Profiles",
    PROFILES: "Profiles",
    PROFILES_CAN_LIST_NAME: "List {name} Profiles ",
    PROFILES_CAN_INSPECT_NAME: "Inspect {name} Profiles",
    PROFILES_CAN_CREATE_NAME: "Create {name} Profiles",
    PROFILES_CAN_LINK_NAME: "Link {name} Profiles",
    PROFILES_CAN_RESTRICT_NAME: "Restrict {name} Profiles",
    PROFILES_CAN_LIST_HELP: "Can list the {name} profiles",
    PROFILES_CAN_INSPECT_HELP: "Can see the context of the {name} profiles",
    PROFILES_CAN_CREATE_HELP: "Can create a {name} profile",
    PROFILES_CAN_LINK_HELP: "Can create links for {name} profiles",
    PROFILES_CAN_RESTRICT_HELP: "Restricted parameters will be shown for {name} profiles",

    SUCCESS_DELETED_PROFILES_MESSAGE: "Successfully deleted {NumOfProfiles} profiles",
    FAIL_DELETE_PROFILES_MESSAGE: "Fail to delete {NumOfProfiles} profiles",
    SUCCESS_FAIL_DELETE_PROFILES_MESSAGE: "Deleted {NumOfDelProfiles} from {NumOfSelProfiles} profiles",
    FAIL_DELETE_PROFILE: "Fail to delete profile",

    PROFILES_TABLE_LINKED_FILTER: "Linked",
    PROFILES_TABLE_UNLINKED_FILTER: "Unlinked",
    PROFILES_TABLE_ENTER_PLACEHOLDER_TEXT: "",
    DEVICE_PROFILE: "Device profile",
    SERVICE_PROFILE: "Service profile",
    SIMPLE_REGISTRATION: "Add Device",


    PROFILES_VIEW_PROFILE: "View Profile",
    CAN_NOT_SEE_PROFILES: "Do not have rights to see profiles",

    SHARE_PROFILE_CHECKBOX: "Share this profile",

    QOS_PROFILES_TITLE: "QoS Profiles",
    ADD_QOS_PROFILE: "Add QoS Profile",
    QOS_PROFILE_NAME: "QoS profile",
    NAV_QOS_PROFILES:  "QoS Profiles",
    NAV_CHANNEL_PROFILES: "Channel Profiles",
    CHANNEL_PROFILES_TITLE: "Channel Profiles",
    ADD_CHANNEL_PROFILE: "Add Channel Profile",
    CHANNEL_PROFILE_NAME: "Channel profile",

    REQUIRED_PROPERTY_MESSAGE: "is a required property",
    SERVICE_PROFILE_CONNECTIVITY: "Connectivity profile",
    SERVICE_PROFILE_ROAMING: "Roaming profile",
    SERVICE_PROFILE_QoS: "QoS profile",
    SERVICE_PROFILE_CHANNEL: "Channel profile",
    SERVICE_PROFILE_LEGEND: "Service Profile",
    SELECT_PROFILE_PLACEHOLDER: "Select profile",
    LEAST_6_CHARACTERS_LONG_ERROR_MESSAGE: "must be at least 6 characters long",
    MOST_64_CHARACTERS_LONG_ERROR_MESSAGE: "must be at most 64 characters long",

    //Static messages
    STATIC_MESSAGE_READ: "I have read and agree with the",
    STATIC_MESSAGE_TERMS_OF_USE: "Terms of Use",
    STATIC_MESSAGE_AND: "and",
    STATIC_MESSAGE_PRIVACY_POLICY: "Privacy Policy",
    STATIC_MESSAGE_PLEASE_SELECT: "Please Select",

    BATCH_MUST_BE_A_STRING: " must be a string",
    BATCH_FIELD: "Field",
    BATCH_INVALID_VALUE: "contains an invalid value.",
    BATCH_MUST_8: " must be an 8-byte identifier.",
    BATCH_ERROR_MUST_16BIT: " must be an 16-byte identifier.",
    BATCH_ERROR_MUST_32BIT: " must be an 32-bit identifier.",
    BATCH_VALID_VALIE_BETWEEN: " valid values are between ",
    BATCH_TO: " to" ,
    BATCH_VALID_IS_NOT_SUPPORTED: " is not supported" ,
    BATCH_MUST_BE_BOOLEN: " must be boolean" ,
    BATCH_CAN_ONLY_BE_A_NUMBER: "can only be a number",
    BATCH_ALLOWED_VALUES_FOR: "Allowed values for ",
    BATCH_ALLOWED_VALUES_FOR_IS: " are sf7, sf8, sf9, sf10, sf11 and sf12",
    BATCH_ALLOWED_VALUES_FOR_STATIC: " are static mobile indoor and outdoor or combination from static,indoor static,outdoor mobile,indoor mobile,outdoor",
    BATCH_OTAA_ABP: " can only have values OTAA or ABP",
    BATCH_0_9: " valid values are 0 to 100",
    BATCH_0: " can only be 0",
    BATCH_CAN_ONLY_VERSION: " can only be 1.1.1, 1.0.3, 1.0.2, 1.0.1 or 1.0.0",
    BATCH_CAN_A_B: " can only be A or B",
    BATCH_CAN_EU_US_CH: " can be EU868, US902, China779, EU433, Australia915, China470, AS923 or INDIA",
    BATCH_ERROR_ROW: " Errors found on row ",
    BATCH_ERROR: " error",
    NO_RIGHTS_FOR_REQUESTED_OPERATION: "The account does not have sufficient rights for the requested operation",
    PROFILE_NOT_FOUND: "The profile is not found",
    REMOVE_REFERENCE_BEFORE_DELETE: "Remove all reference to the profile before deleting it",
    PROFILES_NOT_DELETED: "Profiles not deleted",
    LINKED_PROFILES_NOT_FOUND: "One or more of the linked profile are not found (or not visible to this account)",
    MAC_MSG: "MAC Messages",
    MAC_MSG_HELP: "Setting rights for user to see decoded MAC messages",
    SHOW_MAC_MSG: "Show MAC Messages",
    MAC_MSG: "MAC Messages",
    SHOW_DECODE_PAYLOAD: "Decoded Payload",
    SHOW_DATA: "Data",
    POSITION_ESTIMATES: "Position Estimates",
    EDIT_USER_NETWORK_ACCESS_LOC_INFO: "Gateway Location Information",
    EDIT_USER_NETWORK_ACCESS_LOC_INFO_HELP: "Can view gateway location information",
    DUPLICATE_HEADERS: "The csv file contains duplicate headers",

    // Linked Profiles
    ADD_NEW_LINKED_PROFILE: "Add Linked Profile",
    LINKED_PROFILE_MODAL_SOURCE_PROFILE: "Source Profile",
    LINKED_PROFILE_MODAL_CAN_INSPECT_PROFILE: "Can inspect profile",
    GETTING_TARGET_PROFILES_LIST_ERROR: "Can not get list of profiles for source profile",
    CAN_NOT_GET_TARGET_PROFILE_NAME: "Can not get source profile name",
    CREATE_LINKED_PROFILE_ACTION: "Create Linked Profile",
    BATCH_REGISTER_DEVICES_ERROR3: "The csv file must contain the mandatory properties for device with profiles",
    BATCH_WRONG_FORMAT: " format is not correct",
    UNLINK_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to unlink {ProfileName} profile?",
    UNLINK_LINKED_PROFILE_MODAL_TITLE: "Unlink Profile",
    UNLINK_LINKED_PROFILE_ACTION: "Unlink Profile",
    UNLINK_PROFILE_MODAL_CONFIRM_BUTTON: "Unlink",
    PROFILE_UNLINKED_SUCCESS: "Profile successfully unlinked",
    PROFILE_UNLINK_ERROR: "Error while unlinking profile",
    LINKED_PROFILE_MODAL_TARGET_USER: "Target User",
    LINKED_PROFILE_MODAL_TARGET_USER_PLACEHOLDER: "Select the owner of the target profile",
    ERROR_GETTING_PROFILE_OWNER_LIST: "Error while getting profile owner list",
    CAN_NOT_GET_PROFILE_OWNER_NAME: "Can not get profile owner name",
    LINKED_PROFILE_MODAL_SOURCE_USER: "Source User",
    LINKED_PROFILE_MODAL_SOURCE_USER_PLACEHOLDER: "Select the owner of the source profile",

    // API Based Table
    ERROR_IN_QUERY: "There is an error in the query fields",
    ERROR_UNKNOWN_FILTER_SOURCE: "The specified filter refer to unknown resource",
    ERROR_PAGE_STATE_EXPIRED: "Page state expired",
    ERROR_TOO_BIG_QUERY: "There are too many records in the result",
    ERROR_PAGE_STATE_REQUIRED: "A page state is required to be able to query with the particular option",

    TABLE_SHARED: "Shared",
    FILTER_PROFILES_ERROR: "Error while filtering profiles",

    EDIT_USER_TENANCY_ADMIN_RIGHTS_LABEL: "Tenant Admin Right",
    EDIT_USER_TENANCY_ADMIN_RIGHTS_HELP: "Users with tenant admin right can view and assign customers to tenant",
    ERROR_GETTING_USER_LIST: "Error while getting user list",
    ERROR_GETTING_CUSTOMER_LIST: "Error while getting customer list",
    EMPTY_SPACE_FILTER_ERROR: "Search value can not contain empty spaces",

    PROFILES_NOTES_FIELD: "Notes",

    MY_GATEWAY_NUMBER_MESSAGE:  "There {numberOfGateways, plural, =0{are no gateways} one{is 1 gateway} other{are # gateways}} registered.",
    USERS_NUMBER_MESSAGE:  "There {numberOfUsers, plural, =0{are no users} one{is 1 user} other{are # users}} registered.",
    CUSTOMERS_NUMBER_MESSAGE:  "There {numberOfCustomers, plural, =0{are no customers} one{is 1 customer} other{are # customers}} registered.",
    ORGANIZATIONS_NUMBER_MESSAGE:  "There {numberOfOrganizations, plural, =0{are no organizations} one{is 1 organization} other{are # organizations}} registered.",
    APPS_NUMBER_MESSAGE:  "There {numberOfApps, plural, =0{are no applications} one{is 1 application} other{are # applications}} registered.",
    NUMBER_OF_ITEMS_IN_TABLE:  "There {NumberOfItemsInTable} registered.",

    // Device Profiles Json Schema Form
    DEVICEPROFILES_TITLE: "Device Profile",
    DEVICEPROFILES_PROPERTIES_ACTIVATION_TITLE: "Device activation mode",
    DEVICEPROFILES_PROPERTIES_RFREGION_TITLE: "LoRaWAN region",
    DEVICEPROFILES_PROPERTIES_MACVERSION_TITLE: "LoRaWAN MAC version",
    DEVICEPROFILES_PROPERTIES_REGPARAMSREVISION_TITLE: "LoRaWAN MAC regional parameters revision",
    DEVICEPROFILES_PROPERTIES_MAXEIRP_TITLE: "Max EIRP [dBm]",
    DEVICEPROFILES_PROPERTIES_MAXEIRP_DESCRIPTION: "Set the device max Equivalent Isotropically Radiated Power (EIRP). This is maximum EIRP capability of the device (not necessarily the default value)",
    DEVICEPROFILES_PROPERTIES_DEFAULTDEVICECLASS_TITLE: "Default class of device after reset",
    DEVICEPROFILES_PROPERTIES_SUPPORTSCLASSB_TITLE: "Supports Class B",
    DEVICEPROFILES_PROPERTIES_SUPPORTSCLASSC_TITLE: "Supports Class C",
    DEVICEPROFILES_PROPERTIES_TRANSMIT_CAPABLE_ONLY_TITLE: "Transmit capable only device cannot receive downlink (i.e. it has no receiver). When this is set the network will never send any downlink towards the device",
    DEVICEPROFILES_PROPERTIES_LORA_FCNT32BITS_TITLE: "FCNT 32-bit counter (for 1.0.x devices only)",
    DEVICEPROFILES_PROPERTIES_LORA_FCNT32BITS_DESCRIPTION: "Select the size of the FCNT counter",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_APP_PAYLOAD_TITLE: "Max accepted downlink application payload size [bytes]",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_PHY_PAYLOAD_TITLE: "Max accepted downlink PHY Payload size [bytes]",
    DEVICEPROFILES_PROPERTIES_DL_MAX_NUM_MAC_CMD_TITLE: "Max number of MAC commands in downlink message",
    DEVICEPROFILES_PROPERTIES_DL_MAX_SIZE_MAC_CMD_TITLE: "Max accepted size of MAC commands in downlink message [bytes]",
    DEVICEPROFILES_PROPERTIES_ADR_CAPABILITY_TITLE: "Device support Adaptive Data-Rate (ADR)",
    DEVICEPROFILES_PROPERTIES_SUPPORT_FUOTA_TITLE: "Device support Firmware Update Over The Air (FUOTA)",
    DEVICEPROFILES_PROPERTIES_PERSISTED_FCNT_TITLE: "Device persist the FCNT and keep the FCNT value on device reset (for LoraWan 1.1)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_TITLE: "Device factory settings",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_DESCRIPTION: "If the factory presets are not provided the network will use LoraWan regional default settings for the region.",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_TITLE: "Regional presets",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RFREGION_DESCRIPTION: "LoRaWAN region",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAXEIRP_TITLE: "Max EIRP [dBm]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAXEIRP_DESCRIPTION: "Set the device max Equivalent Isotropically Radiated Power (EIRP). This is default value used for the current region (not necessarily the maximum capability)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_UL_DWELL_TIME_TITLE: "Uplink dwell time (AU915, AS923 and JP923 regions only)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_UL_DWELL_TIME_DESCRIPTION: "Set 0 for no constraint, 1 for 400ms",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_DL_DWELL_TIME_TITLE: "Downlink dwell time (AU915, AS923 and JP923 regions only)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_DL_DWELL_TIME_DESCRIPTION: "Set 0 for no constraint, 1 for 400ms",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_ADR_ACK_LIMIT_TITLE: "ADR_ACK_LIMIT as defined in LoraWan specification",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_ADR_ACK_DELAY_TITLE: "ADR_ACK_DELAY as defined in LoraWan specification",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_TIME_BETWEEN_REJOIN_REQ_TITLE: "Max time between rejoin attempts [seconds] (LoraWan 1.1 only)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_NUM_OF_UL_BETWEEN_REJOIN_REQ_TITLE: "Max number of uplinks between REJOIN requests (LoraWan 1.1 only)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MAX_UL_DR_SUPPORTED_TITLE: "Highest uplink data-rate supported",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX2_DR_TITLE: "Default data-rate of RX2 downlink slot",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX2_FREQ_MHZ_TITLE: "Default frequency [MHz] of RX2 downlink slot",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX1_DELAY_TITLE: "Default delay [seconds] of RX1 downlink slot after TX slot",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_RX1_DR_OFFSET_TITLE: "Default data-rate offset between uplink and downlink",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_FREQ_MHZ_TITLE: "Default frequency [MHz] of class B ping-slot. When set to 0 the hopping slot is used.",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_DR_TITLE: "Default data-rate of class B ping-slot",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_PING_SLOT_PERIODICITY_TITLE: "Class B periodicity of ping-slot",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_TITLE: "Channels Definition",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_FREQ_MHZ_TITLE: "Channel frequency [MHz]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_MASKED_TITLE: "channel disabled (masked)",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_DL_FREQ_MHZ_TITLE: "Downlink frequency if different from uplink frequency [MHz]",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MASK_125KHZ_TITLE: "Channel mask for 125kHz channels",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_MASK_500KHZ_TITLE: "Channel mask for 500kHz channels",
    DEVICEPROFILES_PROPERTIES_FACTORY_PRESETS_ITEMS_PROPERTIES_FREQ_CHANGE_NOT_SUPPORTED_TITLE: "Device frequency list is fixed and cannot be changed by network",

    // Connectivity Profiles Json Schema Form
    CONNECTIVITYPROFILES_TITLE: "Connectivity Profile",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_QUEUE_LENGTH_TITLE: "Maximum downlink queue size",
    CONNECTIVITYPROFILES_PROPERTIES_LORA_LOC_ENABLE_TITLE: "LoRa location service enabled",
    CONNECTIVITYPROFILES_PROPERTIES_CLASS_B_ALLOWED_TITLE: "Class B operation is allowed",
    CONNECTIVITYPROFILES_PROPERTIES_CLASS_C_ALLOWED_TITLE: "Class C operation is allowed",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOW_NON_ADR_TITLE: "Allow devices that does not enable ADR",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOW_NON_ADR_DESCRIPTION: "For device that does not use ADR, and when this flag is not set, communication with the device is not allowed. Any received uplinks will not be forwarded and an error will be sent to the application.",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOWED_DUTY_CYCLE_TITLE: "Allowed duty cycle. Encoded according to LoraWan specification.",
    CONNECTIVITYPROFILES_PROPERTIES_ALLOWED_DUTY_CYCLE_DESCRIPTION: "The aggregated dutycycle is calculated as 1 / allowed_duty_cycle ^ 2",
    CONNECTIVITYPROFILES_PROPERTIES_ENFORCE_DUTY_CYCLE_TITLE: "Network will enforce the duty-cycle by sending the duty-cycle MAC command to the device",
    CONNECTIVITYPROFILES_PROPERTIES_DL_PACKET_EXPIRY_TIME_HOURS_TITLE: "Downlink packet expiry time [hours]",
    CONNECTIVITYPROFILES_PROPERTIES_UL_PACKET_EXPIRY_TIME_HOURS_TITLE: "Uplink packet expiry time [hours]",
    CONNECTIVITYPROFILES_PROPERTIES_ALWAYS_PERSIST_UPLINK_PAYLOAD_TITLE: "Always store uplink payload data regardless of push answer from applications.",
    CONNECTIVITYPROFILES_PROPERTIES_FORCE_MAC_CMD_ENCRYPTION_TITLE: "Force MAC command encryption",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_UL_MSGS_PER_PERIOD_TITLE: "For-Future-Use-Only: Max number of uplink messages per period",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_MSGS_PER_PERIOD_TITLE: "For-Future-Use-Only: Max number of downlink messages per period",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_UL_BYTES_PER_PERIOD_TITLE: "For-Future-Use-Only: Max number of bytes in uplink per period",
    CONNECTIVITYPROFILES_PROPERTIES_MAX_DL_BYTES_PER_PERIOD_TITLE: "For-Future-Use-Only: Max number of bytes in downlink per period",
    CONNECTIVITYPROFILES_PROPERTIES_PERIOD_HOURS_TITLE: "For-Future-Use-Only: Period in hours",
    CONNECTIVITYPROFILES_PROPERTIES_RT_MSG_ALLOWED_TITLE: "Realtime API available",
    CONNECTIVITYPROFILES_PROPERTIES_RT_MSG_DELAY_TITLE: "Time allocated to the application to respond to realtime downlink request message [s]",
    CONNECTIVITYPROFILES_PROPERTIES_DOWNLINK_ALLOWED_TITLE: "Application downlink payload to device is allowed",
    CONNECTIVITYPROFILES_PROPERTIES_CONFIRMED_UPLINK_ALLOWED_TITLE: "Device may send confirmed uplink. If confirmed uplinks are sent when not allowed, no confirmation will be sent to device, uplink will however still be received",
    CONNECTIVITYPROFILES_PROPERTIES_DEVICE_INITIATED_MAC_COMMANDS_ALLOWED_TITLE: "Device may use device initiated MAC commands (such as LinkCheckReq and DeviceTimeReq)",
    CONNECTIVITYPROFILES_PROPERTIES_MULTICAST_ALLOWED_TITLE: "Multicasting is allowed",
    CONNECTIVITYPROFILES_PROPERTIES_FUOTA_ALLOWED_TITLE: "Firmware Update Over The Air (FUOTA) allowed",

    // Roaming Profiles Json Schema Form
    ROAMINGPROFILES_TITLE: "Roaming Profile",
    ROAMINGPROFILES_PROPERTIES_PASSIVE_ROAMING_ALLOWED_TITLE: "Allow passive roaming",

    // QoS Profiles Json Schema Form
    QOSPROFILE_TITLE: "QoS Profile",
    QOSPROFILE_PROPERTIES_REGION_TITLE: "LoRaWAN region",
    QOSPROFILE_PROPERTIES_MIN_DR_TITLE: "Min data-rate",
    QOSPROFILE_PROPERTIES_MIN_DR_DESCRIPTION: "The minimum data rate to be used for uplink frames. The value for min_dr must be equal or lower to the value for max_dr.",
    QOSPROFILE_PROPERTIES_MAX_DR_TITLE: "Max data-rate",
    QOSPROFILE_PROPERTIES_MAX_DR_DESCRIPTION: "The maximum data rate to be used for uplink frames. The value for max_dr must be higher than or equal to the value for min_dr",
    QOSPROFILE_PROPERTIES_MIN_TXP_TITLE: "Min TX power",
    QOSPROFILE_PROPERTIES_MIN_TXP_DESCRIPTION: "The minimum transmit power in dBm to be used for uplink frames. The value for min_txp must be equal or lower to the value for max_txp.",
    QOSPROFILE_PROPERTIES_MAX_TXP_TITLE: "Max TX power",
    QOSPROFILE_PROPERTIES_MAX_TXP_DESCRIPTION: "The maximum transmit power in dBm to be used for uplink frames. The value for man_txp must be higher than or equal to the value for min_txp.",
    QOSPROFILE_PROPERTIES_MIN_TXP_INDEX_TITLE: "Index for minimum TX power (LoRaWAN index value)",
    QOSPROFILE_PROPERTIES_MIN_TXP_INDEX_DESCRIPTION: "The index of the minimum TX power to be used for the uplink frame. Note that lower index is higher power level, meaning index=0 is the highest possible power.",
    QOSPROFILE_PROPERTIES_MAX_TXP_INDEX_TITLE: "Index for maximum TX power (LoRaWAN index value)",
    QOSPROFILE_PROPERTIES_MAX_TXP_INDEX_DESCRIPTION: "The index of the maximum TX power to be used for the uplink frame. Note that lower index is higher power level, meaning index=0 is the highest possible power.",
    QOSPROFILE_PROPERTIES_TXP_SELECT_TITLE: "Select TX power control by power-level-index or power-level-dBm. If index is selected only the index min/max power levels above  will be used, and vice-versa if the power level is selected only the min/max power levels values above are used.",
    QOSPROFILE_PROPERTIES_SNR_MARGIN_DB_TITLE: "SNR margin in dB",
    QOSPROFILE_PROPERTIES_SNR_MARGIN_DB_DESCRIPTION: "This parameter is used for data rate adaptation. The value specified here is the margin taken on averaged SNR over required SNR for demodulation.",
    QOSPROFILE_PROPERTIES_RSSI_MARGIN_DB_TITLE: "RSSI margin in dB",
    QOSPROFILE_PROPERTIES_RSSI_MARGIN_DB_DESCRIPTION: "This parameter is used for transmit power control. The value specified here is the margin on averaged RSSI over required RSSI for demodulation.",
    QOSPROFILE_PROPERTIES_TARGET_REDUNDANCY_TITLE: "Target redundancy",
    QOSPROFILE_PROPERTIES_TARGET_REDUNDANCY_DESCRIPTION: "This parameter is used to control the number of re-transmissions of each uplink unconfirmed frame. Redundancy is defined as overall diversity: spatial diversity (number of gateways seeing the end-device) + temporal  diversity (number of transmissions of each unconfirmed uplink frame).",
    QOSPROFILE_PROPERTIES_MAX_FER_PCT_TITLE: "Max FER %",
    QOSPROFILE_PROPERTIES_MAX_FER_PCT_DESCRIPTION: "Maximum acceptable FER (Frame Error Rate).",
    QOSPROFILE_PROPERTIES_MIN_UNCONF_UL_REP_TITLE: "Min unconfirmed UL repetitions",
    QOSPROFILE_PROPERTIES_MIN_UNCONF_UL_REP_DESCRIPTION: "The minimum number of times a single unconfirmed uplink frame should be received on the network server. The value for min_unconf_ul_rep must be equal or lower to the value for max_unconf_ul_rep.",
    QOSPROFILE_PROPERTIES_MAX_UNCONF_UL_REP_TITLE: "Max unconfirmed UL repetitions",
    QOSPROFILE_PROPERTIES_MAX_UNCONF_UL_REP_DESCRIPTION: "The maximum number of times a single unconfirmed uplink frame should be received on the network server. The value for max_unconf_ul_rep must be higher than or equal to the value for min_unconf_ul_rep.",
    QOSPROFILE_PROPERTIES_AVG_WINDOW_SIZE_TITLE: "Averaging window length",
    QOSPROFILE_PROPERTIES_AVG_WINDOW_SIZE_DESCRIPTION: "Averaging window length. This parameter is used by the ADR algorithm to calculate the history (average RSSI, SNR, FER) used for link adaptation.",
    QOSPROFILE_PROPERTIES_ADDITIONAL_SNR_MARGIN_JOIN_TITLE: "Additional SNR margin on join",
    QOSPROFILE_PROPERTIES_ADDITIONAL_SNR_MARGIN_JOIN_DESCRIPTION: "This parameter is used for data rate adaptation on JOIN or on RESET, when SNR statistics are not good. The additional margin is added for decision on the first received packet.",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_ON_JOIN_TITLE: "Max SF steps for ADR on join",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_ON_JOIN_DESCRIPTION: "The maximum number of data rate steps that can be changed in the first link adaptation. In the case of OTAA, the first adaptation is done in the first downlink after the end-device has joined, while in the case of ABP, it is done in the downlink after the second uplink.",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_TITLE: "Max SF steps for ADR",
    QOSPROFILE_PROPERTIES_MAX_DR_STEPS_FOR_ADR_DESCRIPTION: "The maximum number of data rates steps that can be changed in one shot, in regular mode.",
    QOSPROFILE_PROPERTIES_FRAME_ERROR_RATE_LIMIT_FOR_FSK_TITLE: "Frame error rate [%] limit for FSK after which data-rate will be lowered",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_PWR_CRTL_STEPS_TITLE: "Delay between two TxPower control steps",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_PWR_CRTL_STEPS_DESCRIPTION: "Delay between two consecutive transmit power control steps. This delay is used to let the average RSSI converge to the new value before taking another decision. The delay unit is a number of received uplink frames.",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_NBREP_CRTL_STEPS_TITLE: "Delay between two NbRep control steps",
    QOSPROFILE_PROPERTIES_DELAY_BETWEEN_TWO_NBREP_CRTL_STEPS_DESCRIPTION: "Delay between two consecutive number of repetitions (NbRep) control steps. This delay is used to let the averaged redundancy converge to the new value before taking another decision.",
    QOSPROFILE_PROPERTIES_GATEWAY_AGGREGATION_TIME_TITLE: "Gateway aggregation time [ms]",
    QOSPROFILE_PROPERTIES_GATEWAY_AGGREGATION_TIME_DESCRIPTION: "Time the network server will wait to collect message from all gateways for one uplink packet [ms]. Note that regardless of the value set, the network server will wait a minimum of 350 ms. If a new uplink packet (with higher FCNT) is received during this time, the aggregation is aborted at this time.",

    // Channel Profiles Json Schema Form
    CHANNELPROFILES_TITLE: "Channel Profile",
    CHANNELPROFILES_PROPERTIES_REGION_TITLE: "LoRaWAN region",
    CHANNELPROFILES_PROPERTIES_RX2_DR_TITLE: "RX2 DL slot data-rate",
    CHANNELPROFILES_PROPERTIES_RX2_FREQ_ARR_MHZ_TITLE: "RX2 DL slot frequency",
    CHANNELPROFILES_PROPERTIES_RX2_FREQ_ARR_MHZ_ITEMS_TITLE: "Frequency [MHz]",
    CHANNELPROFILES_PROPERTIES_RX1_DR_OFFSET_TITLE: "RX1 UL data-rate to DL data-rate offset",
    CHANNELPROFILES_PROPERTIES_MAXEIRP_TITLE: "Max EIRP",
    CHANNELPROFILES_PROPERTIES_MAXEIRP_DESCRIPTION: "Set the device max Equivalent Isotropically Radiated Power (EIRP)",
    CHANNELPROFILES_PROPERTIES_UL_DWELL_TIME_TITLE: "Uplink dwell time",
    CHANNELPROFILES_PROPERTIES_UL_DWELL_TIME_DESCRIPTION: "Set 0 for no constraint, 1 for 400ms",
    CHANNELPROFILES_PROPERTIES_DL_DWELL_TIME_TITLE: "Downlink dwell time",
    CHANNELPROFILES_PROPERTIES_DL_DWELL_TIME_DESCRIPTION: "Set 0 for no constraint, 1 for 400ms",
    CHANNELPROFILES_PROPERTIES_ADR_ACK_LIMIT_TITLE: "ADR ack limit",
    CHANNELPROFILES_PROPERTIES_ADR_ACK_DELAY_TITLE: "ADR ack delay",
    CHANNELPROFILES_PROPERTIES_MAX_TIME_BETWEEN_REJOIN_REQ_TITLE: "Max time in seconds between rejoin attempts",
    CHANNELPROFILES_PROPERTIES_MAX_NUM_OF_UL_BETWEEN_REJOIN_REQ_TITLE: "Max number of uplinks between REJOIN requests",
    CHANNELPROFILES_PROPERTIES_RX_DELAY_TITLE: "Delay [s] from TX slot to RX1 slot",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_FREQ_ARR_MHZ_TITLE: "Ping-slot frequency",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_FREQ_ARR_MHZ_ITEMS_TITLE: "Frequency [MHz]",
    CHANNELPROFILES_PROPERTIES_PING_SLOT_DR_TITLE: "Ping-slot data-rate",
    CHANNELPROFILES_PROPERTIES_CHANNELS_TITLE: "Channels Definition",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_FREQ_MHZ_TITLE: "Channel frequency [MHz]",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_MASKED_TITLE: "channel disabled (masked)",
    CHANNELPROFILES_PROPERTIES_CHANNELS_ITEMS_PROPERTIES_DL_FREQ_MHZ_TITLE: "Downlink frequency if different from uplink frequency [MHz]",
    CHANNELPROFILES_PROPERTIES_MASK_125KHZ_TITLE: "channel mask for 125kHz channels for FCC based MAC",
    CHANNELPROFILES_PROPERTIES_MASK_500KHZ_TITLE: "channel mask for 500kHz channels for FCC based MAC",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_PRIORITY_TITLE: "RX downlink slot priority when the TX duration is above 980ms",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_PRIORITY_RX1_SUB_SEC_DURATION_TITLE: "RX downlink slot priority for TX when the duration is less than 980ms",
    CHANNELPROFILES_PROPERTIES_RX_WINDOW_FOR_LINKADRREQ_TITLE: "RX downlink slot priority for downlink can contain LinkAdrReq",
    CHANNELPROFILES_PROPERTIES_USE_AUTOMATIC_FREQ_SELECTION_TITLE: "Use gateway channel plan",
    CHANNELPROFILES_PROPERTIES_USE_AUTOMATIC_FREQ_SELECTION_DESCRIPTION: "When set to true the device will get configured with the frequencies of the main gateway and the channels defined below will be ignored. Should be set to false when using the channels of the channel-profile.",
    CHANNELPROFILES_PROPERTIES_MIN_LORAWAN_SPEC_REQUIRED_TITLE: "Minimum required LoRaWAN MAC version",
    CHANNELPROFILES_PROPERTIES_SEND_FULL_MASK_CONFIGURATION_TITLE: "Force the network server to send the complete mask every time the mask is send. Default is to send only the part of the mask that is modified.",

    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no service profiles registered.",
    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 service profile registered.",
    SERVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} service profiles registered.",

    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no device profiles registered.",
    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 device profile registered.",
    DEVICE_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} device profiles registered.",

    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no connectivity profiles registered.",
    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 connectivity profile registered.",
    CONNECTIVITY_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} connectivity profiles registered.",

    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no roaming profiles registered.",
    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 roaming profile registered.",
    ROAMING_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} roaming profiles registered.",

    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no QoS profiles registered.",
    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 QoS profile registered.",
    QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} QoS profiles registered.",

    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0 : "There are no channel profiles registered.",
    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1 : "There is 1 channel profile registered.",
    CHANNEL_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2 : "There are {NumberOfItemsInTable} channel profiles registered.",

    FORM_ERROR_MESSAGE_REQUIRED: "is a required property",
    FORM_ERROR_MESSAGE_ENUM: "should be equal to one of the allowed values",
    FORM_ERROR_MESSAGE_MAXITEMS: "should NOT have more than {limit} items",
    FORM_ERROR_MESSAGE_MINITEMS: "should NOT have less than {limit} items",
    FORM_ERROR_MESSAGE_MINIMUM_MAXIMUM: "should be {comparison} {limit}",
    FORM_ERROR_MESSAGE_MINLENGTH: "should NOT be shorter than {limit} characters",
    FORM_ERROR_MESSAGE_ANYOF: "should match some schema in anyOf",
    FORM_ERROR_MESSAGE_ONEOF: "should match exactly one schema in oneOf",
    FORM_ERROR_MESSAGE_TYPE_NUMBER: "should be number",
    FORM_ERROR_MESSAGE_TYPE_INTEGER: "should be integer",
    FORM_ERROR_MESSAGE_UNIQUEITEMS: "should NOT have duplicate items (items ## {itemOne} and {itemTwo} are identical)",
    FORM_ERROR_MESSAGE_PATTERN: "should match pattern '{pattern}'",
    FORM_ERROR_MESSAGE_MAXLENGTH: "should NOT be longer than {limit} characters",

    FIND: "Find",
    ACCOUNTS: "Accounts",
    NAV_SIGNED_AS: "Signed as",
    ADMIN_TYPE_CUSTOMER: "Customer Administrator",
    ADMIN_TYPE_USER: "User Administrator",
    ADMIN_TYPE_ORGANIZATION: "Organization Administrator",
    TYPE_CUSTOMER: "Customer",
    TYPE_USER: "User",

    UNAUTHORIZED_ERROR: "The username/password used in the basic authentication scheme is invalid or not present.",
    FORBIDDEN_ERROR: "Account does not have customer administration rights",

    PROFILES_DEVICE_PROFILE: "Device",
    PROFILES_SERVICE_PROFILE: "Service",
    PROFILES_CONNECTIVITY_PROFILE: "Connectivity",
    PROFILES_ROAMING_PROFILE: "Roaming",
    PROFILES_CHANNEL_PROFILE: "Channel",
    PROFILES_QOS_PROFILE: "QoS",

    GET_USER_INFO_ERROR_400: "Something wrong with the JSON message",
    GET_USER_INFO_ERROR_404: "User ID does not exist",
    CAN_NOT_GET_USER_INFO: "Can not get user info",

    GET_PAGINATION_ERROR_400: "There is an error in the query fields",
    GET_PAGINATION_ERROR_404: "The specified filter refer to unknown resource",
    GET_PAGE_STATE_ERROR_410: "The page_state field is used and the page is expired",
    GET_PAGINATION_ERROR_413: "There are too many records in the result",
    GET_PAGE_STATE_ERROR_416: "A page state is required to be able to query with the particular sorting option.",

    UPDATE_USER_ERROR_401: "Changing the tenant_id is not permitted",
    UPDATE_USER_ERROR_403: "Cannot assign rights that higher that account",
    MSG_ASSIGN_APPS_SUCCESS_BODY: 'Successfully assigned app to groups.',
    MSG_ASSIGN_APPS_FAIL_BODY: 'Failed to assign apps to group.',

    MSG_REMOVE_APPS_SUCCESS_BODY: 'Successfully removed apps from groups.',
    MSG_REMOVE_APPS_FAIL_BODY: 'Failed to remove apps from group.',
    CHANGE_OWNER_ASSIGN_TO: "Assign to",
    MSG_USER_CREATE_FORBIDDEN_BODY: "Cannot register a {ut} with more rights than the creator",
    MSG_USER_UPDATE_FORBIDDEN_BODY: "Cannot assign rights that higher that account",

    MIGRATE_DEVICE: "Migrate Device",
    MIGRATE_DEVICES: "Migrate Devices",
    MIGRATE_DEVICE_ACTION_WARNING: "WARNING this action is irreversible",
    MSG_ERROR_INVALID_FIELD: "The search field has invalid value",

    CREATABLE_SELECT_LABEL: "UUID",
    INVALID_PROFILE_UUID_FORMAT: "Invalid profile UUID. Valid format is XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX (0-9, a-z)",

    ACTION_EDIT_TITLE: "Edit",
    ACTION_ADD_TITLE: "Add",

    FORM_ERROR_MESSAGE_UNIQUE_ITEMS: "Channels definition should not have duplicate items",
    FORM_ERROR_MESSAGE_MIN_GREATER_THEN_MAX: "{property} item {item}: dr_min value cannot be greater than dr_max value",
    SUPPORT: "support",
    ALLOW: "allow",
    PROFILE_DOES_NOT_SUPPORT_CLASS_B_C: "{profile} doesn't {support} class {class}",

    MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED: "Changing device profile of device is not allowed",
    HELP_MSG_DEVICE_CHANGING_DEVICE_PROFILE_NOT_ALLOWED: "Changing the device profile is not allowed",
    MSG_DEVICE_BULK_EDIT: "Cannot edit devices created with profiles and parameters together",
    MSG_DEVICE_DEVADDR_NWKSKEY_ALREADY_REGISTERED: "Devaddr and nwkskey pair already registered with other DevEUI",
    MSG_DEVICE_DEVADDR_NWKSKEY_ON_MULTIPLE_DEVICES_NOT_ALLOWED: "Setting devaddr and nwkskey on multiple devices not allowed",
    MSG_USERS_DELETE_BULK_FORBIDDEN_BODY: "Forbidden - cannot delete {count, plural, =0{} =1{{types}} other{{types}s}} belonging to a different parent",
    MSG_USERS_DELETE_BULK_NOT_FOUND_BODY: "Some of the selected {types}s are not found",
    MSG_USERS_DELETE_FORBIDDEN_BODY: "Forbidden - cannot delete {type} belonging to a different parent",
    MSG_USERS_DELETE_NOT_FOUND_BODY: "The {type} is not found",

    CAN_NOT_RETURN_TO_PARENT: "Return to action failed",

    PUSH_MODE_START_FAIL_INVALID_HOST: "Can't start push, no valid host set for app {accountid}",
    PUSH_MODE_START_FAIL_INVALID_PORT: "Can't start push, no valid port set for app {accountid}",

    PROFILE_IS_LOCKED: "The device profile is in use, it cannot be changed",
    SHARE_PROFILE_ALLOWED_ONLY_FOR_CUSTOMERS_LEVEL: '"{option}" can only be set on profile at customer level',
    CHANGE_OWNER_ASSIGN_TO_PARENT: "Assign to parent <{parentId}>",
    NO_RIGHTS_TO_SEE_THIS_PAGE: 'The User does not have permission to see this page.',
    ALERT_MODAL_TITLE_TEXT: "Error",
    DEVICE_PROFILE_PARAMETER_CAN_NOT_BE_CHANGED: "The device profile is in use, field '{parameterName}' cannot be changed",
    ERROR_UPDATING_DEAFULT_PUSH_CONFIG: "There was an error while updating the Default Push Config",
    UPDATE_DEFAULT_PUSH_CONFIG_SUCCESS: "Successfully copied the installation commands",
    UPDATE_DEFAULT_PUSH_CONFIG_ERROR_400: "Pick a model from the list of gateway models",
    MANAGE_APPLICATIONS_NAV_TITLE: "Manage Applications",

    ACTIVE_CONNECTIONS_NAV_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_PAGE_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_RES_401: "The username/password used in the basic authentication scheme is invalid or not present",
    ACTIVE_CONNECTIONS_CANT_GET_DATA: "Can not get data",
    ACTIVE_CONNECTIONS_TABLE_COL_TYPE: "Type",
    ACTIVE_CONNECTIONS_TABLE_COL_STATUS: "Status",
    ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME: "Connection Time",
    ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION: "Application",
    ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS: "Remote Address / Target URL",
    ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS: "Subscriptions",
    ACTIVE_CONNECTIONS_TABLE_COL_ERRORS: "Errors",

    EVENT_LOG_NAV_TITLE: "Event Log",
    EVENT_LOG_PAGE_TITLE: "Event Log",
    EVENT_LOGS_TABLE_COL_TIMESTAMP: "Timestamp",
    EVENT_LOGS_TABLE_COL_EVENT: "Event",
    EVENT_LOGS_TABLE_COL_APPLICATION: "Application",
    EVENT_LOGS_TABLE_COL_TEXT: "Text",
    EVENT_LOG_TABLE_PAGINATION_RES_401: "The username/password used in the basic authentication scheme is invalid or not present",
    EVENT_LOG_CANT_GET_DATA: "Cannot load data",
    EVENT_LOG_TABLE_DATA_RES_401: "The username/password used in the basic authentication scheme is invalid or not present",
    EVENT_LOG_TABLE_DATA_RES_404: "Invalid page_state",
    EVENT_LOG_LOG_LEVEL: "Log Level",
    EVENT_LOG_LEVEL_ERROR_OPTION: "Error",
    EVENT_LOG_LEVEL_INFO_OPTION: "Info",
    EVENT_LOG_LEVEL_DEBUG_OPTION: "Debug",
    EVENT_LOG_TIME_DURATION: "Time Duration",
    EVENT_LOG_TIME_DURATION_1D_OPTION: "1 Day",
    EVENT_LOG_TIME_DURATION_3D_OPTION: "3 Days",
    EVENT_LOG_TIME_DURATION_1W_OPTION: "1 Week",
    EVENT_LOG_TIME_DURATION_2W_OPTION: "2 Weeks",
    EVENT_LOG_LOG_LEVEL_ERROR: "Error while set Log Level",

    UNKNOWN_APP_ID_ERROR: "Unknown application-id in URL.",
    APP_FORBIDDEN_ERROR: "Account does not have administration rights",

    DEVICE_PROFILES_EDIT_PAYLOAD_DECODER: "Edit Payload Decoder",
    DEVICE_PROFILES_TABLE_ACTION_EDIT_PAYLOAD_DECODER: "Edit Payload Decoder",
    UNDO: "Undo",
    PAYLOAD_DECODER_UPDATED_SUCCESS: "Payload decoder schema updated successful",

    HTTP_PUSH_PATH_INVALID_BASIC_AUTH: "Invalid Basic authorization",

    LOG_LEVEL_DROPDOWN_DESC: "The log level determines what events are logged. Error is the default level. Setting higher log level takes effect from the moment it is set and last for a period of time after which the level is automatically set back to error level.",

    RE_PUSH_PAYLOAD: "(Re-)Push Payload",
    RE_PUSH_PAYLOAD_SUCCESS: "Payload re-pushed successfully",
    RE_PUSH_PAYLOAD_ERROR: "Error while re-pushing payload",

    EDIT_USER_ORGANIZATION: "Organization",
    EDIT_CUSTOMER_ORGANIZATION_LABEL: "Organization",
    ORGANIZATION_MSG: "User belongs to organization '{name}'",
    NO_ORGANIZATION_ASSIGNED: "No organization assigned",
    CHANGE_ORGANIZATION: "Change organization",
    EDIT_CUSTOMER_ORGANIZATION_HELP: "Specifies which organization this customer belongs to.",
    SELECT_A_ORGANIZATION: "Select a organization",
    ORGANIZATION_ID: "Organization ID",

    MSG_DEVICE_OTAA_CANT_MIGRATE_TO_ABP_PROFILE: "{device} device cannot be migrated to {profile} profile",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_MAC_VERSION_PROFILE: "Device cannot be migrated to profile with different MAC version",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_REG_PARAM_REVISION: "Device cannot be migrated to profile with different RegParamsRevision",
    MSG_DEVICE_CANT_MIGRATE_TO_DIFFERENT_RX2_DATA_RATE_PROFILE: "Device cannot be migrated to profile with different default rx2 datarate",
    ENTER_TAG_ID: "Enter Tag ID",
    ENTER_TAG_VALUE: "Enter Tag Value",

    ERROR_MSG_BOTH_PROFILES_ARE_REQUIRED_TO_MIGRATE_DEVICE: "Both device and service profile are required to migrate device",
    ERROR_MSG_CORRUPTED_DEVICE: "This device registration seems corrupted",
    ERROR_MSG_MIGRATE_DIFFERENT_FCNT: "Device cannot be migrated to profile with different FCNT 16/32-bit setting",
    ERROR_MSG_MIGRATE_DIFFERENT_RX1_DELAY: "Device cannot be migrated to profile with different rx1_delay",
    ERROR_MIGRATE_ASSIGN_PROFILE_TO_DIRECT_REG_DEVICE: "Assigning profiles to direct-registered device is not allowed",
    ERROR_NOT_UPDATED_DEVICES: "Update of {num} devices failed",
    MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_WITH_FAILS_BULK: "OK, updated {success} devices, {fail} devices failed",
    MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY_BULK: "OK, updated {success} devices",
    ERROR_MSG__DEVICE_INVALID_DEVEUI: "Invalid deveui {deveui}",
    ERROR_MSG_DEVEUI_CANT_BE_UPDATED: "Field deveui not allowed in update",
    ERROR_MSG_CANT_MODIFY_SUSPENDED_STATUS: "Account doesn't have right to modify suspended status",
    ERROR_MSG_DEVICE_LORA_RX_DELAY: "lora_rx_delay2 must be lora_rx_delay1 + 1",
    ERROR_MSG_DEVICE_INVALID_OPTION_VALUE: "Invalid option value",
    ERROR_MSG_DEVICE_PAYLOAD_DECODER: "Error in payload decoder: {message}",
    ERROR_MSG_MUST_HAVE_USERS_ADMIN_TO_SET_USERID: "Must be user admin to set userid field",
    ERROR_MSG_DEVICE_USER_ID_NOT_FOUND: "Userid {userid} not found",
    ERROR_MSG_DEVICE_MUST_BE_CUSTOMER_ADMIN: "Must be customer admin to move to other customer",
    ERROR_MSG_DEVICE_INVALID_DEVEUI: "Unknown groupid or invalid deveui {deveui}",
    ERROR_MSG_CANT_SET_DEVADDR_AND_NWKSKEY_MULTIPLE_DEVICES: "Error, setting devaddr and nwkskey on multiple devices not allowed",
    ERROR_MSG_DEVADDR_NWKSKEY_ALREADY_REGISTERED: "Error, devaddr and nwkskey pair already registered with other deveui",
    ERROR_MSG_DEVICE_SET_PROFILE_UUID_NOT_ALLOWED: "Setting {profile}_profile_uuid is not allowed",
    ERROR_MSG_MANDATORY_WHEN_SWITCH_TO_ABP: "{fields} and devaddr mandatory when switching to ABP activation mode",
    ERROR_MSG_DEVICE_INVALID_MAC_VERSION: "Invalid MAC version / revision",
    ERROR_MSG_DEVICE_IS_BUSY: "Device is busy, please try again later",
    ERROR_MSG_CANT_RESOLVE_DEVICE_PROFILES: "Can't resolve devices profiles",
    ERROR_MSG_SPECIFY_ONLY_ONE_DEVICE: "Please specify only one device when using the non_owner_access option",
    ERROR_MSG_CANT_RESOLVE_CURRENT_DEVICE_PROFILES: "Cannot resolve current device-profile",
    ERROR_MSG_DEVICE_CANT_CHANGE_DEVICE_PROFILE: "Cannot change device-profile. Value of {key} is not compabile."

};

app.config(['$translateProvider', function ($translateProvider) {

    $translateProvider.translations('en', strings);
}]);

module.exports = strings;
