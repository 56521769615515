(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('DeviceService', DeviceServiceFn);

    DeviceServiceFn.$inject = ['$http', "$timeout", "$q", "$filter"];

    function DeviceServiceFn($http, $timeout, $q, $filter) {
        var sortByColumn = $filter("sortByColumn");
        var dateString = $filter('dateString');

        // List all devices
        function getDevicesImpl(params) {
            const defer = $q.defer();

            $http({
                url: `/devices.json`,
                params,
                method: "GET"
            }).then((response) => {
                if (params && params.get_pages) {
                    defer.resolve(response.data);
                    return;
                }
                const devices = response.data;
                devices.map(device => device.last_reception_str = dateString(device.last_reception));
                defer.resolve(devices);
            }).catch(response => defer.reject(response));

            return defer.promise;
        }

        // export devices in csv format
        function exportDevicesImpl(params) {
            const defer = $q.defer();

            $http({
                url: `/export_devices.csv`,
                params,
                method: "GET"
            }).then((response) => {
                defer.resolve(response.data);
            }).catch(response => defer.reject(response));

            return defer.promise;
        }

        // Get individual device data
        function getDeviceImpl(deveui) {
            var defer = $q.defer();

            $http.get('/devices.json/' + deveui).then(
                function (response) {
                    var device = response.data;
                    device.last_reception_str = dateString(device.last_reception);
                    defer.resolve(device);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Sort a list of devices
        function sortDevicesImpl(devices, column='deveui', reverse=false) {
            return sortByColumn(devices, column, reverse);
        }

        // Delete a device
        function deleteDeviceImpl(deveui) {
            return $http.delete('/delete_device/' + deveui, null)
        }

        // Suspend/Unsuspend a device
        function suspendDeviceImpl(deveui,suspend) {
            return $http.put('/updatedevice', { deveui, suspended: suspend });
        }

        // Delete multiple devices
        function deleteDevicesImpl(devices) {
            var promises = devices.map(device => deleteDeviceImpl(device.deveui));
            return Promise.all(promises);
        }

        // Add a device
        function addDeviceImpl(device) {
            return $http.post('/adddevice', device);
        }

        // Update a device
        function updateDeviceImpl(device) {
            if(!device.altitude){
                device.altitude = 0;
            }
            console.log("update device", device);
            return $http.put('/updatedevice', device);
        }
        
        // Adds an array of devices to each group in the array of groups.
        function assignDevicesToGroupsImpl(devices, groups) {
            var groupIds = groups.map(group => group.groupid);
            var groupStr = groupIds.join(",");
            var updateObjs = devices.map(dev => {
                return {
                    deveui: dev.deveui,
                    groups: groupStr
                }
            });

            return updateDevicesImpl(updateObjs);
        }

        // Single group actions
        function removeGroupImpl(device, group) {
            return updateDeviceImpl({
                deveui: device.deveui,
                groups: `-${group.groupid}`
            });
        }

        function addGroupImpl(device, group) {
            return updateDeviceImpl({
                deveui: device.deveui,
                groups: `+${group.groupid}`
            });
        }

        // Multi-group actions
        function removeGroupsImpl(device, groups) {
            const groupsStr = groups.map(group => "-" + group.groupid).join(",");
            return updateDeviceImpl({
                deveui: device.deveui,
                groups: groupsStr
            });
        }

        function addGroupsImpl(device, groups) {
            const groupsStr = groups.map(group => "+" + group.groupid).join(",");
            return updateDeviceImpl({
                deveui: device.deveui,
                groups: groupsStr
            });
        }

        // Single application actions
        function removeAppImpl(device, application) {
            return updateDeviceImpl({
                deveui: device.deveui,
                applications: `-${application.accountid}`
            });
        }

        function addAppImpl(device, application) {
            return updateDeviceImpl({
                deveui: device.deveui,
                applications: `+${application.accountid}`
            });
        }

        // Multi-application actions
        function removeAppsImpl(device, applications) {
            const appString = applications.map(app => "-" + app.accountid).join(",");
            return updateDeviceImpl({
                deveui: device.deveui,
                applications: appString
            });
        }

        function addAppsImpl(device, applications) {
            const appString = applications.map(app => "+" + app.accountid).join(",");
            return updateDeviceImpl({
                deveui: device.deveui,
                applications: appString
            });
        }

        // Multi-device, multi-group, multi-application actions
        function addDevicesToGroupsImpl(devices, groups) {
            var individualPromises = devices.map(
                dev => addGroupsImpl(dev, groups)
            );

            return Promise.all(individualPromises);
        }

        function removeDevicesFromGroupsImpl(devices, groups) {
            var individualPromises = devices.map(
                dev => removeGroupsImpl(dev, groups)
            );

            return Promise.all(individualPromises);
        }

        function addDevicesToAppsImpl(devices, apps) {
            var individualPromises = devices.map(
                dev => addAppsImpl(dev, apps)
            );

            return Promise.all(individualPromises);
        }

        function removeDevicesFromAppsImpl(devices, apps) {
            var individualPromises = devices.map(
                dev => removeAppsImpl(dev, apps)
            );

            return Promise.all(individualPromises);
        }

        // Update an array of devices
        function updateDevicesImpl(devices) {
            const promises = devices.map(device => updateDeviceImpl(device));

            return Promise.all(promises);
        }

        function getDeviceProfileImpl(ProfileUUID) {
            return $http.put('/device-profiles-schema-edit', { ProfileUUID });
        }

        return {
            getDevices: getDevicesImpl,
            exportDevices: exportDevicesImpl,
            getDevice: getDeviceImpl,
            addDevice: addDeviceImpl,
            addGroup: addGroupImpl,
            removeGroup: removeGroupImpl,
            addGroups: addGroupsImpl,
            removeGroups: removeGroupsImpl,
            addDevicesToGroups: addDevicesToGroupsImpl,
            removeDevicesFromGroups: removeDevicesFromGroupsImpl,
            addDevicesToApps: addDevicesToAppsImpl,
            removeDevicesFromApps: removeDevicesFromAppsImpl,
            addApp: addAppImpl,
            removeApp: removeAppImpl,
            addApps: addAppsImpl,
            removeApps: removeAppsImpl,
            updateDevice: updateDeviceImpl,
            updateDevices: updateDevicesImpl,
            assignDevicesToGroups: assignDevicesToGroupsImpl,
            suspendDevice: suspendDeviceImpl,
            deleteDevice: deleteDeviceImpl,
            deleteDevices: deleteDevicesImpl,
            sortDevices: sortDevicesImpl,
            getDeviceProfile: getDeviceProfileImpl
        }
    }
})(angular);