var app = angular.module('dassUiModule');

function ProfilesController($filter, $scope, $element, $attrs, UserService) {
    var $translate = $filter('translate');
    var vm = this;
    vm.profileRights = []

    UserService.getUserData().then(userData => {
        vm.owner = userData.user
        if (vm.user.userid === "") {
            let user = {
                can_list_device_profile: (vm.owner.can_list_device_profile ? true : false),
                can_inspect_device_profile: (vm.owner.can_inspect_device_profile ? true : false),
                can_create_device_profile: (vm.owner.can_create_device_profile ? true : false),
                can_link_device_profile: (vm.owner.can_link_device_profile ? true : false),
                can_list_service_profile: (vm.owner.can_list_service_profile ? true : false),
                can_inspect_service_profile: false,
                can_create_service_profile: false,
                can_link_service_profile: false,
                can_restrict_service_profile: false,
                can_list_connectivity_profile: false,
                can_inspect_connectivity_profile: false,
                can_create_connectivity_profile: false,
                can_link_connectivity_profile: false,
                can_list_roaming_profile: false,
                can_inspect_roaming_profile: false,
                can_create_roaming_profile: false,
                can_link_roaming_profile: false,
                can_list_channel_profile: false,
                can_inspect_channel_profile: false,
                can_create_channel_profile: false,
                can_link_channel_profile: false,
                can_list_qos_profile: false,
                can_inspect_qos_profile: false,
                can_create_qos_profile: false,
                can_link_qos_profile: false,
            }
            angular.extend(vm.user, user)
        }
    }).catch(error => {

    })

    vm.DisableProfileBtn = false;

    vm.$onChanges = (changes) => {
        if (vm.profileType) {
            if (!vm.user[`can_list_${vm.profileType}_profile`]) {
                vm.DisableProfileBtn = true;
            } else {
                vm.DisableProfileBtn = false;
            }
            if (vm.profileType === "service") {
                vm.profileRightsKeys = ['list', 'inspect', 'create', 'link', 'restrict'];
            } else {
                vm.profileRightsKeys = ['list', 'inspect', 'create', 'link'];
            }
            if (vm.callLocation === "applications") {
                var index = vm.profileRightsKeys.indexOf('link');
                if (index > -1) {
                    vm.profileRightsKeys.splice(index, 1);
                }
            }
            vm.profileRightsHelpTexts = vm.profileRightsKeys.map(priv => `PROFILES_CAN_${priv.toUpperCase()}_HELP`)
            vm.profileRightsName = vm.profileRightsKeys.map(priv => `PROFILES_CAN_${priv.toUpperCase()}_NAME`)
            vm.name = vm.profileType[0].toUpperCase() + vm.profileType.slice(1);
            vm.profileRights = vm.profileRightsKeys.map((key, idx) => { return { key: vm.profileRightsKeys[idx], name: vm.profileRightsName[idx], help: vm.profileRightsHelpTexts[idx] } })
        }

    }

    vm.isReadOnly = function() {
        var flag = true;
        if (vm.readOnly == false) {
            flag = false;
        }

        return flag;
    }

    vm.ChangeValue = () => {
        if (!vm.user[`can_list_${vm.profileType}_profile`]) {
            vm.profileRightsKeys.map((right) => {
                if (vm.owner[`can_${right}_${vm.profileType}_profile`]) {
                    vm.user[`can_${right}_${vm.profileType}_profile`] = false;
                }
            });
            vm.DisableProfileBtn = true;
        } else {
            vm.DisableProfileBtn = false;
        }
    }
}

app.component('profiles', {
    templateUrl: 'profiles.html',
    controller: ['$filter', '$scope', '$element', '$attrs', "UserService", ProfilesController],
    controllerAs: "vm",
    bindings: {
        user: '=',
        profileType: '<',
        callLocation: '<',
        readOnly: "=",
    }
});
