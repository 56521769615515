import { toast as t, ToastContent, ToastPosition } from "react-toastify";

class Toaster {
    /**
     * Shorthand to display toast of type 'success'.
     */
    public static success(content: ToastContent) {
        t.success(content, {
            autoClose: 10000,
            bodyClassName: "toast-content",
            closeButton: true,
            closeOnClick: true,
            hideProgressBar: true,
            pauseOnHover: false,
            position: ToastPosition.BOTTOM_RIGHT,
        });
    }

    /**
     * Shorthand to display toast of type 'error'.
     */
    public static error(content: ToastContent) {
        t.error(content, {
            autoClose: false,
            bodyClassName: "toast-content",
            closeButton: true,
            closeOnClick: true,
            hideProgressBar: true,
            pauseOnHover: false,
            position: ToastPosition.BOTTOM_RIGHT,
        });
    }
}

export const toast = Toaster;
