import * as React from "react";
import * as ReactDOM from "react-dom";
import { DeviceModal } from "../components/Device/DeviceModal";
declare const angular;

export class DeviceModals {

  public showAddModal() {
    const addDeviceModal = document.getElementById("addDevModalTrigger") as HTMLAnchorElement;
    const controller = angular.element(addDeviceModal).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <DeviceModal Device={controller} />,
      modalRoot,
    );
  }
  public showEditModal(device) {
    const addDeviceModal = document.getElementById("addDevModalTrigger") as HTMLAnchorElement;
    const controller = angular.element(addDeviceModal).controller();
    const modalRoot = document.getElementById("reactModalRoot") as HTMLElement;
    ReactDOM.unmountComponentAtNode(modalRoot);
    ReactDOM.render(
      <DeviceModal editMode={true} EditDevice={device} Device={controller} />,
      modalRoot,
    );
  }

}
