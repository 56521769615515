(function (angular) {

    var app = angular.module('dassUiModule');
    app.factory('PayloadService', PayloadServiceFn);

    PayloadServiceFn.$inject = ['$http', "$q", "$filter"];

    function PayloadServiceFn($http, $q, $filter) {

        function sendDlPayloadImpl(data) {
            return $http.post('/send_dlpayload', data);
        }

        function sendUlPayloadImpl(data) {
            return $http.post('/send_ulpayload', data);
        }

        function deletePayloadImpl(deveui, packet) {
            console.log("Delete impl");
            return $http.delete(((packet.rssi !== undefined) ? '/delete_ulpayload/' : '/delete_dlpayload/') + deveui + "/" + packet.id, null);
        }

        function deletePayloadsImpl(deveui, packets) {
            var promises = packets.map(packet => deletePayloadImpl(deveui, packet));
            return Promise.all(promises);
        }
        function getPayloadsImpl (device) {
            var defer = $q.defer();

            $http.get('/payloads.json/' + device).then(
                function (response) {
                    defer.resolve(response.data);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        function getDevicePayloadsImpl (device) {
            var defer = $q.defer();

            $http.get('/device_payloads/' + device).then(
                function (response) {
                    defer.resolve(response.data);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }
        function rePushDevicePayloadULImpl (device, payload) {
            return $http.post('/re_push_payload_ul/' + device + '/' + payload);
        }
        return {
            sendDlPayload: sendDlPayloadImpl,
            sendUlPayload: sendUlPayloadImpl,
            deletePayload: deletePayloadImpl,
            deletePayloads: deletePayloadsImpl,
            getPayloads: getPayloadsImpl,
            getDevicePayloads: getDevicePayloadsImpl,
            rePushDevicePayloadUL: rePushDevicePayloadULImpl
        }
    }
})(angular);
