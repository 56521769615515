var app = angular.module('dassUiModule');

function EditAppFormController($filter, $scope, $element, $attrs, $timeout) {
    var ctrl = this;
    ctrl.constants = constants;
    var $translate = $filter('translate');
    ctrl.app = {
        host: '',
        path_prefix: '',
        username: '',
        cleartext_password: '',
        enabled: false,
        valid: false
    };

    $scope.authTypes = [
        {
            name: 'None',
            id: 'no_auth'
        },
        {
            name: 'Basic', 
            id: 'basic'
        },
        {
            id: 'access_token',
            name: 'Access Token'
        },
        {
            id: 'custom',
            name: 'Custom'
        }
    ];
    $scope.authTypesSelected = { name: 'None', id: 'no_auth' };

    $scope.dataFormats = ['base64', 'hex', 'decoded_json'];
    $scope.dataFormatsSelected = {
        selected: 'base64'
    };

    $scope.getDataFormats = function(search) {
        var newDataFormat = $scope.dataFormats.slice();
        if (search && newDataFormat.indexOf(search) === -1) {
            newDataFormat.unshift(search);
        }
        return newDataFormat;
    }

    $scope.$watch('dataFormatsSelected.selected', function(newVal, oldVal) {
        if (newVal !== oldVal) {
            ctrl.app.data_format = newVal;
        }
    });

    $scope.storePayloads = [
        {
            name: 'On failure to push',
            id: 'onfail'
        },
        {
            name: 'Always', 
            id: 'always'
        },
        {
            name: 'Never',
            id: 'never'
        }
    ];

    $scope.storePayloadsSelected = { 
        name: 'On failure to push', 
        id: 'onfail' 
    };

    $scope.getStorePayloads = function(search) {
        var newStorePayload = $scope.storePayloads.slice();
        if (search && newStorePayload.indexOf(search) === -1) {
            newStorePayload.unshift(search);
        }
        return newStorePayload;
    }

    $scope.$watch('storePayloadsSelected.selected', function(newVal, oldVal) {
        if (newVal !== oldVal) {
            ctrl.app.push_options = 'save:' + newVal.id;
        }
    });

    if (ctrl.constants.list_push_subscriptions) {
        $scope.subscriptions = ctrl.constants.list_push_subscriptions;
    }
    else {
        $scope.subscriptions = [];
    }

    $scope.selectedSubsriptions = {};
    if (ctrl.constants.default_push_subscriptions) {
        $scope.selectedSubsriptions.items = ctrl.constants.default_push_subscriptions;
    }
    else {
        $scope.selectedSubsriptions.items = [];
    }

    $scope.$watch('selectedSubsriptions.items', function(newVal, oldVal) {
        if (newVal !== oldVal) {
            ctrl.app.push_subscription = newVal.map((value) => {
                return value;
            }).join(",");
        }
    });

    ctrl.updateValid = () => {
        $scope.configure_push_form.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";
        var frm = $scope.configure_push_form;
        $scope.configure_push_form.required = ctrl.showControls;
        ctrl.valid = !frm.$invalid;
    }

    ctrl.updateAuthType = (selected) => {
        $scope.authTypesSelected = selected;
        var Options = [...$scope.authTypes];
        (function () {
            for( var i = 0; i < Options.length; i++){ 
                if (Options[i].id === selected.id) {
                    Options.splice(i, 1);
                }
            }
        
            if (Options.length > 0) {
                Options.map((option) => {
                    switch (option.id) {
                    case "no_auth":
                        if(ctrl.app.hasOwnProperty("no_auth")) {
                            delete ctrl.app["no_auth"];
                        }
                        break;
                    case "basic":
                        if(ctrl.app.hasOwnProperty("auth_string")) {
                            delete ctrl.app["auth_string"];
                        }
                        if(ctrl.app.hasOwnProperty("username")) {
                            ctrl.app.username = "";
                        }
                        if(ctrl.app.hasOwnProperty("cleartext_password")) {
                            ctrl.app.cleartext_password = "";
                        }
                        break;
                    case "access_token":
                        if(ctrl.app.hasOwnProperty("push_auth_access_token")) {
                            delete ctrl.app["push_auth_access_token"];
                        }
                        break;
                    case "custom":
                        if(ctrl.app.hasOwnProperty("custom_auth")) {
                            delete ctrl.app["custom_auth"];
                        }
                        break;
                    default:
                        break;
                    }
                });
            };        
        })();
        if (selected.id === "no_auth") {
            ctrl.app.no_auth = ""
        }
        if (selected.id !== "custom_auth") {
            ctrl.InvalidBasicAuth = false;
            ctrl.valid = true;
        }
    }

    ctrl.InvalidBasicAuth = false;

    ctrl.ValidateBasicAuth = () => {
        var value = ctrl.app.custom_auth;
        if (value && (value !== "") && value.startsWith("Basic ")) {
            var encodedValue = value.split(" ")[1];
            try {
                var decodedValue = decodeURIComponent(atob(encodedValue).split('').map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                if (decodedValue && decodedValue.includes(":")) {
                    ctrl.InvalidBasicAuth = false;
                    ctrl.valid = true;
                } else {
                    ctrl.InvalidBasicAuth = true;
                    ctrl.valid = false;
                }
            } catch (error) {
                ctrl.InvalidBasicAuth = true;
                ctrl.valid = false;
            }
        } else {
            ctrl.InvalidBasicAuth = false;
            ctrl.valid = true;
        }
    }

    ctrl.crtFile = null;
	ctrl.crtFileChanged = function () {
		var reader = new FileReader();
        reader.onload = function(e) {
            if (e && e.target && e.target.result) {
                ctrl.app.push_auth_cert = e.target.result;

                $timeout(function() {
                    document.getElementById('file-uploader-container').click();
                }, 0);
            }
        };

        try {
            reader.readAsText(ctrl.crtFile);
        }
        catch (error) {

        }
    }
    
    ctrl.keyFile = null;
	ctrl.keyFileChanged = function () {
		var reader = new FileReader();
        reader.onload = function(e) {
            if (e && e.target && e.target.result) {
                ctrl.app.push_auth_key = e.target.result;

                $timeout(function() {
                    document.getElementById('file-uploader-container').click();
                }, 0);
            }
        };

        try {
            reader.readAsText(ctrl.keyFile);
        }
        catch(error) {
            
        }
	}

    ctrl.$onInit = function() {
        localStorage.setItem('subsriptionsListCounter', 0);
        localStorage.setItem('authTypesListCounter', 0);
        localStorage.setItem('dataFormatListCounter', 0);
        localStorage.setItem('storePayloadListCounter', 0);

        if ((ctrl.app.push_auth_access_token && (ctrl.app.push_auth_access_token.length > 0)) ||
            (ctrl.app.username && (ctrl.app.username.length > 0)) ||
            (ctrl.app.cleartext_password && (ctrl.app.cleartext_password.length > 0)) ||
            (ctrl.app.custom_auth && (ctrl.app.custom_auth.length > 0))) {
            if (ctrl.app.hasOwnProperty("no_auth")) {
                delete ctrl.app.no_auth;
            }
        } else {
            ctrl.app.no_auth = "";
        }

        var authStringObj = ctrl.app;
        if (authStringObj.auth_string) {
            if (authStringObj.auth_string.startsWith("Basic")) {
                $scope.authTypesSelected = $scope.authTypes[1];

                let auth_decoded = [];
                auth_decoded = b64DecodeUnicode(vm.items.app.auth_string.slice(6)).split(":");

                ctrl.app.username           = auth_decoded[0] || "";
                ctrl.app.cleartext_password = auth_decoded[1] || "";

            } else if (authStringObj.auth_string.startsWith("Bearer")) {
                $scope.authTypesSelected = $scope.authTypes[2];
                ctrl.app.push_auth_access_token = authStringObj.auth_string.split(" ")[1];
            } else {
                $scope.authTypesSelected = $scope.authTypes[3];
                ctrl.app.custom_auth = authStringObj.auth_string;
            }
        } else if (authStringObj.push_auth_access_token) {
            $scope.authTypesSelected = $scope.authTypes[2];

        } else if (authStringObj.push_auth_username || authStringObj.push_auth_password) {
            $scope.authTypesSelected = $scope.authTypes[1];
            ctrl.app.username = authStringObj.push_auth_username || "";
            ctrl.app.cleartext_password = authStringObj.push_auth_password || "";

        } else {
            $scope.authTypesSelected = $scope.authTypes[0];
        }

        if (ctrl.app.host && ctrl.app.port) {
            ctrl.app.host = ctrl.app.host + ':' + ctrl.app.port;
        }

        if (ctrl.app.push_subscription) {
            var parts = ctrl.app.push_subscription.split(',');

            var subscriptionsArray = [];
            for (var i = 0; i < parts.length; i++) {
                subscriptionsArray.push(parts[i]);
            }

            $scope.selectedSubsriptions.items = subscriptionsArray;
        } else if (ctrl.editMode) {
                $scope.selectedSubsriptions.items = [];
        } else {
            ctrl.app.data_format = $scope.dataFormatsSelected.selected;
            ctrl.app.save = $scope.storePayloadsSelected;
            ctrl.app.push_subscription = $scope.selectedSubsriptions.items.map((item) => {
                return item;
            }).join(",");
        }

        if (ctrl.app.data_format) {
            $scope.dataFormatsSelected.selected = ctrl.app.data_format;
        }
        
        if (ctrl.app.push_options) {
            if (ctrl.app.push_options == "save:never") {
                $scope.storePayloadsSelected.selected = $scope.storePayloads[2];
            }
            else if (ctrl.app.push_options == "save:always") {
                $scope.storePayloadsSelected.selected = $scope.storePayloads[1];
            }
            else {
                $scope.storePayloadsSelected.selected = $scope.storePayloads[0];
            }
        }
        else {
            $scope.storePayloadsSelected.selected = $scope.storePayloads[0];
        }
    }
}

app.component('appEditForm', {
    templateUrl: 'edit_application_form.html',
    controller: ['$filter', '$scope', '$element', '$attrs', '$timeout', EditAppFormController],
    bindings: {
        app: '=',
        showControls: '<',
        editMode: '<',
        valid: '=',
        onAccept: '&',
        onCancel: '&',
    }
});


app.directive('openMenuByClick', ['$timeout', function($timeout) {
    return {
        
        link: function (scope, element, attrs) {
            element.bind('click', function () {
                
                $timeout(function () {
                    localStorage.setItem('authTypesListCounter', 0);
                    localStorage.setItem('dataFormatListCounter', 0);
                    localStorage.setItem('storePayloadListCounter', 0);
                    
                    let subsriptionsListCounter = localStorage.getItem('subsriptionsListCounter');
                    if (subsriptionsListCounter != undefined ) {
                        localStorage.setItem('subsriptionsListCounter', ++subsriptionsListCounter);
                    }

                    if (subsriptionsListCounter % 2 != 0) {
                        $("#"+attrs.openMenuByClick).find('input').click(); 
                    }
                });
            });
        }
    };
}]);

app.directive('openMenuByClickAuth', ['$timeout', function($timeout) {
    return {
        
        link: function (scope, element, attrs) {
            element.bind('click', function () {
                
                $timeout(function () {
                    let authTypesListCounter = localStorage.getItem('authTypesListCounter');
                    if (authTypesListCounter != undefined ) {
                        localStorage.setItem('authTypesListCounter', ++authTypesListCounter);
                    }

                    if (authTypesListCounter % 2 != 0) {
                        $("#"+attrs.openMenuByClickAuth).find('input').click(); 
                    }
                });
            });
        }
    };
}]);

app.directive('openMenuByClickDataFormat', ['$timeout', function($timeout) {
    return {
        
        link: function (scope, element, attrs) {
            element.bind('click', function () {
                
                $timeout(function () {
                    let dataFormatListCounter = localStorage.getItem('dataFormatListCounter');
                    if (dataFormatListCounter != undefined ) {
                        localStorage.setItem('dataFormatListCounter', ++dataFormatListCounter);
                    }

                    if (dataFormatListCounter % 2 != 0) {
                        $("#"+attrs.openMenuByClickDataFormat).find('input').click(); 
                    }
                });
            });
        }
    };
}]);

app.directive('subscruptionsContainer', [function() {
    return {
		link: function($scope, $element) {
		  	$element.on('click', function($event) {
                if ($event.target.className == 'close ui-select-match-close') {
                    $event.stopPropagation();
                }
			});
		}
	};
}]);

app.directive('openMenuByClickStorePayload', ['$timeout', function($timeout) {
    return {
        
        link: function (scope, element, attrs) {
            element.bind('click', function () {
                
                $timeout(function () {
                    let storePayloadListCounter = localStorage.getItem('storePayloadListCounter');
                    if (storePayloadListCounter != undefined ) {
                        localStorage.setItem('storePayloadListCounter', ++storePayloadListCounter);
                    }

                    if (storePayloadListCounter % 2 != 0) {
                        $("#"+attrs.openMenuByClickStorePayload).find('input').click(); 
                    }
                });
            });
        }
    };
}]);
