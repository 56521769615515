import { defaultCipherList } from "constants";

var app = angular.module('dassUiModule');
app.config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);


function signInForward(UserService, ToastService, userid) {
    console.log(`Forwarding sign in to user ${userid}`);
    
    localStorage.removeItem("currentGroup");

    UserService.signInForward(userid).then(
        response => window.location.href = "/mydevices_list.html"
    ).catch(
        error => ToastService.showMessage($translate('MSG_LOGIN_FORWARD_FAILED'), "error")
    );
};

function AdminDeviceController(DeviceService, $filter, $uibModal, $scope, $timeout, UserService, ToastService, GroupService, AppService, MessageService, PayloadService) {

    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = this;
    vm.devices = [];
    vm.users = [];
    vm.user = null;
    vm.workingCount = 0;
    vm.numberOfDevices = 0;
    
    vm.customFilter = {
        admin_mode: true
    };

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
        vm.numberOfDevices = 0;
    };

    vm.showUsers = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'showUsers.html',
            controller: 'DataSelectController',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        dataType: '/users.json',
                        title: "USER_LIST_TITLE",
                        actions: [],
                        bulkActions: [],
                        columns: [{
                            key: "userid",
                            type: "text",
                            title: "USER ID",

                            // sortable: true,
                            // sortKey: "sort_by_userid",
                        }, {
                            key: "administrator",
                            title: "Administrator",
                            type: "text",
                            render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "can_register",
                            title: "Registration Rights",
                            type: "text",
                            render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "can_access_gtw_info",
                            title: "CAN_ACCESS_GATEWAY_INFO",
                            type: "text",
                            render: (user) => user.can_access_gtw_info ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "gtw_admin",
                            title: "Gtw_admin",
                            type: "text",
                            render: (x) => x.gtw_admin ? $translate("YES") : $translate("NO"),
                        }],
                        users: vm.users,
                        owner: vm.user,
                    }
                }
            }
        });

        modalInstance.result.then((selectedUsers) => {
            console.log("Outside modal: ", selectedUsers);
        }, () => {
            // vm.loadDevices()
            console.log("Dismissed");
        });

    }

    vm.showEditDialog = (deveui) => {
        console.log(`Getting ${deveui}`);

        DeviceService.getDevice(deveui).then(
            (device) => {
                const modalInstance = $uibModal.open({
                    backdrop: "static",
                    animation: true,
                    templateUrl: 'addDeviceModalContent.html',
                    controller: 'AddDeviceModalCtrl',
                    size: "lg",
                    resolve: {
                        items: () => {
                            return {
                                device,
                                editMode: true,
                                constants: constants,
                                owner: vm.user,
                            };
                        }
                    }
                });

                modalInstance.result.then((device) => {
                    DeviceService.updateDevice(device).then((response) => {
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                            deveui: formatDeveui(deveui)
                        }), "success");
                        vm.loadDevices();
                    }).catch((response) => {
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                            "error"
                        );
                    });
                }, () => {
                    console.log('Modal dismissed at: ' + new Date());
                });
            }, () => {
                vm.loading = false;
                console.log("Error loading devices");
            }
        );
    };


    vm.signInForward = (userid) => signInForward(UserService, ToastService, userid);

    vm.reloadData = () => {
        console.log("This function should be overwritten by the component before it gets called");
        vm.numberOfDevices = 0;
    };

    vm.showEditDialog = (device) => {
        console.log(`Getting ${device.deveui}`);

        DeviceService.getDevice(device.deveui).then(
            (device) => {
                const modalInstance = $uibModal.open({
                    backdrop: "static",
                    animation: true,
                    templateUrl: 'addDeviceModalContent.html',
                    controller: 'AddDeviceModalCtrl',
                    size: "lg",
                    resolve: {
                        items: () => {
                            return {
                                device,
                                editMode: true,
                                constants: constants,
                                owner: vm.user,
                            };
                        }
                    }
                });

                modalInstance.result.then((device) => {
                    DeviceService.updateDevice(device).then((response) => {
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                            deveui: formatDeveui(device.deveui)
                        }), "success");
                        vm.loadDevices();
                    }).catch((response) => {
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                            "error"
                        );
                    });
                }, () => {
                    console.log('Modal dismissed at: ' + new Date());
                });
            }, () => {
                vm.loading = false;
                console.log("Error loading devices");
            }
        );
    };

    const sendPayloadToDass = (deveui, payload) => {
        const sendobj = {
            deveui: deveui,
            data: btoa(payload.data),
            port: payload.port,
            fcnt: payload.fcnt == null ? undefined : payload.fcnt,
            confirmed: payload.confirmed == null ? undefined : payload.confirmed
        };

        PayloadService.sendDlPayload(sendobj).then(
            (response) => {
                ToastService.showMessage($translate('MSG_SEND_DL_PACKET_SUCCESS_BODY', {
                    deveui: formatDeveui(deveui),
                    transaction_id: response.data.id
                }), "success");

                // vm.loadPayloads();
                console.log(response.data);
            },
            (response) => {
                ToastService.showMessage($translate('MSG_SEND_DL_PACKET_FAIL_BODY'), "error");

                console.log(response.data);
            }
        );
    }

    vm.sendData = (deveui) => {
        if (deveui == null && vm.currentDevice == "") {
            MessageService.showMessage({
                title: $translate('MSG_TITLE_OOPS'),
                body: $translate('MSG_SELECT_DEVICE_BODY')
            });
            return;
        }
        if (deveui == null) {
            deveui = vm.currentDevice;
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: vm.animationsEnabled,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return [];
                }
            }
        });
        modalInstance.result.then((payload) => {
            sendPayloadToDass(deveui, payload);
        }, () => {
        });
    };

    vm.setCurrentDevice = (deveui, url) => {
        console.log("setting current device " + deveui + " " + url);
        UserService.setCurrentDevice(deveui).then(
            (response) => {
                if (url != null) {
                    console.log("redirecting");
                    window.location.href = url;
                }
            },
            (response) => {
                MessageService.showMessage({
                    title: $translate('MSG_TITLE_OOPS'),
                    body: "Can ???"
                });

                // Refresh user data
                vm.loadUserData();
            }
        );
    };

    vm.dataType = '/devices.json';
    vm.initDataTable = () => {
        vm.actions = [{
            type: "action",
            text: "MY_DEVICES_ACTION_EDIT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showEditDialog
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_SEND",
            icon: "fa fa-paper-plane-o fa-fw",
            action: (dev) => vm.sendData(dev.deveui)
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_DATA",
            icon: "fa fa-database fa-fw",
            action: dev => vm.setCurrentDevice(dev.deveui, '/data_list.html')
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "SET_OWNER_AS_USER",
            icon: "fa fa-arrow-right fa-fw",
            action: (device) => vm.signInForward(device.ownerid)
        }];
        vm.bulkActions = [];
        vm.columns = [{
            key: "deveui",
            type: "text",
            title: "DEVEUI",
            filterable: true,
            filterField: "search_deveui",
            filterType: "text",
            filterParams: {
                mapper: (x) => (x && x.trim().replace(new RegExp("-", 'g'), "")) || undefined,
                pattern: /(^(0[xX])?[0-9a-fA-F]{16}$)|(^([0-9a-fA-F]{2}-){7}[0-9a-fA-F]{2}$)/,
                required: false
            },
            sortable: true,
            sortKey: "sort_by_deveui",
            render: (x) => formatDeveui(x.deveui)
        }, {
            key: "device_status",
            title: "STATUS",
            type: "icon_with_tooltip",
            render_icon: statusSymbol,
            render_tooltip: statusTooltip,
            filterable: false,
            filterField: "",
            filterType: "",
            filterParams: {
                mapper: x => x + "!123"
            },
        }, {
            key: "comment",
            title: "COMMENT",
            type: "text",
            filterable: true,
            filterField: "search_comment",
            filterType: "text",
            sortable: true,
            sortKey: "sort_by_comment"
        }, {
            key: "ownerid",
            title: "OWNER",
            type: "text"
        }];
        vm.reloadData();
    };

    Promise.all([vm.loadUserData()]).then(vm.initDataTable);

}

app.controller("AdminDeviceController", ["DeviceService", "$filter", "$uibModal", "$scope", "$timeout", "UserService", "ToastService", "GroupService", "AppService", "MessageService", "PayloadService", AdminDeviceController]);

function AdminDeviceListController(DeviceService, AppService, UserService, GroupService, MessageService, ToastService, PayloadService, DataService, $filter, $uibModal, $scope, $rootScope, $timeout) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = this;
    vm.devices = [];
    vm.apps = [];
    vm.groups = [];

    vm.constants = constants;
    vm.workingCount = 0;

    vm.textFilterTimeout = null;

    vm.currentPage = 1;
    vm.itemsPerPage = 25;
    vm.loading = true;
    vm.user = null;
    vm.loginUserId = null;
    vm.deviceSortReverse = true;
    vm.deviceSortColumn = "last_reception";
    vm.currentDevice = null;
    vm.numberOfDevices = 0;

    vm.customFilter = {
        search_all_users: true,
    };
    vm.loadUserR = () => {
            UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                if(userData.user.customer_admin){
                    vm.customFilter.search_all_customers = true;
                } if(userData.user.administrator){
                    vm.customFilter.search_all_users = true;
                }
                if(userData.user.tenant_admin){
                    vm.customFilter.search_all_tenants = true;
                }
                console.log('122 vm.customFilter ',vm.customFilter );
            }
        )
        
    };
    vm.loadUserR();

    vm.dataType = '/devices.json';
    // vm.dataType=null;

    vm.deselectAll = (device) => {
        vm.numSelected = 0;
        vm.selectedDevices = [];
        vm.devices.forEach((device) => {
            device._marked = false;
        });

        vm.selectAll = false;
    }
    vm.sendData = (deveui) => {
        if (deveui == null && vm.currentDevice == "") {
            MessageService.showMessage({
                title: $translate('MSG_TITLE_OOPS'),
                body: $translate('MSG_SELECT_DEVICE_BODY')
            });
            return;
        }
        if (deveui == null) {
            deveui = vm.currentDevice;
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: vm.animationsEnabled,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return [];
                }
            }
        });
        modalInstance.result.then((payload) => {
            sendPayloadToDass(deveui, payload);
        }, () => {
        });
    };

    vm.deleteGroups = () => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY', {
                count: vm.assignGroupsSelection.length
            })
        }).then((resp) => {
            GroupService.deleteGroups(vm.assignGroupsSelection).then(() => {
                ToastService.showMessage($translate('MSG_GROUPS_DELETED_SUCCESS_BODY', {
                    count: vm.assignGroupsSelection.length
                }), "success");

                vm.loadDevices();
                vm.loadGroups();
            }).catch((err) => {
                ToastService.showMessage($translate('MSG_GROUPS_DELETED_FAIL_BODY'), "error");
            });
        });
    };

    vm.toggleDevicesSelected = () => {
        if (!vm.selectAll) vm.deselectAll();
        else vm.selectAll();
    };

    vm.textFilterUpdated = () => {
        console.log("Search");
        vm.currentPage = 1;

        $timeout.cancel(vm.textFilterTimeout);
        vm.textFilterTimeout = $timeout(() => {
            console.log("Re-request");
            vm.loadDevices();
        }, 500);
    };

    vm.applyGroups = () => {
        console.log("Trying to apply groups");
        const groups = vm.assignGroupsSelection;
        console.log("Applying ", groups);

        DeviceService.assignDevicesToGroups(vm.selectedDevices, groups).then(
            (response) => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_SUCCESS_BODY'), "success", "ok");

                vm.loadDevices();
            },
            (response) => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_FAIL_BODY', "error", "remove"));
            }
        );

        vm.assignGroupsSelection = [];
        vm.deselectAll();
    };

    vm.deviceTags = null;
    $scope.$on('updateDeviceTags', function(event, data) {  // when there is a change from the child component
        vm.deviceTags = data;

        // with broadcast, again I am informing to the parent component that there is a change so that component can re-render again
        $rootScope.$broadcast('updateDeviceTagsSecond', vm.deviceTags);
    });

    vm.deviceNewTagName = null;
    $scope.$on('updateDeviceNewTagName', function(event, data) {    // when there is a change from the child component
        vm.deviceNewTagName = data;

        // with broadcast, again I am informing to the parent component that there is a change so that component can re-render again
        $rootScope.$broadcast('updateDeviceNewTagNameSecond', vm.deviceNewTagName);
    });

    // Add multiple groups to multiple devices
    vm.showGroupSelectDialog = (device) => {
        let devicesToChange = vm.selectedDevices;
        if (device) {
            devicesToChange = [device];
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addGroupModalContent.html',
            controller: 'AddGroupModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: constants,
                        assignMode: true,
                        owner: vm.user
                    };
                }
            }
        });

        modalInstance.result.then((result) => {
            const { action, groups } = result;
            let promise = null;
            if (action == 'add') {
                promise = DeviceService.addDevicesToGroups(devicesToChange, groups);
            } else if (action == 'remove') {
                promise = DeviceService.removeDevicesFromGroups(devicesToChange, groups);
            }

            if (!promise) {
                console.log(`Unexpected action on group assignment: ${action}`);
                return;
            }

            promise.then(() => {
                if (action == 'add') {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_SUCCESS_BODY'), "success", "ok");
                } else {
                    ToastService.showMessage($translate('MSG_REMOVE_DEVICES_SUCCESS_BODY'), "success", "ok");
                }
            }).then(() => {
                vm.loadDevices();
                vm.loadGroups();
            }).then(vm.deselectAll)
                .catch((err) => {
                    console.log("Error assigning devices", err);
                    if (action == 'add') {
                        ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_FAIL_BODY'), "success", "ok");
                    } else {
                        ToastService.showMessage($translate('MSG_REMOVE_DEVICES_FAIL_BODY'), "success", "ok");
                    }
                });
        }, function () {
            //
        });
    };

    vm.showSelectAppDialog = (device) => {
        let devicesToChange = vm.selectedDevices;
        if (device) {
            devicesToChange = [device];
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'selectAppsModal.html',
            controller: 'SelectAppsModalCtrl',
            resolve: {
                items: () => {
                    return {}
                }
            },
            size: "lg"
        });

        modalInstance.result.then((data) => {
            const selectedApps = data.apps;
            const deleteMode = data.action === "remove";
            console.log(data);
            console.log(deleteMode ? "Delete" : "add" + " selected ", selectedApps);
            if (deleteMode) {
                DeviceService.removeDevicesFromApps(devicesToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_UNASSIGN_DEVICES_APPS_SUCCESS_BODY'), "success", "ok");
                }).then(() => {
                    vm.loadDevices();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_UNASSIGN_DEVICES_APPS_FAIL_BODY', "error", "remove"));
                });
            } else {
                DeviceService.addDevicesToApps(devicesToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_APPS_SUCCESS_BODY'), "success", "ok");
                }).then(() => {
                    vm.loadDevices();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_APPS_FAIL_BODY', "error", "remove"));
                });
            }

        });
    };

    vm.showSendDataDialog = (group) => {
        console.log($uibModal, $uibModalInstance);
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return {
                        isMulticast: true
                    };
                }
            }
        });

        modalInstance.result.then((payload) => {
            console.log("Sending payload to group ", group, payload);
            GroupService.sendMulticast(group, payload.data, payload.port, payload.fcnt).then((result) => {
                ToastService.showMessage($translate('MSG_MULTICAST_SUCCESS', vm.group), "success", "send")
            }).catch((err) => {
                ToastService.showMessage($translate('MSG_MULTICAST_FAIL', vm.group), "error", "remove")
                console.log("Error sending multicast", err);
            });
        });
    }

    vm.resetGroup = function () {
        vm.group = {
            groupid: "",
            title: "",
            comment: ""
        };
    }

    vm.edit = (group) => {
        vm.resetGroup();
        vm.editMode = true;
        vm.addMode = false;
        vm.group = group;

        // TODO: Figure out a better way to do this ?
        $(".modal").animate({ scrollTop: 0 }, "slow");
    }

    vm.delete = (group) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY', {
                count: 1
            })
        }).then(() => {
            GroupService.deleteGroup(group.groupid).then(() => {
                ToastService.showMessage($translate('MSG_DELETE_GROUP_SUCCESS', group), "success")
            })
                .then(vm.loadGroups)
                .catch(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUP_FAIL', group), "error")
                });
            vm.cancelForm();
        });
    }

    vm.showAddGroupDialog = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addGroupModalContent.html',
            controller: 'AddGroupModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: vm.constants,
                        owner: vm.user
                    }
                }
            }
        });

        modalInstance.result.then((group) => {
            vm.loadDevices();
            vm.loadGroups();
        }, () => {
            vm.loadDevices();
            vm.loadGroups();
        });
    };

    vm.showDeleteDialog = (deveui) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_DEVICE_TITLE'),
            body: $translate('MSG_DELETE_DEVICE_BODY', {
                deveui: formatDeveui(deveui)
            })
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending delete request");
                DeviceService.deleteDevice(deveui).then(
                    (response) => {
                        ToastService.showMessage($translate('MSG_DELETE_DEVICE_SUCCESS_BODY', {
                            deveui: formatDeveui(deveui)
                        }), "success");

                        let i;
                        for (i = 0; i < vm.devices.length; i++) {
                            if (vm.devices[i].deveui.toLowerCase() == deveui.toLowerCase()) {
                                vm.devices.splice(i, 1);
                                break;
                            }
                        }


                    },
                    (response) => {
                        ToastService.showMessage($translate('MSG_DELETE_DEVICE_FAIL_BODY'), "error");
                    }
                );
            }
            vm.loadDevices();
        });
    };

    vm.showAddDialog = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addDeviceModalContent.html',
            controller: 'AddDeviceModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: constants,
                        owner: vm.user
                    };
                }
            }
        });

        modalInstance.result.then((device) => {
            console.log("Adding device ", device);
            DeviceService.addDevice(device).then(
                (response) => {
                    let deveui = device.deveui.replace(new RegExp("-", 'g'), "");
                    ToastService.showMessage($translate('MSG_DEVICE_ADDED_SUCCESS_BODY', {
                        deveui: formatDeveui(deveui)
                    }), "success");
                    vm.loadDevices();
                },
                (response) => {
                    if (response.status === 412) {
                        ToastService.showMessage($translate('MAX_NUM_REACHED', {
                            item: "devices"
                        }), "error");
                    } else {
                        const msg = `${response.data}`;
                        console.log(msg);
                        ToastService.showNagMessage(msg,
                            "error",
                            "exclamation-sign"
                        );
                        console.log(response.data);
                    }
                }
            );
        }, () => {
            console.log('Modal dismissed at: ' + new Date());
        });
    };


    vm.showBatchEditDialog = () => {
        const devicesToEdit = vm.selectedDevices.map(dev => dev.deveui);
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addDeviceModalContent.html',
            controller: 'AddDeviceModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: constants,
                        owner: vm.user,
                        devices: devicesToEdit,
                        batch: true
                    };
                }
            }
        });

        modalInstance.result.then((device) => {
            device.deveui = devicesToEdit.join(",");
            console.log("Batch edit devices ", devicesToEdit, device);

            DeviceService.updateDevice(device).then((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                    deveui: ""
                }), "success");
                vm.deselectAll();
                vm.loadDevices();
            }).catch((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                    "error"
                );
            });
        }, () => {
            console.log('Modal dismissed at: ' + new Date());
        });
    };

    vm.showPositionDialog = (device) => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'selectPositionOnMapModalContent.html',
            controller: 'SelectPositionOnMapModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        latitude: device.latitude,
                        longitude: device.longitude,
                        altitude: device.altitude
                    };
                }
            }
        });

        modalInstance.result.then((location) => {
            const updateObj = {
                deveui: device.deveui
            };
            updateObj.altitude = location.alt;
            updateObj.latitude = location.lat;
            updateObj.longitude = location.lng;

            DeviceService.updateDevice(updateObj).then((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                    deveui: formatDeveui(device.deveui)
                }), "success");
                vm.loadDevices();
            }).catch((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                    "error"
                );
                response.data && ToastService.showMessage($translate(response.data),
                    "error"
                );
            });

        }, () => {
            console.log('Modal dismissed at: ' + new Date());
        });
    };

    const sendPayloadToDass = (deveui, payload) => {
        const sendobj = {
            deveui: deveui,
            data: btoa(payload.data),
            port: payload.port,
            fcnt: payload.fcnt == null ? undefined : payload.fcnt,
            confirmed: payload.confirmed == null ? undefined : payload.confirmed
        };

        PayloadService.sendDlPayload(sendobj).then(
            (response) => {
                const msg = $translate('MSG_SEND_DL_PACKET_SUCCESS_BODY', {
                    deveui: formatDeveui(deveui),
                    transaction_id: response.data.id
                });
                ToastService.showMessage(msg, "success");
                console.log(msg, response.data);
            },
            (response) => {
                ToastService.showMessage($translate('MSG_SEND_DL_PACKET_FAIL_BODY'), "error");

                console.log(response.data);
            }
        );
    };

    vm.showSendDataDialog = (deveui) => {
        if (deveui == null && !vm.currentDevice || vm.currentDevice === "") {
            MessageService.showMessage({
                title: $translate('MSG_TITLE_OOPS'),
                body: $translate('MSG_SELECT_DEVICE_BODY')
            });
            return;
        }

        if (deveui == null) {
            deveui = vm.currentDevice;
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return [];
                }
            }
        });

        modalInstance.result.then((payload) => {
            sendPayloadToDass(deveui, payload);
        }, () => {
        });
    };

    vm.showEditDialog = (device) => {
        console.log(`Getting ${device.deveui}`);

        DeviceService.getDevice(device.deveui).then(
            (device) => {
                if(device.device_profile_uuid || device.service_profile_uuid){
                    window.deviceModals.showEditModal({device});
                } else {
                    const modalInstance = $uibModal.open({
                        backdrop: "static",
                        animation: true,
                        templateUrl: 'addDeviceModalContent.html',
                        controller: 'AddDeviceModalCtrl',
                        size: "lg",
                        resolve: {
                            items: () => {
                                return {
                                    device,
                                    editMode: true,
                                    constants: constants,
                                    owner: vm.user,
                                };
                            }
                        }
                    });

                    modalInstance.result.then((device) => {
                        if (vm.deviceTags) {  // add the tags to the device which is sent to update
                            device.tags = vm.deviceTags;
                        }

                        DeviceService.updateDevice(device).then((response) => {
                            ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                                deveui: formatDeveui(device.deveui)
                            }), "success");
                            vm.loadDevices();
                        }).catch((response) => {
                            ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                                "error"
                            );
                        });
                    }, () => {
                        console.log('Modal dismissed at: ' + new Date());
                    });
                }
            }, () => {
                vm.loading = false;
                console.log("Error loading devices");
            }
        );
    };

    vm.setCurrentDevice = (deveui, url) => {
        console.log("setting current device " + deveui + " " + url);
        UserService.setCurrentDevice(deveui).then(
            (response) => {
                if (url != null) {
                    console.log("redirecting");
                    window.location.href = url;
                }
            },
            (response) => {
                MessageService.showMessage({
                    title: $translate('MSG_TITLE_OOPS'),
                    body: "Can ???"
                });

                // Refresh user data
                vm.loadUserData();
            }
        );
    };

    vm.batchDeleteDevices = () => {
        console.log("delete");
        MessageService.showMessage({
            title: $translate('MSG_DELETE_DEVICES_TITLE'),
            body: $translate('MSG_DELETE_DEVICES_BODY', {
                count: vm.selectedDevices.length
            })
        }).then((ok) => {
            DeviceService.deleteDevices(vm.selectedDevices).then((response) => {
                ToastService.showMessage($translate('MSG_DELETE_DEVICES_SUCCESS_BODY', vm.selectedDevices), "success");
                vm.loadDevices();
            }).catch((response) => {
                console.log("Failed deleting devices", response);
                ToastService.showMessage($translate('MSG_DELETE_DEVICES_FAIL_BODY', vm.selectedDevices), "error");
            });
            vm.loadDevices();
        }).catch(() => {
            console.log("Failed deleting devices");
        });
    };

    vm.loadDevices = () => {
        if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {
            vm.workingCount += 1;
            reloadPromise.then(() => {
                vm.workingCount -= 1;
            }).catch(err => {
                vm.workingCount -= 1;
            });
        }
    };

    vm.loadGroups = () => {
        console.log("Loading groups");
        vm.workingCount += 1;

        return GroupService.getGroups().then(
            (groups) => {

                vm.groups = groups;
                vm.workingCount -= 1;
            },
            () => {
                console.log("Error loading groups");
                vm.workingCount -= 1;
            }
        );
    };

    vm.loadApps = () => {
        console.log("Loading apps");
        vm.workingCount += 1;

        return AppService.getApps().then(
            (apps) => {

                vm.apps = apps;
                vm.workingCount -= 1;
            },
            (err) => {
                console.log("Error loading apps", err);
                vm.workingCount -= 1;
            }
        );
    };

    vm.open = function () {
		var modalInstance = $uibModal.open({
          backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});
	
		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                vm.loginUserId = userData.loginuserid;

                if(!userData.user.administrator){
					vm.open();
				}
                
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
    };

    vm.actionMenuOpened = (device) => {
        DeviceService.getDevice(device.deveui).then(dev => {
            device.activation = dev.activation;
        });
    }

    vm.deviceDataMapper = (device) => {
        device.last_reception_str = dateString(device.last_reception);
        return device;
    };
    
    vm.editLinkDisable = (device) => {
        var parts = vm.loginUserId.split('/');
        var originallyLoggedInUser = vm.loginUserId;
        var forwarderUser = null;

        if (parts[0]) {
            originallyLoggedInUser = parts[0];
        }

        if (parts[1]) {
            forwarderUser = parts[1];
        }

        device.editLinkDisable = true;
        if(device.ownerid == vm.user.userid) {  // used to check only for the forwarded user, or the initial user if no forwarded user
            device.editLinkDisable = false;
        }

        return device.editLinkDisable;
    };

    vm.signInForward = (userid) => signInForward(UserService, ToastService, userid);
    vm.initDataTable = () => {
        vm.actions = [{
            type: "action",
            text: "MY_DEVICES_ACTION_EDIT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showEditDialog,
            disabled: (entry, device) => vm.editLinkDisable(device)
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_SEND",
            icon: "fa fa-paper-plane-o fa-fw",
            action: (dev) => vm.sendData(dev.deveui),
            disabled: (entry, device) => vm.editLinkDisable(device)
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_DATA",
            icon: "fa fa-database fa-fw",
            action: dev => vm.setCurrentDevice(dev.deveui, '/data_list.html'),
            disabled: (entry, device) => vm.editLinkDisable(device)
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "SET_OWNER_AS_USER",
            icon: "fa fa-arrow-right fa-fw",
            action: (device) => vm.signInForward(device.ownerid)
        }];

        vm.bulkActions = [];

        vm.columns = [{
            key: "deveui",
            type: "text",
            title: "DEVEUI",
            style: {
                minWidth: "230px"
            },
            filterable: true,
            filterField: "search_deveui",
            filterType: "text",
            filterParams: {
                mapper: (function (x) {
                    if (x == undefined) {
                        return x;
                    }

                    return x.replace(new RegExp("-", 'g'), "");
                  }),
                // validation: (field, value) => {
                //     return new Promise((resolve, reject) => {
                //         DataService.getData('/devices.json', { search_deveui: value, get_pages: true }).then(data => {
                //             resolve(data.num_devices > 0);
                //         }).catch(err => {
                //             resolve(false);
                //         });
                //     })
                // }
            },
            sortable: false,
            sortKey: "sort_by_deveui",
            filterSearchMinLength: 4,
            render: (x) => formatDeveui(x.deveui)
        }, {
            key: "device_status",
            title: "STATUS",
            type: "icon_with_tooltip",
            render_icon: statusSymbol,
            render_tooltip: statusTooltip,
            filterable: false,
            filterField: "",
            filterType: "",
            filterParams: {
                mapper: x => x + "!123"
            },
        }, {
            key: "comment",
            title: "COMMENT",
            type: "text",
            filterable: false,
            filterField: "search_comment",
            filterType: "text",
            sortable: false,
            sortKey: "sort_by_comment",
            filterParams: {
                mapper: (x) => x || undefined
                // validation: (field, value) => {
                //     return new Promise((resolve, reject) => {
                //         DataService.getData('/devices.json', { search_comment: value, get_pages: true }).then(data => {
                //             resolve(data.num_devices > 0);
                //         }).catch(err => {
                //             resolve(false);
                //         });
                //     })
                // }
            }
        }, {
            key: "ownerid",
            title: "OWNER",
            type: "text"
        }];
        vm.reloadData();
        
    };

    vm.tableSelectionChanged = (dev) => {
        console.log("Selection changed ", dev);

        vm.selectedDevices = dev;
    };

    vm.reloadData = () => {
        console.log("This should get overwritten");
    };

    vm.loadDevices();
    vm.loadUserData();
    Promise.all([vm.loadGroups(), vm.loadApps()]).then(vm.initDataTable);
};

app.controller("AdminDeviceListController", ["DeviceService", "AppService", "UserService", "GroupService", "MessageService", "ToastService", "PayloadService", "DataService", "$filter", "$uibModal", "$scope", "$rootScope", "$timeout", AdminDeviceListController]);


function AdminUsersController(DeviceService, $filter, $uibModal, $scope, $timeout, UserService, ToastService, GroupService, AppService, MessageService) {

    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = this;
    vm.devices = [];
    vm.users = [];
    vm.user = null;
    vm.workingCount = 0;
    vm.numberOfUsers = 0;
    UserService.getUsers('user').then(
		(user) => {
				vm.numberOfUsers = user.length;
			}
		);
    vm.customFilter = {
        search_all_users: true,
    };

    vm.open = function () {
		var modalInstance = $uibModal.open({
          backdrop: "static",
		  animation: true,
		  ariaLabelledBy: 'modal-title',
		  ariaDescribedBy: 'modal-body',
		  templateUrl: 'customErrorModal.html',
		  controller: 'CustomErrorModalController as vm',
		  size: 'error-modal-class',
		  resolve: {
			items: () => {
				return {
					error: "The User does not have permission to see this page.",
					title: "Error"
				};
			}
		   }
		});
	
		modalInstance.result.then(function () {
			window.location.href = "/app";
		});
	};

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                if(userData.user.customer_admin){
                    vm.customFilter.search_all_customers = true;
                } if(userData.user.administrator){
                    vm.customFilter.search_all_users = true;
                }
                if(userData.user.tenant_admin){
                    vm.customFilter.search_all_tenants = true;
                }
                if(!userData.user.customer_admin && !userData.user.administrator){
                    vm.open();
				}
                
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
        
    };

    vm.showUsers = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'showUsers.html',
            controller: 'DataSelectController',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        dataType: '/users.json',
                        title: "USER_LIST_TITLE",
                        actions: [],
                        bulkActions: [],
                        columns: [{
                            key: "userid",
                            type: "text",
                            title: "USER ID",
                        }, {
                            key: "administrator",
                            title: "Administrator",
                            type: "text",
                            render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "can_register",
                            title: "Registration Rights",
                            type: "text",
                            render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "can_access_gtw_info",
                            title: "CAN_ACCESS_GATEWAY_INFO",
                            type: "text",
                            render: (user) => user.can_access_gtw_info ? $translate("YES") : $translate("NO"),
                        }, {
                            key: "gtw_admin",
                            title: "Gtw_admin",
                            type: "text",
                            render: (x) => x.gtw_admin ? $translate("YES") : $translate("NO"),
                        }],
                        users: vm.users,
                        owner: vm.user,
                    }
                }
            }
        });

        modalInstance.result.then((selectedUsers) => {
            console.log("Outside modal: ", selectedUsers);
        }, () => {
            // vm.loadDevices()
            console.log("Dismissed");
        });

    }



    vm.deleteUser = (userid, type) => {
        type = type === "customer" ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase();

        if (userid.toLowerCase() == vm.user.userid.toLowerCase()) {
            MessageService.showMessage({
                title: "Oops!",
                body: (type === "customer" ? $translate('USER_TYPE_CUSTOMER') + " '" : $translate('USER_TYPE_USER') + " '") + userid + "' cannot delete own account"
            }, (ok) => { });
            return;
        }

        MessageService.showMessage({
            title: $translate('MSG_USER_DELETE_CONFIRM_TITLE', {
                type: type
            }),
            body: $translate('MSG_USER_DELETE_CONFIRM', {
                type: type,
                userid: userid
            })
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending delete request");
                UserService.deleteUser(userid, type).then(response => {
                    ToastService.showMessage($translate('MSG_USER_DELETE_SUCCESS_BODY', {
                        type: type,
                        userid: userid
                    }), "success");
                    vm.reloadData();
                }).catch(err => {
                    ToastService.showMessage($translate('MSG_USER_DELETE_FAIL_BODY', {
                        type: type
                    }), "error");
                });
            }
        });
    };

    vm.reloadData = () => {
        console.log("This function should be overwritten by the component before it gets called");
    };

    vm.signInForward = (userid) => signInForward(UserService, ToastService, userid);


    vm.deleteUser = (userid, type) => {
        const UserType = (type === "customer" ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase());

        if (userid.toLowerCase() == vm.user.userid.toLowerCase()) {
            MessageService.showMessage({
                title: "Oops!",
                body: (type === "customer" ? $translate('USER_TYPE_CUSTOMER') + " '" : $translate('USER_TYPE_USER') + " '") + userid + "' cannot delete own account"
            }, (ok) => { });
            return;
        }

        MessageService.showMessage({
            title: $translate('MSG_USER_DELETE_CONFIRM_TITLE', {
                type: UserType
            }),
            body: $translate('MSG_USER_DELETE_CONFIRM', {
                type: UserType,
                userid: userid
            })
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending delete request");
                UserService.deleteUser(userid, type).then(response => {
                    ToastService.showMessage($translate('MSG_USER_DELETE_SUCCESS_BODY', {
                        type: UserType,
                        userid: userid
                    }), "success");
                    vm.loadUsers(type);
                }).catch(err => {
                    let message = "";
                    switch (err.status) {
                        case 403:
                            message = "MSG_USERS_DELETE_FORBIDDEN_BODY";
                            break;
                        case 404:
                            message = "MSG_USERS_DELETE_NOT_FOUND_BODY";
                            break;
                        default:
                            message = "MSG_USER_DELETE_FAIL_BODY";
                            break;
                    }
                    ToastService.showMessage($translate(message, {
                        type: UserType
                    }), "error");
                });
            }
        });
    };

    vm.addUserOnDass = (user, usertype) => {
        let ut = usertype === "customer" ? $translate('USER_TYPE_CUSTOMER').toLowerCase() : $translate('USER_TYPE_USER').toLowerCase();

        UserService.addUserOnDass(user, usertype).then(response => {
            ToastService.showMessage($translate('MSG_USER_CREATE_SUCCESS_BODY', {
                ut: usertype
            }), "success"
            );
            vm.loadUsers(usertype);
        }).catch(err => {
            ToastService.showMessage($translate('MSG_USER_CREATE_FAIL_BODY', {
                ut: usertype
            }), "error"
            );
        });
    }

    vm.deleteUsers = () => {
        const UserType = (vm.userType === "customer" ? $translate("USER_TYPE_CUSTOMER").toLowerCase() : $translate("USER_TYPE_USER").toLowerCase());
        MessageService.showMessage({
            title: $translate('MSG_USERS_DELETE_CONFIRM_TITLE', {
                type: vm.userType
            }),
            body: $translate('MSG_USERS_DELETE_CONFIRM', {
                count: vm.selectedUsers.length,
                type: UserType
            })
        }).then(async (ok) => {
            console.log("sending delete request");
            try {
                const response = await UserService.deleteUsers(vm.selectedUsers, vm.userType);                
                if (response.length > 0) {
                    let success = 0;
                    response.map((res) => {
                        if (res.status === 200) {
                            success = success + 1;
                        }
                    });
                    if (success > 0) {
                        ToastService.showMessage($translate('MSG_USERS_DELETE_SUCCESS_BODY',
                            {
                                count: vm.selectedUsers.length,
                                type: UserType
                            }
                        ), "success");
                        vm.loadUsers(vm.userType);
                    }
                }
            } catch (err) {
                let message = "";
                switch (err.status) {
                    case 403:
                        message = 'MSG_USERS_DELETE_BULK_FORBIDDEN_BODY';
                        break;
                    case 404:
                        message = 'MSG_USERS_DELETE_BULK_NOT_FOUND_BODY';
                        break;
                    default:
                        message = 'MSG_USERS_DELETE_FAIL_BODY';
                        break;
                }
                ToastService.showMessage($translate(message, {
                        count: vm.selectedUsers.length,
                        types: UserType
                }), "error");
                vm.loadUsers(vm.userType);
            }
        });
    }

    vm.addUser = (user, usertype) => {
		vm.loadUserData();
		setTimeout(() => {
			if (user) {
				UserService.getUser(user.userid, usertype).then(response => {
					let user_ = response;
					const modalInstance = $uibModal.open({
                        backdrop: "static",
						animation: vm.animationsEnabled,
						templateUrl: 'addUserModalContent.html',
						controller: 'AddUserModalCtrl as vm',
						size: "",
						resolve: {
							items: () => {
								return {
									owner: vm.user,
									user: user_,
									usertype: usertype
								};
							}
						}
					});
					modalInstance.result.then((updateduser) => {
						vm.loadUserData();
						vm.loadUsers(usertype);
					}, () => {
						$log.info('Modal dismissed at: ' + new Date());
					});
				})
			} else {
				const modalInstance = $uibModal.open({
                    backdrop: "static",
					animation: vm.animationsEnabled,
					templateUrl: 'addUserModalContent.html',
					controller: 'AddUserModalCtrl as vm',
					size: "",
					resolve: {
						items: () => {
							return {
								owner: vm.user,
								user: user,
								usertype: usertype
							};
						}
					}
				});
				modalInstance.result.then((updateduser) => {
					vm.loadUserData();
					vm.loadUsers(usertype);
				}, () => {
					$log.info('Modal dismissed at: ' + new Date());
				});
			}
		}, 700);
	};
    vm.loadUsers = (type) => {
        if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {
            vm.workingCount += 1;
            reloadPromise.then(() => {
                vm.updateDataType(type);
                vm.type = type;
                vm.workingCount -= 1;
            }).catch(err => {
                vm.workingCount -= 1;
            });
        }
        UserService.getUsers('user').then(
            (user) => {
                    vm.numberOfUsers = user.length;
                }
            );
    };

    vm.dataType = '/users.json';
    vm.initDataTable = () => {

        vm.actions = [{
            type: "action",
            text: "EDIT_USER",
            icon: "fa fa-pencil-square-o fa-fw",
            action: (user) => vm.addUser(user, (user.is_customer ? "customer" : "user"))
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "DELETE_USER",
            icon: "fa fa-trash-o fa-fw",
            action: (user) => vm.deleteUser(user.userid, (user.is_customer ? "customer" : "user"))
        }, {
            type: "action",
            text: "SET_AS_USER",
            icon: "fa fa-arrow-right fa-fw",
            action: (user) => vm.signInForward(user.userid)
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "SET_PARENT_AS_USER",
            icon: "fa fa-arrow-right fa-fw",
            action: (user) => vm.signInForward(user.parentid)
        }];
        vm.bulkActions = [{
            type: "action",
            text: "DELETE_USERS",
            icon: "fa fa-trash-o fa-fw",
            action: vm.deleteUsers
        }];
        vm.columns = [{
            key: "userid",
            type: "text",
            title: "USER_ID",
            filterable: true,
            filterField: 'search_id',
            filterType: 'text',
            filterSearchMinLength: 1,
            filterParams: {
                mapper: (x) => x || undefined
            }
        }, {
            key: "administrator",
            title: "ADMINISTRATOR",
            type: "text",
            filterField: 'search_administrator',
            filterable: true,
            filterType: 'boolean_toggle',
            render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
        }, {
            key: "can_register",
            title: "USER_LIST_REGISTRATION_RIGHTS",
            type: "text",
            filterField: 'search_can_register',
            filterable: true,
            filterType: 'boolean_toggle',
            render: (user) => user.can_register ? $translate("YES") : $translate("NO"),
        }, {
            key: "parentid",
            title: "USER_PARENT",
            type: "text"
        }];
        vm.reloadData();
    };

    vm.tableSelectionChanged = (dev) => {
        console.log("Selection changed ", dev);
        vm.userType = "user"
        vm.selectedUsers = dev;
    };


    vm.loadUserData();
    vm.initDataTable();

}

app.controller("AdminUsersController", ["DeviceService", "$filter", "$uibModal", "$scope", "$timeout", "UserService", "ToastService", "GroupService", "AppService", "MessageService", AdminUsersController]);
