import * as React from "react";
import { Button, Col, Glyphicon, Grid, Row } from "react-bootstrap";
import { toast } from "../../utils/Toaster";
import { LinkedProfileFormModal } from "./../../components/Profiles/LinkedProfileFormModal";
import { ProfileForm as QOSProfileFormModal } from "./../../components/Profiles/ProfileFormModal";
import { ProfilesTable as QOSProfilesTable } from "./../../components/Profiles/ProfilesTable";
import { strings } from "./../../services/Localization";
import { Whoami } from "./../../services/Login";
import {
    AllCustomerList,
    AllUserList,
    DefaultSizePerPage,
 } from "./../../services/Profiles/Profiles";
import {
    AddQOSProfile,
    CreateLinkedQOSProfile,
    DeleteQOSProfile,
    DeleteQOSProfilesBulk,
    GetLinkedQOSProfile,
    GetQOSProfile,
    GetSourceProfiles,
    GetTableData,
    GetTablePagination,
    QOSProfileSchema,
    QOSProfilesList,
    SearchProfileDescription,
    SearchProfileName,
    SearchProfileUUID,
    UnlinkLinkedQOSProfile,
    UpdateLinkedQOSProfile,
    UpdateQOSProfile,
} from "./../../services/Profiles/QoSProfiles";
declare const constants;
import { AlertModal } from "./../../components/Modals/AlertModal";
import { RemoveDuplicateValues } from "./../../controllers/Profiles/RemoveDupliceteValues";

export default class QOSProfiles extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            ActivateLoader: true,
            ConvertToLinkedMode: false,
            CurrentPage: 1,
            DisableTargetProfile: false,
            EditMode: false,
            Filtering: [],
            FormData: {},
            FormSchema: {},
            LinkedFilter: null,
            LoadingData: false,
            LoggedUser: null,
            PageLimit: DefaultSizePerPage,
            PaginationData: {
                pages: [],
                per_page: DefaultSizePerPage,
                total: 1,
            },
            ProfileUUID: undefined,
            ProfilesSourceUser: undefined,
            SharedFilter: null,
            ShowDeleteModal: false,
            ShowLinkedProfileForm: false,
            ShowQOSProfilesForm: false,
            Sorting: {
                sortName: undefined,
                sortOrder: undefined,
            },
            TableData: [],
            ViewMode: false,
        };
    }

    public componentDidMount() {
        if (constants.enable_profiles !== true) {
            window.location.href = "/app";
        } else {
            this.LoggedIn();
        }
    }

    public ToggleModal = () => {
        this.setState({
            ShowQOSProfilesForm: !this.state.ShowQOSProfilesForm,
        });
    }

    public ToggleDeleteModal = () => {
        this.setState({
            ShowDeleteModal: !this.state.ShowDeleteModal,
        });
    }

    public LoggedIn = async () => {
        try {
            const response: any = await Whoami();
            if (response.user) {
                if (!response.user.can_list_qos_profile) {
                    this.setState({
                        ShowAlertModal: true,
                    });
                } else {
                    this.setState({
                        LoggedUser: response.user,
                    });
                }
            } else {
                this.setState({
                    LoggedUser: null,
                });
                window.location.href = "/app/signin";
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.setState({
                LoggedUser: null,
            });
        }
    }

    public QOSProfilesList = async (
        PageState = this.state.PaginationData.pages[0].page_state,
        PageLimit = this.state.PageLimit,
        Sorting = this.state.Sorting,
        Filtering = this.state.Filtering,
        LinkedFilter = this.state.LinkedFilter,
        SharedFilter = this.state.SharedFilter,
    ) => {
        this.setState({
            LoadingTableData: true,
            TableData: [],
        });
        try {
            const response: any = await GetTableData(
                PageState,
                PageLimit,
                Sorting,
                Filtering,
                LinkedFilter,
                SharedFilter,
            );
            if (response.status === 200) {
                this.setState({
                    LoadingData: false,
                    LoadingTableData: false,
                    TableData: await response.json(),
                });
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = strings.ERROR_IN_QUERY;
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.ERROR_UNKNOWN_FILTER_SOURCE;
                        break;
                    case 410:
                        message = strings.ERROR_PAGE_STATE_EXPIRED;
                        break;
                    case 413:
                        message = strings.ERROR_TOO_BIG_QUERY;
                        break;
                    case 416:
                        message = strings.ERROR_PAGE_STATE_REQUIRED;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_DATA;
                        break;
                }
                toast.error(message);
                this.setState({
                    LoadingData: false,
                    LoadingTableData: false,
                });
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.CAN_NOT_GET_DATA);
            this.setState({
                LoadingData: false,
                LoadingTableData: false,
            });
        }
    }

    public LoadingData = () => {
        this.setState({
            LoadingData: true,
        });
        if (this.state.LoggedUser.can_list_qos_profile) {
            this.TablePagination();
        } else {
            this.setState({
                LoadingData: false,
            });
            toast.error(strings.CAN_NOT_SEE_PROFILES);
        }
    }

    public QOSProfileSchema = async (parameters = undefined) => {
        try {
            const data = await QOSProfileSchema(parameters);
            this.setState({
                FormSchema: data,
            });
            if (!this.state.EditMode && !this.state.ViewMode) {
                this.setState({
                    ActivateLoader: false,
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                ShowQOSProfilesForm: false,
            });
            toast.error(strings.CAN_NOT_GET_DATA);
        }
    }

    public AddNewQOSProfile = () => {
        this.setState({
            ActivateLoader: true,
            EditMode: false,
            ShowQOSProfilesForm: true,
            ViewMode: false,
        });
        this.QOSProfileSchema().then(() => {
            this.setState({
                ActivateLoader: false,
            });
        });
    }

    public EditQOSProfile = async (UUID) => {
        this.setState({
            ActivateLoader: true,
            EditMode: true,
            FormData: {},
            FormSchema: {},
            ProfileUUID: UUID,
            ShowQOSProfilesForm: true,
            ViewMode: false,
        });

        try {
            const response: any = await GetQOSProfile(UUID);
            if (response.status === 200) {
                if (this.state.LoggedUser.can_inspect_qos_profile) {
                    this.QOSProfileSchema().then(async () => {
                        if (Object.keys(this.state.FormSchema).length > 0) {
                            this.setState({
                                ActivateLoader: false,
                                FormData: await response.json(),
                            });
                        }
                    });
                } else {
                    this.setState({
                        ActivateLoader: false,
                        FormData: await response.json(),
                    });
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_DATA;
                        break;
                }
                toast.error(message);
                this.ToggleModal();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.ToggleModal();
            toast.error(strings.CAN_NOT_GET_DATA);
        }
    }

    public DeleteQOSProfile = async (ProfileUUID) => {
        try {
            const response: any = await DeleteQOSProfile(ProfileUUID);
            if (response.status === 200) {
                this.ToggleDeleteModal();
                toast.success(strings.PROFILE_DELETED_SUCCESS);
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
                return true;
            } else {
                let message = "";
                switch (response.status) {
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    case 423:
                        message = strings.REMOVE_REFERENCE_BEFORE_DELETE;
                        break;
                    default:
                        message = strings.FAIL_DELETE_PROFILE;
                        break;
                }
                this.ToggleDeleteModal();
                toast.error(message);
                return false;
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            return false;
        }
    }

    public DeleteQOSProfilesBulk = async (ProfilesUUID) => {
        let success = 0;
        let fails = 0;
        try {
            const DeletedProfiles = await DeleteQOSProfilesBulk(ProfilesUUID);
            DeletedProfiles.map((response: any) => {
                if (response.status === 200) {
                    success = success + 1;
                } else {
                    fails = fails + 1;
                }
            });

            this.ToggleDeleteModal();
            if (this.state.LoggedUser.can_list_qos_profile) {
                this.TablePagination();
            } else {
                toast.error(strings.CAN_NOT_SEE_PROFILES);
            }

            if (success > 0 && fails === 0) {
                const SuccessMessage = strings.formatString(
                    strings.SUCCESS_DELETED_PROFILES_MESSAGE,
                    { NumOfProfiles: success },
                );
                toast.success(SuccessMessage);
                return true;
            } else if (fails > 0 && success === 0) {
                const FailMessage = strings.formatString(
                    strings.FAIL_DELETE_PROFILES_MESSAGE,
                    { NumOfProfiles: fails },
                );
                toast.error(FailMessage);
                return false;
            } else {
                const DeletedFailMessage = strings.formatString(
                    strings.SUCCESS_FAIL_DELETE_PROFILES_MESSAGE,
                    { NumOfDelProfiles: success, NumOfSelProfiles: fails + success },
                );
                toast.success(DeletedFailMessage);
                return true;
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            return false;
        }
    }

    public ViewQOSProfile = async (ProfileUUID) => {
        if (this.state.LoggedUser.can_inspect_qos_profile) {
            this.QOSProfileSchema();
        }
        this.setState({
            ActivateLoader: true,
            EditMode: false,
            FormData: {},
            ShowQOSProfilesForm: true,
            ViewMode: true,
        });
        try {
            const response: any = await GetQOSProfile(ProfileUUID);
            if (response.status === 200) {
                this.setState({
                    ActivateLoader: false,
                    FormData: await response.json(),
                });
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_DATA;
                        break;
                }
                toast.error(message);
                this.ToggleModal();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.ToggleModal();
            toast.error(strings.CAN_NOT_GET_DATA);
        }
    }

    public UpdateQOSProfile = async (SchemaData) => {
        this.setState({
            FormData: SchemaData,
        });
        try {
            const response: any = await UpdateQOSProfile(this.state.ProfileUUID, SchemaData);
            if (response.status === 200) {
                this.ToggleModal();
                toast.success(strings.PROFILE_UPDATED_SUCCESS);
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.PROFILE_NOT_UPDATED;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.PROFILE_NOT_UPDATED);
        }
    }

    public AddQOSProfile = async (SchemaData) => {
        this.setState({
            FormData: SchemaData,
        });
        try {
            const response: any = await AddQOSProfile(SchemaData);
            if (response.status === 200) {
                toast.success(strings.PROFILE_CREATED_SUCCESS);
                this.ToggleModal();
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    default:
                        message = strings.PROFILE_NOT_CREATED;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.PROFILE_NOT_CREATED);
        }
    }

    public GetProfilesList = async () => {
        try {
            const QOSProfilesResponse = await QOSProfilesList();
            this.setState({
                TargetProfilesList: QOSProfilesResponse,
            });
        } catch (error) {
            console.log(error);
            toast.error(strings.GETTING_TARGET_PROFILES_LIST_ERROR);
        }
    }

    public CreateLinkedProfile = async (ProfileData) => {
        try {
            const response: any = await CreateLinkedQOSProfile(ProfileData);
            if (response.status === 200) {
                toast.success(strings.PROFILE_CREATED_SUCCESS);
                this.ToggleLinkedProfileModal();
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
            } else {
                let message: any = "";
                const resData = await response.json();
                switch (response.status) {
                    case 400:
                        message = resData;
                        break;
                    case 403:
                        if (resData.match(/^shared_with_all_users can only be set on profile at customer level$/)) {
                            message = strings.formatString(strings.SHARE_PROFILE_ALLOWED_ONLY_FOR_CUSTOMERS_LEVEL, {
                                option: strings.SHARE_PROFILE_CHECKBOX,
                            });
                        } else {
                            message = resData;
                        }
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.PROFILE_NOT_CREATED;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
            toast.error(strings.PROFILE_NOT_CREATED);
        }
    }

    public GetSelectedTargetProfile = async (TargetProfile) => {
        try {
            const SelectedProfileResponse: any =
                await GetQOSProfile(TargetProfile);
            if (SelectedProfileResponse.status === 200) {
                const SelectedProfileData = await SelectedProfileResponse.json();
                this.setState({
                    SelectedTargetProfile: {
                        profile_name: SelectedProfileData.profile_name,
                        profile_uuid: SelectedProfileData.profile_uuid,
                    },
                });
            } else {
                this.setState({
                    SelectedTargetProfile: {
                        profile_name: TargetProfile,
                        profile_uuid: TargetProfile,
                    },
                });
            }
        } catch (error) {
            console.log(error);
            this.setState({
                SelectedTargetProfile: {
                    profile_name: TargetProfile,
                    profile_uuid: TargetProfile,
                },
            });
            toast.error(strings.CAN_NOT_GET_TARGET_PROFILE_NAME);
        }
    }

    public EditLinkedProfile = async (profileUUID) => {
        this.setState({
            ActivateLoader: true,
            ConvertToLinkedMode: false,
            DisableTargetProfile: false,
            EditMode: true,
            FormData: {},
            FormSchema: {},
            ProfileUUID: profileUUID,
            ShowLinkedProfileForm: true,
            ViewMode: false,
        });
        try {
            const response: any = await GetLinkedQOSProfile(profileUUID);
            if (response.status === 200) {
                const LinkedProfileData = await response.json();
                const TargetProfilesList = await this.GetProfilesList();
                const SelectedTargetProfile =
                    await this.GetSelectedTargetProfile(LinkedProfileData.link_to_profile_uuid);

                const AwaitPromises: any = [];

                if (this.state.LoggedUser.administrator || this.state.LoggedUser.customer_admin) {
                    const ProfilesOwnerList = await this.ProfileOwnerList();
                    AwaitPromises.push(
                        ProfilesOwnerList,
                        SelectedTargetProfile,
                        TargetProfilesList,
                    );
                } else {
                    AwaitPromises.push(
                        SelectedTargetProfile,
                        TargetProfilesList,
                    );
                }

                if (LinkedProfileData.can_be_inspected && this.state.LoggedUser.can_inspect_qos_profile) {
                    const ResponseSchema = await QOSProfileSchema();
                    this.setState({
                        FormSchema: ResponseSchema,
                    });
                    AwaitPromises.push(
                        ResponseSchema,
                    );
                }

                Promise.all(AwaitPromises).then( () => {
                    this.setState({
                        ActivateLoader: false,
                        FormData: LinkedProfileData,
                    });
                });
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_DATA;
                        break;
                }
                toast.error(message);
                this.ToggleLinkedProfileModal();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.ToggleLinkedProfileModal();
            toast.error(strings.CAN_NOT_GET_DATA);
        }
    }

    public UpdateLinkedProfile = async (ProfileData) => {
        try {
            const response: any = await UpdateLinkedQOSProfile(this.state.ProfileUUID, ProfileData);
            if (response.status === 200) {
                this.ToggleLinkedProfileModal();
                toast.success(strings.PROFILE_UPDATED_SUCCESS);
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.PROFILE_NOT_UPDATED;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.PROFILE_NOT_UPDATED);
        }
    }

    public ToggleLinkedProfileModal = () => {
        this.setState({
            ProfilesSourceUser: undefined,
            SearchProfilesValue: "",
            ShowLinkedProfileForm: !this.state.ShowLinkedProfileForm,
        });
    }

    public ConvertProfileToLinked = async (ProfileName, ProfileUUID) => {
        this.setState({
            ActivateLoader: true,
            ConvertToLinkedMode: true,
            DisableTargetProfile: true,
            EditMode: false,
            FormData: {},
            SelectedTargetProfile: {
                profile_name: ProfileName,
                profile_uuid: ProfileUUID,
            },
            ShowLinkedProfileForm: true,
            ViewMode: false,
        });

        const ProfileOwnerList = await this.ProfileOwnerList();

        Promise.all([ProfileOwnerList]).then(() => {
            this.setState({
                ActivateLoader: false,
            });
        });
    }

    public UnlinkLinkedProfile = async (ProfileUUID, TargetProfileUUID) => {
        try {
            const response: any = await UnlinkLinkedQOSProfile(ProfileUUID, TargetProfileUUID);
            if (response.status === 200) {
                toast.success(strings.PROFILE_UNLINKED_SUCCESS);
                if (this.state.LoggedUser.can_list_qos_profile) {
                    this.TablePagination();
                } else {
                    toast.error(strings.CAN_NOT_SEE_PROFILES);
                }
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.PROFILE_UNLINK_ERROR;
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.PROFILE_UNLINK_ERROR);
        }
    }

    public GetUserList = async (UserFilter = undefined) => {
        let UserList = [];
        try {
            const response: any = await AllUserList(UserFilter);
            if (response.status === 200) {
                UserList = await response.json();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.ERROR_GETTING_USER_LIST);
        }

        return UserList;
    }

    public GetCustomerList = async (UserFilter = undefined) => {
        let CustomerList = [];
        try {
            const response: any = await AllCustomerList(UserFilter);
            if (response.status === 200) {
                CustomerList = await response.json();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            toast.error(strings.ERROR_GETTING_CUSTOMER_LIST);
        }
        return CustomerList;
    }

    public ProfileOwnerList = async () => {
        try {
            if (this.state.LoggedUser.administrator && this.state.LoggedUser.customer_admin) {
                const GetUsers = await this.GetUserList();
                const GetCustomers = await this.GetCustomerList();

                Promise.all([
                    GetCustomers,
                    GetUsers,
                ]).then(() => {
                    const ProfileOwner = GetUsers.concat(GetCustomers);
                    return ProfileOwner;
                }).then((OwnerList: any) => {
                    const OwnerUsers: any = [];
                    OwnerList.map((user) => {
                        OwnerUsers.push({
                            description: "",
                            profile_name: user.userid,
                            profile_uuid: "",
                        });
                    });
                    this.setState({
                        ProfileOwnerList: OwnerUsers,
                    });
                });
            } else if (this.state.LoggedUser.administrator) {
                this.GetUserList().then((OwnerList: any) => {
                    const OwnerUsers: any = [];
                    OwnerList.map((user) => {
                        OwnerUsers.push({
                            description: "",
                            profile_name: user.userid,
                            profile_uuid: "",
                        });
                    });
                    this.setState({
                        ProfileOwnerList: OwnerUsers,
                    });
                });
            } else if (this.state.LoggedUser.customer_admin) {
                this.GetCustomerList().then((OwnerList: any) => {
                    const OwnerUsers: any = [];
                    OwnerList.map((user) => {
                        OwnerUsers.push({
                            description: "",
                            profile_name: user.userid,
                            profile_uuid: "",
                        });
                    });
                    this.setState({
                        ProfileOwnerList: OwnerUsers,
                    });
                });
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    public GetSourceProfiles = async (SourceUser) => {
        try {
            const response: any = await GetSourceProfiles(SourceUser);
            if (response.status === 200) {
                this.setState({
                    ProfilesSourceUser: SourceUser,
                    TargetProfilesList: await response.json(),
                });
            }
        } catch (error) {
            console.log("Error: ", error);
            toast.error(strings.GETTING_TARGET_PROFILES_LIST_ERROR);
        }
    }

    public ViewLinkedProfile = async (profileUUID) => {
        this.setState({
            ActivateLoader: true,
            ConvertToLinkedMode: false,
            DisableTargetProfile: false,
            EditMode: false,
            FormData: {},
            FormSchema: {},
            ShowLinkedProfileForm: true,
            ViewMode: true,
        });
        try {
            const response: any = await GetLinkedQOSProfile(profileUUID);
            if (response.status === 200) {
                const LinkedProfileData = await response.json();
                const SelectedTargetProfile =
                    await this.GetSelectedTargetProfile(LinkedProfileData.link_to_profile_uuid);

                const AwaitPromises: any = [];

                AwaitPromises.push(
                    SelectedTargetProfile,
                );

                if (LinkedProfileData.can_be_inspected && this.state.LoggedUser.can_inspect_qos_profile) {
                    const ResponseSchema = await QOSProfileSchema();
                    this.setState({
                        FormSchema: ResponseSchema,
                    });
                    AwaitPromises.push(
                        ResponseSchema,
                    );
                }

                Promise.all(AwaitPromises).then( () => {
                    this.setState({
                        ActivateLoader: false,
                        FormData: LinkedProfileData,
                    });
                });
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = await response.json();
                        break;
                    case 403:
                        message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                        break;
                    case 404:
                        message = strings.PROFILE_NOT_FOUND;
                        break;
                    default:
                        message = strings.CAN_NOT_GET_DATA;
                        break;
                }
                toast.error(message);
                this.ToggleLinkedProfileModal();
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            this.ToggleLinkedProfileModal();
            toast.error(strings.CAN_NOT_GET_DATA);
        }
    }

    public TablePagination = async (
        LimitPerPage = this.state.PageLimit,
        CurrentPage = this.state.CurrentPage,
        Sorting = this.state.Sorting,
        Filtering = this.state.Filtering,
        LinkedFilter = this.state.LinkedFilter,
        SharedFilter = this.state.SharedFilter,
    ) => {
        if (this.state.LoggedUser.can_list_qos_profile) {
            this.setState({
                CurrentPage,
                LoadingTableData: true,
                PageLimit: LimitPerPage,
            });
            try {
                const Pagination: any = await GetTablePagination(
                    LimitPerPage,
                    Sorting,
                    Filtering,
                    LinkedFilter,
                    SharedFilter,
                );
                if (Pagination.status === 200) {
                    const PaginationData = await Pagination.json();
                    if (Math.ceil(PaginationData.total / PaginationData.per_page) < CurrentPage) {
                        CurrentPage = Math.ceil(PaginationData.total / PaginationData.per_page);
                        if (CurrentPage < 1) {
                            CurrentPage = 1;
                        }
                    }
                    if (JSON.stringify(Filtering) !== JSON.stringify(this.state.Filtering)) {
                        CurrentPage = 1;
                    }
                    this.setState({
                        CurrentPage,
                        Filtering,
                        LinkedFilter,
                        PaginationData,
                        SharedFilter,
                        Sorting,
                    });
                    if (PaginationData.pages.length > 0) {
                        this.QOSProfilesList(
                            PaginationData.pages[CurrentPage - 1].page_state,
                            LimitPerPage,
                            Sorting,
                            Filtering,
                            LinkedFilter,
                            SharedFilter,
                        );
                    } else {
                        this.setState({
                            LoadingData: false,
                            LoadingTableData: false,
                            TableData: [],
                        });
                    }
                } else {
                    const ResMessage = await Pagination.json();
                    let message = "";
                    switch (Pagination.status) {
                        case 403:
                            message = strings.NO_RIGHTS_FOR_REQUESTED_OPERATION;
                            break;
                        default:
                            message = ResMessage;
                            break;
                    }
                    toast.error(message);
                    this.setState({
                        LoadingData: false,
                        LoadingTableData: false,
                        TableData: [],
                    });
                }
            } catch (error) {
                console.log("Error:", error);
                toast.error(strings.CAN_NOT_GET_DATA);
                this.setState({
                    LoadingData: false,
                    LoadingTableData: false,
                    TableData: [],
                });
            }
        } else {
            toast.error(strings.CAN_NOT_GET_DATA);
            this.setState({
                LoadingData: false,
                LoadingTableData: false,
                TableData: [],
            });
        }
    }

    public SourceProfiles =  (SearchValue) => {
        const SourceUser = this.state.ProfilesSourceUser;
        return new Promise(async (resolve) => {
            if (SearchValue.length > 0) {
                const SourceProfilesArray: any = [];
                try {
                    const SearchProfileNameResponse: any = await SearchProfileName(SearchValue, SourceUser);
                    if (SearchProfileNameResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileNameResponse.json());
                    }
                    const SearchProfileDescriptionResponse: any =
                        await SearchProfileDescription(SearchValue, SourceUser);
                    if (SearchProfileDescriptionResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileDescriptionResponse.json());
                    }
                    const SearchProfileUUIDResponse: any = await SearchProfileUUID(SearchValue, SourceUser);
                    if (SearchProfileUUIDResponse.status === 200) {
                        SourceProfilesArray.push(await SearchProfileUUIDResponse.json());
                    }
                    Promise.all([
                        SearchProfileDescriptionResponse,
                        SearchProfileNameResponse,
                        SearchProfileUUIDResponse,
                    ]).then(() => {
                        const SelectOptions: any = [];
                        SourceProfilesArray.map((SearchResults) => {
                            SearchResults.map((Profiles) => {
                                SelectOptions.push({
                                    description: Profiles.description,
                                    label: Profiles.profile_name,
                                    value: Profiles.profile_uuid,
                                });
                            });
                        });
                        resolve(RemoveDuplicateValues(SelectOptions, "value"));
                    });
                } catch (error) {
                    console.log(error);
                    toast.error(strings.FILTER_PROFILES_ERROR);
                }
            } else {
                try {
                    const DefaultProfilesResponse: any = await GetSourceProfiles(SourceUser);
                    if (DefaultProfilesResponse.status === 200) {
                        const AllProfiles = await DefaultProfilesResponse.json();
                        const DefaultProfiles: any = [];
                        AllProfiles.map((Profile) => {
                            DefaultProfiles.push({
                                description: Profile.description,
                                label: Profile.profile_name,
                                value: Profile.profile_uuid,
                            });
                        });
                        resolve(DefaultProfiles);
                        this.setState({
                            TargetProfilesList: AllProfiles,
                        });
                    }
                } catch (error) {
                    console.log(error);
                    toast.error(strings.GETTING_TARGET_PROFILES_LIST_ERROR);
                }
            }
        });
    }

    public ProfileOwnerListFilter = (SearchValue) => {
        return new Promise(async (resolve) => {
            try {
                if (SearchValue.indexOf(" ") === -1) {
                    if (this.state.Errors !== undefined) {
                        this.setState({
                            Errors: undefined,
                        });
                    }
                    if (this.state.LoggedUser.administrator && this.state.LoggedUser.customer_admin) {
                        const GetUsers = await this.GetUserList(SearchValue);
                        const GetCustomers = await this.GetCustomerList(SearchValue);

                        Promise.all([
                            GetCustomers,
                            GetUsers,
                        ]).then((AllUsersLists: any) => {
                            const OwnerUsers: any = [];
                            AllUsersLists.map((UsersList) => {
                                UsersList.map((user) => {
                                    OwnerUsers.push({
                                        description: "",
                                        label: user.userid,
                                        value: "",
                                    });
                                });
                            });
                            resolve(OwnerUsers);
                        });
                    } else if (this.state.LoggedUser.administrator) {
                        this.GetUserList(SearchValue).then((OwnerList: any) => {
                            const OwnerUsers: any = [];
                            OwnerList.map((user) => {
                                OwnerUsers.push({
                                    description: "",
                                    label: user.userid,
                                    value: "",
                                });
                            });
                            resolve(OwnerUsers);
                        });
                    } else if (this.state.LoggedUser.customer_admin) {
                        this.GetCustomerList(SearchValue).then((OwnerList: any) => {
                            const OwnerUsers: any = [];
                            OwnerList.map((user) => {
                                OwnerUsers.push({
                                    description: "",
                                    label: user.userid,
                                    value: "",
                                });
                            });
                            resolve(OwnerUsers);
                        });
                    }
                } else {
                    if (this.state.Errors === undefined) {
                        toast.error(strings.EMPTY_SPACE_FILTER_ERROR);
                        this.setState({
                            Errors: 1,
                        });
                    }
                }
            } catch (error) {
                console.log("Error: ", error);
            }
        });
    }

    public render() {
        if (constants.enable_profiles !== true) {
            return null;
        } else if (this.state.LoggedUser === null) {
            return(
                <AlertModal
                    ShowModal={this.state.ShowAlertModal}
                    CloseModal={async () => {
                        await this.setState({
                            ShowAlertModal: true,
                        });
                        window.location.href = "/app";
                    }}
                    ModalBody={strings.formatString(strings.NO_RIGHTS_TO_SEE_THIS_PAGE, {
                        profile: strings.PROFILES_QOS_PROFILE.toLowerCase(),
                    })}
                    ConfirmBtn={true}
                    OnConfirm={async () => {
                        await this.setState({
                            ShowAlertModal: false,
                        });
                        window.location.href = "/app";
                    }}
                />
            );
        } else {
            const { LoggedUser } = this.state;
            let textNumberOfItems = strings.QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0;
            if (this.state.PaginationData.total === 0) {
                textNumberOfItems = strings.QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_0;
            }
            if (this.state.PaginationData.total === 1) {
                textNumberOfItems = strings.QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_1;
            }
            if (this.state.PaginationData.total > 1) {
                textNumberOfItems = strings.QOS_PROFILES_NUMBER_OF_ITEMS_IN_TABLE_2;
            }
            return (
                <Grid fluid={true} className="DeviceProfiles">
                    <h2>
                        {strings.QOS_PROFILES_TITLE}
                    </h2>
                    <p>{strings.formatString(textNumberOfItems, {
                        NumberOfItemsInTable: this.state.PaginationData.total}) }
                    </p>
                    <Row className="show-grid ReactPages">
                        <Col sm={5} className="positionRight position_ab">
                            {LoggedUser.can_create_qos_profile &&
                                (
                                    <Button className="black_b_btn" onClick={this.AddNewQOSProfile}>
                                        <Glyphicon glyph="plus" />
                                        {strings.ADD_QOS_PROFILE}
                                    </Button>
                                )
                            }
                            <Button className="black_b_btn" onClick={this.LoadingData}>
                                <Glyphicon
                                    glyph="refresh"
                                    className={this.state.LoadingData ? "spinning" : ""}
                                /> {strings.REFRESH_LIST}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col sm={12}>
                            <QOSProfilesTable
                                TableData={this.state.TableData}
                                EditProfile={this.EditQOSProfile}
                                EditLinkedProfile={this.EditLinkedProfile}
                                ConvertProfileToLinked={this.ConvertProfileToLinked}
                                UnlinkLinkedProfile={this.UnlinkLinkedProfile}
                                DeleteProfile={this.DeleteQOSProfile}
                                DeleteProfilesBulk={this.DeleteQOSProfilesBulk}
                                OpenDeleteModal={this.state.ShowDeleteModal}
                                ToggleDeleteModal={this.ToggleDeleteModal}
                                LoggedUser={LoggedUser}
                                ViewProfile={this.ViewQOSProfile}
                                Profile="QOS"
                                ViewLinkedProfile={this.ViewLinkedProfile}
                                PaginationData={this.state.PaginationData}
                                TablePagination={this.TablePagination}
                                GetTableData={this.QOSProfilesList}
                                LoadingTableData={this.state.LoadingTableData}
                            />
                        </Col>
                    </Row>
                    {this.state.ShowQOSProfilesForm && (
                        <QOSProfileFormModal
                            ShowModal={this.state.ShowQOSProfilesForm}
                            HideModal={this.ToggleModal}
                            FormData={this.state.EditMode || this.state.ViewMode ? this.state.FormData || {} : {}}
                            FormSchema={this.state.FormSchema || {}}
                            UpdateProfile={this.UpdateQOSProfile}
                            AddProfile={this.AddQOSProfile}
                            EditMode={this.state.EditMode}
                            Profile="QOSProfile"
                            ProfileName={strings.QOS_PROFILE_NAME}
                            ViewMode={this.state.ViewMode}
                            LoadingData={this.state.ActivateLoader}
                            UserRights={LoggedUser}
                            GetNewSchema={this.QOSProfileSchema}
                        />
                    )}
                    {this.state.ShowLinkedProfileForm && (
                        <LinkedProfileFormModal
                            ShowModal={this.state.ShowLinkedProfileForm}
                            EditMode={this.state.EditMode}
                            HideModal={this.ToggleLinkedProfileModal}
                            FormData={this.state.FormData}
                            FormSchema={this.state.FormSchema}
                            SourceProfiles={this.state.TargetProfilesList}
                            SelectedProfile={this.state.SelectedTargetProfile}
                            Profile="QOSProfiles"
                            ProfileName={strings.QOS_PROFILE_NAME}
                            UserRights={LoggedUser}
                            LoadingData={this.state.ActivateLoader}
                            CreateProfile={this.CreateLinkedProfile}
                            UpdateProfile={this.UpdateLinkedProfile}
                            DisableTargetProfile={this.state.DisableTargetProfile}
                            ConvertToLinkedMode={this.state.ConvertToLinkedMode}
                            ProfileOwnerList={this.state.ProfileOwnerList}
                            GetSourceProfiles={this.GetSourceProfiles}
                            ViewMode={this.state.ViewMode}
                            FilterProfiles={this.SourceProfiles}
                            FilterUsers={this.ProfileOwnerListFilter}
                        />
                    )}
                </Grid>
            );
        }
    }
}
