export const InstallGateway = (Data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/install_gtw", {
                body: JSON.stringify(Data),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status === 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status === 200) {
                        window.location.href = "/app/signin";
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetGatewaysModels = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/gateway_models", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status === 200) {
                        window.location.href = "/app/signin";
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetGatewaysModelImage = (imageId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/gateway_model_image/" + imageId, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "image/png",
                    "Accept": "Application/base64",
                },
                method: "GET",
            });

            if (response.status === 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status === 200) {
                        window.location.href = "/app/signin";
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
