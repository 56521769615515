// tslint:disable:max-line-length
import * as React from "react";
import {
    Button,
    Col,
    Modal,
    OverlayTrigger,
    Panel,
    PanelGroup,
    Popover,
    Row,
} from "react-bootstrap";
import SelectDropdown from "react-select";
import { toast } from "../../utils/Toaster";

import Dropzone from "react-dropzone";
import { GetDefaultPushConfiguration, UpdateDefaultPushConfiguration } from "../../services/PushConfig";
import { strings } from "./../../services/Localization";
declare const constants;

import "react-toastify/dist/ReactToastify.css";
import "./DefaultPushConfigModal.css";

export class DefaultPushConfigModal extends React.Component<any, any> {

    private dropzoneRefCRT: React.RefObject<any>;
    private dropzoneRefKey: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.state = {
            AccessTokenOptionChecked: false,
            AccessTokenValue: "",
            AuthStringValue: "",
            AuthTypeOptions: [
                { value: "no_auth", label: "None" },
                { value: "basic", label: "Basic" },
                { value: "access_token", label: "Access Token" },
                { value: "custom", label: "Custom" },
            ],
            BasicOptionChecked: false,
            CrtStringValue: "",
            CustomOptionChecked: false,
            DataFormatOptions: [
                { value: "hex", label: "hex" },
                { value: "base64", label: "base64" },
                { value: "decoded_json", label: "decoded_json" },
            ],
            DataStorePayloadOptions: [
                { value: "save:onfail", label: "On failure to push" },
                { value: "save:always", label: "Always" },
                { value: "save:never", label: "Never" },
            ],
            ErrorAccessToken: false,
            ErrorHostName: false,
            ErrorHostPass: false,
            ErrorHostUserName: false,
            ErrorPathPrefix: false,
            ErrorPort: false,
            HostNameValue: "",
            HostPassValue: "",
            HostUserNameValue: "",
            KeyStringValue: "",
            NoAuthChecked: true,
            PassphraseValue: "",
            PathPrefixValue: "",
            PortValue: "",
            SelectedAuthType: { value: "no_auth", label: "None" },
            SelectedDataFormat: { label: "base64", value: "base64" },
            SelectedStorePayload: { label: "On failure to push", value: "save:onfail" },
            SelectedSubscription: [],
            ShowModal: true,
            Subscriptions: null,
            SubscriptionsOptions: [],
            activeAccordionKey: "0",
            collapseIcon: "glyphicon-plus",
        };

        this.dropzoneRefCRT = React.createRef();
        this.dropzoneRefKey = React.createRef();
    }

    public componentDidMount() {
        if (constants.list_push_subscriptions) {
            const subscriptionsLength = constants.list_push_subscriptions.length;
            const subscriptionsArray = [] as any;
            for (let i = 0; i < subscriptionsLength; i++) {
                subscriptionsArray.push({
                    value: constants.list_push_subscriptions[i],
                    label: constants.list_push_subscriptions[i],
                });
            }

            this.setState({
                SubscriptionsOptions: subscriptionsArray,
            });
        }

        this.GetDefaultPushConfigFunc();
    }

    public openDialogCrt = () => {
        // Note that the ref is set async,
        // so it might be null at some point
        if (this.dropzoneRefCRT.current) {
            this.dropzoneRefCRT.current.open();
        }
    }

    public openDialogKey = () => {
        // Note that the ref is set async,
        // so it might be null at some point
        if (this.dropzoneRefKey.current) {
            this.dropzoneRefKey.current.open();
        }
    }

    public closeModal = () => {
        this.setState({ ShowModal: false });
    }

    public UpdateDefaultPushConfigFunc = async () => {
        try {
            let subscriptionsStr = "";

            if (this.state.SelectedSubscription) {
                const selectedSubscriptionsLength = this.state.SelectedSubscription.length;
                if (selectedSubscriptionsLength > 0) {
                    for (let i = 0; i < selectedSubscriptionsLength - 1; i++) {
                        subscriptionsStr += this.state.SelectedSubscription[i].value;
                        subscriptionsStr += ",";
                    }

                    subscriptionsStr += this.state.SelectedSubscription[selectedSubscriptionsLength - 1].value;
                }
            }

            let port = 0;
            let portNumber = 0;
            let hostNameStr = this.state.HostNameValue;
            const parts = this.state.HostNameValue.split(":");
            if (parts.length >= 2) {
                const portStr = parts[parts.length - 1];
                const portParts = portStr.split("/");

                port = portParts;
                if (portParts.length > 0) {
                    port = portParts[0];
                }

                // tslint:disable-next-line:radix
                portNumber = parseInt(port.toString()) || 0;

                hostNameStr = parts[0] + ":" + parts[1];
            }

            const defaultPushConfig = {
                data_format: this.state.SelectedDataFormat.value,
                push_options: this.state.SelectedStorePayload.value,
                host: hostNameStr,
                path_prefix: this.state.PathPrefixValue,
                port: portNumber,
                push_auth_cert: this.state.CrtStringValue,
                push_auth_key: this.state.KeyStringValue,
                push_auth_passphrase: this.state.PassphraseValue,
                push_subscription: subscriptionsStr,
                retry_policy: 0,
            };

            if (this.state.AccessTokenOptionChecked) {
                (defaultPushConfig as any).push_auth_access_token = this.state.AccessTokenValue;
            } else if (this.state.CustomOptionChecked) {
                (defaultPushConfig as any).auth_string = this.state.AuthStringValue;
            } else if (this.state.BasicOptionChecked) {

                (defaultPushConfig as any).push_auth_username = this.state.HostUserNameValue;
                (defaultPushConfig as any).push_auth_password = this.state.HostPassValue;

            } else {
                (defaultPushConfig as any).auth_string = "";
            }

            const response: any = await UpdateDefaultPushConfiguration(defaultPushConfig);

            if (response.status === 200) {
                toast.success(strings.UPDATE_DEFAULT_PUSH_CONFIG_SUCCESS);
                this.setState({
                    ShowModal: false,
                });
            } else {
                const data = await response.json();
                toast.error(data);
            }
        } catch (error) {
            toast.error(strings.ERROR_UPDATING_DEAFULT_PUSH_CONFIG);
        }
    }

    public GetDefaultPushConfigFunc = async () => {
        const response: any = await GetDefaultPushConfiguration();

        if (response.status === 200) {
            const data = await response.json();

            let authStringValue = "";
            let selectedAuthType = { value: "basic", label: "Basic" };
            let accessTokenValue = "";
            let hostUsername = "";
            let hostPass = "";
            let authStr = false;
            let accessToken = false;
            let customAuth = false;
            let NoAuth = false;

            // Decode the autorisation options, both "legacy" (auth_string) and the current ones (push_auth_XX)
            //
            if (data.auth_string) {

                if (data.auth_string.startsWith("Basic")) {
                    const parts = data.auth_string.split(" ");

                    authStr = true;

                    const dec = window.atob(parts[1]);
                    const userNameParts = dec.split(":");

                    hostUsername = userNameParts[0];
                    hostPass = userNameParts[1];

                    selectedAuthType = { value: "basic", label: "Basic" };

                } else if (data.auth_string.startsWith("Bearer")) {
                    accessToken = true;
                    const parts = data.auth_string.split(" ");
                    accessTokenValue = parts[1];
                    selectedAuthType = { value: "access_token", label: "Access Token" };
                } else {
                    customAuth = true;

                    authStringValue = data.auth_string;
                    selectedAuthType = { value: "custom", label: "Custom" };
                }

            } else if (data.push_auth_access_token) {

                accessToken = true;
                accessTokenValue = data.push_auth_access_token;
                selectedAuthType = { value: "access_token", label: "Access Token" };

            } else if (data.push_auth_username || data.push_auth_password) {

                authStr      = true;
                hostUsername = data.push_auth_username || "";
                hostPass     = data.push_auth_password || "";
                selectedAuthType = { value: "basic", label: "Basic" };

            } else {

                NoAuth = true;
                selectedAuthType = { value: "no_auth", label: "None" };
            }

            if (data.push_auth_cert) {
                this.setState({
                    CrtStringValue: data.push_auth_cert,
                });
            }

            if (data.push_auth_cert) {
                this.setState({
                    KeyStringValue: data.push_auth_cert,
                });
            }

            let PushSubscription: any = [];
            if (data.push_subscription && data.push_subscription.includes(",")) {
                PushSubscription = data.push_subscription.split(",").map((option) => {
                    return {value: option, label: option};
                });
            } else if (data.push_subscription && data.push_subscription.length > 0) {
                const subscriptions = data.push_subscription;
                PushSubscription.push({value: subscriptions, label: subscriptions});
            }

            let storePayload = {
                value: "save:onfail",
                label: "On failure to push"
            }
            if (data.push_options == "save:always") {
                storePayload = {
                    value: "save:always",
                    label: "Always"
                };
            } else if (data.push_options == "save:never") {
                storePayload = {
                    value: "save:never",
                    label: "Never",
                };
            }

            this.setState({
                AccessTokenOptionChecked: accessToken,
                AccessTokenValue: accessTokenValue,
                AuthStringValue: authStringValue,
                BasicOptionChecked: authStr,
                CustomOptionChecked: customAuth,
                HostNameValue: data.host + ":" + data.port,
                HostPassValue: hostPass,
                HostUserNameValue: hostUsername,
                NoAuthChecked: NoAuth,
                PathPrefixValue: data.path_prefix,
                SelectedAuthType: selectedAuthType,
                SelectedDataFormat: { value: data.data_format, label: data.data_format },
                SelectedStorePayload: storePayload,
                SelectedSubscription: PushSubscription,
            });
        }
    }

    public b64DecodeUnicode = (str) => {
        const enc = window.btoa(str);

        return enc;
    }

    public checkHostName = (event) => {
        const inputValue = event.target.value;
        let flag = true;
        if (/^(?:(?:http|mqtt|kafka)(s)?:\/\/)+(([{]{2}.+?[}]{2})?(\${[\w.-]+})?[\w.-]*)+(:([0-9]{1,5}))?$/.test(inputValue)) {
            flag = false;
        }

        this.setState({
            ErrorHostName: flag,
            HostNameValue: inputValue,
        });
    }

    public handleSubscriptionChange = (selectedOption) => {
        this.setState({
            SelectedSubscription: selectedOption,
        });
    }

    public handleDataFormatChange = (selectedOption) => {
        this.setState({
            SelectedDataFormat: selectedOption,
        });
    }

    public handleStorePayloadChange = (selectedOption) => {
        this.setState({
            SelectedStorePayload: selectedOption,
        });
    }

    public handleAccessTypeChange = (selectedOption) => {
        this.setState({
            SelectedAuthType: selectedOption,
        });

        if (selectedOption.value === "access_token") {
            this.setState({
                AccessTokenOptionChecked: true,
                AuthStringValue: "",
                BasicOptionChecked: false,
                CustomOptionChecked: false,
                HostPassValue: "",
                HostUserNameValue: "",
                NoAuthChecked: false,
            });
        } else if (selectedOption.value === "basic") {
            this.setState({
                AccessTokenOptionChecked: false,
                AccessTokenValue: "",
                AuthStringValue: "",
                BasicOptionChecked: true,
                CustomOptionChecked: false,
                NoAuthChecked: false,
            });
        } else if (selectedOption.value === "custom") {
            this.setState({
                AccessTokenOptionChecked: false,
                AccessTokenValue: "",
                AuthStringValue: "",
                BasicOptionChecked: false,
                CustomOptionChecked: true,
                HostPassValue: "",
                HostUserNameValue: "",
                NoAuthChecked: false,
            });
        } else if (selectedOption.value === "no_auth") {
            this.setState({
                AccessTokenOptionChecked: false,
                AccessTokenValue: "",
                AuthStringValue: "",
                BasicOptionChecked: false,
                CustomOptionChecked: false,
                HostPassValue: "",
                HostUserNameValue: "",
                NoAuthChecked: true,
            });
        }
        if (selectedOption.value !== "custom") {
            this.setState({
                InvalidBasicAuth: false,
            });
        }
    }

    public handleAccordionSelect = (activeAccordionKey) => {
        let newCollapseIcon = "glyphicon-plus";
        if (this.state.collapseIcon === "glyphicon-plus") {
            newCollapseIcon = "glyphicon-remove";
        }

        this.setState({
            activeAccordionKey,
            collapseIcon: newCollapseIcon,
        });
    }

    public checkPort = (event) => {
        const inputValue = event.target.value;
        this.setState({
            PortValue: inputValue,
        });

        let flag = true;
        if (inputValue > 0  && inputValue <= 65535) {
            flag = false;
        }

        this.setState({
            ErrorPort: flag,
        });
    }

    public checkPathPrefix = (event) => {
        const inputValue = event.target.value;
        this.setState({
            PathPrefixValue: inputValue,
        });
    }

    public checkHostUserPassValue = (event) => {
        const inputValue = event.target.value;
        this.setState({
            HostPassValue: inputValue,
        });
    }

    public checkPassphraseValue = (event) => {
        const inputValue = event.target.value;
        this.setState({
            PassphraseValue: inputValue,
        });
    }

    public checkAccessToken = (event) => {
        const inputValue = event.target.value;
        this.setState({
            AccessTokenValue: inputValue,
        });
    }

    public checkCustomString = (event) => {
        const inputValue = event.target.value;
        let InvalidBasicAuth = false;
        if (inputValue && (inputValue !== "") && inputValue.startsWith("Basic ")) {
            const encodedValue = inputValue.split(" ")[1];
            try {
                const decodedValue = window.atob(encodedValue);
                if (decodedValue && decodedValue.includes(":")) {
                    InvalidBasicAuth = false;
                } else {
                    InvalidBasicAuth = true;
                }
            } catch (error) {
                InvalidBasicAuth = true;
            }
        } else {
            InvalidBasicAuth = false;
        }
        this.setState({
            AuthStringValue: inputValue,
            InvalidBasicAuth,
        });
    }

    public checkCrtString = (event) => {
        const inputValue = event.target.value;
        this.setState({
            CrtStringValue: inputValue,
        });
    }

    public checkKeyString = (event) => {
        const inputValue = event.target.value;
        this.setState({
            KeyStringValue: inputValue,
        });
    }

    public checkHostUserName = (event) => {
        const inputValue = event.target.value;
        this.setState({
            HostUserNameValue: inputValue,
        });

        let flag = true;
        if (/^[a-zA-Z0-9_.@-]{0,40}$/.test(inputValue)) {
            flag = false;
        }

        this.setState({
            ErrorHostUserName: flag,
        });
    }

    public PopOverHover = (content) => {
        return (
            <Popover
                id="PushSettings"
                className="push_settings_popover_new_style"
            >
                {content}
            </Popover>
        );
    }

    public render() {
        const { ErrorHostName, ErrorHostUserName, InvalidBasicAuth } = this.state;
        const that = this;

        return (
            <div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="sm"
                    className="ReactModal default-push-config"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {strings.EDIT_GLOBAL_PUSH_CONFIG}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all">
                        <Row>
                            <Col sm={12} className="padding_0_all">
                                <Row>
                                    <Col sm={12} className="form-horizontal new_style_form white-background">
                                        <div className={this.state.ErrorHostName ? "form-group has-error" : "form-group"}>
                                            <div className="col-sm-12">
                                                <strong className="new_style_font_weight">{strings.HTTP_PUSH_HOSTNAME}:</strong>
                                            </div>
                                            <div className="col-sm-12">
                                                <input type="text"
                                                    className="form-control new_style_focus"
                                                    value={this.state.HostNameValue}
                                                    onChange={this.checkHostName}
                                                    required />
                                                <small className={`help-block new_style_help_block_color font_size_11 ${this.state.ErrorHostName ? "has-error" : "success"}`}>
                                                    {strings.PUSH_HOST_HOSTNAME_HELP}
                                                </small>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} className="form-horizontal new_style_form white-background">
                                        <div className={this.state.ErrorPathPrefix ? "form-group has-error" : "form-group"}>
                                            <div className="col-sm-12">
                                                <strong className="new_style_font_weight">{strings.HTTP_PUSH_PATH_PREFIX}:</strong>
                                            </div>
                                            <div className="col-sm-12">
                                                <input type="text"
                                                    className="form-control new_style_focus"
                                                    value={this.state.PathPrefixValue}
                                                    onChange={this.checkPathPrefix}
                                                    required />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <div className="custom-background">
                            <Row>
                                <Col sm={12} className="form-horizontal new_style_form">
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <strong className="auth-methods">{strings.HTTP_PUSH_PATH_AUTH_METHODS}</strong>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className={`form-horizontal new_style_form PushConfigSelect_Wrapper ${this.state.NoAuthChecked ? "margin_b_15" : ""}`}>
                                    <SelectDropdown
                                        value={this.state.SelectedAuthType}
                                        onChange={this.handleAccessTypeChange}
                                        options={this.state.AuthTypeOptions}
                                        isSearchable={false}
                                        classNamePrefix="PushConfigSelect"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={6} className={`form-horizontal new_style_form ${this.state.BasicOptionChecked ? "basic-enabled" : "basic-disaabled"}`}>
                                    <div className={this.state.ErrorHostUserName ? "form-group has-error" : "form-group"}>
                                        <div className="col-sm-12">
                                            <strong className="new_style_font_weight">{strings.HTTP_PUSH_HOST_USERNAME}</strong>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control new_style_focus"
                                                value={this.state.HostUserNameValue}
                                                onChange={this.checkHostUserName}
                                                disabled={this.state.BasicOptionChecked ? false : true}
                                                required
                                            />
                                            <OverlayTrigger
                                                trigger={["hover", "focus"]}
                                                placement="bottom"
                                                overlay={this.PopOverHover(
                                                    strings.PUSH_HOST_USERNAME_HELP,
                                                )}
                                            >
                                                    <small className={`help-block new_style_help_block_color font_size_11 short-text-with-dots ${this.state.ErrorHostUserName ? "has-error" : "success"}`}>
                                                    {strings.PUSH_HOST_USERNAME_HELP}
                                                </small>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={6} className={`form-horizontal new_style_form ${this.state.BasicOptionChecked ? "basic-enabled" : "basic-disaabled"}`}>
                                    <div className={this.state.ErrorHostPass ? "form-group has-error" : "form-group"}>
                                        <div className="col-sm-12">
                                            <strong className="new_style_font_weight">{strings.HTTP_PUSH_HOST_PASSWORD}:</strong>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="password"
                                                className="form-control new_style_focus"
                                                value={this.state.HostPassValue}
                                                onChange={this.checkHostUserPassValue}
                                                disabled={this.state.BasicOptionChecked ? false : true}
                                                required />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className={`form-horizontal new_style_form ${this.state.AccessTokenOptionChecked ? "token-enabled" : "token-disaabled"}`}>
                                    <div className={this.state.ErrorAccessToken ? "form-group has-error" : "form-group"}>
                                        <div className="col-sm-12">
                                            <strong className="new_style_font_weight">{strings.HTTP_PUSH_PATH_TOKEN_AUTH}:</strong>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control new_style_focus"
                                                value={this.state.AccessTokenValue}
                                                onChange={this.checkAccessToken}
                                                disabled={this.state.AccessTokenOptionChecked ? false : true}
                                                required />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className={`form-horizontal new_style_form ${this.state.CustomOptionChecked ? "custom-auth-enabled" : "custom-auth-disaabled"}`}>
                                    <div className={`form-group ${(InvalidBasicAuth) ? "has-error" : ""}`}>
                                        <div className="col-sm-12">
                                            <strong className="new_style_font_weight">{strings.HTTP_PUSH_PATH_CUSTOM_AUTH}:</strong>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="text"
                                                className="form-control new_style_focus"
                                                value={this.state.AuthStringValue}
                                                onChange={this.checkCustomString}
                                                disabled={this.state.CustomOptionChecked ? false : true}
                                                required />

                                            <small className={`help-block new_style_help_block_color font_size_11 short-text-with-dots ${(ErrorHostUserName || InvalidBasicAuth) ? "has-error" : "success"}`}>
                                                {InvalidBasicAuth ? strings.HTTP_PUSH_PATH_INVALID_BASIC_AUTH : strings.HTTP_PUSH_PATH_CERTIFICATE_DESC}
                                            </small>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <PanelGroup
                            accordion
                            id="accordion-certificate"
                            activeKey={this.state.activeAccordionKey}
                            onSelect={this.handleAccordionSelect}
                        >
                            <Panel eventKey="1">
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <strong className="auth-methods">
                                                    {strings.HTTP_PUSH_PATH_CERTIFICATE_TITLE}
                                                </strong>
                                            </div>

                                            <div className="col-md-2 collapse-container-sign">
                                                <button type="button" className="btn btn-default btn-sm">
                                                    <span className={`glyphicon ${this.state.collapseIcon}`}></span>
                                                </button>
                                            </div>
                                        </div>
                                    </Panel.Title>
                                </Panel.Heading>

                                <Panel.Body collapsible>
                                    <Row className="padding_top">
                                        <Col sm={12} className="form-horizontal new_style_form white-background">
                                            <div className="form-group">
                                                <Row>
                                                    <Col sm={12} className="form-horizontal new_style_form">
                                                        <strong className="auth-methods">{strings.HTTP_PUSH_CRT_FILE}</strong>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} className="form-horizontal new_style_form switch-container">
                                                        <Dropzone ref={this.dropzoneRefCRT} accept=".pem"
                                                            onDrop={async ([file]) => {
                                                                const reader = new FileReader();
                                                                reader.onload = function(e) {
                                                                    if (e && e.target && e.target.result) {
                                                                        that.setState({
                                                                            CrtStringValue: e.target.result,
                                                                        });
                                                                    }
                                                                };

                                                                reader.readAsText(file);
                                                              }}
                                                        >
                                                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                                                let dragDropText = strings.HTTP_PUSH_DRAG_DROP_TEXT;

                                                                if (acceptedFiles.length > 0) {
                                                                    dragDropText = acceptedFiles[0].name;
                                                                }

                                                                return (
                                                                    <section className="crt-file-upload-container">
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <p>
                                                                                {dragDropText}
                                                                            </p>
                                                                        </div>
                                                                    </section>
                                                                ); }
                                                            }
                                                        </Dropzone>

                                                        <textarea name="body"
                                                            className="form-control new_style_focus pem-container"
                                                            spellCheck={false}
                                                            onChange={this.checkCrtString}
                                                            value={this.state.CrtStringValue} />
                                                    </Col>

                                                    <Col sm={1} className="form-horizontal new_style_form switch-container">
                                                        <label className="or-text-container">
                                                            {strings.HTTP_PUSH_OR_TEX}
                                                        </label>
                                                    </Col>

                                                    <Col sm={3} className="form-horizontal new_style_form switch-container btn-container">
                                                        <button
                                                            type="button"
                                                            onClick={this.openDialogCrt}
                                                            className="btn btn-md black_b_btn">
                                                            {strings.HTTP_PUSH_SHOOSE_FILE}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                    {/*  */}
                                    <Row>
                                        <Col sm={12} className="form-horizontal new_style_form white-background">
                                            <div className="form-group">
                                                <Row>
                                                    <Col sm={12} className="form-horizontal new_style_form">
                                                        <strong className="auth-methods">{strings.HTTP_PUSH_KEY_FILE}</strong>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12} className="form-horizontal new_style_form switch-container">
                                                        <Dropzone ref={this.dropzoneRefKey} accept=".pem"
                                                            onDrop={async ([file]) => {
                                                                const reader = new FileReader();
                                                                reader.onload = function(e) {
                                                                    if (e && e.target && e.target.result) {
                                                                        that.setState({
                                                                            KeyStringValue: e.target.result,
                                                                        });
                                                                    }
                                                                };

                                                                reader.readAsText(file);
                                                              }}
                                                        >
                                                            {({getRootProps, getInputProps, acceptedFiles}) => {
                                                                let dragDropText = strings.HTTP_PUSH_DRAG_DROP_TEXT;

                                                                if (acceptedFiles.length > 0) {
                                                                    dragDropText = acceptedFiles[0].name;
                                                                }

                                                                return (
                                                                    <section className="crt-file-upload-container">
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <p>
                                                                                {dragDropText}
                                                                            </p>
                                                                        </div>
                                                                    </section>
                                                                ); }
                                                            }
                                                        </Dropzone>

                                                        <textarea name="body"
                                                            className="form-control new_style_focus pem-container"
                                                            spellCheck={false}
                                                            onChange={this.checkKeyString}
                                                            value={this.state.KeyStringValue} />
                                                    </Col>

                                                    <Col sm={1} className="form-horizontal new_style_form switch-container">
                                                        <label className="or-text-container">
                                                            {strings.HTTP_PUSH_OR_TEX}
                                                        </label>
                                                    </Col>

                                                    <Col sm={3} className="form-horizontal new_style_form switch-container btn-container">
                                                        <button
                                                            type="button"
                                                            onClick={this.openDialogKey}
                                                            className="btn btn-md black_b_btn">
                                                            {strings.HTTP_PUSH_SHOOSE_FILE}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} className="form-horizontal new_style_form white-background">
                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                        <strong className="new_style_font_weight auth-methods">{strings.HTTP_PUSH_PASSPHRASE}</strong>
                                                </div>
                                                <div className="col-sm-12">
                                                    <input type="text"
                                                        className="form-control new_style_focus"
                                                        value={this.state.PassphraseValue}
                                                        onChange={this.checkPassphraseValue}
                                                        required />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Panel.Body>
                            </Panel>
                        </PanelGroup>

                        <div className="custom-background">
                            <Row>
                                <Col sm={12} className="form-horizontal new_style_form">
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <strong className="auth-methods">{strings.HTTP_PUSH_DATA_FORMAT}</strong>
                                        </div>
                                        <div className="col-sm-12 PushConfigSelect_Wrapper">
                                            <SelectDropdown
                                                className="drop-up"
                                                value={this.state.SelectedDataFormat}
                                                onChange={this.handleDataFormatChange}
                                                options={this.state.DataFormatOptions}
                                                classNamePrefix="PushConfigSelect"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="form-horizontal new_style_form">
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <strong className="auth-methods">{strings.HTTP_PUSH_STORE_PAYLOAD}</strong>
                                        </div>
                                        <div className="col-sm-12 PushConfigSelect_Wrapper">
                                            <SelectDropdown
                                                className="drop-up"
                                                value={this.state.SelectedStorePayload}
                                                onChange={this.handleStorePayloadChange}
                                                options={this.state.DataStorePayloadOptions}
                                                classNamePrefix="PushConfigSelect"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="form-horizontal new_style_form">
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <strong className="auth-methods">{strings.HTTP_PUSH_SUBSCRIPTIONS}</strong>
                                        </div>
                                        <div className="col-sm-12 PushConfigSelect_Wrapper">
                                            <SelectDropdown
                                                className="drop-up"
                                                value={this.state.SelectedSubscription}
                                                onChange={this.handleSubscriptionChange}
                                                options={this.state.SubscriptionsOptions}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                                classNamePrefix="PushConfigSelect"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer">
                        <div className="modal_footer_btns">
                            <Button
                                onClick={this.UpdateDefaultPushConfigFunc}
                                className="React_new_style_btn_ok"
                                disabled={(ErrorHostName || ErrorHostUserName || InvalidBasicAuth) ? true : false}
                            >
                                {strings.OK}
                            </Button>
                            <Button onClick={this.closeModal} className="React_new_style_btn_cancel">
                                {strings.CANCEL}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
