import * as React from "react";
import { Button, ButtonGroup, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { getUsersInfo, UsersPagination } from "../../services/Users";
import { strings } from "./../../services/Localization";
export class DataTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            CurrentPage: 1,
            FilterAdministratorRight: null,
            FilterRegistrationRight: null,
            LoadingData: false,
            PageLimit: props.defaultSize,
            PaginationData: {
                pages: [],
                per_page: 3,
                total: 1,
            },
            SearchUserId: null,
            UserType: "users",
            data: [],
            pagination: props.pagination,
            showPages: this.props.pagination.total,
        };
    }

    public async componentWillMount() {
        try {
            if (this.state.pagination.pages.length > 0) {
                const response = await getUsersInfo(
                    this.state.UserType,
                    this.state.pagination.pages[0].page_state,
                    this.state.PageLimit,
                    null,
                    null,
                    null,
                );
                this.setState({ data: response });
            } else {
                this.setState({ data: [] });
            }
        } catch (error) {
            console.log(error);
            this.setState({ data: [] });
        }
    }

    public getUserInfo = async (page) => {
        try {
            const response: any = await getUsersInfo(
                this.state.UserType,
                this.state.pagination.pages[page - 1].page_state,
                this.state.PageLimit,
                this.state.SearchUserId,
                this.state.FilterAdministratorRight,
                this.state.FilterRegistrationRight,
            );
            this.setState({ data: response, showPages: this.state.pagination.total, LoadingData: false });
            console.log(response);
        } catch (error) {
            this.setState({
                LoadingData: false,
            });
            return error;
        }
    }

    public SelectUserType = async (value: any) => {
        this.setState({
            CurrentPage: 1,
            FilterAdministratorRight: null,
            FilterRegistrationRight: null,
            LoadingData: true,
            SearchUserId: null,
            UserType: value,
            data: [],
        });

        try {
            const DataPagination: any = await UsersPagination(
                value,
                this.state.PageLimit,
                null,
                null,
                null,
            );
            this.setState({
                pagination: DataPagination,
                showPages: DataPagination.total,
            });
            await this.getUserInfo(1);
        } catch (error) {
            this.setState({
                LoadingData: false,
            });
            console.log(error);
        }
      }

    public renderPaginationPanel = (props: any) => {
        setTimeout(() => {
            if (props.currPage < 1) {
                props.changePage(1);
            } else if (props.currPage > props.totalPages) {
                props.changePage(props.totalPages);
            }
        }, 100);
        return (
            <div className="row React_marg_11_top change_owner ChangeOwnerTable_Pagination">
                <div className="col-sm-12 col-md-12 padding_0_all">
                    <div className="row">
                        <div className="col-sm-12 padding_0_all">
                            <ToggleButtonGroup
                                type="radio"
                                name="checkUser"
                                value={this.state.UserType}
                                onChange={this.SelectUserType}
                            >
                                {this.props.UserRights.administrator && (
                                    <ToggleButton className="black_b_btn usersGroupActive" value="users">
                                        {strings.USER_LIST_TITLE}
                                    </ToggleButton>
                                )}
                                {this.props.UserRights.customer_admin && (
                                    <ToggleButton className="black_b_btn usersGroupActive" value="customers">
                                        {strings.CUSTOMERS_TITLE}
                                    </ToggleButton>
                                )}
                            </ToggleButtonGroup>
                            <Button
                                className="black_b_btn refreshButton"
                                onClick={this.RefreshTableData}
                            >
                                <i className={
                                    `glyphicon glyphicon-refresh ${(this.state.LoadingData) ? "spinning" : ""}`
                                }></i> {strings.REFRESH_LIST}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 React_per_page_style padding_left padding_0_all">
                            <label>{strings.SHOWING_PER_PAGE} </label>
                            {props.components.sizePerPageDropdown}
                        </div>
                            <div className="col-sm-6 React_pagination_style padding_0_all">
                            {props.components.pageList}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public onPageChange = async (page, props) => {
        try {
            if (props !== this.state.PageLimit) {
                const DataPagination: any = await UsersPagination(
                    this.state.UserType,
                    props,
                    this.state.SearchUserId,
                    this.state.FilterAdministratorRight,
                    this.state.FilterRegistrationRight,
                );
                this.setState({
                    PageLimit: props,
                    pagination: DataPagination,
                });
            }
            await this.setState({
                CurrentPage: page,
                showPages: this.state.pagination.total,
            });
            await this.getUserInfo(page);
        } catch (error) {
            console.log(error);
        }
    }

    public FormatBoolean = (cell: any, row: any) => {
        return cell === true ? strings.YES : strings.NO;
    }

    public onRowSelect = (row) => {
        this.props.changeOwner(row.userid);
    }

    public onFilterChange = async (filterObj) => {
        try {
            if (filterObj.userid) {
                this.setState({
                    SearchUserId: filterObj.userid.value,
                });
                UsersPagination(
                    this.state.UserType,
                    this.state.PageLimit,
                    filterObj.userid.value,
                    this.state.FilterAdministratorRight,
                    this.state.FilterRegistrationRight,
                ).then((PaginationResponse: any) => {
                    if (PaginationResponse) {
                        if (PaginationResponse.pages && (PaginationResponse.pages.length > 0)) {
                            getUsersInfo(
                                this.state.UserType,
                                PaginationResponse.pages[0].page_state,
                                this.state.PageLimit,
                                filterObj.userid.value,
                                this.state.FilterAdministratorRight,
                                this.state.FilterRegistrationRight,
                            ).then((DataResponse: any) => {
                                this.setState({
                                    CurrentPage: 1,
                                    data: DataResponse,
                                    pagination: PaginationResponse,
                                    showPages: PaginationResponse.total,
                                });
                            }).catch((error) => {
                                console.log(error);
                            });
                        } else {
                            this.setState({
                                CurrentPage: 1,
                                data: [],
                                pagination: PaginationResponse,
                                showPages: PaginationResponse.total,
                            });
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                const DataPagination: any = await UsersPagination(
                    this.state.UserType,
                    this.state.PageLimit,
                    null,
                    this.state.FilterAdministratorRight,
                    this.state.FilterRegistrationRight,
                );
                this.setState({pagination: DataPagination, SearchUserId: null});
                await this.getUserInfo(1);
            }
        } catch (error) {
            return error;
        }
    }

    public RefreshTableData = async () => {
        this.setState({
            LoadingData: true,
        });
        try {
            const DataPagination: any = await UsersPagination(
                this.state.UserType,
                this.state.PageLimit,
                this.state.SearchUserId,
                this.state.FilterAdministratorRight,
                this.state.FilterRegistrationRight,
            );
            this.setState({
                pagination: DataPagination,
                showPages: DataPagination.total,
            });
            await this.getUserInfo(this.state.CurrentPage);
        } catch (error) {
            this.setState({
                LoadingData: false,
            });
            console.log(error);
        }
    }

    public FilterRights = async (right, checked) => {
        if (this.state[right] === checked) {
            checked = null;
        }
        await this.setState({
            [right]: checked,
        });
        UsersPagination(
            this.state.UserType,
            this.state.PageLimit,
            this.state.SearchUserId,
            this.state.FilterAdministratorRight,
            this.state.FilterRegistrationRight,
        ).then((PaginationResponse: any) => {
            if (PaginationResponse) {
                if (PaginationResponse.pages && (PaginationResponse.pages.length > 0)) {
                    getUsersInfo(
                        this.state.UserType,
                        PaginationResponse.pages[0].page_state,
                        this.state.PageLimit,
                        this.state.SearchUserId,
                        this.state.FilterAdministratorRight,
                        this.state.FilterRegistrationRight,
                    ).then((DataResponse: any) => {
                        this.setState({
                            CurrentPage: 1,
                            data: DataResponse,
                            pagination: PaginationResponse,
                            showPages: PaginationResponse.total,
                        });
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.setState({
                        CurrentPage: 1,
                        data: [],
                        pagination: PaginationResponse,
                        showPages: PaginationResponse.total,
                    });
                }
            }
        }).catch((error) => {
            console.log(error);
            this.setState({
                CurrentPage: 1,
                data: [],
            });
        });
    }

    public FilterButtons = (right) => {
        return (
            <div className="table_filter_buttons">
                <ButtonGroup>
                    <Button
                        onClick={() => {
                            this.FilterRights(right, true);
                        }}
                        className={(this.state[right] === true) ? "active" : ""}
                    >
                        {strings.YES}
                    </Button>
                    <Button
                        onClick={() => {
                            this.FilterRights(right, false);
                        }}
                        className={(this.state[right] === false) ? "active" : ""}
                    >
                        {strings.NO}
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

    public renderSizePerPageDropDown = (props) => {
        return (
            <select
                className="form-control custom_size_per_page_drop_down"
                defaultValue={props.currSizePerPage}
                onChange={(event) => props.changeSizePerPage(event.target.value)}
            >
                {
                    props.sizePerPageList.map((optionVal, index) => {
                        return (
                            <option
                                key={index}
                                value={optionVal.value}
                            >
                                {optionVal.text}
                            </option>
                        );
                    })
                }
            </select>
        );
    }

    public render() {
        const selectRow: any = {
            mode: this.props.mode,
            onSelect: this.onRowSelect,
        };
        const options: any = {
            alwaysShowAllBtns: true,
            firstPage: strings.FIRST,
            lastPage: strings.LAST,
            nextPage: strings.NEXT,
            noDataText: strings.NO_ITEMS_MATCHING,
            onFilterChange: this.onFilterChange,
            onPageChange: this.onPageChange,
            page: this.state.CurrentPage,
            pageStartIndex: 1,
            paginationPanel: this.renderPaginationPanel,
            paginationPosition: "top",
            paginationSize: 3,
            prePage: strings.PREVIOUS,
            sizePerPage: 3,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            sizePerPageList: [{
                text: "3", value: 3,
            }, {
                text: "10", value: 10,
            }, {
                text: "25", value: 25,
            }, {
                text: "50", value: 50,
            }, {
                text: "100", value: 100,
            }],
            withFirstAndLast: true,
        };
        return (
            <BootstrapTable
                ref="table"
                data={this.state.data}
                striped={true}
                hover={true}
                pagination={true}
                options={options}
                selectRow={selectRow}
                tableContainerClass="ReactPages new_style_dark react_new_style_dark UserTable ChangeOwnerTable"
                remote={true}
                fetchInfo={{ dataTotalSize: (this.state.showPages > 0) ? this.state.showPages : 1 }}>
                <TableHeaderColumn
                    isKey={true}
                    dataField="userid"
                    filter={{
                        defaultValue: this.state.SearchUserId,
                        placeholder: strings.USER_ID,
                        type: "TextFilter",
                        delay: 3000,
                    }}>
                    {strings.USER_ID}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="administrator"
                    dataFormat={this.FormatBoolean}
                >
                    {strings.ADMINISTRATOR} <br/>
                    {this.FilterButtons("FilterAdministratorRight")}
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="can_register"
                    dataFormat={this.FormatBoolean}
                >
                    {strings.USER_LIST_REGISTRATION_RIGHTS} <br/>
                    {this.FilterButtons("FilterRegistrationRight")}
                </TableHeaderColumn>
            </BootstrapTable>
        );
    }
}
