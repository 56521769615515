export const userData = (username, password) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/signin", {
                body: JSON.stringify({
                    password,
                    userid: username,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            }
            else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

const emptyUuid = "00000000-0000-0000-0000-000000000000";


export const Whoami = () => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/whoami", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            const data = await response.json();
            if (data && data.user) {
                data.user.is_tenant = !(!data.user.tenant_id || data.user.tenant_id === emptyUuid);
            }
            resolve(data);
        } catch (e) {
            reject(e);
        }
    });
};

export const SignInForward = (userid) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/signinforward" + (userid ? "?userid=" + userid : ""), {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });
            if (response.status === 200) {
                const data = await response.json();
                resolve(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }
            else {
                reject(response);
            }
        } catch (e) {
            reject(e);
        }
    });
};

export const SignOut = (userid) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/signout", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            resolve(response);
        } catch (e) {
            reject(e);
        }
    });
};
