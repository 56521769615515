var app = angular.module('dassUiModule');


// TODO: See if there's a better way to use these constants
if (constants.isChinese) {
    app.config(['mapScriptServiceProvider', function (provider) {
        provider.setKey('gd0GyxGUxSCoAbmdyQBhyhrZ');
    }]);
}


function MapComponentController($filter, $scope, $element, $attrs, $timeout) {
    var $translate = $filter('translate');
    var vm = this;

    vm.mapCenter = {
        lat: 57.04398622401856,
        lng: 9.976964903354265,
        zoom: 8
    }

    vm.osmOpts = {
        bounds: undefined,
        defaults: {
            tileLayer: 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiaWJvc2hrb3YiLCJhIjoiY2oyMW00eWd0MDAweTM0bXVsa2ZpbjQwcSJ9.qPrClD9XqeQ4bWqNgEW34Q',
            maxZoom: 18,
            path: {
                weight: 10,
                color: '#800000',
                opacity: 1
            }
        },
        layers: {
            baselayers: {
                osm: {
                    name: 'OpenStreetMap',
                    type: 'xyz',
                    url: 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiaWJvc2hrb3YiLCJhIjoiY2oyMW00eWd0MDAweTM0bXVsa2ZpbjQwcSJ9.qPrClD9XqeQ4bWqNgEW34Q',
                    layerOptions: {
                        maxZoom: 18,    
                        attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, ' +
                            '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                            'Imagery © <a href="http://mapbox.com">Mapbox</a>',
                        id: 'mapbox.streets'
                    }
                }
            },
            overlays: {
                devices: {
                    name: '',
                    type: 'markercluster',
                    visible: true
                },
                gateways: {
                    name: '',
                    type: 'markercluster',
                    visible: true
                }
            }
        },
        deviceIcon: {
            type: 'awesomeMarker',
            icon: 'cog',
            markerColor: 'red'
        },
        gatewayIcon: {
            type: 'awesomeMarker',
            icon: 'star',
            prefix: 'fa',
            markerColor: 'blue'
        },
        markers: []
    };

    vm.baiduMarkerOpts = {
        enableDragging: false
    }

    vm.baiduOpts = {
        centerAndZoom: {
            latitude: 57.04398622401856,
            longitude: 9.976964903354265,
            zoom: 13
        },
        enableScrollWheelZoom: true,
        disableDoubleClickZoom: true
    };

    vm.baiduMarkers = [];

    vm.mapShowLabels = false;
    vm.mapShowGateways = true;
    vm.mapShowDevices = true;
    vm.mapDevicesMovable = false;
    vm.useBaidu = constants.isChinese;
    
    if (constants.isChinese) {

        vm.navOpts = {
            anchor: window.BMAP_ANCHOR_TOP_RIGHT
        };

        vm.overOpts = {
            anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT
        };

        vm.baiduLoaded = function (map) {
            vm.baiduMap = map;
            vm.baidumarkers = [];
            window.bmap = map;
            window.debug_ctrl = this;
            window.debug_scope = vm;
            vm.mapSettingUpdated();
            vm.centerMap(vm.center);
        }
    }

    vm.ChangeDraggable = false;
    this.$onChanges = function (changesObj) {
        console.log(vm);
        if (changesObj.center) {
            $timeout(() => {
                console.log("Centering to", vm.center)
                vm.centerMap(vm.center);
                vm.getBoundsOfMarkers();
            }, 100);
        }
        if (changesObj.useBaidu) {
            vm.useOSM = !vm.useBaidu;
        }
        if (changesObj.markersDraggable) {
            if ((changesObj.markersDraggable.currentValue && !changesObj.markersDraggable.previousValue) ||
            (!changesObj.markersDraggable.currentValue && changesObj.markersDraggable.previousValue)) {
                vm.ChangeDraggable = true;
            } else {
                vm.ChangeDraggable = false;
            }
        } else {
            vm.ChangeDraggable = false;
        }
        vm.mapSettingUpdated();
    };

    vm.getBoundsOfMarkers = function () {
        if (vm.useOSM) {
            var bounds = new L.LatLngBounds();
            vm.osmOpts.markers.forEach(marker => {
                if (!marker.lat || !marker.lng) {
                    return;
                }
                bounds.extend(new L.LatLng(marker.lat, marker.lng));
            });

            vm.osmOpts.bounds = bounds;
        }

        if (vm.useOSM || vm.useBaidu) return;
        var bounds = new google.maps.LatLngBounds();
        var dev, i;
        if (vm.mapShowDevices) {
            for (i = 0; i < vm.devices.length; i++) {
                dev = vm.devices[i];
                if (dev != null && dev.latitude != null && dev.longitude != null) {
                    bounds.extend(new google.maps.LatLng(dev.latitude, dev.longitude));
                }
            }
        }

        if (vm.mapShowGateways) {
            for (i = 0; i < vm.gateways.length; i++) {
                gw = vm.gateways[i];
                if (gw != null && gw.latitude != null && gw.longitude != null) {
                    bounds.extend(new google.maps.LatLng(gw.latitude, gw.longitude));
                }
            }
        }
    };

    vm.getMarkerForDevice = function (dev) {
        var target = null;
        if (vm.useOsm) {
            vm.osmOpts.markers.forEach(marker => {
                if (marker.deveui && marker.deveui === dev.deveui) {
                    target = marker;
                    return false;
                }
                return true;
            });
        } else {
            vm.baiduMarkers.forEach(marker => {
                if (marker.stdMarker && marker.stdMarker.deveui === dev.deveui) {
                    target = marker;
                    return false;
                }
                return true;
            });
        }

        return target;
    }

    vm.testRecentering = () => {
        console.log("Test recentering");
        vm.mapCenter.lat = 14;
        vm.mapCenter.lng = 15;
        vm.mapCenter.zoom = 8;
    }

    vm.centerMap = function (obj, zoom = 14) {
        var marker = vm.getMarkerForDevice(obj);
        if (vm.useOSM) {
            vm.mapCenter.lat = obj.lat;
            vm.mapCenter.lng = obj.lng;
            vm.mapCenter.zoom = zoom;
            if (marker) {
                marker.focus = true;
            }
        } else if (vm.useBaidu) {
            if (vm.baiduMap) {
                vm.baiduMap.panTo(new BMap.Point(obj.lng, obj.lat));
            }
            if (marker) {
                marker.showMessage();
            }
        }
    }

    vm.mapcenter = function (obj) {
        var i;

        if (obj.latitude != null && obj.longitude != null) {
            vm.centerMap(obj);
        } else {

            // showMessage({
            //     title: $translate('MSG_NO_LOCATION_TITLE'),
            //     body: $translate('MSG_NO_LOCATION_BODY', obj)
            // }, function (ok) {
            //     // if (ok == "ok") {
            //     // 	obj.latitude = vm.map.center.latitude;
            //     // 	obj.longitude = vm.map.center.longitude;

            //     // 	obj.options.draggable = true;
            //     // 	obj.options.animation = google.maps.Animation.DROP;
            //     // }
            // });
        }
    };

    vm.leafletEvents = { // or just {} //all events
        markers: {
            enable: ['dragend', 'remove'],
            logic: 'emit'
        }
    }

    $scope.$on("leafletDirectiveMarker.remove", function (event, args) {
        if (vm.markersDraggable && vm.ChangeDraggable && vm.newMarkers) {
            vm.osmOpts.markers = vm.newMarkers;
        }
    });

    $scope.$on("leafletDirectiveMarker.dragend", function (event, args) {
        const marker = args.model;
        if (args.leafletObject && args.leafletObject._popup && args.leafletObject._popup._content) {
            const deviceID = args.leafletObject._popup._content;
            marker.message = deviceID;
            marker.deveui = deviceID.replace(/-/g,'');
        }
        if (args.leafletObject && args.leafletObject.options && args.leafletObject.options.icon && args.leafletObject.options.icon.options && args.leafletObject.options.icon.options.icon) {
            if (args.leafletObject.options.icon.options.icon === "cog") {
                marker.layer = "devices";
            } else if (args.leafletObject.options.icon.options.icon === "star") {
                marker.layer = "gateways";
            }
        }
        vm.emitDragEnd(marker);
    });

    vm.emitDragEnd = function(marker) {
        if (vm.onDragEnd) {
            console.log("Emitting drag end");
            vm.onDragEnd({marker});
        }        
    }


    vm.mapSettingUpdated = function () {
        vm.useOSM = !vm.useBaidu;

        if (vm.useOSM) {
            if (vm.markersDraggable && vm.ChangeDraggable) {
                vm.osmOpts.markers = [];
                vm.newMarkers = vm.markers.map(function (marker) {
                    marker.icon = (marker.layer === "devices") ? vm.osmOpts.deviceIcon : vm.osmOpts.gatewayIcon;
                    marker.draggable = vm.markersDraggable;
                    return marker;
                });
            } else {
                vm.osmOpts.markers = vm.markers.map(function (marker) {
                    marker.icon = (marker.layer === "devices") ? vm.osmOpts.deviceIcon : vm.osmOpts.gatewayIcon;
                    marker.draggable = vm.markersDraggable;
                    return marker;
                });
            }

            // vm.osmOpts.layers.overlays.devices.visible = vm.mapShowDevices;
            // vm.osmOpts.layers.overlays.gateways.visible = vm.mapShowGateways;
        }

        $scope.markers = vm.markers;
        if (vm.useBaidu) {
            if (vm.markerClusterer) {
                vm.markerClusterer.removeMarkers(vm.baiduMarkers);
            }
            vm.baiduMarkers = vm.markers.map(stdMarker => {
                var pt = new BMap.Point(stdMarker.lng, stdMarker.lat);
                var marker = new BMap.Marker(pt, {
                    enableDragging: vm.markersDraggable
                });
                marker.stdMarker = stdMarker;

                marker.showMessage = function () {
                    if (!stdMarker.message) {
                        return;
                    }
                    var infoWin = new BMap.InfoWindow(stdMarker.message);
                    this.openInfoWindow(infoWin);
                }.bind(marker);

                marker.addEventListener("click", function () {
                    var strXY = this.getPosition().lng.toFixed(3) + ", " + this.getPosition().lat.toFixed(3);
                    marker.showMessage();
                });

                marker.addEventListener("dragend", function(event) {
                    console.log(this);
                    var pos = this.getPosition();
                    stdMarker.lat = pos.lat;
                    stdMarker.lng = pos.lng;
                    vm.emitDragEnd(stdMarker);
                })
                return marker;
            });

            if (vm.baiduMap) {
                window.markerClusterer = vm.markerClusterer = new BMapLib.MarkerClusterer(vm.baiduMap, {
                    markers: vm.baiduMarkers
                });
                vm.markerClusterer.addMarkers(vm.baiduMarkers);
            }
        }
    };
}

app.component('mapComponent', {
    templateUrl: 'map-component.html',
    controller: ['$filter', '$scope', '$element', '$attrs', '$timeout', MapComponentController],
    controllerAs: "vm",
    bindings: {
        markers: '<',
        markersDraggable: '<',
        center: '<',
        useBaidu: '<',
        onDragEnd: '&',
    }
});
