declare const constants;

export const ChannelProfilesList = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profiles-list", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            const data = await response.json();
            resolve(data);
        } catch (e) {
            reject(e);
        }
    });
};

export const AllChannelProfiles = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profiles-all", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const ChannelProfileSchema = (Parameters = undefined) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-schema", {
                body: JSON.stringify({
                    Parameters,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const EditChannelProfile = (ProfileUUID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profiles-schema-edit", {
                body: JSON.stringify({
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetChannelProfile = (ProfileUUID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-get", {
                body: JSON.stringify({
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateChannelProfile = (ProfileUUID, ProfileData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-update", {
                body: JSON.stringify({
                    Data: ProfileData,
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const AddChannelProfile = (Data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-add", {
                body: JSON.stringify({
                    ProfileData: Data,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const DeleteChannelProfile = (UUID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-delete", {
                body: JSON.stringify({
                    ProfileUUID: UUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "DELETE",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const DeleteChannelProfilesBulk = async (ProfilesUUID) => {
    const promises: any = [];
    const AllPromises: any = [];
    let MaximumRequests = 10;
    if (constants.max_concurrent_requests) {
        MaximumRequests = constants.max_concurrent_requests;
    }
    ProfilesUUID.map(async (Profile) => {
        const promise = DeleteChannelProfile(Profile.profile_uuid);
        AllPromises.push(promise);
        promises.push(promise);
        if (promises.length >= MaximumRequests) {
            promises.length = 0;
            await Promise.all(promises);
        }
    });
    await Promise.all(promises);
    return await Promise.all(AllPromises);
};

export const CreateLinkedChannelProfile = (Data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-linked-add", {
                body: JSON.stringify({
                    ProfileData: Data,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLinkedChannelProfile = (ProfileUUID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-linked-get", {
                body: JSON.stringify({
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UpdateLinkedChannelProfile = (ProfileUUID, ProfileData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-linked-update", {
                body: JSON.stringify({
                    Data: ProfileData,
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const UnlinkLinkedChannelProfile = (ProfileUUID, TargetProfileUUID) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-unlink", {
                body: JSON.stringify({
                    Data: {
                        copy_from_profile_uuid: TargetProfileUUID,
                    },
                    ProfileUUID,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetSourceProfiles = (SourceUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profile-source-profiles", {
                body: JSON.stringify({
                    UserId: SourceUser,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetTablePagination = (LimitPerPage, Sorting, Filtering, LinkedFilter, SharedFilter) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profiles-table-pagination", {
                body: JSON.stringify({
                    LimitPerPage,
                    LinkedFilter,
                    SharedFilter,
                    colsFiltering: Filtering,
                    sortValues: Sorting,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetTableData = (PageState, LimitPerPage, Sorting, Filtering, LinkedFilter, SharedFilter) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/channel-profiles-table-data", {
                body: JSON.stringify({
                    LimitPerPage,
                    LinkedFilter,
                    PageState,
                    SharedFilter,
                    colsFiltering: Filtering,
                    sortValues: Sorting,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchProfileName = (SearchValue, SourceUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/search-channel-profile-name", {
                body: JSON.stringify({
                    ProfileName: SearchValue,
                    SourceUser,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchProfileDescription = (SearchValue, SourceUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/search-channel-profile-description", {
                body: JSON.stringify({
                    ProfileDescription: SearchValue,
                    SourceUser,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const SearchProfileUUID = (SearchValue, SourceUser) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/search-channel-profile-uuid", {
                body: JSON.stringify({
                    ProfileUUID: SearchValue,
                    SourceUser,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
