import * as React from "react";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    DropdownButton,
    MenuItem,
    Modal,
} from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { DefaultSizePerPage } from "../../services/Profiles/Profiles";
import { FilterText } from "./../../controllers/Profiles/ProfileTableController";
import { strings } from "./../../services/Localization";
import CodeEditorModal from "./CodeEditorModal";

const WindowWidth = window.matchMedia("(max-width: 767px)");

export class ProfilesTable extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            ActivateBulkAction: false,
            ActivateSingleAction: false,
            ActivateUnlinkAction: false,
            BulkAction: 0,
            CountClicks: 0,
            CurrentPage: 1,
            FilterColumns: {},
            FilterLinked: null,
            FilterShared: null,
            LinkToProfileUUID: undefined,
            OpenCodeEditorModal: false,
            PaginationSize: 5,
            ProfileName: "",
            ProfileUUID: undefined,
            SelectedProfiles: [],
            SizePerPage: DefaultSizePerPage,
            UnlinkProfileName: "",
            sortName: undefined,
            sortOrder: undefined,
        };
    }

    public SizePerPageForMobile = (width) => {
        if (width.matches) {
            this.setState({
                PaginationSize: 3,
            });
        } else {
            this.setState({
                PaginationSize: 5,
            });
        }
    }

    public componentDidMount() {
        this.SizePerPageForMobile(WindowWidth);
        WindowWidth.addListener(this.SizePerPageForMobile);
        this.props.TablePagination();
    }

    public componentWillUnmount() {
        WindowWidth.removeListener(this.SizePerPageForMobile);
    }

    public renderPaginationPanel = (props: any) => {
        setTimeout(() => {
            if (props.currPage < 1) {
                props.changePage(1);
            } else if (props.currPage > props.totalPages) {
                props.changePage(props.totalPages);
            }
        }, 100);
        return (
            <div className="container-fluid ProfilesTablePagination">
                <div className="row React_marg_11_top">
                    <div className="col-sm-2 col-sm-offset-5 React_per_page_style">
                        <span>{strings.SHOWING_PER_PAGE} </span>
                        {props.components.sizePerPageDropdown}
                    </div>
                    <div className="col-sm-5={true}" id="React_pagination_style">{props.components.pageList}</div>
                </div>
            </div>
        );
    }

    public ActionButton = (cell: any, row: any) => {
        const EditPayloadDecoder = (this.props.Profile === "Device" && !row.is_shared && !row.link_to_profile_uuid &&
            // tslint:disable-next-line:no-bitwise
            ((this.props.LoggedUser._license & 0x40) !== 0) && this.props.LoggedUser.can_create_device_profile);
        const CanCreateProfiles = (this.props.LoggedUser.can_create_device_profile &&
            this.props.Profile === "Device" && !row.is_shared) ||
            (this.props.LoggedUser.can_create_service_profile && this.props.Profile === "Service" && !row.is_shared) ||
            (this.props.LoggedUser.can_create_roaming_profile && this.props.Profile === "Roaming" && !row.is_shared) ||
            (this.props.LoggedUser.can_create_connectivity_profile &&
            this.props.Profile === "Connectivity" && !row.is_shared) ||
            (this.props.LoggedUser.can_create_qos_profile && this.props.Profile === "QOS" && !row.is_shared) ||
            (this.props.LoggedUser.can_create_channel_profile && this.props.Profile === "Channel" && !row.is_shared);
        const CanLinkProfiles = (this.props.Profile === "Device" && this.props.LoggedUser.can_link_device_profile &&
            !row.shared_with_all_users && !row.link_to_profile_uuid) ||
            (this.props.Profile === "Service" && this.props.LoggedUser.can_link_service_profile &&
            !row.shared_with_all_users && !row.link_to_profile_uuid) ||
            (this.props.Profile === "Roaming" && this.props.LoggedUser.can_link_roaming_profile &&
            !row.shared_with_all_users && !row.link_to_profile_uuid) ||
            (this.props.Profile === "Connectivity" && this.props.LoggedUser.can_link_connectivity_profile &&
            !row.shared_with_all_users && !row.link_to_profile_uuid);
        const CanViewProfile = (this.props.LoggedUser.can_list_device_profile &&
            !this.props.LoggedUser.can_create_device_profile &&
            this.props.Profile === "Device" || row.is_shared) ||
            (this.props.LoggedUser.can_list_service_profile &&
            !this.props.LoggedUser.can_create_service_profile &&
            this.props.Profile === "Service" || row.is_shared) ||
            (this.props.LoggedUser.can_list_roaming_profile &&
            !this.props.LoggedUser.can_create_roaming_profile &&
            this.props.Profile === "Roaming" || row.is_shared) ||
            (this.props.LoggedUser.can_list_connectivity_profile &&
            !this.props.LoggedUser.can_create_connectivity_profile &&
            this.props.Profile === "Connectivity" || row.is_shared) ||
            (this.props.LoggedUser.can_list_qos_profile &&
            !this.props.LoggedUser.can_create_qos_profile &&
            this.props.Profile === "QOS" || row.is_shared) ||
            (this.props.LoggedUser.can_list_channel_profile &&
            !this.props.LoggedUser.can_create_channel_profile &&
            this.props.Profile === "Channel" || row.is_shared);
        const CanDeleteProfile = (this.props.LoggedUser[`can_list_${this.props.Profile.toLowerCase()}_profile`] && !row.is_shared && (
                (!row.link_to_profile_uuid && this.props.LoggedUser[`can_create_${this.props.Profile.toLowerCase()}_profile`]) ||
                (row.link_to_profile_uuid  && this.props.LoggedUser[`can_link_${this.props.Profile.toLowerCase()}_profile`])));

        return (
            <ButtonToolbar
                className="align_center React_action_button"
            >
                <DropdownButton
                    bsSize="xsmall"
                    title={strings.ACTION}
                    id="ActionDropdown"
                    pullRight={true}
                    className="React_btn_bg_transparent"
                >
                    {CanViewProfile && (
                        <MenuItem
                            eventKey="3"
                            onClick={() => {
                                if (row.link_to_profile_uuid) {
                                    this.props.ViewLinkedProfile(row.profile_uuid);
                                } else {
                                    this.props.ViewProfile(row.profile_uuid);
                                }
                            }}
                        >
                            <i className="fa fa-eye fa-fw icon_style"></i>
                            {strings.PROFILES_VIEW_PROFILE}
                        </MenuItem>
                    )}
                    {CanCreateProfiles && (
                        <div>
                            <MenuItem
                                eventKey="1"
                                onClick={() => {
                                    if (row.link_to_profile_uuid) {
                                        this.props.EditLinkedProfile(row.profile_uuid);
                                    } else {
                                        this.props.EditProfile(row.profile_uuid);
                                    }
                                }}
                            >
                                <i className="fa fa-pencil-square-o fa-fw icon_style"></i>
                                {strings.PROFILE_EDIT_ACTION}
                            </MenuItem>
                            {(row.can_be_inspected && ((this.props.Profile !== "QOS") &&
                            (this.props.Profile !== "Channel"))) && (
                                <MenuItem
                                    eventKey="5"
                                    onClick={() => {
                                        this.setState({
                                            ActivateBulkAction: false,
                                            ActivateSingleAction: false,
                                            ActivateUnlinkAction: true,
                                            LinkToProfileUUID: row.link_to_profile_uuid,
                                            ProfileUUID: row.profile_uuid,
                                            UnlinkProfileName: row.profile_name,
                                        });
                                        this.props.ToggleDeleteModal();
                                    }}
                                >
                                    <i className="fa fa-unlink icon_style"></i>
                                    {strings.UNLINK_LINKED_PROFILE_ACTION}
                                </MenuItem>
                            )}
                            {CanLinkProfiles && (
                                <MenuItem
                                    eventKey="4"
                                    onClick={() => {
                                        this.props.ConvertProfileToLinked(row.profile_name, row.profile_uuid);
                                    }}
                                >
                                    <i className="fa fa-link icon_style"></i>
                                    {strings.CREATE_LINKED_PROFILE_ACTION}
                                </MenuItem>
                            )}
                        </div>
                    )}
                    {EditPayloadDecoder && (
                        <MenuItem
                            eventKey="5"
                            onClick={() => this.ToggleCodeEditorModal(
                                row.profile_uuid || "",
                            )}
                        >
                            <img
                                src={"/theme/images/code-editor.png"}
                                className="table-action-img"
                                alt=""
                            />
                            {strings.DEVICE_PROFILES_TABLE_ACTION_EDIT_PAYLOAD_DECODER}
                        </MenuItem>
                    )}
                    {CanDeleteProfile && (
                        <div>
                            <MenuItem divider={true} />
                            <MenuItem
                                eventKey="2"
                                onClick={() => {
                                    this.props.ToggleDeleteModal();
                                    this.setState({
                                        ActivateBulkAction: false,
                                        ActivateSingleAction: true,
                                        ActivateUnlinkAction: false,
                                        ProfileName: row.profile_name,
                                        ProfileUUID: row.profile_uuid,
                                    });
                                }}
                            >
                                <i className="fa fa-trash-o fa-fw icon_style"></i>
                                {strings.PROFILE_DELETE_ACTION}
                            </MenuItem>
                        </div>
                    )}
                </DropdownButton>
            </ButtonToolbar>
        );
    }

    public onRowSelect = (row: any, isSelected: any) => {
        if (isSelected) {
            this.setState({
                BulkAction: this.state.BulkAction + 1,
                SelectedProfiles: [...this.state.SelectedProfiles, row],
            });
        } else {
            const index = this.state.SelectedProfiles.indexOf(row);
            this.state.SelectedProfiles.splice(index, 1);
            this.setState({
                BulkAction: this.state.BulkAction - 1,
                SelectedProfiles: this.state.SelectedProfiles,
            });
        }
    }

    public onSelectAll = (isSelected: any, currentDisplayAndSelectedData: any) => {
        if (isSelected) {
            this.setState({
                BulkAction: currentDisplayAndSelectedData.length,
                SelectedProfiles: currentDisplayAndSelectedData,
            });
        } else {
            this.setState({
                BulkAction: 0,
                SelectedProfiles: [],
            });
        }
    }

    public LinkedFilter = async (checked) => {
        if (this.state.FilterLinked === checked) {
            checked = null;
        }
        this.setState({
            FilterLinked: checked,
        });
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions();
        this.props.TablePagination(
            this.state.SizePerPage,
            this.state.CurrentPage,
            Sorting,
            FilterValues,
            checked,
        );
    }

    public FilterOptions = (FilterColumns = this.state.FilterColumns) => {
        const FilterValues: any = [];
        if (Object.keys(FilterColumns).length > 0) {
            Object.keys(FilterColumns).map((filter: any) => {
                const SetFilterValue: any = {};
                if (filter === "profile_name") {
                    SetFilterValue.colName = "name";
                    SetFilterValue.colValue = FilterColumns[filter].value;
                } else if (filter === "profile_uuid") {
                    SetFilterValue.colName = "uuid";
                    SetFilterValue.colValue = FilterColumns[filter].value;
                } else {
                    SetFilterValue.colName = filter;
                    SetFilterValue.colValue = FilterColumns[filter].value;
                }
                FilterValues.push(SetFilterValue);
            });
        }
        return FilterValues;
    }

    public SortingOptions = (sortName = this.state.sortName, sortOrder = this.state.sortOrder) => {
        if (sortName === "profile_name") {
            sortName = "name";
        } else if (sortName === "profile_uuid") {
            sortName = "uuid";
        }
        const Sorting = {
            sortName,
            sortOrder,
        };
        return Sorting;
    }

    public onPageChange = async (page, sizePerPage) => {
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions();
        if ((page > 0) && (page <= this.props.PaginationData.pages.length) &&
            (this.state.SizePerPage === sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });

            this.props.GetTableData(
                this.props.PaginationData.pages[page - 1].page_state,
                sizePerPage,
                Sorting,
                FilterValues,
                this.state.FilterLinked,
            );

        } else if ((page > 0) && (page <= this.props.PaginationData.pages.length) &&
            (this.state.SizePerPage !== sizePerPage)) {

            this.setState({
                CurrentPage: page,
                SizePerPage: sizePerPage,
            });
            this.props.TablePagination(
                sizePerPage,
                page,
                Sorting,
                FilterValues,
                this.state.FilterLinked,
            );
        }
    }

    public onSortChange = async (sortName, sortOrder) => {
        const Sorting = await this.SortingOptions(sortName, sortOrder);
        const FilterValues = await this.FilterOptions();
        let CountClicks = this.state.CountClicks;
        if (this.state.sortName === sortName) {
            CountClicks = CountClicks + 1;
        } else {
            CountClicks = 0;
        }
        this.setState({
            CountClicks,
            sortName,
            sortOrder,
        });
        if (CountClicks === 2) {
            Sorting.sortName = undefined,
            Sorting.sortOrder = undefined,
            this.setState({
                CountClicks: 0,
                sortName: undefined,
                sortOrder: undefined,
            });
            this.props.TablePagination(
                this.state.SizePerPage,
                this.state.CurrentPage,
                Sorting,
                FilterValues,
                this.state.LinkedFilter,
            );
        } else {
            this.props.TablePagination(
                this.state.SizePerPage,
                this.state.CurrentPage,
                Sorting,
                FilterValues,
                this.state.LinkedFilter,
            );
        }
    }

    public onFilterChange = async (FilterObj) => {
        this.setState({
            FilterColumns: FilterObj,
        });
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions(FilterObj);
        this.props.TablePagination(
            this.state.SizePerPage,
            this.state.CurrentPage,
            Sorting,
            FilterValues,
            this.state.LinkedFilter,
        );
    }

    public SharedFormatData = (cell: any, row: any) => {
        if (row.shared_with_all_users) {
            return row.shared_with_all_users === true ? strings.YES : strings.NO;
        } else {
            return row.is_shared === true ? strings.YES : strings.NO;
        }
    }

    public SharedFilter = async (checked) => {
        if (this.state.FilterShared === checked) {
            checked = null;
        }
        this.setState({
            FilterShared: checked,
        });
        const Sorting = await this.SortingOptions();
        const FilterValues = await this.FilterOptions();
        this.props.TablePagination(
            this.state.SizePerPage,
            this.state.CurrentPage,
            Sorting,
            FilterValues,
            this.state.FilterLinked,
            checked,
        );
    }

    public renderSizePerPageDropDown = (props) => {
        return (
            <select
                className="form-control custom_size_per_page_drop_down"
                defaultValue={props.currSizePerPage}
                onChange={(event) => props.changeSizePerPage(event.target.value)}
            >
                {
                    props.sizePerPageList.map((optionVal, index) => {
                        return (
                            <option
                                key={index}
                                value={optionVal.value}
                            >
                                {optionVal.text}
                            </option>
                        );
                    })
                }
            </select>
        );
    }

    public ToggleCodeEditorModal = (profileUUID) => {
        this.setState({
            OpenCodeEditorModal: !this.state.OpenCodeEditorModal,
            ProfileUUID: profileUUID || "",
        });
    }
    public customTextFilter = (filterHandler, customFilterParameters) => {
        return <FilterText FilterHandler = {filterHandler} customFilterParameters = {customFilterParameters}/>;
    }
    public render() {
        const { OpenCodeEditorModal, ProfileUUID } = this.state;
        const SelectedProfiles = (this.state.SelectedProfiles.length !== 0) && (
            (this.state.SelectedProfiles.length > 1) ? (
                this.state.SelectedProfiles.map((ProfileName) => {
                    return ProfileName.profile_name;
                }).join(", ") || ""
            ) : (
                this.state.SelectedProfiles[0].profile_name || ""
            )
        );
        const selectRow: any = {
            mode: "checkbox",
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
        };
        const options: any = {
            alwaysShowAllBtns: true,
            firstPage: strings.FIRST,
            lastPage: strings.LAST,
            nextPage: strings.NEXT,
            noDataText: (this.props.LoadingTableData ?
                <div><i className="fa fa-spinner fa-spin"></i> {strings.LOADING}...</div> : strings.NO_ITEMS_MATCHING),
            onFilterChange: this.onFilterChange,
            onPageChange: this.onPageChange,
            onSortChange: this.onSortChange,
            page: this.state.CurrentPage,
            pageStartIndex: 1,
            paginationPanel: this.renderPaginationPanel,
            paginationPosition: "top",
            paginationSize: this.state.PaginationSize,
            prePage: strings.PREVIOUS,
            sizePerPage: this.state.SizePerPage,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            sizePerPageList: [{
                text: "3", value: 3,
            }, {
                text: "10", value: 10,
            }, {
                text: "25", value: 25,
            }, {
                text: "50", value: 50,
            }, {
                text: "100", value: 100,
            }],
            sortIndicator: false,
            sortName: this.state.sortName,
            sortOrder: this.state.sortOrder,
            withFirstAndLast: true,
        };
        const CanCreateProfiles = (this.props.LoggedUser.can_create_device_profile &&
            this.props.Profile === "Device") ||
            (this.props.LoggedUser.can_create_service_profile && this.props.Profile === "Service") ||
            (this.props.LoggedUser.can_create_roaming_profile && this.props.Profile === "Roaming") ||
            (this.props.LoggedUser.can_create_connectivity_profile && this.props.Profile === "Connectivity") ||
            (this.props.LoggedUser.can_create_qos_profile && this.props.Profile === "QOS") ||
            (this.props.LoggedUser.can_create_channel_profile && this.props.Profile === "Channel");

        const HideLinkedColumn = ((this.props.Profile === "QOS") || (this.props.Profile === "Channel")) ? true : false;
        const CustomFilter: any = "CustomFilter";
        const CustomPlaceHolderProfileName: any = {placeholder: "TABLE_PROFILE_NAME"};
        const CustomPlaceHolderComment: any = {placeholder: "TABLE_COMMENT"};
        const CustomPlaceHolderUuid: any = {placeholder: "TABLE_UUID"};
        return (
            <div>
                <BootstrapTable
                    ref="table"
                    data={(this.props.LoadingTableData ? [] : this.props.TableData)}
                    striped={true}
                    hover={true}
                    pagination={true}
                    options={options}
                    selectRow={selectRow}
                    tableContainerClass="ReactPages new_style_dark react_new_style_dark ProfilesTable"
                    remote={true}
                    fetchInfo={{
                        dataTotalSize: (this.props.PaginationData.total > 0) ? this.props.PaginationData.total : 1,
                    }}
                >
                    <TableHeaderColumn
                        dataSort={true}
                        dataField="profile_name"
                        filter={{
                            customFilterParameters: CustomPlaceHolderProfileName,
                            getElement: this.customTextFilter,
                            type: CustomFilter,
                        }}
                    >
                        {strings.TABLE_PROFILE_NAME}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="description"
                        dataSort={true}
                        filter={{
                            customFilterParameters: CustomPlaceHolderComment,
                            getElement: this.customTextFilter,
                            type: CustomFilter,
                        }}
                    >
                        {strings.TABLE_COMMENT}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey={true}
                        dataSort={true}
                        dataField="profile_uuid"
                        filter={{
                            customFilterParameters: CustomPlaceHolderUuid,
                            getElement: this.customTextFilter,
                            type: CustomFilter,
                        }}
                    >
                        {strings.TABLE_UUID}
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        hidden={HideLinkedColumn}
                        dataField="link_to_profile_uuid"
                    >
                        {strings.TABLE_LINK} <br/>
                        <div className="sort_links">
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        this.LinkedFilter(true);
                                    }}
                                    className={(this.state.FilterLinked === true) ? "active" : ""}
                                >
                                    {strings.PROFILES_TABLE_LINKED_FILTER}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.LinkedFilter(false);
                                    }}
                                    className={(this.state.FilterLinked === false) ? "active" : ""}
                                >
                                    {strings.PROFILES_TABLE_UNLINKED_FILTER}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="shared_with_all_users"
                        dataFormat={this.SharedFormatData}
                        thStyle={{ width: "170px" }}
                        tdStyle={{ width: "170px", textAlign: "center" }}
                    >
                        {strings.TABLE_SHARED} <br/>
                        <div className="sort_shared">
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        this.SharedFilter(true);
                                    }}
                                    className={(this.state.FilterShared === true) ? "active" : ""}
                                >
                                    {strings.YES}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.SharedFilter(false);
                                    }}
                                    className={(this.state.FilterShared === false) ? "active" : ""}
                                >
                                    {strings.NO}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        thStyle={{
                            backgroundColor: "#e0dfdf",
                            marginTop: "0px",
                            paddingTop: "0px",
                            verticalAlign: "top",
                            width: "150px",
                        }}
                        tdStyle={{ width: "150px" }}
                        dataFormat={this.ActionButton}
                        dataAlign="center"
                    >
                        <div className="BulkActionHeader">
                            <ButtonToolbar className="React_bulk_action_button">
                                <DropdownButton
                                    bsSize="xsmall"
                                    title={`${strings.BULK_ACTION} (${this.state.BulkAction})`}
                                    id="BulkActionDropdown"
                                    disabled={(this.state.BulkAction === 0 ||
                                        CanCreateProfiles !== true
                                    )}
                                    className="React_btn_bg_transparent React_text_white"
                                    pullRight={true}
                                >
                                    {CanCreateProfiles && (
                                        <MenuItem
                                            eventKey="1"
                                            onClick={() => {
                                                this.props.ToggleDeleteModal();
                                                this.setState({
                                                    ActivateBulkAction: true,
                                                    ActivateSingleAction: false,
                                                    ActivateUnlinkAction: false,
                                                });
                                            }}
                                        >
                                            <i className="fa fa-trash-o fa-fw"></i>
                                            {strings.BULK_ACTION_DELETE_PROFILES}
                                        </MenuItem>
                                    )}
                                </DropdownButton>
                            </ButtonToolbar>
                        </div>
                    </TableHeaderColumn>
                </BootstrapTable>

                {OpenCodeEditorModal && (
                    <CodeEditorModal
                        ShowModal={OpenCodeEditorModal}
                        HideModal={this.ToggleCodeEditorModal}
                        ProfileUUID={ProfileUUID}
                    />
                )}

                {this.props.OpenDeleteModal && (
                    <Modal
                        show={this.props.OpenDeleteModal}
                        onHide={this.props.ToggleDeleteModal}
                        className="ReactModal"
                        bsSize="lg"
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.ActivateUnlinkAction ?
                                    strings.UNLINK_LINKED_PROFILE_MODAL_TITLE : strings.DELETE_PROFILE_MODAL_TITLE
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center">
                                {this.state.ActivateUnlinkAction && (
                                    strings.formatString(
                                        strings.UNLINK_PROFILE_MESSAGE_MODAL_BODY,
                                        { ProfileName: this.state.UnlinkProfileName || "" },
                                    )
                                )}
                                {this.state.ActivateBulkAction && (
                                    (this.state.SelectedProfiles.length === 1) ?
                                        strings.formatString(
                                            strings.DELETE_PROFILE_MESSAGE_MODAL_BODY,
                                            { ProfileName: SelectedProfiles || "" },
                                        )
                                        :
                                        strings.formatString(
                                            strings.DELETE_PROFILES_MESSAGE_MODAL_BODY,
                                            { ProfilesNames: SelectedProfiles || "" },
                                        )
                                )}
                                {this.state.ActivateSingleAction &&
                                    strings.formatString(
                                        strings.DELETE_PROFILE_MESSAGE_MODAL_BODY,
                                        { ProfileName: this.state.ProfileName || "" },
                                    )
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="React_new_style_btn_ok"
                                onClick={() => {
                                    if (this.state.ActivateBulkAction) {
                                        this.props.DeleteProfilesBulk(this.state.SelectedProfiles)
                                            .then((response) => {
                                                if (response) {
                                                    const table: any = this.refs.table;
                                                    table.cleanSelected();
                                                    this.setState({
                                                        BulkAction: 0,
                                                        SelectedProfiles: [],
                                                    });
                                                }
                                            });
                                    }
                                    if (this.state.ActivateSingleAction) {
                                        this.props.DeleteProfile(this.state.ProfileUUID)
                                            .then((response) => {
                                                if (response) {
                                                    const table: any = this.refs.table;
                                                    table.cleanSelected();
                                                    this.setState({
                                                        BulkAction: 0,
                                                        SelectedProfiles: [],
                                                    });
                                                }
                                            });
                                    }
                                    if (this.state.ActivateUnlinkAction) {
                                        this.props.UnlinkLinkedProfile(
                                            this.state.ProfileUUID,
                                            this.state.LinkToProfileUUID,
                                        );
                                        this.props.ToggleDeleteModal();
                                    }
                                }}
                            >
                                {this.state.ActivateUnlinkAction ?
                                    strings.UNLINK_PROFILE_MODAL_CONFIRM_BUTTON : strings.ACTION_DELETE
                                }
                            </Button>
                            <Button
                                className="React_new_style_btn_cancel"
                                onClick={this.props.ToggleDeleteModal}
                            >
                                {strings.CLOSE_BUTTON_TEXT}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        );
    }
}
