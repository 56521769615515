
export interface IOboeFail {
    statusCode: number;
    body: string;
    thrown: Error;
    jsonBody: any;
}

// TODO: we should import this in a proper way, however as it is loaded globally to serve
// the angular stuff, it is already globally loaded, so it is just declared here.
declare function oboe(options: any);

export const GetTableRequest = (
                url: string,
                options: any,
                oboeCallback?: (oboeObj: any) => void) => {

    let query = "";
    for (const key of Object.keys(options)) {
        if (options[key] != null) {
            query += (query === "" ? "?" : "&") + key + "=" + encodeURIComponent(options[key]);
        }
    }

    const response = new Promise<any>((resolve, reject) => {
        let statusCode: number;
        let savedHeaders = {};
        const oboeObj = oboe({
            url: url + query,
            method: "GET"
        });
        oboeObj.on("done", (data) => {
            // console.log("oboe done");
            resolve({ status: statusCode, json: data });
        });
        oboeObj.on("start", (status: number, headers: any) => {
            // console.log("oboe start", status, headers);
            statusCode = status;
            savedHeaders = headers;
        });
        oboeObj.on("fail", (fail: IOboeFail) => {
            // console.log("oboe fail", fail);
            if (fail.statusCode != null) {
                reject(Object.assign({ headers: savedHeaders }, fail));
            }
        });
        if (oboeCallback) {
            oboeCallback(oboeObj);
        }
    });

    return response;
};

export const signOut = async () => {
    try {
        const signOutResponse = await fetch("/signout", {
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            method: "PUT",
        });
        const txt = await signOutResponse.text();
        if (signOutResponse.status !== 200) {
            console.log(txt);
        }
    } catch (e) {
        //
    }
    window.location.href = '/app/signin';
}


export const SetLogLevel = (LogLevel, TimeDuration) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/event-set-log-level", {
                body: JSON.stringify({
                    LogLevel,
                    TimeDuration,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetLogLevel = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/event-get-log-level", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
