// tslint:disable:max-line-length
import * as React from "react";
import {
    Button,
    Col,
    Modal,
    Row,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { toast } from "../../utils/Toaster";

import { GetGatewaysModelImage, GetGatewaysModels, InstallGateway } from "../../services/Gateways";
import { strings } from "./../../services/Localization";

import "react-toastify/dist/ReactToastify.css";
import "./EnterpriseAddGateway.css";
import "./MultiCarousel.css";
declare const constants;

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
    },
};
export class EnterpriseAddGateway extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            InstallCmdCode: "",
            LoadingModels: true,
            ShowInstallCommandCode: false,
            ShowModal: true,
            gtwModels: [],
            gtwName: "",
            listGtwModels: [],
            selectedGtwModel: null,
            selectedGtwModelId: -1,
        };
    }

    public componentDidMount() {
        this.GetGatewaysModelsFunc();
    }

    public GetGatewaysModelsFunc = async () => {
        const response: any = await GetGatewaysModels();

        if (response.status === 200) {
            const data = await response.json();
            let gtwModels: any = [];
            if (data && data.hasOwnProperty("gateway_models") && data.gateway_models.hasOwnProperty("gateway_models") &&
                (data.gateway_models.gateway_models.constructor === Array)) {
                    gtwModels = data.gateway_models.gateway_models;
            }

            if (gtwModels.length > 0) {
                const gtwModelsArray = await gtwModels.map(async (value, index) => {
                    const modelObj = value;
                    modelObj.id = index;
                    modelObj.image = await this.GetGatewaysModelImageFunc(value.image);
                    return modelObj;
                });
                Promise.all(gtwModelsArray)
                .then(async (models) => {
                    this.setState({
                        gtwModels: (models) ? models : [],
                    });
                })
                .catch((error) => {
                    console.log("Get gateway models error => ", error);
                })
                .then(() => {
                    this.setState({
                        LoadingModels: false,
                    });
                });
            } else {
                this.setState({
                    LoadingModels: false,
                    gtwModels,
                });
            }
        } else {
            this.setState({
                LoadingModels: false,
                gtwModels: [],
            });
        }
    }

    public GetGatewaysModelImageFunc = async (imageName) => {
        const response: any = await GetGatewaysModelImage(imageName);

        if (response.status === 200) {
            const data = await response.text();
            try {
                return Buffer.from(data, "binary").toString("base64");
            } catch (error) {
                console.log("get gateway image error => ", error);
                return null;
            }
        } else {
            return null;
        }
    }

    public InstallGatewayFunc = async () => {
        try {
            let data: any = {
                model: this.state.selectedGtwModel,
                name: this.state.gtwName,
            };

            const response: any = await InstallGateway(data);

            if (response.status === 200) {
                data = await response.json();

                if (data.hasOwnProperty("install_cmd")) {
                    const textField: any = document.createElement("textarea");
                    textField.innerText = data.install_cmd;
                    const parentElement = document.getElementById("clipboard-text-holder");
                    if (parentElement) {
                        parentElement.appendChild(textField);
                        textField.select();
                        document.execCommand("copy");
                        parentElement.removeChild(textField);
                    }
                    this.setState({
                        InstallCmdCode: data.install_cmd,
                        ShowInstallCommandCode: true,
                    });
                }

                toast.success(strings.UPDATE_DEFAULT_PUSH_CONFIG_SUCCESS);
            } else {
                let message = "";
                switch (response.status) {
                    case 400:
                        message = strings.UPDATE_DEFAULT_PUSH_CONFIG_ERROR_400;
                        break;
                    default:
                        message = await response.json();
                        break;
                }
                toast.error(message);
            }
        } catch (error) {
            toast.error(strings.ERROR_UPDATING_DEAFULT_PUSH_CONFIG);
        }
    }

    public selectGtwModel = (gtwId) => {
        let gtwModel = "";
        for (const key in this.state.gtwModels) {
            if (this.state.gtwModels[key].id === gtwId) {
                gtwModel = this.state.gtwModels[key].model;

                break;
            }
        }

        this.setState({
            InstallCmdCode: "",
            ShowInstallCommandCode: false,
            selectedGtwModel: gtwModel,
            selectedGtwModelId: gtwId,
        });
    }

    public isGtwModelSelected = (gtwModel) => {
        let gtwModelClass = "";
        if (gtwModel.id === this.state.selectedGtwModelId) {
            gtwModelClass = "selected";
        }

        return gtwModelClass;
    }

    public isGtwModelChecked = (gtwModel) => {
        let isChecked = false;
        if (gtwModel.id === this.state.selectedGtwModelId) {
            isChecked = true;
        }

        return isChecked;
    }

    public closeModal = () => {
        this.setState({ ShowModal: false });
    }

    public gtwNameChange = (event) => {
        this.setState({
            InstallCmdCode: "",
            ShowInstallCommandCode: false,
            gtwName: event.target.value,
        });
    }

    public getImagePath = (imageName) => {
        return "/rest/gateways/models/images/" + imageName;
    }

    public render() {
        const {
            gtwModels,
            InstallCmdCode,
            LoadingModels,
            ShowInstallCommandCode,
            selectedGtwModel,
        } = this.state;

        return (
            <div>
                <Modal
                    show={this.state.ShowModal}
                    onHide={this.closeModal}
                    bsSize="sm"
                    className="ReactModal default-push-config enterprise-add-gateway"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {strings.ADD_GATEWAY}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all">
                        <Row>
                            <Col sm={12} className="form-horizontal">
                                <div className="desc">
                                    <p className="info">
                                        {strings.E_GTW_INFO}
                                    </p>

                                    <p>
                                        {strings.E_GTW_INFO_MSG_1}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_2}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_3}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_4}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_5}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_6}
                                    </p>
                                    <p>
                                        {strings.E_GTW_INFO_MSG_7}
                                        <a href={constants.enterpriseModeGtwInfoLink} target="_blank"> {strings.E_GTW_INFO_MSG_8}</a>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <div className="custom-background">
                            <Row className="name-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <p className="gtw-name-label">
                                        {strings.GATEWAY_NAME}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="name-input-container">
                                <Col sm={12} className="form-horizontal">
                                    <input type="text"
                                        className="form-control new_style_focus"
                                        value={this.state.gtwName}
                                        id="testId2"
                                        onChange={this.gtwNameChange} />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <p className="gtw-models">
                                        {strings.E_GTW_MODELS}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="models-container">
                                <Col sm={12} className="carousel-container">
                                    {(LoadingModels) ? (
                                        <div className="loading_models">
                                            <i className="fa fa-spinner fa-spin fa-4x"></i>
                                        </div>
                                    ) : (
                                        (gtwModels.length > 0) ? (
                                            <Carousel
                                                swipeable={true}
                                                draggable={false}
                                                showDots={false}
                                                responsive={responsive}
                                                ssr={false}
                                                infinite={true}
                                                keyBoardControl={true}>

                                                {
                                                    gtwModels.map((gtwModel, i) => {
                                                        const modelName = gtwModel.full_model_names.map((fullModelName, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    {fullModelName}
                                                                </li>
                                                            );
                                                        });
                                                        return (
                                                            <div className="model-parent-container" key={i} onClick={() => this.selectGtwModel(gtwModel.id)}>
                                                                <div className={`${this.isGtwModelSelected(gtwModel)} model-container`} key={gtwModel.id}>
                                                                    <div className="row name-container">
                                                                        <div className="col-md-12">
                                                                            <h4 className="gtw-name">
                                                                                { gtwModel.model }
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row image-container">
                                                                        <div className="col-md-12">
                                                                            {(gtwModel.image) && <img src={`data:image/png;base64,${gtwModel.image}`} />}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="line">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 names-container">
                                                                            <ul className="full-names">
                                                                                { modelName }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row check-box-container">
                                                                    <div className="col-md-12">
                                                                        <input
                                                                            checked={this.isGtwModelChecked(gtwModel)}
                                                                            readOnly={true}
                                                                            type="checkbox"
                                                                            name="gtw-model"
                                                                            value={gtwModel.model}
                                                                            onClick={() => this.selectGtwModel(gtwModel.id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </Carousel>
                                        ) : (
                                            <div className="no_gtw_models">{strings.E_GTW_NO_MODELS}</div>
                                        )
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <p className="install">
                                        {strings.E_GTW_INSTALLATION}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="clipboard-btn-container">
                                <Col sm={12}>
                                    {(ShowInstallCommandCode) ? (
                                        <div className="textarea_style">
                                            {InstallCmdCode}
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={this.InstallGatewayFunc}
                                            className="clipboard-btn"
                                            disabled={(selectedGtwModel) ? false : true}
                                        >
                                            <img src={`/theme/images/fa-copy.png`} />
                                            {strings.E_GTW_COPY_TO_CLIPBOARD}
                                        </Button>
                                    )}
                                    <div id="clipboard-text-holder"></div>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                    <div className="modal-footer">
                        <div className="modal_footer_btns">
                            <Button
                                onClick={this.closeModal}
                                className="React_new_style_btn_ok"
                            >
                                {strings.OK}
                            </Button>
                            <Button
                                onClick={this.closeModal}
                                className="React_new_style_btn_cancel"
                            >
                                {strings.CANCEL}
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
