export const addDevice = (device) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/adddevice", {
                body: JSON.stringify(
                    device,
                ),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const getDevice = (deveui) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/devices.json/" + deveui, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            if (response.status >= 400) {
                const data = await response.text();
                reject(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }
            else {
                const data = await response.json();
                resolve(data);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const editDevice = (device) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/updatedevice", {
                body: JSON.stringify(
                    device,
                ),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
