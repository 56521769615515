import { JSONSchema6 } from "json-schema";
import * as React from "react";
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Form, { UiSchema } from "react-jsonschema-form";
import {
    ChangeValueToDefault,
    JsonUISchema,
    SchemaValidation,
    transformErrors,
    TranslateForm,
} from "./../../controllers/Profiles/ProfileFormController";
import { strings } from "./../../services/Localization";
import { SelectDropdown } from "./../DropdownComponent/SelectDropdown";

export class ProfileForm extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ChannelProfileUUID: "",
            ConnectivityProfileUUID: "",
            DefaultValues: {profile_name: "", profile_uuid: ""},
            FirstFormLoading: true,
            FormData: this.props.FormData.content || {},
            FormSchema: this.props.FormSchema || {},
            HasErrors: false,
            Loading: true,
            ProfileDesc: this.props.FormData.description || "",
            ProfileName: this.props.FormData.profile_name || "",
            ProfileNotes: this.props.FormData.notes,
            QoSProfileUUID: "",
            RoamingProfileUUID: "",
            ShareProfile: false,
            ValidationRequestSent: false,
        };
    }

    public componentWillReceiveProps(nextProps) {
        if (this.props.EditMode || this.props.ViewMode) {
            this.setState({
                FormData: nextProps.FormData.content || {},
                ProfileDesc: nextProps.FormData.description || "",
                ProfileName: nextProps.FormData.profile_name || "",
                ProfileNotes: nextProps.FormData.notes,
            });
            if (this.props.UserRights.is_customer) {
                this.setState({
                    ShareProfile: nextProps.FormData.shared_with_all_users || this.state.ShareProfile,
                });
            }
            if (this.props.Profile === "DeviceProfiles" && !this.props.UserRights.can_inspect_device_profile) {
                this.setState({
                    FormData: {},
                    FormSchema: {},
                });
            }
            if (this.props.Profile === "ServiceProfiles" && this.props.UserRights.can_inspect_service_profile) {
                if (Object.keys(nextProps.SelectedProfile).length > 0) {
                    this.setState({
                        ChannelProfileUUID: nextProps.SelectedProfile.ChannelProfile.profile_uuid,
                        ConnectivityProfileUUID: nextProps.SelectedProfile.ConnectivityProfile.profile_uuid,
                        QoSProfileUUID: nextProps.SelectedProfile.QoSProfile.profile_uuid,
                        RoamingProfileUUID: nextProps.SelectedProfile.RoamingProfile.profile_uuid,
                    });
                }
            }

            if ((nextProps.FormData.content !== undefined) && (Object.keys(nextProps.FormSchema).length > 0) &&
            (this.props.FormData.content !== nextProps.FormData.content) && (nextProps.LoadingData === false) &&
            (nextProps.FormSchema.validation !== undefined)) {
                SchemaValidation(
                    nextProps.FormData.content,
                    this.state.FormData,
                    nextProps.FormSchema.validation,
                ).then((validation: any) => {
                    if (validation !== undefined) {
                        this.props.GetNewSchema(validation);
                        this.setState({
                            FirstFormLoading: false,
                        });
                    }
                });
            }
        }
        if (this.state.FormSchema !== nextProps.FormSchema) {
            if (this.state.FirstFormLoading === false) {
                ChangeValueToDefault(
                    this.state.FormData,
                    nextProps.FormSchema.properties,
                ).then((newData) => {
                    this.setState({
                        FormData: newData,
                    });
                });
            }
            this.setState({
                FormSchema: TranslateForm(this.props.Profile, nextProps.FormSchema) || {},
            });
        }
        setTimeout(() => {
            if (nextProps.LoadingData !== this.state.Loading) {
                this.setState({
                    Loading: nextProps.LoadingData,
                });
            }
        }, 500);
    }

    public CheckForErrors = () => {
        const length = this.state.ProfileName.length;
        if (length === 0) {
            return {message: strings.REQUIRED_PROPERTY_MESSAGE, error: "error"};
        } else if (length < 6) {
            return {message: strings.LEAST_6_CHARACTERS_LONG_ERROR_MESSAGE, error: "error"};
        } else if (length > 64) {
            return {message: strings.MOST_64_CHARACTERS_LONG_ERROR_MESSAGE, error: "error"};
        }
        return {message: "", error: null};
    }

    public OnChangeValue = (event) => {
        this.setState({
            [event.name]: event.value,
        });
    }

    public OnSubmitForm = (FormData) => {
        const SubmitData: any = {
            description: this.state.ProfileDesc,
            profile_name: this.state.ProfileName,
            shared_with_all_users: this.state.ShareProfile,
        };
        if (!this.props.EditMode && !this.props.ViewMode) {
            SubmitData.notes = this.state.ProfileNotes || "";
        } else {
            if (this.state.ProfileNotes !== undefined) {
                SubmitData.notes = this.state.ProfileNotes;
            }
        }
        if ((this.props.EditMode &&
            (this.props.UserRights.can_inspect_device_profile && this.props.Profile === "DeviceProfiles" ||
            this.props.UserRights.can_inspect_service_profile && this.props.Profile === "ServiceProfiles" ||
            this.props.UserRights.can_inspect_connectivity_profile && this.props.Profile === "ConnectivityProfiles" ||
            this.props.UserRights.can_inspect_roaming_profile && this.props.Profile === "RoamingProfiles" ||
            this.props.UserRights.can_inspect_qos_profile && this.props.Profile === "QOSProfile" ||
            this.props.UserRights.can_inspect_channel_profile && this.props.Profile === "ChannelProfiles")) ||
            !this.props.EditMode) {
                if (FormData === "ServiceProfile") {
                    SubmitData.content = {
                        channel_profile_uuid: this.state.ChannelProfileUUID,
                        connectivity_profile_uuid: this.state.ConnectivityProfileUUID,
                        qos_profile_uuid: this.state.QoSProfileUUID,
                        roaming_profile_uuid: this.state.RoamingProfileUUID,
                    };
                } else {
                    SubmitData.content = FormData.formData;
                }
        }
        if (this.props.EditMode) {
            if (this.CheckForErrors().error === null) {
                this.props.UpdateProfile(SubmitData);
            }
        } else {
            if (this.CheckForErrors().error === null) {
                this.props.AddProfile(SubmitData);
            }
        }
    }

    public SelectedProfile = (ProfileData) => {
        if (ProfileData.ProfileName === "ChannelProfile") {
            this.setState({
                ChannelProfileUUID: ProfileData.value,
            });
        } else if (ProfileData.ProfileName === "QoSProfile") {
            this.setState({
                QoSProfileUUID: ProfileData.value,
            });
        } else if (ProfileData.ProfileName === "RoamingProfile") {
            this.setState({
                RoamingProfileUUID: ProfileData.value,
            });
        } else if (ProfileData.ProfileName === "ConnectivityProfile") {
            this.setState({
                ConnectivityProfileUUID: ProfileData.value,
            });
        }
    }

    public render() {
        if ((this.state.Loading === false) && (Object.keys(this.state.FormData).length > 0) &&
        (!this.props.EditMode && !this.props.ViewMode) && (this.state.ValidationRequestSent === false)) {
            SchemaValidation(
                this.state.FormData,
                {},
                this.state.FormSchema.validation,
            ).then((validation: any) => {
                this.setState({
                    FirstFormLoading: false,
                    ValidationRequestSent: true,
                });
                if (validation !== undefined) {
                    this.props.GetNewSchema(validation);
                }
            });
        }
        const schema: JSONSchema6 = this.state.FormSchema;
        const uiSchema: UiSchema = JsonUISchema({
            error: this.props.Error || null,
            name: this.props.Profile,
            values: this.state.FormData,
        });
        if (this.props.ViewMode && Object.keys(this.state.FormSchema).length !== 0) {
            Object.keys(this.state.FormSchema.properties).map((key) => {
                uiSchema[key] = { "ui:readonly": true };
            });
        }
        return (
            <div>
                <Modal
                    show={this.props.ShowModal}
                    onHide={this.props.HideModal}
                    bsSize="lg"
                    className="ReactModal"
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {this.props.ViewMode ?
                                this.props.FormData.profile_name :
                                this.props.EditMode ?
                                    `${strings.ACTION_EDIT_TITLE} ${this.props.ProfileName}` :
                                    `${strings.ACTION_ADD_TITLE} ${this.props.ProfileName}`
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="padding_0_all ScrollableBody">
                        <Row>
                            {this.state.Loading ? (
                                <Col sm={12} className="LoaderWrapper">
                                    <i className="fa fa-spinner fa-spin fa-5x"></i>
                                </Col>
                            ) : (
                                <Col sm={12} className="padding_0_all">
                                    <form
                                        className={(Object.keys(this.props.FormSchema).length === 0) ?
                                            "ViewProfileForm margin_b_30 SuccessBorder" :
                                            "ViewProfileForm SuccessBorder"
                                        }
                                    >
                                        <FormGroup
                                            controlId="ProfileName"
                                            validationState={this.CheckForErrors().error === "error" ? "error" : null}
                                        >
                                            <ControlLabel>{strings.TABLE_PROFILE_NAME}</ControlLabel>
                                            <FormControl
                                                type="text"
                                                value={this.state.ProfileName}
                                                disabled={this.props.ViewMode ? true : false}
                                                onChange={(e) => this.OnChangeValue(e.target)}
                                                name="ProfileName"
                                                required={true}
                                            />
                                            {(this.CheckForErrors().error === "error") &&
                                                <HelpBlock
                                                    className="react_required_custom_message"
                                                >
                                                    {this.CheckForErrors().message}
                                                </HelpBlock>
                                            }
                                        </FormGroup>
                                        <FormGroup
                                            controlId="ProfileDesc"
                                        >
                                            <ControlLabel>{strings.TABLE_COMMENT}</ControlLabel>
                                            <FormControl
                                                componentClass="textarea"
                                                disabled={this.props.ViewMode ? true : false}
                                                value={this.state.ProfileDesc}
                                                onChange={(e) => this.OnChangeValue(e.target) }
                                                name="ProfileDesc"
                                            />
                                        </FormGroup>
                                        {(((this.state.ProfileNotes !== undefined) &&
                                        (this.props.EditMode || this.props.ViewMode)) ||
                                        (!this.props.EditMode && !this.props.ViewMode)) && (
                                            <FormGroup
                                                controlId="ProfileNotes"
                                            >
                                                <ControlLabel>{strings.PROFILES_NOTES_FIELD}</ControlLabel>
                                                <FormControl
                                                    componentClass="textarea"
                                                    disabled={this.props.ViewMode ? true : false}
                                                    value={this.state.ProfileNotes}
                                                    onChange={(e) => this.OnChangeValue(e.target) }
                                                    name="ProfileNotes"
                                                />
                                            </FormGroup>
                                        )}
                                    </form>
                                    {(this.props.Profile === "ServiceProfiles") ? (
                                        (((this.props.EditMode || this.props.ViewMode) &&
                                        this.props.UserRights.can_inspect_service_profile) ||
                                        (!this.props.EditMode && !this.props.ViewMode)) && (
                                        <div style={{padding: "0 30px"}}>
                                            <h3 className="service_profile_legend">{strings.SERVICE_PROFILE_LEGEND}</h3>
                                            <form>
                                                <FormGroup
                                                    controlId="ProfileDesc"
                                                >
                                                    <ControlLabel>{strings.SERVICE_PROFILE_CHANNEL}</ControlLabel>
                                                    <SelectDropdown
                                                        Profiles={this.props.ChannelProfiles}
                                                        ProfileName="ChannelProfile"
                                                        SelectedProfile={this.SelectedProfile}
                                                        DefaultValue={(this.props.SelectedProfile ?
                                                            this.props.SelectedProfile.ChannelProfile :
                                                            this.state.DefaultValues
                                                        )}
                                                        Disabled={this.props.ViewMode}
                                                        Remote={true}
                                                        RemoteFilter={this.props.FilterChannelProfiles}
                                                        Required={true}
                                                    />
                                                    {(this.state.ChannelProfileUUID === "") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {strings.REQUIRED_PROPERTY_MESSAGE}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                                <FormGroup
                                                    controlId="ProfileDesc"
                                                >
                                                    <ControlLabel>{strings.SERVICE_PROFILE_QoS}</ControlLabel>
                                                    <SelectDropdown
                                                        Profiles={this.props.QoSProfiles}
                                                        ProfileName="QoSProfile"
                                                        SelectedProfile={this.SelectedProfile}
                                                        DefaultValue={(this.props.SelectedProfile ?
                                                            this.props.SelectedProfile.QoSProfile :
                                                            this.state.DefaultValues
                                                        )}
                                                        Disabled={this.props.ViewMode}
                                                        MenuPosition={"top"}
                                                        Remote={true}
                                                        RemoteFilter={this.props.FilterQoSProfiles}
                                                        Required={true}
                                                    />
                                                    {(this.state.QoSProfileUUID === "") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {strings.REQUIRED_PROPERTY_MESSAGE}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                                <FormGroup
                                                    controlId="ProfileDesc"
                                                >
                                                    <ControlLabel>{strings.SERVICE_PROFILE_ROAMING}</ControlLabel>
                                                    <SelectDropdown
                                                        Profiles={this.props.RoamingProfiles}
                                                        ProfileName="RoamingProfile"
                                                        SelectedProfile={this.SelectedProfile}
                                                        DefaultValue={(this.props.SelectedProfile ?
                                                            this.props.SelectedProfile.RoamingProfile :
                                                            this.state.DefaultValues
                                                        )}
                                                        Disabled={this.props.ViewMode}
                                                        MenuPosition={"top"}
                                                        Remote={true}
                                                        RemoteFilter={this.props.FilterRoamingProfiles}
                                                        Required={true}
                                                    />
                                                    {(this.state.RoamingProfileUUID === "") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {strings.REQUIRED_PROPERTY_MESSAGE}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                                <FormGroup
                                                    controlId="ProfileDesc"
                                                >
                                                    <ControlLabel>{strings.SERVICE_PROFILE_CONNECTIVITY}</ControlLabel>
                                                    <SelectDropdown
                                                        Profiles={this.props.ConnectivityProfiles}
                                                        ProfileName="ConnectivityProfile"
                                                        SelectedProfile={this.SelectedProfile}
                                                        DefaultValue={(this.props.SelectedProfile ?
                                                            this.props.SelectedProfile.ConnectivityProfile :
                                                            this.state.DefaultValues
                                                        )}
                                                        Disabled={this.props.ViewMode}
                                                        MenuPosition={"top"}
                                                        Remote={true}
                                                        RemoteFilter={this.props.FilterConnectivityProfiles}
                                                        Required={true}
                                                    />
                                                    {(this.state.ConnectivityProfileUUID === "") &&
                                                        <HelpBlock
                                                            className="react_required_custom_message"
                                                        >
                                                            {strings.REQUIRED_PROPERTY_MESSAGE}
                                                        </HelpBlock>
                                                    }
                                                </FormGroup>
                                            </form>
                                        </div>)
                                    ) : (
                                        ((this.props.FormData.content &&
                                        (Object.keys(this.props.FormSchema).length !== 0)) ||
                                        (!this.props.EditMode || !this.props.ViewMode)) &&
                                            <Form
                                                method="POST"
                                                autocomplete="off"
                                                schema={schema}
                                                uiSchema={uiSchema}
                                                liveValidate={true}
                                                formData={this.state.FormData}
                                                transformErrors={transformErrors(this.state.FormSchema)}
                                                showErrorList={false}
                                                onChange={(formData) => {
                                                    const HasErrors = (formData.errors.length > 0) ? true : false;
                                                    if (this.state.FirstFormLoading === false) {
                                                        SchemaValidation(
                                                            formData.formData,
                                                            this.state.FormData,
                                                            this.state.FormSchema.validation,
                                                        ).then((validation: any) => {
                                                            if (validation !== undefined) {
                                                                this.props.GetNewSchema(validation);
                                                            }
                                                        });
                                                    } else {
                                                        this.setState({
                                                            FirstFormLoading: false,
                                                        });
                                                    }
                                                    this.setState({
                                                        FormData: formData.formData,
                                                        HasErrors,
                                                    });
                                                }}
                                                onSubmit={(formData) => {
                                                    this.OnSubmitForm(formData);
                                                }}
                                                className="DeviceProfileForm SuccessBorder"
                                            >
                                                <div className={(Object.keys(this.props.FormSchema).length !== 0) ?
                                                            "FormBtnsStyle WithScroll" : "FormBtnsStyle"
                                                    }>
                                                    {((this.props.UserRights.is_customer &&
                                                    (this.props.Profile !== "QOSProfile" &&
                                                    this.props.Profile !== "ChannelProfiles")) ||
                                                    (this.props.UserRights.is_customer &&
                                                    (this.props.Profile === "QOSProfile" ||
                                                    this.props.Profile === "ChannelProfiles") &&
                                                    !this.props.UserRights.is_tenant)) && (
                                                        <Checkbox
                                                            className="pull-left ShareProfileCheckBox"
                                                            checked={this.state.ShareProfile}
                                                            disabled={this.props.ViewMode}
                                                            onChange={() => {
                                                                this.setState({
                                                                    ShareProfile: !this.state.ShareProfile,
                                                                });
                                                            }}
                                                        >
                                                            {strings.SHARE_PROFILE_CHECKBOX}
                                                        </Checkbox>
                                                    )}
                                                    <div className="pull-right">
                                                        {!this.props.ViewMode && (
                                                            <Button
                                                                type="submit"
                                                                className="React_new_style_btn_ok"
                                                                disabled={((this.CheckForErrors().error === null) &&
                                                                    (!this.state.HasErrors)) ?
                                                                    false : true
                                                                }
                                                            >
                                                                {this.props.EditMode ? strings.ACTION_EDIT :
                                                                strings.ACTION_ADD}
                                                            </Button>
                                                        )}
                                                        <Button
                                                            onClick={this.props.HideModal}
                                                            className="React_new_style_btn_cancel"
                                                        >
                                                            {strings.CLOSE_BUTTON_TEXT}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                    )}
                                    {((this.props.ViewMode && !this.props.FormData.content &&
                                    (Object.keys(this.props.FormSchema).length === 0)) ||
                                    (this.props.Profile === "ServiceProfiles")) &&
                                        <div className={(Object.keys(this.props.FormSchema).length !== 0) ?
                                                    "FormBtnsStyle WithScroll" : "FormBtnsStyle"
                                                }
                                        >
                                            {(this.props.UserRights.is_customer) && (
                                                <Checkbox
                                                    className="pull-left ShareProfileCheckBox"
                                                    checked={this.state.ShareProfile}
                                                    disabled={this.props.ViewMode}
                                                    onChange={() => {
                                                        this.setState({
                                                            ShareProfile: !this.state.ShareProfile,
                                                        });
                                                    }}
                                                >
                                                    {strings.SHARE_PROFILE_CHECKBOX}
                                                </Checkbox>
                                            )}
                                            {(!this.props.ViewMode && (this.props.Profile === "ServiceProfiles")) && (
                                                <Button
                                                    className="React_new_style_btn_ok"
                                                    disabled={((this.CheckForErrors().error === null) &&
                                                        (this.props.Profile === "ServiceProfiles") &&
                                                        (((this.state.ChannelProfileUUID &&
                                                            this.state.ConnectivityProfileUUID &&
                                                            this.state.RoamingProfileUUID &&
                                                            this.state.QoSProfileUUID) !== "") &&
                                                        (this.props.UserRights.can_inspect_service_profile)) ||
                                                        (!this.props.UserRights.can_inspect_service_profile)) ?
                                                        false : true
                                                    }
                                                    onClick={() => {
                                                        this.OnSubmitForm("ServiceProfile");
                                                    }}
                                                >
                                                    {this.props.EditMode ? strings.ACTION_EDIT : strings.ACTION_ADD}
                                                </Button>
                                            )}
                                            <div className="pull-right">
                                                <Button
                                                    onClick={this.props.HideModal}
                                                    className="React_new_style_btn_cancel"
                                                >
                                                    {strings.CLOSE_BUTTON_TEXT}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </Col>
                            )}
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
