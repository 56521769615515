var app = angular.module('dassUiModule');

function UserFormController($filter, $scope, $rootScope, $window, $timeout, $element, $attrs, ToastService, TenantService, $uibModal, UserService, MessageService, DataService) {
    var $translate = $filter('translate');
    var vm = this;

    vm.tenants = [];
    vm.actualTenant = null;
    vm.deletedTenantId = null;
    vm.owner = {};
    vm.constants = constants;
    vm.activeTab = 0;
    vm.actualOrganization = null;

    vm.selectedProfileType = '';

    vm.upper = false;
    vm.lower = false;
    vm.special = false;
    vm.length = false;
    vm.complex = false;
    vm.length_value = 0;
    vm.show_length = false;
    vm.number = false;
    vm.count = 0;
    vm.autocomplete = (constants.disable_autocomplete_for_credentials === true) ? "off" : "on";

    vm.tabs = [];
    vm.admin_policy = false;
    vm.user_id = undefined;
    // [
    //     'EDIT_USER_ADMIN_RIGHTS',
    //     'EDIT_USER_GATEWAYS',
    //     'EDIT_USER_LORA_LOCATION',
    //     'EDIT_USER_SYSTEM_SETTINGS',
    //     'EDIT_USER_TENANCY'
    // ];

    // Reference: http://dotansimha.github.io/angularjs-dropdown-multiselect/docs/#/main
    vm.tenantSelectSettings = {
        displayProp: "name",
        searchField: "name",
        dynamicTitle: true,
        enableSearch: true,
        selectionLimit: 1,
        scrollableHeight: '100px',
        scrollable: true
    }

    vm.addTab = (heading) => {
        vm.tabs.push(heading);
        console.log(heading);
    }

    vm.tenantsTranslations = {
        dynamicButtonTextSuffix: $translate("TENANT_SELECTED_SUFFIX"),
        buttonDefaultText: $translate("TENANT_SELECT_ASSIGN_BUTTON"),
        checkAll: $translate("TENANT_SELECT_ALL"),
        uncheckAll: $translate("TENANT_SELECT_NONE"),
    }

    vm.loadTenants = () => {
        if (!vm.owner.tenant_admin) {
            return;
        }
        TenantService.getTenants()
            .then(tenants => {
                vm.tenants = tenants;
                vm.updateTenant();
                console.log("Loaded tenants ", tenants);
            })
            .catch(err => {
                ToastService.showMessage($translate("MSG_ERROR_LOADING_TENANTS"), "error");
                console.error("Error loading tenants ", err);
            });
    }

    vm.selectedTenant = undefined;

    vm.updateTenant = () => {
        vm.deletedTenantId = vm.user.tenant_id;

        vm.tenants.forEach(function (tenant) {
            if (tenant.tenantid == vm.user.tenant_id) {
                vm.actualTenant = tenant;
                vm.deletedTenantId = null;
                console.log("TEnant", vm.actualTenant)
                return;
            }
        }, this);
    }

    vm.user_pass_length = '';
    vm.checkUserPassLength = function (password) {
        var buttonUpdateCustomer = document.getElementById("update-button");
        var allInputsPrivacyPolicy = $(".form-group.button-allign.margin_b_10");
        
        if (password >= 8 && (password % 1 === 0) || password == null) {
            vm.user_pass_length = '';
        }
        else {
            vm.user_pass_length = 'has-error';
        }

        setTimeout(function(){
            if(allInputsPrivacyPolicy.hasClass("has-error")){
                buttonUpdateCustomer.setAttribute("disabled", true);
            }
            else{
                buttonUpdateCustomer.removeAttribute("disabled")
            }
        },10);
    }
    
    vm.admin_pass_length = '';
    vm.checkAdminPassLength = function (password) {
        var buttonUpdateCustomer = document.getElementById("update-button");
        var allInputsPrivacyPolicy = $(".form-group.button-allign.margin_b_10");

        if (password >= 8 && (password % 1 === 0) || password == null) {
            vm.admin_pass_length = '';
        }
        else {
            vm.admin_pass_length = 'has-error';
        }

        setTimeout(function(){
            if(allInputsPrivacyPolicy.hasClass("has-error")){
                buttonUpdateCustomer.setAttribute("disabled", true);
            }
            else{
                buttonUpdateCustomer.removeAttribute("disabled")
            }
        },10);
    }
    
    vm.app_pass_length = '';
    vm.checkAppPassLength = function (password) {
        var buttonUpdateCustomer = document.getElementById("update-button");
        var allInputsPrivacyPolicy = $(".form-group.button-allign.margin_b_10");

        if (password >= 8 && (password % 1 === 0) || password == null) {
            vm.app_pass_length = '';
        }
        else {
            vm.app_pass_length = 'has-error';
        }

        setTimeout(function(){
            if(allInputsPrivacyPolicy.hasClass("has-error")){
                buttonUpdateCustomer.setAttribute("disabled", true);
            }
            else{
                buttonUpdateCustomer.removeAttribute("disabled")
            }
        },10);
	}

    vm.ShowTenantsTable = false;

    vm.showTenantTable = () => {
        if (vm.ShowTenantsTable === false) {
            vm.ShowTenantsTable = true;
            vm.ShowOrganizationsTable = false;

            vm.tableSelectionChanged = (items) => {
                console.log("Selection changed ", items);

                vm.selectedItems = items;

                vm.selectedTenant = vm.selectedItems;
                if (vm.selectedItems.length > 0) {
                    vm.user.tenant_id = vm.selectedItems[0].tenantid;
                }
            };

            vm.reloadData = () => {
                console.log("This function should be overwritten by the component before it gets called");
            };

            vm.initDataTable = () => {
                console.log("Init datatable");
                vm.title = "SELECT_A_TENNANT";
                vm.columns = [{
                    key: "tenantid",
                    type: "text",
                    title: "TENANT_ID",
                    filterable: true,
                    filterField: "search_id",
                    filterType: "text",
                    filterParams: {
                        validation: (field, value) => {
                            return new Promise((resolve, reject) => {
                                DataService.getData('/tenants.json', { search_id: value, get_pages: true }).then(data => {
                                    resolve(data.num_devices > 0);
                                }).catch(err => {
                                    resolve(false);
                                });
                            })
                        }
                    },
                }, 
                {
                    key: "name",
                    title: "TENANT_NAME",
                    type: "text",
                    filterable: true,
                    filterField: "search_name",
                    filterType: "text",
                    filterParams: {
                        validation: (field, value) => {
                            return new Promise((resolve, reject) => {
                                DataService.getData('/tenants.json', { search_name: value, get_pages: true }).then(data => {
                                    resolve(data.num_devices > 0);
                                }).catch(err => {
                                    resolve(false);
                                });
                            })
                        }
                    },
                    sortable: true,
                    sortKey: "sort_by_name",
                }];
                vm.actions = [];
                vm.bulkActions = [];
                vm.dataType = '/tenants.json';
                vm.reloadData()
            };

            vm.initDataTable();
        }
        else {
            vm.ShowTenantsTable = false;
        }
    }

    vm.selectedTenant = undefined;

    vm.ShowOrganizationsTable = false;
    vm.selectedOrganization = undefined;

    if (vm.user && vm.user.organisationid) {
        vm.actualOrganization = vm.user.organisationid;
    }

    vm.showOrganizationTable = () => {
        if (vm.ShowOrganizationsTable === false) {
            vm.ShowOrganizationsTable = true;
            vm.ShowTenantsTable = false;

            vm.tableSelectOrganization = (items) => {
                console.log("Selection changed ", items);

                vm.selectedItems = items;

                vm.selectedOrganization = vm.selectedItems;
                if (vm.selectedItems.length > 0) {
                    vm.user.organisationid = vm.selectedItems[0].userid;
                }
            };

            vm.initOrganizationDataTable = () => {
                console.log("Init datatable");
                vm.title = "SELECT_A_ORGANIZATION";
                vm.org_columns = [{
                    key: "userid",
                    type: "text",
                    title: "ORGANIZATION_ID",
                    filterable: true,
                    filterField: "search_id",
                    filterType: "text",
                    filterParams: {
                        validation: (field, value) => {
                            return new Promise((resolve, reject) => {
                                DataService.getData('/organizations.json', { search_id: value ? value : "", get_pages: true }).then(data => {
                                    resolve(data.num_devices > 0);
                                }).catch(err => {
                                    resolve(false);
                                });
                            })
                        }
                    },
                }];
                vm.actions = [];
                vm.bulkActions = [];
                vm.org_dataType = '/organizations.json';
            };

            vm.initOrganizationDataTable();
        }
        else {
            vm.ShowOrganizationsTable = false;
        }
    }

    vm.selectEvents = {
        onItemDeselect: (item) => {
            vm.user.tenant_id = undefined;
        },
        onItemSelect: (item) => {
            vm.user.tenant_id = item.id;
        }
    }

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
    };

    vm.customFilter = {
        	all: true
        }
        vm.openUserTagsAll = '?all=true';
         vm.openUserTags = () => {
        console.log('open User Tags');
    
        vm.dataType = 'users.json';
        vm.perpage = 100;
        vm.initDataTable = () => {
            vm.rowTagsAdd = 1;
            vm.columns = [ {
                key: "administrator",
                title: "Administrator",
                filterField: 'search_administrator',
                filterable: true,
                filterType: 'boolean_toggle',
                type: "text",
                render: (user) => user.administrator ? $translate("YES") : $translate("NO"),
            }];
        };

        vm.initDataTable();
    }
    vm.openUserTags();
    this.$onChanges = function (changesObj) {
        var usertype = vm.user_type;
        vm.typ = usertype == "customer" ? $translate('USER_TYPE_CUSTOMER') : $translate('USER_TYPE_USER');

        vm.passwordConf = "";
        vm.canadmin = vm.owner.administrator == true;
        vm.cangtwadmin = vm.owner.gtw_admin == true;
        vm.cancustadmin = vm.owner.customer_admin == true && usertype == "customer";
        vm.cansysadmin = vm.owner.sys_admin == true;
        vm.dataarchiveadmin = vm.owner.data_archive_admin == true;
        vm.canaccesslogs = vm.owner.can_access_logs == true;
        vm.cansysadmin_or_dataarchiveadmin = vm.cansysadmin || vm.dataarchiveadmin || vm.canaccesslogs;
        vm.canloraloc = vm.owner.loraloc_enable == true;
        vm.pass_policy = vm.owner.new_userid ? vm.owner.new_userid.includes("/") : undefined;
        vm.device_profiles = vm.owner.can_list_device_profile || vm.owner.can_inspect_device_profile || vm.owner.can_create_device_profile || vm.owner.can_link_device_profile; 
	    vm.service_profiles = vm.owner.can_list_service_profile || vm.owner.can_inspect_service_profile ||    vm.owner.can_create_service_profile || vm.owner.can_link_service_profile || vm.owner.can_restrict_service_profile;
	    vm.connectivity_profiles = vm.owner.can_list_connectivity_profile || vm.owner.can_inspect_connectivity_profile ||     vm.owner.can_create_connectivity_profile || vm.owner.can_link_connectivity_profile;
	    vm.roaming_profiles = vm.owner.can_list_roaming_profile || vm.owner.can_inspect_roaming_profile ||    vm.owner.can_create_roaming_profile || vm.owner.can_link_roaming_profile;
	    vm.channel_profiles = vm.owner.can_list_channel_profile || vm.owner.can_inspect_channel_profile ||    vm.owner.can_create_channel_profile || vm.owner.can_link_channel_profile
	    vm.qos_profiles = vm.owner.can_list_qos_profile || vm.owner.can_inspect_qos_profile || vm.owner.can_create_qos_profile ||  vm.owner.can_link_qos_profile;
        vm.loadTenants();
        // if (vm.owner.userid === vm.user.userid) {
        //     //vm.loadUserData();
        // }
        console.log(vm);
    };

    vm.ChangeValue = () => {
        if (!vm.user.can_own_gtw) {
            if (vm.user.can_add_gtw) {
                vm.user.can_add_gtw = false;
            }
            if (vm.user.can_mng_gtw) {
                vm.user.can_mng_gtw = false;
            }
        }
    }

    vm.changeAdminRight = () => {
        if(vm.user.customer_admin){
            vm.user.administrator = true;
        } else if (vm.user.tenant_admin) {
            vm.user.administrator = true;
            vm.user.customer_admin = true;
        }
        if (!vm.editMode && vm.user.password.length >= 1) {
            vm.checkPasswordPolicy(vm.user.password);
        }
    }

    vm.checkPasswordPolicy = (password) => {
        if (password === undefined) {
            password = null;
        }
        let parentid = false;
        if (vm.user.parentid) {
            parentid = true;
        }
        if (!vm.editMode || (vm.editMode && password && password.length === 1)) {
            vm.admin_policy = false;
            if (vm.owner.userid === vm.user.userid) {
                vm.user_id = vm.owner.userid;
                if (vm.owner.administrator === true || vm.owner.sys_admin === true || vm.owner.customer_admin === true) {
                    vm.admin_policy = true;

                }
            } else if (vm.owner.userid !== vm.user.userid) {
                vm.user_id = vm.user.userid;
                if (vm.user.administrator === true || vm.user.sys_admin === true || vm.user.customer_admin === true) {
                    vm.admin_policy = true;
                }
            }
        }
        const objToSend = {
            userid: vm.user_id,
            password: password,
            type: vm.userType,
            other_policy: vm.userType === "customer" && vm.editMode,
            administrator: vm.admin_policy,
            parentid: parentid
        };

        if (vm.user.password == '') {
            vm.user._passwordConf = '';
        }

        UserService.validatePasswordPolicy(objToSend).then(validationResult => {
            angular.extend(vm, validationResult.data);
            vm.user.validationResult = {};
            angular.extend(vm.user.validationResult, validationResult.data)
        }).catch(err => {
            MessageService.showMessage({
                title: $translate("ERROR"),
                body: $translate(err.data)
            });
        });
    }

    vm.changeTab = () => {
        vm.ShowTenantsTable = false;
    }

    // this is helper to be able to revert to previous tag name/desc when the user clicks on the cancel btn
    vm.oldTagName = '';
    vm.oldTagDesc = '';

    vm.editTagNameValidity = true;
    vm.editTag = function(index) {
        if (!vm.editTagNameValidity || !vm.validTagName) {
            // if some of the tags have incorrent tag name, forbid to edit some other tag

            return;
        }

        var counter = 0;
        for (var tagKey in vm.user.tags) {
            if (counter == index) {
                vm.oldTagName = tagKey;
                vm.oldTagDesc = vm.user.tags[tagKey];

                break;
            }

            counter++;
        }

        if (vm.editTagNameValidity) {
            vm.hideAllTagsInput();
        }

        // show corresponding buttons depending of which row is currently being edited
        var tagKey = document.querySelector('.tags-table .table-body .key-' + index);
        var tagDesc = document.querySelector('.tags-table .table-body .val-' + index);
        var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + index);
        var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + index);
        if (tagKey) {
            tagKey.setAttribute("tags", "show");
        }
        if (tagDesc) {
            tagDesc.setAttribute("tags", "show");
        }
        if (tagActionsBtns) {
            tagActionsBtns.setAttribute("tags", "show");
        }
        if (tagEditBtns) {
            tagEditBtns.setAttribute("tags", "show");
        }

        $timeout(function() {   // we need timeout for immediate effect, otherwise the focus wont fork
            try {
                document.querySelector('.tags-table .table-body .key-' + index + ' .tag-input-container').focus();
            }
            catch (e) {
                
            }
        }, 0);
    }

    vm.hideAllTagsInput = function() {
        var counter = 0;
        for (var tagKey in vm.user.tags) {

            // hide actions button for all tags
            var tagKey = document.querySelector('.tags-table .table-body .key-' + counter);
            var tagDesc = document.querySelector('.tags-table .table-body .val-' + counter);
            var tagEditBtns = document.querySelector('.tags-table .table-body .current-tag-edit-btns-' + counter);
            var tagActionsBtns = document.querySelector('.tags-table .table-body .current-tag-action-btns-' + counter);
            if (tagKey) {
                tagKey.setAttribute("tags", "hide");
            }
            if (tagDesc) {
                tagDesc.setAttribute("tags", "hide");
            }
            if (tagActionsBtns) {
                tagActionsBtns.setAttribute("tags", "hide");
            }
            if (tagEditBtns) {
                tagEditBtns.setAttribute("tags", "hide");
            }
            
            counter++;
        }
    }

    vm.deleteTag = function(index) {
        var counter = 0;
        for (var tagKey in vm.user.tags) {
            if (counter == index) {
                delete vm.user.tags[tagKey];
                break;
            }
            
            counter++;
        }
    }

    vm.newTagKeyVal = '';
    vm.editTagKey = function(newTagKey, index) {
        var userTags = {};  
        var counter = 0;
        for (var tagKey in vm.user.tags) {
            if (counter == index) {
                var bFlag = false;
                var secondCounter = 0;
                for (var currentTagKey in vm.user.tags) {
                    if (currentTagKey == newTagKey && counter != secondCounter) {
                        bFlag = true;

                        break;
                    }

                    secondCounter++;
                }

                if (bFlag) {
                    userTags[tagKey] = vm.user.tags[tagKey];
                    vm.newTagKeyVal = newTagKey;
                    document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "show");

                    vm.user.newTagName = '%$';
                }
                else {
                    userTags[newTagKey] = vm.user.tags[tagKey];
                    vm.newTagKeyVal = '';
                    document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");

                    vm.user.newTagName = '';
                }

                var patt = /^[a-zA-Z\d\-_]*$/;
                var result = newTagKey.match(patt);
                if (!result) {
                    vm.editTagNameValidity = false;
                    document.querySelector('.popover-tag-' + counter).setAttribute("tags", "show");
                }
                else {
                    vm.editTagNameValidity = true;
                    document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                }
            }
            else {
                userTags[tagKey] = vm.user.tags[tagKey];
            }

            counter++;
        }

        vm.user.tags = userTags;
    }

    vm.editTagVal = function(newTagVal, index) {
        var counter = 0;
        for (var tagKey in vm.user.tags) {
            if (counter == index) {
                vm.user.tags[tagKey] = newTagVal;
                break;
            }

            counter++;
        }
    }

    vm.sameTagName = false;
    vm.newTagName = "";
    vm.newTagDesc = "";
    vm.insertTag = function() {
        var bFlag = false;
        for (var tagKey in vm.user.tags) {
            if (tagKey == vm.newTagName) {
                bFlag = true;

                break;
            }
        }

        vm.sameTagName = bFlag;
        if (bFlag) {
            return;
        }
        
        if (vm.newTagName != '' && vm.newTagDesc != '' && vm.validTagName) {
            if (vm.user && vm.user.tags) {
                vm.user.tags[vm.newTagName] = vm.newTagDesc;
            }
            else {
                vm.user.tags = {};
                vm.user.tags[vm.newTagName] = vm.newTagDesc;
            }
    
            vm.newTagName = "";
            vm.newTagDesc = "";
    
            document.querySelector('.new-tag.name').focus();
        }
        else if (!vm.validTagName && vm.newTagDesc != '') {
            document.querySelector('.new-tag.name').focus();
        }
        else if (vm.newTagDesc == '') {
            document.querySelector('.new-tag.desc').focus();
        }
        else if (vm.newTagName == '' && vm.newTagDesc == '') {
            document.querySelector('.new-tag.name').focus();
        }
    }

    vm.clearTagsInput = function() {
        vm.newTagName = "";
        vm.newTagDesc = "";
        vm.validTagName = true;
        vm.sameTagName = false;

        $timeout(function() {   // this is needed to immediately send the change to app-modals.js, because the Update btn function is there - and this value show us if the button should be enabled or disabled
            vm.user.newTagName = "";

        }, 0);
    }

    vm.validTagName = true;
    vm.enterOnNewTag = function(event, inputToFocus) {
        $timeout(function() {   // this is needed to immediately send the change to app-modals.js, because the Update btn function is there - and this value show us if the button should be enabled or disabled
            vm.user.newTagName = vm.newTagName;

            var bFlag = false;
            for (var tagKey in vm.user.tags) {
                if (tagKey == vm.newTagName) {
                    bFlag = true;

                    break;
                }
            }

            vm.sameTagName = bFlag;

        }, 0);

        if(event.keyCode == 13) {   // '13' is the key code for enter
            event.preventDefault();
            
            if (vm.newTagName != '' && vm.newTagDesc != '') {
                vm.insertTag();
            }
            else if (vm.newTagName != '' && inputToFocus == 'newTagDesc') {     // move the focus to tag description input
                document.querySelector('.new-tag.desc').focus();
            }
            else if (vm.newTagDesc != '' && inputToFocus == 'newTagName') {     // move the focus to tag name input
                document.querySelector('.new-tag.name').focus();
            }

            $timeout(function() {
                document.querySelector('.tags-table').click();
            }, 0);
        }
        else if (inputToFocus == 'newTagDesc') {
            $timeout(function() {   // hack with timeout to immediately display/hide the tooltip if there is and error in the tag name
                var patt = /^[a-zA-Z\d\-_]*$/;
                var result = vm.newTagName.match(patt);
                if (!result) {
                    vm.validTagName = false;
                }
                else {
                    vm.validTagName = true;
                }

            }, 0);
        }
    }

    vm.enterOnTagName = function(event, tagPositionNumber) {
        if(event.keyCode == 13) {   // '13' is the key code for enter
            event.preventDefault();

            if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

                vm.user.newTagName = '%$';
                return;
            }
            else {
                vm.user.newTagName = '';
            }

            if (vm.editTagNameValidity) {
                vm.hideAllTagsInput();
            }
        }
        else if (event.keyCode != 9 && tagPositionNumber >= 0) {
            vm.editTagNameValidity = true;
            document.querySelector('.popover-tag-' + tagPositionNumber).setAttribute("tags", "hide");
        }
    }

    vm.saveTag = function() {
        if (vm.newTagKeyVal != '') {    // if the edited tag name already exist, don`t hide the input box

            vm.user.newTagName = '%$';
            return;
        }
        else {
            vm.user.newTagName = '';
        }

        if (vm.editTagNameValidity) {
            vm.hideAllTagsInput();
        }
    }

    vm.isReadOnly = function() {
        var flag = true;
        if (vm.readOnly == false) {
            flag = false;
        }

        return flag;
    }

    vm.clearCurrentTagInput = function(index) {
        var newTags = {};
        var counter = 0;
        for (var tagKey in vm.user.tags) {
            if (counter == index) {
                newTags[vm.oldTagName] = vm.oldTagDesc;

                vm.editTagNameValidity = true;
                vm.user.newTagName = '';
                vm.newTagKeyVal = '';
                document.querySelector('.popover-tag-' + counter).setAttribute("tags", "hide");
                document.querySelector('.same-tag-name-' + counter).setAttribute("tags", "hide");
            }
            else {
                newTags[tagKey] = vm.user.tags[tagKey];
            }

            counter++;
        }

        vm.user.tags = newTags;
    }
}

console.log("Registering component");
app.component('userForm', {
    templateUrl: 'userForm.html',
    controller: ['$filter', '$scope', '$rootScope', '$window', '$timeout', '$element', '$attrs', "ToastService", "TenantService", "$uibModal", "UserService", "MessageService", "DataService", UserFormController],
    controllerAs: "vm",
    bindings: {
        user: '=',
        userType: '<',
        items: '=',
        owner: '<',
        editMode: '<',
        readOnly: '='
    }
});
