import * as React from "react";
import { strings } from "../../services/Localization";
import { Whoami } from "../../services/Login";
import "./welcome.css";
declare const constants;
// import config  from '../../../../../server/config';
export class Welcome extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      signedIn: false,
    };
  }

  public async componentWillMount() {
    try {
      const response: any = await Whoami();
      if (response.user) {
        this.setState({ signedIn: true });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public render() {
    return (
      <div>
        <div className="container">
          <div>
            <div className="col-md-10 welcomePage">
              <div className="col-md-6 welcomePageCenter">
                <div className="welcomePageContent">
                  <h1 className="welcomePageTitle">{constants.welcome_title}</h1>
                  <p className="welcomePageMessage">{constants.welcome_text}</p>
                  {this.state.signedIn === false && (
                    <div>
                      <p>
                        <a className="btn btn-lg welcomePageButtonLearn"
                          href={constants.welcome_link} role="button">{strings.WELCOME_LEARN_MORE}</a>
                        {constants.enable_sign_up === true && (
                          <a className="btn btn-lg welcomePageButtonSingUp"
                            href="/signup.html" role="button">{strings.WELCOME_SIGN_UP}</a>
                        )}
                      </p>
                      <div className="left-allign ng-scope" >
                        {((constants.forgot_username_link !== undefined) &&
                          (constants.forgot_username_link === true)) && (
                          <a href="/forgot_username.html"
                            className="returnLogin text-center display_inl"
                          >
                            {strings.FORGOT_USERNAME}
                          </a>
                        )}
                        {((constants.forgot_password_link !== undefined) &&
                          (constants.forgot_password_link === true)) && (
                          <a href="/forgot_password.html"
                            className="returnLogin text-center display_inl"
                          >
                            {strings.FORGOT_PASSWORD}
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 welcomePageCenter">
                <div className="welcomePageContent">
                  <img src={"/theme/images/mock-up.png"} className="img-responsive" alt="" />
                  <p className="text_under_mc">{strings.WELCOME_RIGHT_MESSAGE}</p>
                </div>
                <div className="welcomePowerBy p-r-0">
                  <div title={constants.version_long}>{constants.version}</div>
                  <div className="text_center_style">{strings.POWERED_BY}
                    <img className="logo_orbiwise" src={"images/logo-orbiwise-noir-horizontal.png"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {constants.hideWelcomeBox === true && (
          <div className="welcomePowerBy">
            <small>
              {strings.POWERED_BY}
              <img className="logo" src={"images/logo-orbiwise-noir-horizontal.png"} />
            </small>
          </div>
        )}
      </div>
    );
  }
}
