(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('GatewayService', GatewayServiceFn);

    GatewayServiceFn.$inject = ['$http', "$q", "$filter"];

    function GatewayServiceFn($http, $q, $filter) {
        var sortByColumn = $filter("sortByColumn");

        // List all gateways
        function getGatewaysImpl() {
            var defer = $q.defer();
            $http.get('/gateways.json').then(
                function (response) {
                    var gateways = response.data;
                    defer.resolve(gateways);
                },
                function (response) {
                    defer.reject(response);
                }
            );
            return defer.promise;
        }

        // Get individual gateway data
        function getGatewayImpl(gtw_id) {
            return $http.get('/gateways.json/' + gtw_id);
        }

        // Sort a list of gateways
        function sortGatewaysImpl(gateways, column = 'gtw_id', reverse = false) {
            return sortByColumn(gateways, column, reverse);
        }

        // Delete a gateway
        function deleteGatewayImpl(gtw_id) {
            return $http.delete('/gateways.json/' + gtw_id)
        }

        //Delete gateways
        function deleteGatewaysImpl(gateways) {
            var promises = gateways.map(gateway => deleteGatewayImpl(gateway.id));
            return Promise.all(promises);
        }

        // Add a gateway
        function addGatewayImpl(gateway) {
            return $http.post('/add_gateway', gateway);
        }

        // Update a gateway
        function updateGatewayImpl(gateway) {
            console.log("update Gataway impl",gateway);
            return $http.put('/update_gateway/', gateway);
        }

        return {
            getGateways: getGatewaysImpl,
            getGateway: getGatewayImpl,
            addGateway: addGatewayImpl,
            updateGateway: updateGatewayImpl,
            deleteGateway: deleteGatewayImpl,
            deleteGateways: deleteGatewaysImpl,
            sortGateways: sortGatewaysImpl
        }
    }
})(angular);
