(function (angular) {
    var app = angular.module('dassUiModule');
    app.factory('UserService', UserServiceFn);

    UserServiceFn.$inject = ['$http', "$q"];

    function UserServiceFn($http, $q) {
        // Get individual gateway data
        function getUserDataImpl() {
            var defer = $q.defer();
            $http.get('/whoami/', {headers:{'Cache-Control': 'no-cache'}}).then(function (response) {

                const allowed = [
                    "/forgot_password.html", "/forgot_username.html", "/email_username.html",
                    "/password_reset_instructions.html", 
                    "/reset_password.html", "/password_successfully_changed.html", "/signin.html",
                    "/new_password.html", "/welcome.html", "/signup.html", "/initial_password.html"
                ];

                if (!response.data.user && 
                    (window.location.pathname == '/admin_users.html' || 
                    window.location.pathname == '/data_list.html' || 
                    window.location.pathname == '/mygateways_list.html' || 
                    window.location.pathname == '/multicast_activity.html' || 
                    window.location.pathname == '/mydevices_register.html' || 
                    window.location.pathname == '/admin_devices.html' || 
                    window.location.pathname == '/users_list.html' || 
                    window.location.pathname == '/mydevices_list.html')) {

                    window.location.href = '/app/signin';
                }
                else if (!response.data.user && allowed.indexOf(window.location.pathname) < 0) {
                    window.location.href = 'welcome.html';
                }

                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        function getUserImpl(id, usertype) {
            var defer = $q.defer();
            var url = usertype === "customer" ? '/customers.json' : usertype === "organization" ? '/organizations.json' : '/users.json';
            url = `${url}/${id}?all=true`;
            
            $http.get(url).then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });

            return defer.promise;
        }
        function getUsersImpl(type) {
            var defer = $q.defer();
            $http.get(type === "customer" ? '/customers.json' : type === "organization" ? '/organizations.json' : '/users.json').then(function (response) {
                defer.resolve(response.data);
            }, function (response) {
                defer.reject(response);
            });
            return defer.promise;
        }

        function setCurrentDeviceImpl(deveui) {
            return $http.put('/setcurdev?deveui=' + deveui);
        }

        function deleteUserImpl(userid, user_type) {
            return $http.delete('/delete_' + user_type + '/' + userid, null);
        }

        function deleteUsersImpl(users, user_type) {
            var promises = users.map(user => deleteUserImpl(user.userid, user_type));
            return Promise.all(promises);
        }

        function addUserOnDassImpl(user, ut) {
            return $http.post('/add' + ut, user);
        }

        function updateUserImpl(user) {
            return $http.put('/user', user);
        }

        function signInOnDassImpl(user) {
            var defer = $q.defer();
            
             $http.put('/signin', user).then(response => {
                 console.log(response);
                 if (response.data != null && response.data.user != null && response.data.user.userid != null && response.data.user.userid != "") {
                     defer.resolve(response.data);
                 } else {
                     defer.reject("SIGN_IN_FAILED");
                 }
             }).catch(error => defer.reject(error))
             return defer.promise;
        }

        function signOutOnDassImpl() {
            return $http.put('/signout');
        }

        function signInForwardImpl(userid) {
            return $http.put('/signinforward' + (userid ? '?userid=' + userid : ''));
        }

        function signUpUserImpl(user) {
            return $http.post('/signup/step/1/', user);
        }

        function setInitialPasswordImpl(user) {
            return $http.post('/signup/step/4/', user);
        }

        function setNewPasswordImpl(user) {
            return $http.post('/signup/forgot_password/', user);
        }

        function forgotUsernameImpl(user) {
            return $http.post('/signup/forgot_username/', user);
        }

        function resetPasswordImpl(user) {
            return $http.post('/signup/reset_password/', user);
        }

        function forgotPasswordImpl(user) {
            return $http.post('/signup/forgot_password/', user);
        }
        
        function validatePasswordPolicyImpl(user){
            return $http.put('/validate_policy', user);
        }

        function changePasswordImpl(user) {
            return $http.put('/change_password', user);
        }

        function getAllUserDataImpl(userId) {
            return $http.get('/get_all_user_data/' + userId);
        }
        
        return {
            getAllUserData: getAllUserDataImpl,
            getUserData: getUserDataImpl,
            getUser: getUserImpl,
            getUsers: getUsersImpl,
            setCurrentDevice: setCurrentDeviceImpl,
            deleteUser: deleteUserImpl,
            deleteUsers: deleteUsersImpl,
            signUpUser: signUpUserImpl,
            signInForward: signInForwardImpl,
            signInOnDass: signInOnDassImpl,
            signOutOnDass: signOutOnDassImpl,
            addUserOnDass: addUserOnDassImpl,
            updateUser: updateUserImpl,
            setInitialPassword: setInitialPasswordImpl,
            setNewPassword: setNewPasswordImpl,
            forgotUsername: forgotUsernameImpl,
            resetPassword: resetPasswordImpl,
            changePassword: changePasswordImpl,
            validatePasswordPolicy: validatePasswordPolicyImpl
        }
    }
})(angular);
