export const getUsers = () => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/users.json", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });
            const data = await response.json();
            if (response.status >= 400) {
                reject(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }
            else {
                resolve(data);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const getUser = (LimitPerPage, search_id) => {
    return new Promise(async (resolve, reject) => {

        try {
            const response = await fetch("/users.json", {
                body: JSON.stringify({
                    LimitPerPage,
                    search_id,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });
            const data = await response.json();
            if (response.status >= 400) {
                reject(data);
            }
            else if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }
            else {
                resolve(data);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const getUsersInfo = (
    UserType,
    PageState,
    LimitPerPage,
    searchUserId,
    FilterAdministrator,
    FilterRegistration,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/${UserType}_data`, {
                body: JSON.stringify({
                    FilterAdministrator,
                    FilterRegistration,
                    LimitPerPage,
                    PageState,
                    searchUserId,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const UsersPagination = (UserType, LimitPerPage, searchUserId, FilterAdministrator, FilterRegistration) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/${UserType}_pagination`, {
                body: JSON.stringify({
                    FilterAdministrator,
                    FilterRegistration,
                    LimitPerPage,
                    searchUserId,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const GetAllUserData = (UserId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(`/get_all_user_data/${UserId}`, {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
