export const DefaultSizePerPage = 3;
export const DefaultPaginationSize = 3;

export const Tenants = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/tenants.json", {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const TenantsPagination = (limit, Filtering, Sorting) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/tenants_pagination", {
                body: JSON.stringify({
                    PageLimit: limit,
                    colsFiltering: Filtering,
                    sortValues: Sorting,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const TenantsPageData = (limit, pageState, Filtering, Sorting) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("/tenants_data", {
                body: JSON.stringify({
                    PageLimit: limit,
                    PageState: pageState,
                    colsFiltering: Filtering,
                    sortValues: Sorting,
                }),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                method: "PUT",
            });

            if (response.status == 401) { // the status should be changed!!!
                try {
                    const signOutResponse = await fetch("/signout", {
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "PUT",
                    });

                    if (signOutResponse.status == 200) {
                        window.location.href = '/app/signin';
                    }

                    resolve(signOutResponse);
                } catch (e) {
                    reject(e);
                }
            }

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};
