var app = angular.module('dassUiModule');

function MapViewController(DeviceService, GatewayService, $filter, $scope, $timeout, $uibModal, ToastService) {
    const formatDeveui = $filter("formatDeveui");
    const vm = this;
    const $translate = $filter("translate");
    
    vm.devices = [];
    vm.gateways = [];
    vm.sortedGateways = [];
    vm.sortedDevices = [];
    vm.idFilter = { search_deveui: undefined };
    vm.commentFilter  = { search_comment: undefined };
    vm.gatewayFilter = { id: undefined };

    vm.searchTimeout = null;

    vm.loadGateways = () => {
        GatewayService.getGateways()
        .then((gateways) => {
            vm.gateways = gateways;
            // console.log("Loaded gateway", vm.gateways);
        }).catch((e) => {
            console.log("Error loading gateways");
            if (e.status == 401) {
                window.location.href = '/app/signin';
            }
        }).then(() => {
            vm.loadDevices();
        });
    };

    vm.showPositionDialog = (device) => {
        DeviceService.getDevice(device.deveui).then((response) => {
            const modalInstance = $uibModal.open({
                backdrop: "static",
                animation: true,
                templateUrl: 'selectPositionOnMapModalContent.html',
                controller: 'SelectPositionOnMapModalCtrl',
                size: "lg",
                resolve: {
                    items: () => {
                        return {
                            latitude: response.latitude,
                            longitude: response.longitude,
                            altitude: response.altitude,
                            layer: 'devices'
                        };
                    }
                }
            });

            modalInstance.result.then((location) => {
                const updateObj = {
                    deveui: device.deveui
                }
                updateObj.altitude = location.alt || 0;
                updateObj.latitude = location.lat;
                updateObj.longitude = location.lng;
                DeviceService.updateDevice(updateObj).then((response) => {
                    ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                        deveui: formatDeveui(device.deveui)
                    }), "success");
                    vm.loadDevices();
                }).catch((response) => {
                    ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                        "error"
                    );
                    response.data && ToastService.showMessage($translate(response.data),
                        "error"
                    );
                });

            }, () => {
                console.log('Modal dismissed at: ' + new Date());
            });

        }).catch((response) => {
            console.log('Error:', response);
        });

    }

    vm.filterFieldUpdated = (event) => {
        setTimeout(() => {
            var valueDevUi = event.target.value;
            var resultvalueDevUi = valueDevUi.replace(/-/g, "");
            var searchFieldTooltip = document.querySelector('.search-tooltip-charachter');
            var inputField = document.getElementsByClassName("search_input_newstyle");

            if(resultvalueDevUi.length == 0){
                searchFieldTooltip.setAttribute("tooltip", "hide");
                inputField[0].previousElementSibling.parentElement.classList.remove("error-map-input");
                inputField[0].previousElementSibling.parentElement.classList.remove("success-map-input");
            } else if(resultvalueDevUi.length < 4){
                searchFieldTooltip.setAttribute("tooltip", "show");
                inputField[0].previousElementSibling.parentElement.classList.remove("success-map-input");
                inputField[0].previousElementSibling.parentElement.classList.add("error-map-input");
            } else{
                searchFieldTooltip.setAttribute("tooltip", "hide");
                inputField[0].previousElementSibling.parentElement.classList.remove("error-map-input");
                inputField[0].previousElementSibling.parentElement.classList.add("success-map-input");
            }
        }, 0);
    }

    vm.updateFilter = () => {
        console.log(vm.idFilter);
        vm.idFilter.search_deveui = vm.idFilter.search_deveui && vm.idFilter.search_deveui.replace(/-/g, "") || "";
        vm.commentFilter.search_comment = vm.idFilter.search_deveui || "";
        vm.gatewayFilter.id = vm.idFilter.search_deveui;

        console.log(vm.gatewayFilter);
        $timeout.cancel(vm.searchTimeout);
        vm.searchTimeout = $timeout(() => {
            console.log("Re-request ", vm.idFilter);
            vm.loadDevices();
        }, 500);
    }

    vm.loadDevices = () => {
        DeviceService.getDevices(vm.idFilter).then(
            (devices) => {
                vm.devices = devices;
                console.log("Loaded devices", vm.devices);
                return DeviceService.getDevices(vm.commentFilter);
            }).then((commentDevices) => {
                vm.devices = _.uniq(_.union(vm.devices, commentDevices), false, (item, key, deveui) => item.deveui);
            }).catch((err) => {
                console.log("Error loading devices", err);
            }).then(() => {
                if ((vm.showDevices && vm.showGateways) || (!vm.showDevices && vm.showGateways)) {
                    vm.gatewaysChanged();
                } else if (vm.showDevices) {
                    vm.devicesChanged();
                }
            });
    };

    vm.deviceMarkers = [];
    vm.gatewayMarkers = [];
    vm.markers = [];
    vm.mapDevicesMovable = false;
    vm.showDevices = true;
    vm.showGateways = true;

    vm.mapCenter = {
        lat: 57.04398622401856,
        lng: 9.976964903354265,
        zoom: 13
    };

    vm.focusDevice = (device) => {
        if (device.deveui && (device.longitude == null || device.latitude == null)) {
            vm.showPositionDialog(device);
            return;
        }
        
        vm.mapCenter = {
            lat: device.latitude,
            lng: device.longitude,
            zoom: 14,
            deveui: device.deveui
        }
    }
    vm.focusGateway = (device) => {
        // console.log('vm.focusGateway -2',device);
        if (!device.longitude || !device.latitude) {
            vm.viewGatewayOnMap(device);
            return;
        }
        
        vm.mapCenter = {
            lat: device.latitude,
            lng: device.longitude,
            zoom: 14,
            deveui: device.deveui
        }
    }
    

    vm.viewGatewayOnMap = (gateway) => {
        GatewayService.getGateway(gateway.id).then(
            (response) => {
                const modalInstance = $uibModal.open({
                    backdrop: "static",
                    animation: vm.animationsEnabled,
                    templateUrl: 'viewGatewayOnMapModalContent.html',
                    controller: 'ViewGatewayOnMapModalCtrl',
                    size: "lg",
                    resolve: {
                        items: () => {
                            return {
                                name: gateway.name,
                                latitude: response.data.latitude,
                                longitude: response.data.longitude,
                                status: gateway.status,
                                modalMap: true,
                                layer: 'gateways'
                            };
                        }
                    }
                });

                modalInstance.result.then((location) => {
                    // console.log('viewGatewayOnMap location',location);
        
                    var UpdateGateway = {};
                    
                    if(location.disableLatLng){
                        UpdateGateway = {
                            id: gateway.id,
                            name: location.name,
                        };
                    }
                    else{
                        UpdateGateway = {
                            id: gateway.id,
                            name: location.name,
                            latitude: location.lat,
                            longitude: location.lng,
                            altitude: 0,
                        };
                    }
                    
                    // console.log('viewGatewayOnMap UpdateGateway',UpdateGateway);
                    GatewayService.updateGateway(UpdateGateway)
                    .then((result) => {
                        gateway.longitude = location.lng;
                        gateway.latitude = location.lat;
                        gateway.name = location.name;
                        ToastService.showMessage($translate('MSG_GATEWAY_POS_UPDATE_SUCCESS'), 'success');
                        vm.loadGateways();
                    })
                    .catch((error) => {
                        if (error.status == 404) {
                            ToastService.showMessage($translate('MSG_GATEWAY_ID_NOT_FOUND', {
                                gateway_id: gateway.id
                            }), 'error');
                        }
                        else {
                            ToastService.showMessage($translate('MSG_GATEWAY_POS_UPDATE_FAIL'), 'error');
                        }
                    });
                }, () => {
                    console.log('Modal dismissed at: ' + new Date());
                });

            },
            (e) => {
                console.log("Error: ", e);
            }
        );
	};

    vm.devicesChanged = () => {
        // console.log("Devices changed");
        vm.deviceMarkers = vm.devices.filter((device) => device.longitude && device.latitude).map(device => {
            return {
                lat: device.latitude,
                lng: device.longitude,
                message: formatDeveui(device.deveui),
                draggable: vm.mapDevicesMovable,
                deveui: device.deveui,
                layer: 'devices'
            }
        });

        if (vm.showGateways) {
            vm.markers = vm.deviceMarkers.concat(vm.gatewayMarkers);
        } else {
            vm.markers = vm.deviceMarkers;
        }
        // console.log("device Changed Markets:", vm.markers);
    };

    vm.gatewaysChanged = () => {
        // console.log("Gateways changed");
        vm.gatewayMarkers = vm.gateways.filter((gateway) => gateway.longitude && gateway.latitude).map(gateway => {
            return {
                lat: gateway.latitude,
                lng: gateway.longitude,
                message: formatDeveui(gateway.id),
                draggable: vm.mapDevicesMovable,
                layer: 'gateways'
            }
        });

        if (!vm.showDevices && vm.showGateways) {
            vm.markers = vm.gatewayMarkers;
        } else {
            vm.devicesChanged();
        }
        // console.log("gataways Changed Markets:", vm.markers);
    };

    vm.mapSettingUpdated = () => {
        vm.markers = [];
        if (vm.showDevices) {
            vm.markers = vm.markers.concat(vm.deviceMarkers);
        }
        if (vm.showGateways) {
            vm.markers = vm.markers.concat(vm.gatewayMarkers);
        }
    }

    vm.dragEnd = (device) => {
        if(device.layer == "devices"){
            const updateObj = {
                deveui: device.deveui
            }
            updateObj.altitude = device.alt || 0;
            updateObj.latitude = device.lat || '';
            updateObj.longitude = device.lng || '';

            DeviceService.updateDevice(updateObj).then((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                    deveui: formatDeveui(device.deveui)
                }), "success");
                // vm.loadDevices();
            }).catch((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                    "error"
                );
                response.data && ToastService.showMessage($translate(response.data),
                    "error"
                );
            }).then(vm.mapSettingUpdated); 
        }
                
        if(device.layer == "gateways"){
            device.id = device.message.replace('-','');
            // console.log('device.id', device.id);
            const UpdateGateway = {
                id: device.id,
                latitude: device.lat,
                longitude: device.lng,
                altitude: 0,
            }

            GatewayService.updateGateway(UpdateGateway)
            .then((result) => {
                ToastService.showMessage($translate('MSG_GATEWAY_POS_UPDATE_SUCCESS'), 'success');
                // vm.loadGateways();
            })
            .catch(error => {
                ToastService.showMessage($translate('MSG_GATEWAY_POS_UPDATE_FAIL'), 'error');
            }).then(vm.mapSettingUpdated);
        }
    }
};

app.controller("MapViewController", ["DeviceService", "GatewayService", "$filter", "$scope", "$timeout", "$uibModal", "ToastService", MapViewController])
