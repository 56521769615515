import "babel-polyfill";
import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ActiveConnections from "./pages/Applications/ActiveConnections";
import EventLog from "./pages/Applications/EventLog";
import ChannelProfiles from "./pages/Profiles/ChannelProfiles";
import ConnectivityProfiles from "./pages/Profiles/ConnectivityProfiles";
import DeviceProfile from "./pages/Profiles/DeviceProfiles";
import QOSProfiles from "./pages/Profiles/QoSProfiles";
import RoamingProfiles from "./pages/Profiles/RoamingProfiles";
import ServiceProfiles from "./pages/Profiles/ServiceProfiles";
import { SignIn } from "./pages/SignIn/SignInPage";
import { Welcome } from "./pages/Welcome/WelcomePage";

class App extends React.Component {
  public componentDidMount() {
    const RemoveClass = document.querySelector("body > div") as HTMLElement;
    RemoveClass.classList.remove("background-container");
  }
  public render() {
    return (
      <div className="App">
        <header className="App-header background-container">
          <Router basename="/app/">
            <div>
              <header className="App-header">
              </header>
              <div>
                <Route exact path="/" component={Welcome} />
                <Route path="/signin" component={SignIn} />
                <Route path="/device_profiles" component={DeviceProfile} />
                <Route path="/connectivity_profiles" component={ConnectivityProfiles} />
                <Route path="/service_profiles" component={ServiceProfiles} />
                <Route path="/roaming_profiles" component={RoamingProfiles} />
                <Route path="/qos_profiles" component={QOSProfiles} />
                <Route path="/channel_profiles" component={ChannelProfiles} />
                <Route path="/active_connections" component={ActiveConnections} />
                <Route path="/event_log" component={EventLog} />
              </div>
            </div>
          </Router>
        </header>
      </div>
    );
  }
}

export default App;
