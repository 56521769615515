var app = angular.module('dassUiModule');

function DeviceListController(DeviceService, AppService, UserService, GroupService, MessageService, ToastService, PayloadService, DataService, $filter, $uibModal, $scope, $rootScope, $timeout) {
    const $translate = $filter("translate");
    const formatDeveui = $filter("formatDeveui");
    const statusSymbol = $filter("statusSymbol");
    const statusTooltip = $filter("statusTooltip");
    const elipsis = $filter("elipsis");
    const dateString = $filter('dateString');

    let vm = this;
    vm.devices = [];
    vm.apps = [];
    vm.groups = [];

    vm.constants = constants;
    vm.workingCount = 0;

    vm.textFilterTimeout = null;

    vm.currentPage = 1;
    vm.itemsPerPage = 25;
    vm.loading = true;
    vm.user = null;
    vm.deviceSortReverse = true;
    vm.deviceSortColumn = "last_reception";
    vm.currentDevice = null;
    vm.numberOfDevices = 0;
    vm.scanning = 0;
    vm.payloadsExportDate = new Date();
    vm.exportQueryStr = '';
    vm.can_export = false;

    vm.dataType = '/devices.json';
    // vm.dataType=null;

    vm.deselectAll = (device) => {
        vm.numSelected = 0;
        vm.selectedDevices = [];

        vm.devices.forEach((device) => {
            device._marked = false;
        });

        vm.selectAll = false;
    }

    vm.deleteGroups = () => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY', {
                count: vm.assignGroupsSelection.length
            })
        }).then((resp) => {
            GroupService.deleteGroups(vm.assignGroupsSelection).then(() => {
                ToastService.showMessage($translate('MSG_GROUPS_DELETED_SUCCESS_BODY', {
                    count: vm.assignGroupsSelection.length
                }), "success");

                vm.loadDevices();
                vm.loadGroups();
            }).catch((err) => {
                ToastService.showMessage($translate('MSG_GROUPS_DELETED_FAIL_BODY'), "error");
            });
        });
    };

    vm.toggleDevicesSelected = () => {
        if (!vm.selectAll) vm.deselectAll();
        else vm.selectAll();
    };

    vm.textFilterUpdated = () => {
        console.log("Search");
        vm.currentPage = 1;

        $timeout.cancel(vm.textFilterTimeout);
        vm.textFilterTimeout = $timeout(() => {
            console.log("Re-request");
            vm.loadDevices();
        }, 500);
    };

    vm.applyGroups = () => {
        console.log("Trying to apply groups");
        const groups = vm.assignGroupsSelection;
        console.log("Applying ", groups);

        DeviceService.assignDevicesToGroups(vm.selectedDevices, groups).then(
            (response) => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_SUCCESS_BODY'), "success", "ok");

                vm.loadDevices();
            },
            (response) => {
                ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_FAIL_BODY', "error", "remove"));
            }
        );

        vm.assignGroupsSelection = [];
        vm.deselectAll();
    };

    // Add multiple groups to multiple devices
    vm.showGroupSelectDialog = (device) => {
        let devicesToChange = vm.selectedDevices;
        if (device) {
            devicesToChange = [device];
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addGroupModalContent.html',
            controller: 'AddGroupModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: constants,
                        assignMode: true,
                        owner: vm.user
                    };
                }
            }
        });

        modalInstance.result.then((result) => {
            const { action, groups } = result;
            let promise = null;
            if (action == 'add') {
                promise = DeviceService.addDevicesToGroups(devicesToChange, groups);
            } else if (action == 'remove') {
                promise = DeviceService.removeDevicesFromGroups(devicesToChange, groups);
            }

            if (!promise) {
                console.log(`Unexpected action on group assignment: ${action}`);
                return;
            }

            promise.then(() => {
                if (action == 'add') {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_SUCCESS_BODY'), "success", "ok");
                } else {
                    ToastService.showMessage($translate('MSG_REMOVE_DEVICES_SUCCESS_BODY'), "success", "ok");
                }
            }).then(() => {
                vm.loadDevices();
                vm.loadGroups();
            }).then(vm.deselectAll)
                .catch((err) => {
                    console.log("Error assigning devices", err);
                    if (action == 'add') {
                        ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_FAIL_BODY'), "success", "ok");
                    } else {
                        ToastService.showMessage($translate('MSG_REMOVE_DEVICES_FAIL_BODY'), "success", "ok");
                    }
                });
        }, function () {
            //
        });
    };

    vm.exportDevices = (bSendRequest) => {
        var groups = $scope.child.unmappedFilter.group;
        var searchDevUi = $scope.child.unmappedFilter.search_deveui;
        var apps = $scope.child.unmappedFilter.application;
        var searchComment = $scope.child.unmappedFilter.search_comment;
        var deviceStartDate = $scope.child.unmappedFilter.date.startDate;
        var deviceEndDate = $scope.child.unmappedFilter.date.endDate;
        vm.exportQueryStr = '';

        if (vm.selectedDevices.length > 0) {
            var devUisStr = '';
            var selectedDevicesLength = vm.selectedDevices.length;
            for (var i = 0; i < selectedDevicesLength - 1; i++) {
                devUisStr += vm.selectedDevices[i].deveui;
                devUisStr += ',';
            }

            if (selectedDevicesLength > 0) {
                devUisStr += vm.selectedDevices[selectedDevicesLength - 1].deveui;
            }

            vm.exportQueryStr += '&deveuis=';
            vm.exportQueryStr += devUisStr;
        }

        if (groups.length > 0) {
            vm.exportQueryStr += '&group=';
            vm.exportQueryStr += groups[0].groupid;
        }

        if (apps.length > 0) {
            vm.exportQueryStr += '&application=';
            vm.exportQueryStr += apps[0].accountid;
        }

        if (searchComment) {
            vm.exportQueryStr += '&search_comment=';
            vm.exportQueryStr += searchComment;
        }

        if (searchDevUi) {
            vm.exportQueryStr += '&search_deveui=';
            vm.exportQueryStr += searchDevUi;
        }

        if (deviceStartDate && $scope.child.filter.date_changed) {
            vm.exportQueryStr += '&from_date=';
            vm.exportQueryStr += deviceStartDate.toISOString();
        }

        if (deviceEndDate && $scope.child.filter.date_changed) {
            vm.exportQueryStr += '&to_date=';
            vm.exportQueryStr += deviceEndDate.toISOString();
        }

        if (bSendRequest) {
            var url = window.location.protocol;
            var tz = "&tz=" + (new Date()).getTimezoneOffset();
            url += '//';
            url += window.location.host;
            window.location.assign(url + '/export_devices.csv?download_filename=devices.csv' + tz + vm.exportQueryStr);
        }
    }

    vm.exportPayloads = () => {
        vm.exportDevices(false);

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'payloadsDateRangeModal.html',
            controller: 'PayloadsDateRangeCtrl',
            resolve: {
                items: () => {
                    return {
                        exportQueryStr: vm.exportQueryStr
                    }
                }
            },
            size: "sm"
        });
    }

    vm.showSelectAppDialog = (device) => {
        let devicesToChange = vm.selectedDevices;
        if (device) {
            devicesToChange = [device];
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'selectAppsModal.html',
            controller: 'SelectAppsModalCtrl',
            resolve: {
                items: () => {
                    return {}
                }
            },
            size: "lg"
        });

        modalInstance.result.then((data) => {
            const selectedApps = data.apps;
            const deleteMode = data.action === "remove";
            console.log(data);
            console.log(deleteMode ? "Delete" : "add" + " selected ", selectedApps);
            if (deleteMode) {
                DeviceService.removeDevicesFromApps(devicesToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_UNASSIGN_DEVICES_APPS_SUCCESS_BODY'), "success", "ok");
                }).then(() => {
                    vm.loadDevices();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_UNASSIGN_DEVICES_APPS_FAIL_BODY', "error", "remove"));
                });
            } else {
                DeviceService.addDevicesToApps(devicesToChange, selectedApps).then(() => {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_APPS_SUCCESS_BODY'), "success", "ok");
                }).then(() => {
                    vm.loadDevices();
                }).then(vm.deselectAll).catch((err) => {
                    ToastService.showMessage($translate('MSG_ASSIGN_DEVICES_APPS_FAIL_BODY', "error", "remove"));
                });
            }

        }).catch((result) => {
            console.log(result);
        });
    };

    vm.showSendDataDialog = (group) => {
        console.log($uibModal, $uibModalInstance);
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return {
                        isMulticast: true
                    };
                }
            }
        });

        modalInstance.result.then((payload) => {
            console.log("Sending payload to group ", group, payload);
            GroupService.sendMulticast(group, payload.data, payload.port, payload.fcnt).then((result) => {
                ToastService.showMessage($translate('MSG_MULTICAST_SUCCESS', vm.group), "success", "send")
            }).catch((err) => {
                ToastService.showMessage($translate('MSG_MULTICAST_FAIL', vm.group), "error", "remove")
                console.log("Error sending multicast", err);
            });
        });
    }

    vm.resetGroup = function () {
        vm.group = {
            groupid: "",
            title: "",
            comment: ""
        };
    }

    vm.edit = (group) => {
        vm.resetGroup();
        vm.editMode = true;
        vm.addMode = false;
        vm.group = group;

        // TODO: Figure out a better way to do this ?
        $(".modal").animate({ scrollTop: 0 }, "slow");
    }

    vm.delete = (group) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_GROUPS_TITLE'),
            body: $translate('MSG_DELETE_GROUPS_BODY', {
                count: 1
            })
        }).then(() => {
            GroupService.deleteGroup(group.groupid).then(() => {
                ToastService.showMessage($translate('MSG_DELETE_GROUP_SUCCESS', group), "success")
            })
                .then(vm.loadGroups)
                .catch(() => {
                    ToastService.showMessage($translate('MSG_DELETE_GROUP_FAIL', group), "error")
                });
            vm.cancelForm();
        });
    }

    vm.showAddGroupDialog = () => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'addGroupModalContent.html',
            controller: 'AddGroupModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        constants: vm.constants,
                        owner: vm.user
                    }
                }
            }
        });

        modalInstance.result.then((group) => {
            vm.loadDevices();
            vm.loadGroups();
        }, () => {
            vm.loadDevices();
            vm.loadGroups();
        });
    };

    vm.showDeleteDialog = (deveui) => {
        MessageService.showMessage({
            title: $translate('MSG_DELETE_DEVICE_TITLE'),
            body: $translate('MSG_DELETE_DEVICE_BODY', {
                deveui: formatDeveui(deveui)
            })
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending delete request");
                DeviceService.deleteDevice(deveui).then(
                    (response) => {
                        ToastService.showMessage($translate('MSG_DELETE_DEVICE_SUCCESS_BODY', {
                            deveui: formatDeveui(deveui)
                        }), "success");

                        let i;
                        for (i = 0; i < vm.devices.length; i++) {
                            if (vm.devices[i].deveui.toLowerCase() == deveui.toLowerCase()) {
                                vm.devices.splice(i, 1);
                                break;
                            }
                        }


                    },
                    (response) => {
                        ToastService.showMessage($translate('MSG_DELETE_DEVICE_FAIL_BODY'), "error");
                    }
                ).then(vm.loadDevices);
            }
        });
    };

    vm.showSuspendDialog = (deveui,suspend) => {
        MessageService.showMessage({
            title: $translate(suspend ? 'MSG_SUSPEND_DEVICE_TITLE' : 'MSG_UNSUSPEND_DEVICE_TITLE'),
            body: $translate(suspend ? 'MSG_SUSPEND_DEVICE_BODY' : 'MSG_UNSUSPEND_DEVICE_BODY', {
                deveui: formatDeveui(deveui)
            })
        }).then((ok) => {
            if (ok == "ok") {
                console.log("sending suspend request");
                DeviceService.suspendDevice(deveui,suspend).then(
                    (response) => {
                        ToastService.showMessage($translate(suspend ? 'MSG_SUSPEND_DEVICE_SUCCESS_BODY' :
                                                                      'MSG_UNSUSPEND_DEVICE_SUCCESS_BODY', {
                            deveui: formatDeveui(deveui)
                        }), "success");

                        let i;
                        for (i = 0; i < vm.devices.length; i++) {
                            if (vm.devices[i].deveui.toLowerCase() == deveui.toLowerCase()) {
                                vm.devices.splice(i, 1);
                                break;
                            }
                        }


                    },
                    (response) => {
                        ToastService.showMessage($translate(suspend ? 'MSG_SUSPEND_DEVICE_FAIL_BODY' :
                                                                      'MSG_UNSUSPEND_DEVICE_FAIL_BODY'), "error");
                    }
                ).then(vm.loadDevices);
            }
        });
    };

    vm.showAddDialog = (id) => {
        if (id === "addDevModalTrigger") {
            window.deviceModals.showAddModal({});
        } else {
            const modalInstance = $uibModal.open({
                backdrop: "static",
                animation: true,
                templateUrl: 'addDeviceModalContent.html',
                controller: 'AddDeviceModalCtrl',
                size: "lg",
                resolve: {
                    items: () => {
                        return {
                            constants: constants,
                            owner: vm.user
                        };
                    }
                }
            });

            modalInstance.result.then((device) => {
                console.log("Adding device ", device);

                if (!device.expiry_time_downlink) {
                    delete device.expiry_time_downlink;
                }
                if (!device.expiry_time_uplink) {
                    delete device.expiry_time_uplink;
                }

                if (vm.deviceTags) {  // add the tags to the device which is sent to update
                    device.tags = vm.deviceTags;
                }

                DeviceService.addDevice(device).then(
                    (response) => {
                        let deveui = device.deveui.replace(new RegExp("-", 'g'), "");
                        ToastService.showMessage($translate('MSG_DEVICE_ADDED_SUCCESS_BODY', {
                            deveui: formatDeveui(deveui)
                        }), "success");
                        vm.loadDevices();
                    },
                    (response) => {
                        let responseStatusStr = response.status + '';
                        if (response.status === 412) {
                            ToastService.showMessage($translate('MAX_NUM_REACHED', {
                                item: "devices"
                            }), "error");
                        }
                        else if (responseStatusStr.match(/^5[0-9]{2}$/g)) {
                            ToastService.showMessage($translate('MSG_DEVICE_ADDED_FAIL_BODY'),
                                "error"
                            );
                        }
                        else {
                            const msg = `${response.data}`;
                            console.log(msg);
                            ToastService.showNagMessage(msg,
                                "error",
                                "exclamation-sign"
                            );
                            console.log(response.data);
                        }
                    }
                );
            }, () => {
                console.log('Modal dismissed at: ' + new Date());
            });
        }
    };


    vm.showBatchEditDialog = (MigrateModeBulk = false) => {
        if (MigrateModeBulk) {
            if (!vm.DisableMigrateDevicesBtn) {
                window.deviceModals.showEditModal({
                    device: vm.selectedDevices.map(dev => dev.deveui),
                    MigrateDevice: true,
                    MigrateDeviceBulk: true
                });
            }
        } else {
            const devicesToEdit = vm.selectedDevices.map(dev => dev.deveui);
            if (vm.EditDevicesWithParameters && vm.EditDevicesWithProfiles) {
                ToastService.showMessage($translate('MSG_DEVICE_BULK_EDIT'),
                    "error"
                );
            } else if (vm.EditDevicesWithProfiles) {
                window.deviceModals.showEditModal({
                    device: vm.selectedDevices.map(dev => dev.deveui),
                    EditDeviceBulk: true,
                });
            } else {
                const modalInstance = $uibModal.open({
                    backdrop: "static",
                    animation: true,
                    templateUrl: 'addDeviceModalContent.html',
                    controller: 'AddDeviceModalCtrl',
                    size: "lg",
                    resolve: {
                        items: () => {
                            return {
                                constants: constants,
                                owner: vm.user,
                                devices: devicesToEdit,
                                batch: true
                            };
                        }
                    }
                });
    
                modalInstance.result.then((device) => {
                    device.deveui = devicesToEdit.join(",");
                    console.log("Batch edit devices ", devicesToEdit, device);
    
                    DeviceService.updateDevice(device).then((response) => {
                        const FirstLetterUppercase = (text) => {
                            return text.charAt(0).toUpperCase() + text.slice(1);
                        };
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_BULK_SUCCESS_BODY', {
                            device: devicesToEdit.length > 1 ?
                            FirstLetterUppercase($translate('MSG_DEVICE_DEVICES')) :
                            FirstLetterUppercase($translate('MSG_DEVICE_DEVICE') + " " + devicesToEdit) || ""
                        }), "success");
                        vm.deselectAll();
                        vm.loadDevices();
                    }).catch((response) => {
                        ToastService.showMessage($translate('MSG_DEVICE_UPDATE_BULK_FAIL_BODY', {
                            device: devicesToEdit.length > 1 ?
                            $translate('MSG_DEVICE_DEVICES') :
                            $translate('MSG_DEVICE_DEVICE') || "",
                        }),
                            "error"
                        );
                    });
                }, () => {
                    console.log('Modal dismissed at: ' + new Date());
                });
            }
        }
    };

    vm.showPositionDialog = (device) => {
        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'selectPositionOnMapModalContent.html',
            controller: 'SelectPositionOnMapModalCtrl',
            size: "lg",
            resolve: {
                items: () => {
                    return {
                        latitude: device.latitude,
                        longitude: device.longitude,
                        altitude: device.altitude
                    };
                }
            }
        });

        modalInstance.result.then((location) => {
            const updateObj = {
                deveui: device.deveui
            };
            updateObj.altitude = location.alt;
            updateObj.latitude = location.lat;
            updateObj.longitude = location.lng;

            DeviceService.updateDevice(updateObj).then((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                    deveui: formatDeveui(device.deveui)
                }), "success");
                vm.loadDevices();
            }).catch((response) => {
                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY', response),
                    "error"
                );
                response.data && ToastService.showMessage($translate(response.data),
                    "error"
                );
            });

        }, () => {
            console.log('Modal dismissed at: ' + new Date());
        });
    };

    const sendPayloadToDass = (deveui, payload) => {
        const sendobj = {
            deveui: deveui,
            data: btoa(payload.data),
            port: payload.port,
            fcnt: payload.fcnt == null ? undefined : payload.fcnt,
            confirmed: payload.confirmed == null ? undefined : payload.confirmed
        };

        PayloadService.sendDlPayload(sendobj).then(
            (response) => {
                const msg = $translate('MSG_SEND_DL_PACKET_SUCCESS_BODY', {
                    deveui: formatDeveui(deveui),
                    transaction_id: response.data.id
                });
                ToastService.showMessage(msg, "success");
                console.log(msg, response.data);
            },
            (response) => {
                let message = response.data;
                switch (response.status) {
                    case 404:
                        message = $translate("MSG_SEND_DL_PACKET_FAIL_MUST_SPECIFY_FCNT");
                        break;
                    case 403:
                        message = $translate("MSG_SEND_DL_PACKET_FAIL_DEVICE_IS_NOT_ACTIVATED", {
                            deveui: formatDeveui(deveui)
                        });
                        break;
                    case 409:
						if (response.data.match(/^downlink queue full,\W? [0-9]+ (payload|payloads)\W? already enqueued$/)) {
							message = $translate('MSG_SEND_DL_PACKET_FULL_DOWNLINK_QUEUE_BODY', {
								payloads: response.data.match(/\d+/)[0],
							});
                        } else {
							message = $translate('MSG_SEND_DL_PACKET_FAIL_BODY');
						}
                        break;
                    
                    default:
                        message = response.data;
                        break;
                }
                ToastService.showMessage(message, "error");

                console.log(response.data);
            }
        );
    };

    vm.showSendDataDialog = (deveui) => {
        if (deveui == null && !vm.currentDevice || vm.currentDevice === "") {
            MessageService.showMessage({
                title: $translate('MSG_TITLE_OOPS'),
                body: $translate('MSG_SELECT_DEVICE_BODY')
            });
            return;
        }

        if (deveui == null) {
            deveui = vm.currentDevice;
        }

        const modalInstance = $uibModal.open({
            backdrop: "static",
            animation: true,
            templateUrl: 'sendDataModalContent.html',
            controller: 'SendDataModalCtrl',
            size: "",
            resolve: {
                items: () => {
                    return [];
                }
            }
        });

        modalInstance.result.then((payload) => {
            sendPayloadToDass(deveui, payload);
        }, () => {
        });
    };

    vm.deviceTags = null;
    $scope.$on('updateDeviceTags', function(event, data) {  // when there is a change from the child component
        vm.deviceTags = data;

        // with broadcast, again I am informing to the parent component that there is a change so that component can re-render again
        $rootScope.$broadcast('updateDeviceTagsSecond', vm.deviceTags);
    });

    vm.deviceNewTagName = null;
    $scope.$on('updateDeviceNewTagName', function(event, data) {    // when there is a change from the child component
        vm.deviceNewTagName = data;

        // with broadcast, again I am informing to the parent component that there is a change so that component can re-render again
        $rootScope.$broadcast('updateDeviceNewTagNameSecond', vm.deviceNewTagName);
    });

    vm.showEditDialog = (device, MigrateDevice = false) => {
        console.log(`Getting ${device.deveui}`);

        DeviceService.getDevice(device.deveui).then(
            (device) => {
                if(device.device_profile_uuid && device.service_profile_uuid && !MigrateDevice){
                    window.deviceModals.showEditModal({device});
                } else {
                    if (MigrateDevice) {
                        window.deviceModals.showEditModal({device, MigrateDevice: true});
                    } else {

                        const modalInstance = $uibModal.open({
                            backdrop: "static",
                            animation: true,
                            templateUrl: 'addDeviceModalContent.html',
                            controller: 'AddDeviceModalCtrl',
                            size: "lg",
                            resolve: {
                                items: () => {
                                    return {
                                        device,
                                        editMode: true,
                                        constants: constants,
                                        owner: vm.user,
                                    };
                                }
                            }
                        });
    
                        modalInstance.result.then((device) => {
                            console.log(JSON.stringify(device));

                            if (!device.expiry_time_downlink) {
                                delete device.expiry_time_downlink;
                            }
                            if (!device.expiry_time_uplink) {
                                delete device.expiry_time_uplink;
                            }

                            if (vm.deviceTags) {  // add the tags to the device which is sent to update
                                device.tags = vm.deviceTags;
                            }

                            DeviceService.updateDevice(device).then((response) => {
                                ToastService.showMessage($translate('MSG_DEVICE_UPDATE_SUCCESS_BODY', {
                                    deveui: formatDeveui(device.deveui)
                                }), "success");
                                vm.loadDevices();
                            }).catch((response) => {
                                let responseStatusStr = response.status + '';
                                if (responseStatusStr.match(/^5[0-9]{2}$/g)) {
                                    ToastService.showMessage($translate('MSG_DEVICE_UPDATE_FAIL_BODY'), "error");
                                } else {
                                    const ResponseMessage = response.data;
                                    let message = "";
                                    switch (response.status) {
                                        case 400:
                                            const InvalidDeveUI = ResponseMessage.match(/^invalid deveui (.*)$/);
                                            const PayloadDecError = ResponseMessage.match(/^error in payload decoder: (.*)$/);
                                            const SetProfileErr = ResponseMessage.match(/^setting (\w*)_profile_uuid is not allowed$/);
                                            const MandatoryForABPMode = ResponseMessage.match(/^(.*) and devaddr mandatory when switching to ABP activation mode$/);
                                            if (InvalidDeveUI) {
                                                message = $translate("ERROR_MSG__DEVICE_INVALID_DEVEUI", {
                                                    deveui: InvalidDeveUI[1],
                                                });
                                            } else if (ResponseMessage.match(/^field deveui not allowed in update$/)) {
                                                message = $translate("ERROR_MSG_DEVEUI_CANT_BE_UPDATED");
                                            } else if (ResponseMessage.match(/^lora_rx_delay2 must be lora_rx_delay1 \+ 1$/)) {
                                                message = $translate("ERROR_MSG_DEVICE_LORA_RX_DELAY");
                                            } else if (ResponseMessage.match(/^invalid option value$/)) {
                                                message = $translate("ERROR_MSG_DEVICE_INVALID_OPTION_VALUE");
                                            } else if (PayloadDecError) {
                                                message = $translate("ERROR_MSG_DEVICE_PAYLOAD_DECODER", {
                                                    message: PayloadDecError[1],
                                                });
                                            } else if (SetProfileErr) {
                                                message = $translate("ERROR_MSG_DEVICE_SET_PROFILE_UUID_NOT_ALLOWED", {
                                                    profile: SetProfileErr[1],
                                                });
                                            } else if (MandatoryForABPMode) {
                                                message = $translate("ERROR_MSG_MANDATORY_WHEN_SWITCH_TO_ABP", {
                                                    fields: MandatoryForABPMode[1],
                                                });
                                            } else if (ResponseMessage.match(/^invalid MAC version \/ revision$/)) {
                                                message = $translate("ERROR_MSG_DEVICE_INVALID_MAC_VERSION");
                                            } else if (ResponseMessage.match(/^please specify only one device when using the non_owner_access option$/)) {
                                                message = $translate("ERROR_MSG_SPECIFY_ONLY_ONE_DEVICE");
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        case 403:
                                            if (ResponseMessage.match(/^account doesn't have right to modify suspended status$/)) {
                                                message = $translate("ERROR_MSG_CANT_MODIFY_SUSPENDED_STATUS");
                                            } else if (ResponseMessage.match(/^must be user admin to set userid field$/)) {
                                                message = $translate("ERROR_MSG_MUST_HAVE_USERS_ADMIN_TO_SET_USERID");
                                            } else if (ResponseMessage.match(/^must be customer admin to move to other customer$/)) {
                                                message = $translate("ERROR_MSG_DEVICE_MUST_BE_CUSTOMER_ADMIN");
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        case 404:
                                            const UsrIdNotFound = ResponseMessage.match(/^userid (.*) not found$/);
                                            const InvDeveui = ResponseMessage.match(/^unknown groupid or invalid deveui (.*)$/);
                                            if (UsrIdNotFound) {
                                                message = $translate("ERROR_MSG_DEVICE_USER_ID_NOT_FOUND", {
                                                    userid: UsrIdNotFound[1],
                                                });
                                            } else if (InvDeveui) {
                                                message = $translate("ERROR_MSG_DEVICE_INVALID_DEVEUI", {
                                                    deveui: InvDeveui[1],
                                                });
                                            } else if (ResponseMessage.match(/^can't resolve devices profiles$/)) {
                                                message = $translate("ERROR_MSG_CANT_RESOLVE_DEVICE_PROFILES");
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        case 406:
                                            const RetKey = ResponseMessage.match(/^cannot change device-profile. value of (.*) is not compabile.$/);
                                            if (ResponseMessage.match(/^cannot resolve current device-profile$/)) {
                                                message = $translate("ERROR_MSG_CANT_RESOLVE_CURRENT_DEVICE_PROFILES");
                                            } else if (RetKey) {
                                                message = $translate("ERROR_MSG_DEVICE_CANT_CHANGE_DEVICE_PROFILE", {
                                                    key: RetKey[1],
                                                });
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        case 409:
                                            if (ResponseMessage.match(/^Error, setting devaddr and nwkskey on multiple devices not allowed$/)) {
                                                message = $translate("MSG_DEVICE_DEVADDR_NWKSKEY_ON_MULTIPLE_DEVICES_NOT_ALLOWED");
                                            } else if (ResponseMessage.match(/^Error, devaddr and nwkskey pair already registered with other deveui$/)) {
                                                message = $translate("MSG_DEVICE_DEVADDR_NWKSKEY_ALREADY_REGISTERED");
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        case 423:
                                            if (ResponseMessage.match(/^device is busy, please try again later$/)) {
                                                message = $translate("ERROR_MSG_DEVICE_IS_BUSY");
                                            } else {
                                                message = ResponseMessage;
                                            }
                                            break;
                                        default:
                                            message = $translate('MSG_DEVICE_UPDATE_FAIL_BODY', response);
                                            break;
                                    }
                                    ToastService.showMessage(message, "error");
                                }
                            });
                        }, () => {
                            console.log('Modal dismissed at: ' + new Date());
                        });
                    }
                }
            }, () => {
                vm.loading = false;
                console.log("Error loading devices");
            }
        );
        
    };

    vm.setCurrentDevice = (deveui, url) => {
        console.log("setting current device " + deveui + " " + url);
        UserService.setCurrentDevice(deveui).then(
            (response) => {
                if (url != null) {
                    console.log("redirecting");
                    window.location.href = url;
                }
            },
            (response) => {
                MessageService.showMessage({
                    title: $translate('MSG_TITLE_OOPS'),
                    body: "Can ???"
                });

                // Refresh user data
                vm.loadUserData();
            }
        );
    };

    vm.batchDeleteDevices = () => {
        console.log("delete");
        MessageService.showMessage({
            title: $translate('MSG_DELETE_DEVICES_TITLE'),
            body: $translate('MSG_DELETE_DEVICES_BODY', {
                count: vm.selectedDevices.length
            })
        }).then((ok) => {
            DeviceService.deleteDevices(vm.selectedDevices).then((response) => {
                ToastService.showMessage($translate('MSG_DELETE_DEVICES_SUCCESS_BODY', vm.selectedDevices), "success");
                vm.loadDevices();
            }).catch((response) => {
                console.log("Failed deleting devices", response);
                ToastService.showMessage($translate('MSG_DELETE_DEVICES_FAIL_BODY', vm.selectedDevices), "error");
            });
            vm.loadDevices();
        }).catch(() => {
            console.log("Failed deleting devices");
        });
    };

    vm.loadDevices = () => {
        if (!vm.reloadData) return;
        var reloadPromise = vm.reloadData();
        if (reloadPromise) {
            vm.workingCount += 1;
            reloadPromise.then(() => {
                vm.workingCount -= 1;

            }).catch(err => {
                vm.workingCount -= 1;
            });
        }
    };

    vm.loadGroups = () => {
        vm.workingCount += 1;

        return GroupService.getGroups().then(
            (groups) => {

                vm.groups = groups;
                vm.workingCount -= 1;
            },
            () => {
                console.log("Error loading groups");
                vm.workingCount -= 1;
            }
        );
    };

    vm.loadApps = () => {
        vm.workingCount += 1;

        return AppService.getApps().then(
            (apps) => {

                vm.apps = apps;
                vm.workingCount -= 1;
            },
            (err) => {
                console.log("Error loading apps", err);
                vm.workingCount -= 1;
            }
        );
    };

    vm.loadUserData = () => {
        vm.workingCount += 1;
        UserService.getUserData().then(
            (userData) => {
                vm.user = userData.user;
                vm.can_export = (vm.user._license & 0x80) == 0x80;
                vm.currentDevice = userData.currentDevice;
                vm.workingCount -= 1;
            },
            (response) => {
                console.log("error getting user data:" + response.status);
                vm.workingCount -= 1;
            }
        )
    };

    vm.actionMenuOpened = (device) => {
        DeviceService.getDevice(device.deveui).then(dev => {
            if (device.device_profile_uuid) {
                if (vm.user.can_list_device_profile) {
                    DeviceService.getDeviceProfile(device.device_profile_uuid)
                    .then(res => {
                        device.activation = res.data.content.activation;
                    }).catch(err => {
                        console.log(err);
                        device.status = err.status;
                    });
                }
            } else {
                device.activation = dev.activation;
            }
        });
    }

    vm.deviceDataMapper = (device) => {
        device.last_reception_str = dateString(device.last_reception);
        return device;
    };

    vm.initDataTable = () => {
        vm.actions = [{
            type: "action",
            text: "MY_DEVICES_ACTION_EDIT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showEditDialog,
            id: "addDevModalTrigger"
        }, {
            type: "action",
            text: "MIGRATE_DEVICE",
            image: "/theme/images/migrate.png",
            action: (device) => vm.showEditDialog(device, (true)),
            visible: (entry, device) => vm.constants.enable_device_migration_to_profiles,
            disabled: (entry, device) => device.device_profile_uuid && device.service_profile_uuid
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_SEND",
            icon: "fa fa-paper-plane-o fa-fw",
            action: (dev) => vm.showSendDataDialog(dev.deveui),
            visible: (entry, device) => (vm.user.can_list_device_profile ? (device.activation === "abp" || device.device_status === 3 || device.activation === "ABP" || device.status === 400) : true)
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_DATA",
            icon: "fa fa-database fa-fw",
            action: dev => vm.setCurrentDevice(dev.deveui, '/data_list.html')
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_POSITION",
            icon: "fa fa-map-o fa-fw",
            action: vm.showPositionDialog
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "GROUP_ASSIGNMENT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showGroupSelectDialog
        }, {
            type: "action",
            text: "APP_ASSIGNMENT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showSelectAppDialog
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_UNSUSPEND",
            icon: "fa fa-exclamation-triangle fa-fw",
            visible: (entry, device) => device.suspended && (vm.user.is_customer && vm.user.customer_admin ||
                                                             !vm.user.is_customer && vm.user.administrator),
            action: (dev) => vm.showSuspendDialog(dev.deveui, false)
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_SUSPEND",
            icon: "fa fa-exclamation-triangle fa-fw",
            visible: (entry, device) => !device.suspended && (vm.user.is_customer && vm.user.customer_admin ||
                                                              !vm.user.is_customer && vm.user.administrator),
            action: (dev) => vm.showSuspendDialog(dev.deveui, true)
        }, {
            type: "action",
            text: "MY_DEVICES_ACTION_DELETE",
            icon: "fa fa-trash-o fa-fw",
            visible: (entry, device) => vm.user.can_register,
            action: (dev) => vm.showDeleteDialog(dev.deveui)
        }];

        vm.bulkActions = [{
            type: "action",
            text: "MY_DEVICES_ACTION_EDIT_DEVICES",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showBatchEditDialog
        }, {
            type: "action",
            text: "MIGRATE_DEVICES",
            image: "/theme/images/migrate.png",
            action: () => vm.showBatchEditDialog(true),
            visible: (entry, device) => vm.constants.enable_device_migration_to_profiles,
            disabled: (entry, device) => vm.DisableMigrateDevicesBtn
        },
        {
            type: "action",
            text: "MY_DEVICES_ACTION_DELETE_DEVICES",
            icon: "fa fa-trash-o fa-fw",
            action: vm.batchDeleteDevices
        }, {
            type: "separator"
        }, {
            type: "action",
            text: "GROUP_ASSIGNMENT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showGroupSelectDialog
        }, {
            type: "action",
            text: "APP_ASSIGNMENT",
            icon: "fa fa-pencil-square-o fa-fw",
            action: vm.showSelectAppDialog
        }];

        vm.columns = [{
            key: "deveui",
            type: "text",
            title: "DEVEUI",
            style: {
                minWidth: "230px"
            },
            filterable: true,
            filterField: "search_deveui",
            filterType: "text",
            filterSearchMinLength: 1,
            filterParams: {
                mapper: x => x.replace(new RegExp("-", 'g'), ""),
            },
            sortable: true,
            sortKey: "sort_by_deveui",
            render: (x) => formatDeveui(x.deveui)
        }, {
            key: "device_status",
            title: "STATUS",
            type: "icon_with_tooltip",
            render_icon: statusSymbol,
            render_tooltip: statusTooltip,
            filterable: false,
            filterField: "",
            filterType: "",
            filterParams: {
                mapper: x => x + "!123"
            },
        }, {
            key: "comment",
            title: "COMMENT",
            type: "text",
            filterable: true,
            filterField: "search_comment",
            filterType: "text",
            sortable: true,
            sortKey: "sort_by_comment",
            filterParams: {
            }
        }, {
            key: "groups",
            title: "MY_DEVICES_GROUPS",
            type: "text_with_tooltip",
            tooltip_props: {
                tooltipClass: "increase-popover-width",
                appendToBody: true,
                trigger: "mouseenter"
            },
            render_tooltip: x => (x.groups).replace(/,/g, ', '),
            render: x => elipsis(x.groups, 20),
            filterable: true,
            filterField: "group",
            filterType: "multiselect",
            filterParams: {
                data: vm.groups,
                mapper: x => x.length > 0 ? x[0].groupid : undefined,
                selectSettings: {
                    displayProp: "groupid",
                    searchField: "groupid",
                    dynamicTitle: false,
                    enableSearch: true,
                    selectionLimit: 1,
                    scrollableHeight: '300px',
                    scrollable: true
                },
                translations: {
                    dynamicButtonTextSuffix: $translate("GROUPS_SELECTED_SUFFIX"),
                    buttonDefaultText: $translate("GROUPS_SELECT_BUTTON"),
                    checkAll: $translate("GROUPS_SELECT_ALL"),
                    uncheckAll: $translate("GROUPS_SELECT_NONE"),
                }
            }
        }, {
            key: "applications",
            title: "MY_DEVICES_APPLICATIONS",
            type: "text_with_tooltip",
            tooltip_props: {
                tooltipClass: "increase-popover-width",
                appendToBody: true,
                trigger: "mouseenter"
            },
            render_tooltip: x => (x.applications).replace(/,/g, ', '),
            render: x => elipsis(x.applications, 20),
            filterable: true,
            filterField: "application",
            filterType: "multiselect",
            filterParams: {
                data: vm.apps,
                mapper: (x) => x.length > 0 ? x[0].accountid : undefined,
                selectSettings: {
                    displayProp: "accountid",
                    searchField: "accountid",
                    dynamicTitle: false,
                    enableSearch: true,
                    selectionLimit: 1,
                    scrollableHeight: '300px',
                    scrollable: true
                },
                translations: {
                    dynamicButtonTextSuffix: $translate("APPS_SELECTED_SUFFIX"),
                    buttonDefaultText: $translate("APPS_SELECT_BUTTON"),
                    checkAll: $translate("APPS_SELECT_ALL"),
                    uncheckAll: $translate("APPS_SELECT_NONE"),
                }
            }
        }, {
            key: "last_reception_str",
            title: "LAST_SEEN",
            type: "text",
            filterable: true,
            filterField: "date",
            filterType: 'daterange',
            filterParams: {
                startField: "from_date",
                endField: "to_date",
                mapper: (x) => x && x.format()
            },
            sortable: true,
            sortKey: "sort_by_date"
        }];
        // this is needed for: filtering by groups, applications and Last Seen - Date Range
        Promise.all([vm.loadGroups(), vm.loadApps()]).then(
            function () {
                vm.loadDevices();
            }
        );
    };

    vm.DisableMigrateDevicesBtn = false;
    vm.EditDevicesWithProfiles = false;
    vm.EditDevicesWithParameters = false;

    vm.tableSelectionChanged = (dev) => {
        vm.selectedDevices = dev;

        if (dev.length > 0) {
            let DevicesWithProfiles = 0;
            let DevicesWithParameters = 0;
            dev.map((device) => {
                if (device.device_profile_uuid && device.service_profile_uuid) {
                    DevicesWithProfiles = DevicesWithProfiles + 1;
                } else {
                    DevicesWithParameters = DevicesWithParameters + 1;
                }
            });
            if (DevicesWithProfiles === 0) {
                vm.EditDevicesWithProfiles = false;
                vm.DisableMigrateDevicesBtn = false;
            } else {
                vm.EditDevicesWithProfiles = true;
                vm.DisableMigrateDevicesBtn = true;
            }

            if (DevicesWithParameters === 0) {
                vm.EditDevicesWithParameters = false;
            } else {
                vm.EditDevicesWithParameters = true;
            }
        }
    };

    vm.reloadData = () => {
        console.log("This should get overwritten");
    };

    vm.checkFlag = (license, bit) => {
        if (license && bit) {
            let flag = false;
            switch (bit) {
                case 1:
                    flag = (license & 0x1) !== 0;
                    break;
                case 2:
                    flag = (license & 0x2) !== 0;
                    break;
                default:
                    flag = false;
                    break;
            }
            return flag;
        } else {
            return false;
        }
    };

    vm.loadUserData();
    Promise.all([vm.loadGroups(), vm.loadApps()]).then(vm.initDataTable);
    $scope.child = {};
    $scope.loadTableProgress = 0;
    $scope.$watch("loadTableProgress", () => {
        vm.scanning        = $scope.child.loadingPages ? $scope.child.scanned : 0;
        vm.numberOfDevices = $scope.child.pageData && $scope.child.pageData.total || 0;
        // console.log("got progress update");
    }, true);
};

app.controller("DeviceListController", ["DeviceService", "AppService", "UserService", "GroupService", "MessageService", "ToastService", "PayloadService", "DataService", "$filter", "$uibModal", "$scope", "$rootScope", "$timeout", DeviceListController]);
